import * as subscriptions from '../../graphql/subscriptions';

import Amplify, { API, graphqlOperation } from 'aws-amplify';

import React, { useEffect, useState } from 'react'

function useAppNotifications() {
    const [notificationCount, setNotificationsCount] = useState(0);
    useEffect( () => {
        const fetchNotificationsCount =  async () => {
            await API.graphql(
                graphqlOperation(subscriptions.onCreateNotificationsLog)
            ).subscribe({
                next: ({ provider, value }) => {
                    setNotificationsCount(count => (value.data.onCreateNotificationsLog) ? count + 1 : 0);
                },
                error: error => console.warn(error)
            });
        }
        fetchNotificationsCount();
    }, [])

    return notificationCount;
}

export default useAppNotifications
