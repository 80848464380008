import { Drawer, Hidden, Paper } from "@material-ui/core";
import React, { Fragment } from "react";
import {
  setSidebarFooter,
  setSidebarHover,
  setSidebarToggle,
  setSidebarToggleMobile,
  setSidebarUserbox
} from "../../reducers/ThemeOptions";

import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarHeader from "../SidebarHeader";
import SidebarMenu from "../SidebarMenu";
import SidebarUserbox from "../SidebarUserbox";
import clsx from "clsx";
import { connect } from "react-redux";
import navItems from "./navItems";
import { useUser } from "../../providers/UserProvider";
const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarHover,
    setSidebarHover,
    sidebarToggle,
    sidebarUserbox,
    sidebarShadow
  } = props;

  const { user } = useUser();
  let navigation = '';
  if (user?.accountType === 'SuperAdmin' || user?.accountType === 'SuperAdminUser') {
    navigation = navItems.map(list => { return { ...list, content: list.content.filter(item => item.label === 'Vendor Management' || item.label === 'User Management' || item.label === 'Products' || item.label === 'Rewards' || item.label === 'Dashboard') } })
  } else if (user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser') {
    navigation = navItems.map(list => { return { ...list, content: list.content.filter(item => item.label === 'User Management' || item.label === 'Products' || item.label === 'Rewards' || item.label === 'Dashboard' || item.label === 'Question Management' || item.label === 'Question Workflow' || item.label ===  'Campaigns' || item.label ===  'Customer Category' || item.label === 'Customer Management') } })
  } else {
    navigation = navItems.map(list => { return { ...list, content: list.content.filter(item => (item.label != 'Vendor Management' && item.label != 'User Management' && item.label != 'Products' && item.label != 'Question Management' && item.label != 'Question Workflow' && item.label != 'Campaigns' && item.label != 'Customer Category' && item.label != 'Customer Management')) } })
  }

  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div
      className={clsx({
        "app-sidebar-nav-close": sidebarToggle && !sidebarHover
      })}
    >
      {navigation.map((list, index) => (
        <SidebarMenu
          component="div"
          key={index}
          pages={list.content}
          title={list.label}
          user={user}
          setSidebarToggleMobile={setSidebarToggleMobile}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
        >
          <SidebarHeader />
          <PerfectScrollbar>
            {sidebarUserbox && <SidebarUserbox />}
            {sidebarMenuContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx("app-sidebar-wrapper", {
            "app-sidebar-wrapper-close": sidebarToggle,
            "app-sidebar-wrapper-open": sidebarHover,
            "app-sidebar-wrapper-fixed": sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div
            className={clsx({
              "app-sidebar-menu": sidebarFixed,
              "app-sidebar-collapsed": sidebarToggle && !sidebarHover
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarUserbox && <SidebarUserbox />}
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarHover: enable => dispatch(setSidebarHover(enable)),
  setSidebarFooter: enable => dispatch(setSidebarFooter(enable)),
  setSidebarUserbox: enable => dispatch(setSidebarUserbox(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
