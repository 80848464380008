import { Button, FormGroup } from "@material-ui/core";
import { Form, Formik } from "formik";
import {
  updateCoupon,
  listCouponsByProduct,
  listCoupons,
  getCoupon
} from "../../containers/curd/coupon";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactSelectChip from "../../../common/components/ReactSelectChip";
import { listVendorProduct } from "../../containers/curd/vendorProducts";
import { createProductCoupon, deleteProductCoupon } from "../../containers/curd/ProductCouponRelation";

const useStyles = makeStyles({
  root: {
    border: "1.5px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 10,
    padding: "10px 14px 18.5px 14px",
  },
  error: {
    color: "#f83245",
  },
});

function AssignCoupon({
  user,
  closeModal,
  setCreatedItem,
  editable,
  setUpdatedItem,
  setSnackBarDetails,
  rowdata
}) {
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  const defaultOption = [];
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [oldselected, setoldSelected] = useState([])
  const [products, setProducts] = useState([]);
  const [oldOptions, setoldOptions] = useState([]);

  const handleChangeProduct = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  const resetSelectedOption = () => {
    setSelectedOption(defaultOption);
  };

  const getAllCoupons = async (selected, allData) => {
    console.log(selected);
    let getOptionofData = await allData.filter((item, i) => {
      if (!selected.includes(item.id)) {
        console.log(selected.includes(item.id));
        return item.id;
      }
    });
    return getOptionofData;
  };

  const selectOldOption = async (arr, productid) => {
    console.log(arr, productid)
    let selectOldOpt = await arr.filter((item) => item.productID === productid).map((item) => item.id)
    setoldOptions(state => [...state, selectOldOpt[0]])
    return
  }

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const allValue = [];
      const tempOldOpt = []
      if (editable) {
        let productID = editable.id;
        let selected = await listVendorProduct(user.id);
        console.log("RowDaa", rowdata)
        let selectoption = await Promise.all(rowdata.allCoupons.items.map(async (item) => {
          console.log("Name", item.couponID)
          const name = await getCoupon(item.couponID)
          if (name) {
            return {
              value: item.id,
              label: name.name,
            };
          }
        }));

        const filteredOpt = selectoption.filter((item) => item != undefined)
        setSelectedOption(filteredOpt);
        setoldSelected(filteredOpt)
        tempOldOpt.push(...filteredOpt)
        selectoption.map((item, i) => {
          allValue.push(item?.value);
        });
      }
      let listCoupon = await listCoupons(user.id);
      const CouponsOptions = await getAllCoupons(allValue, listCoupon);
      let option = CouponsOptions.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      let remainingOpt = option.filter((opt)=> !tempOldOpt.some((data) => opt.label === data.label)) 
      setProducts(remainingOpt);
      setLoading(false);
    };
    fetchList();
  }, [editable]);
  const classes = useStyles();

  const handleDelete = (i) => async () => {
    let itemToPush = {};
    let dataToUpdate = {};
    dataToUpdate.productID = "null";

    selectedOption.map((item, index) => {
      if (i === index) {
        itemToPush = item;
      }
    });

    let oldOptions = [];
    oldOptions.push(itemToPush.value);
    setoldOptions(state => [...state, itemToPush.value]);
    setProducts(state => [...state, itemToPush]);
    setSelectedOption((chips) => chips.filter((chip, index) => i !== index));
  };

  const istobeUpdate = async (selectedOption, oldOptions) => {
    if (!editable) return;
    const newIds = [];
    if (selectedOption.length < oldOptions.length) {
      return "deleted";
    } else {
      await selectedOption.map((item, i) => {
        if (!oldOptions.includes(item.value)) {
          newIds.push(item.value);
        }
      });
      if (newIds.length > 0) {
        return "updated";
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            setSubmitted(true);
            console.log("~ selectedOption", selectedOption, oldOptions);
            const toCreate = selectedOption?.filter(({ value: id1 }) => !oldselected.some((id2) => id1 === id2.value))
            const toBeDeleted = oldselected.filter(({ value }) => oldOptions.includes(value)).map((item) => item.value)
            const stat = await istobeUpdate(toCreate, toBeDeleted);

            if (!stat) {
              setSubmitted(false);
              closeModal();
              return;
            }
            if (!toCreate.length === 0) {
              return;
            }

            let dataToUpdate = {};
            dataToUpdate.productID = editable.id;
            console.log("~ selectedOption", toCreate);
            if (selectedOption.length < 1) {
              setUpdatedItem([]);
            }
            if (toBeDeleted.length > 0) {
              console.log("Deleting", toBeDeleted)
              await deleteProductCoupon(toBeDeleted);
            }
            let isUpdated = await Promise.all(
              toCreate?.map(async (item) => {
                let input = {
                  couponID: item.value,
                  vendorProductID: editable.id,
                };
                const check = await createProductCoupon(input);
                return check
              })
            );
            console.log("Isupdated", isUpdated)
            if (isUpdated) {
              setUpdatedItem(selectedOption);
              resetSelectedOption();
              if (stat === "deleted") {
                setSnackBarDetails({
                  message: "Coupon Deleted successfully",
                  severity: "error",
                  links: [],
                });
              } else if(stat === 'updated') {
                setSnackBarDetails({
                  message: `Coupon Added successfully`,
                  severity: "success",
                  links: [],
                });
              }

              setSubmitted(false);
              closeModal();
            }

          } catch (error) {
            setSnackBarDetails({
              message: error.message,
              severity: "error",
              links: [],
            });
          }
        }}
      >
        {({ values, errors, touched, isValid, handleReset }) => (
          <Form>
            <FormGroup>
              <FormGroup>
                <ReactSelectChip
                  label="Search Coupon"
                  options={products}
                  setOptions={setProducts}
                  selectedOptions={selectedOption}
                  setSelectedOptions={setSelectedOption}
                  onDelete={handleDelete}
                />
              </FormGroup>

              {submitted && selectedOption.value === "" && (
                <p className={classes.error}>Coupon is required.</p>
              )}
            </FormGroup>
            <Button
              type="submit"
              className="mt-4 mb-2"
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AssignCoupon;
