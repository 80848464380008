import { css } from "@emotion/react";
import React from "react";
import img from "../../assets/hero-image.jpg";

export const LandingSection = ({ children }) => (
  <section
    css={css`
      margin-top: 0;
      height: 100vh;
      filter: grayscale(80%);
      background-image: url(${img});
      background-size: cover;
      background-position: top;
      position: relative;
    `}
  >
    <div
      css={css`
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 15px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
    >
      <h1
        css={css`
          font-weight: 300;
          font-size: 2em;
          text-align: center;
        `}
      >
        Symptom Tracker
      </h1>
    </div>
  </section>
);
