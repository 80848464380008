import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Amplify, { Auth, Storage } from 'aws-amplify'
import awsconfig from '../../../aws-exports'
const IMG_URL = process.env.REACT_APP_S3_BASE_URL;

Amplify.configure(awsconfig)
Auth.configure(awsconfig)


const useStyles = makeStyles({
  inputcontainer: { display: 'flex', borderColor: 'rgba(122, 123, 151, 0.3) !important', height: '55px', alignItems: 'center' },
  inputField: { display: 'flex', alignItems: 'center' }
})

const UploadFile = (props) => {

  const {setUploadedImg, uploadedImg} = props

  const [state, setState] = React.useState({
    companylogo: null,
    files:null
  })

  const uploadImage = async() => {
    try {
      if(!state.files?.files[0].name) return
      const fileInfo = await Storage.put(state.files?.files[0].name, state.files?.files[0], {
        contentType: "image/png", // contentType is optional
      });
      if(fileInfo.key){
        const StorageUrl = `${IMG_URL}${fileInfo.key}`
        setUploadedImg(StorageUrl)
      }
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  const styles = useStyles()
  React.useEffect(() => {
    let url =  state.companylogo ? window.URL.createObjectURL(state.companylogo) : "default.png"
    uploadImage()
  }, [state.companylogo])

  const img_url =  state.companylogo ? window.URL.createObjectURL(state.companylogo) : "default.png"

  return (
    <div className="w-100">
       <div className='d-flex justify-content-between w-100 align-items-center'>
      <div className={`input-group mb-2 pl-3 py-2 rounded border border-1  ${styles.inputcontainer}`}>
        <div className="custom-file">
          <input 
            type="file" 
            name="fileuploadImg" 
            onChange={(e) => {
              e.target.files.length > 0 && setState({ companylogo: e.target.files[0], files:e.target }) 
            }} 
            className={`custom-file-input ${styles.inputField}`} 
            id="inputGroupFile02" 
          />
        </div>
      </div>
      {state.companylogo && (
        <div>
          <img className="preview img-thumbnail rounded mb-3" width="100px" height="100px" src={img_url} alt="" />
        </div>
      )}
    </div>
      {state.companylogo && !uploadedImg && <p className="text-warning">Uploading...</p>}
      {uploadedImg && <p className="text-success">File Uploded successfully</p>}
    </div>
   
  )
}

export default UploadFile