import * as Yup from 'yup';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { createPreference, listPreferences, updatePreference } from './curd/preference';

import CircularProgress from "@material-ui/core/CircularProgress";
import DoxSnackBar from '../../common/components/DoxSnackBar';
import FormikCheckboxField from '../../common/components/FormikCheckboxField';
import FormikSelect from '../../common/components/FormikSelect';
import React, { useEffect, useState } from 'react'

const validationSchema = Yup.object({
    frequency: Yup.string().required("response frequency is required."),
    questions: Yup.string().required("questions is required."),
    remind_me: Yup.string().required("remind me is required."),
})

const frequency = [
    { value: 'Daily', label: 'Daily' },
    { value: "Every other day", label: "Every other day" },
    { value: 'Weekly', label: ' Weekly' }
];

const remind_me = [
    { value: 'Once per day', label: 'Once per day' },
    { value: 'Twice per day', label: 'Twice per day' },
    { value: 'Until I respond ', label: 'Until I respond' },

];

const Preference = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [snackBarDetails, setSnackBarDetails] = useState({
        message: null,
        links: [],
        severity: "error",
    });

    const [id, setId] = useState(null);
    useEffect( () => {

        const fetchListPreferences = async () => {
            let defaultValue = {
                user: props.user.id,
                frequency: "Daily",
                questions: "1",
                sendedNotifications: 0,
                notification_on: {
                    email: false,
                    sms: false,
                    app: true
                },
                remind_me: "Once per day"
            }
            let { data } = await listPreferences(props.user.id);
            let res = data.listPreferences.items[0];
            if (!res) {
                await createPreference(defaultValue);
            }

            setId(res ? res.id : null);
            setInitialValues((val) => res ? {
                frequency: res.frequency,
                questions: res.questions,
                notification_on: res.notification_on,
                remind_me: res.remind_me

            } : defaultValue)
            setIsLoading(false)
        }
        fetchListPreferences();
    }, [])
    return (
        <>
            <DoxSnackBar snackBarDetails={snackBarDetails} />
            <Box>
                <Card style={{ minHeight: '550px' }}>
                    <CardHeader component={() => <h3 className="ml-4 mt-4">Preferences</h3>} />
                    <CardContent className="px-sm-5">
                        {!isLoading ? <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={async (values) => {
                                try {
                                    values.notification_on.app = true;
                                    let { data } = await updatePreference({ ...values, id, user: props.user.id });
                                    let res = data.updatePreference;
                                    setInitialValues({
                                        frequency: res.frequency,
                                        questions: res.questions,
                                        notification_on: res.notification_on,
                                        remind_me: res.remind_me

                                    })
                                    setSnackBarDetails({
                                        message:
                                            "Preference update successfully",
                                        severity: "success",
                                        links: [],
                                    });

                                } catch (error) {
                                    setSnackBarDetails({
                                        message: error.message,
                                        severity: "error",
                                        links: [],
                                    });
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isValid,
                                handleReset
                            }) => (
                                <Form>
                                    <FormGroup className="mb-3">
                                        <FormikSelect
                                            label="Response frequency"
                                            name='frequency'
                                            value={values}
                                            options={frequency}
                                        />
                                        {touched.frequency && errors.frequency && <small className="text-danger">{errors.frequency}</small>}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikSelect
                                            label={`Questions/${values.frequency != "" ? values.frequency : 'day'}`}
                                            name='questions'
                                            value={values}
                                            options={new Array(20).fill("").map((v, i) => ({ value: i + 1, label: i + 1 }))}
                                            
                                        />
                                        {touched.questions && errors.questions && <small className="text-danger">{errors.questions}</small>}
                                    </FormGroup>
                                    <label className="mt-3 mb-0"> Notification On:</label>
                                    <FormGroup className="ml-5">
                                        <FormikCheckboxField
                                            name="notification_on.email"
                                            color="secondary"
                                            trueValue={true}
                                            falseValue={false}
                                            controlLabel={"Email"}
                                        ></FormikCheckboxField>
                                        <FormikCheckboxField
                                            name="notification_on.sms"
                                            color="secondary"
                                            trueValue={true}
                                            falseValue={false}
                                            controlLabel={"Text/SMS"}
                                        ></FormikCheckboxField>
                                        <FormikCheckboxField
                                            name="notification_on.app"
                                            color="secondary"
                                            disabled
                                            trueValue={true}
                                            falseValue={false}
                                            controlLabel={"App"}
                                        ></FormikCheckboxField>
                                    </FormGroup>


                                    <FormGroup>
                                        <FormikSelect
                                            label="Remind me"
                                            name='remind_me'
                                            value={values}
                                            options={remind_me}
                                        />
                                        {touched.remind_me && errors.remind_me && <small className="text-danger">{errors.remind_me}</small>}
                                    </FormGroup>
                                    <Button
                                        type="submit"
                                        className="mt-4 mb-2"
                                        variant="contained"
                                        color="primary"
                                        disabled={!isValid}
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                            : <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                <CircularProgress />
                            </div>
                        }
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default Preference;
