export default (theme) => ({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowHelperText: {
    marginTop: 0,
  },
  columnContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
});
