import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import NoteIcon from '@material-ui/icons/Note';
import { mergeClasses } from '@material-ui/styles';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  //   const [state, setState] = React.useState({
//     top: false,
//     left: false,
//     bottom: false,
//     right: false,
//   });

//   const toggleDrawer = (anchor, open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

// const pages = [
//     {
//       title: 'Dashboard',
//       href: '/dashboard',
//       icon: <DashboardIcon />
//     },
//     {
//       title: 'Users',
//       href: '/users',
//       icon: <PeopleIcon />
//     },
//     {
//       title: 'Products',
//       href: '/products',
//       icon: <ShoppingBasketIcon />
//     },
//     {
//       title: 'Authentication',
//       href: '/sign-in',
//       icon: <LockOpenIcon />
//     },
//     {
//       title: 'Typography',
//       href: '/typography',
//       icon: <TextFieldsIcon />
//     },
//     {
//       title: 'Icons',
//       href: '/icons',
//       icon: <ImageIcon />
//     },
//     {
//       title: 'Account',
//       href: '/account',
//       icon: <AccountBoxIcon />
//     },
//     {
//       title: 'Settings',
//       href: '/settings',
//       icon: <SettingsIcon />
//     }
//   ];

  return (
    <div>
        <>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon fontSize="large" />
          </IconButton>
          <Drawer 
            anchor="left" 
            open={open} 
            onClose={() => setOpen(false)}>
            <div
                className={ clsx(classes.list, classes.fullList)}
                role="presentation"
                onClick={() => setOpen(true)}
                onKeyDown={() => setOpen(false)}
            >
            <List>
                {['Products', 'Treatments', 'Conditions', 'Placeholder'].map((text, index) => (
                <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <AddShoppingCartIcon /> : <AssignmentIcon /> }</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['Admin', 'Users', 'Reports'].map((text, index) => (
                <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
                ))}
            </List>
            </div>
          </Drawer>
        </>
      
    </div>
  );
}
