/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from "react";
import Header from "../../common/components/header";
import Layout from "../../common/components/layout.js";
// import API, { graphqlOperation } from "@aws-amplify/api";
// import * as queries from "../../graphql/queries";
// import * as mutations from "../../graphql/mutations";
import { useQuestion } from "../components/useQuestion";
import { useForm } from "../components/useForm";
import { QuestionContext } from "../components/questionContext";

const QuestionContainer = () => {
  const [values, handleChange] = useForm({
    questionID: "",
    // category: [],
    // followupToID: "",
    // question: [],
    // possibleResponses: [],
  });

  // const { question, setQuestion } = useContext(QuestionContext);

  const [count, setCount] = useState(() =>
    JSON.parse(localStorage.getItem("count"))
  );

  // const { data, loading } = useQuestion("DOX-0001");
  //    useQuestion(questionItem);
  //    let someResponse = JSON.parse()

  //    let question = JSON.parse()
  const { questions } = useQuestion('DOX-0002');

  useEffect(() => {
    localStorage.setItem("count", JSON.stringify(count));
  }, [count]);

  return (
    <>
      <div>
        <Header></Header>
        {/* <div>{!question ? "loading..." : question} </div> */}
        <div>count: {count}</div>
        <button onClick={() => setCount(c => c + 1)}>increment</button>
        {/* <div> this question: {questionHook.question.data.listQuestionss.items} </div> */}
      </div>
      <div>
        <h4></h4>
      </div>
    </>
  );
};

export default QuestionContainer;
