import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import * as customQueries from "./customQueries/queries";
import API, { graphqlOperation } from "@aws-amplify/api";

export const createCustomerCategory = async (input) => {
  
  let { data: { createCustomerCategory } } = await API.graphql(
    graphqlOperation(mutations.createCustomerCategory, { input })
  );
  return createCustomerCategory;
}

export const listCustomerCategories = async (userId) => {
  let params = {
    filter: {
      createdBy: {
        eq: userId
      }
    }
  };
  let { data: { listCustomerCategories } } = await API.graphql(
    graphqlOperation(customQueries.listCustomerCategories, params)
  );
  return listCustomerCategories.items;
}

export const deleteCustomerCategory = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    const id = coupons[index];
    let { data: { deleteCustomerCategory } } = await API.graphql(
      graphqlOperation(mutations.deleteCustomerCategory, { input: { id } })
    )
    promises.push(deleteCustomerCategory);
  }
  return Promise.all(promises);
}

export const updateCustomerCategory = async (input) => {
  let { data: { updateCustomerCategory } } = await API.graphql(
    graphqlOperation(mutations.updateCustomerCategory, { input })
  );
  return updateCustomerCategory;
}

export const getCustomerCategory = async (input) => {
  let { data: { getCustomerCategory } } = await API.graphql(
    graphqlOperation(customQueries.getCustomerCategory, { id: input })
  );
  return getCustomerCategory;
}

