import * as Yup from 'yup';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react'
import ReactSelect from '../../../common/components/ReactSelect';
import { createCustomerCategoryRelation, deleteCustomerCategoryRelation } from '../../containers/curd/customerCategoryRelation';


const validationSchema = Yup.object({
})


function AssignCategory({ options, user, closeModal, setCreatedItem, editable, setUpdatedItem, setSnackBarDetails }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
    });

    
    const defaultOption = {
        value: '',
        label: 'Search Product'
    }
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [submitted, setSubmitted] = useState(false);

    const demoUser = [
        {
            value: '1',
            label: 'user 1'
        },
        {
            value: '2',
            label: 'user 2'
        },

    ]
    const [couponScannedUser, setCouponScannedUser] = useState(demoUser);

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [sendCoupon, setSendCoupon] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    
    
    const handleSendCoupon = (e) => {
        const value = e.target.checked;
        setSendCoupon(value);
    }

    const handleSelectAll = (e) => {
        const value = e.target.checked;
        if(value) {
            setSelectedCategory(options);
        }else{
            setSelectedCategory([]);
        }
        setSelectAll(value);
    }

    useEffect(() => {
        if(selectedCategory.length > 0 && selectedCategory.length === options.length){
            setSelectAll(true);
        }else{
            setSelectAll(false);
        }
    },[selectedCategory])

    const resetSelectedOption = () => {
        setSelectedOption(defaultOption);
        setSelectedCategory([]);
    }

    useEffect( () => {
        const fetchList = async () => {
            if(options){
                console.log('~ editable', editable);
                let categories = editable?.categories || [];
                let selected = options.filter(item => categories.includes(item.value));
                setSelectedCategory(selected)
            }
        }
        fetchList();
    }, [options, editable?.categories])


    useEffect(() => {
        setInitialValues((values) => {
            if (!editable) return values;
            let { name } = editable;
            return {
                name,
            }
        })
        
    }, [editable])

    const resetAll = (e) => {
        setSelectedCategory([]);
        resetSelectedOption();
        setSelectAll(false);
    }

    const cancel = (e) => {
        setInitialValues({
            name: '',
        })
        resetAll();
        closeModal()
    }

    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                            console.log('~ editable', editable);                                                                                                                                                                                                          
                            // delete prev
                            let listcustomerCategoryRelations = editable?.customerCategoryRelations?.items || [];
                            listcustomerCategoryRelations = listcustomerCategoryRelations.map(item => item.id);
                            await deleteCustomerCategoryRelation(listcustomerCategoryRelations);
                            let relations = [];
                            await Promise.all(
                                selectedCategory.map( async(node) => {
                                    let input = {
                                        userID: editable.userID,
                                        customerCategoryID: node.value
                                    }
                                    let relation = await createCustomerCategoryRelation(input);
                                    relations.push(relation);
                                })
                            )
                            let newitem = {
                                ...editable,
                                customerCategoryRelations:{
                                    items: relations
                                }
                            }
                           
                            setUpdatedItem(newitem);
                        // }
                        

                        setInitialValues({
                            name: '',
                        })
                        resetSelectedOption();
                       
                        setSnackBarDetails({
                            message:
                                `Customer category ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        closeModal()
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                        // closeModal()
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form style={{marginBottom:'150px'}}>
                        {/* <FormGroup>
                            <FormikTextField
                                name="name"
                                margin="normal"
                                label="Name"
                            />
                        </FormGroup> */}
                        <FormGroup className="d-flex flex-row justify-content-between align-items-center">
                            <ReactSelect
                                isMulti={true}
                                name="users"
                                label="Select Categories"
                                options={options}
                                onChange={setSelectedCategory}
                                selectedOption={selectedCategory}
                                style={{width:'83%'}}
                            />
                            <FormControlLabel
                                style={{ marginRight: '0px' }}
                                control={
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Select all"
                            />
                        </FormGroup>
                        
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            onClick={(e)=>{setSubmitted(true)}}
                        >
                            Submit
                        </Button>
                        {editable && (
                        <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                        )}
                        {!editable && (
                        <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            onClick={resetAll}
                        >
                            Reset
                        </Button>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AssignCategory
