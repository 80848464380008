import { FormControlLabel, Radio } from "@material-ui/core";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";
import React from "react";

const FormikRadioGroupField = ({ render, ...props }) => {
  let { options, ...rest } = { ...props };
  return (
    <Field {...rest} component={RadioGroup}>
      {options &&
        options.map((option) => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio key={option.value} />}
              label={option.label}
            />
          );
        })}
    </Field>
  );
};

export default FormikRadioGroupField;
