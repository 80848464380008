
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Amplify, { Auth, Storage } from 'aws-amplify'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import FormikTextField from '../../common/components/FormikTextField'
import awsconfig from '../../aws-exports'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const useStyles = makeStyles({
    inputcontainer: { display: 'flex', borderColor: 'rgba(122, 123, 151, 0.3) !important', height: '55px', alignItems: 'center' },
    inputField: { display: 'flex', alignItems: 'center' }
})

function CompanyInfo() {

    const [state, setState] = useState({
        companyname: "",
        companylogo: null
    })
    const styles = useStyles()

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ [name]: value })
    }

    React.useEffect(() => {
        let url =  state.companylogo ? window.URL.createObjectURL(state.companylogo) : "default.png"
    }, [state.companylogo])
    const img_url =  state.companylogo ? window.URL.createObjectURL(state.companylogo) : "default.png"
    return (
        <>
            <Formik initialValues={state} onSubmit={async(values) => {
                if(state.companylogo){
                    try{
                        const check = await Storage.put(state.companylogo.name, state.companylogo, {
                            contentType:'image/png'
                        }) 
                    }catch(error){
                        console.log("Error in uploading image", error)
                    }
                }
            }}>
                {({ isValid }) => (
                    <Form className="SignIn w-100">
                        <div className="mb-4 mt-3">
                            <FormikTextField
                                className="w-100"
                                variant="outlined"
                                label="Company Name"
                                name="companyname"
                                type="text"
                                value={state.companyname || ""}
                                onChange={(e) => handleChange(e)}
                                placeholder="Company Name"
                            />
                        </div>
                        <div className={`input-group mb-2 pl-3 py-2 rounded border border-1  ${styles.inputcontainer}`}>
                            <div className="custom-file">
                                <input type="file" name="companylogo" onChange={(e) => { setState({companylogo :e.target.files[0]})}} className={`custom-file-input ${styles.inputField}`} id="inputGroupFile02" />
                            </div>
                        </div>
                        {state.companylogo && (
                            <div>
                                <img className="preview img-thumbnail rounded mb-3" width="100px" height="100px" src={img_url} alt="" />
                            </div>
                        )}
                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={!isValid}
                            >
                                Update
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CompanyInfo