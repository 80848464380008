import React, { useEffect, useState } from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style/userproductStyle.css'
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { getAllCoupons } from './curd/coupon';

import { groupBy } from '../../utils/commonFunctions';
import { getSingleUser } from './curd/vendors';
import moment from 'moment';
import { getVendorProductNameById } from './curd/vendorProducts';

const TabelColoumn = [
    { id: 1, name: "Coupon Name" },
    { id: 2, name: "Discount", alignRight: true },
    { id: 3, name: "Redeem Date", alignRight: true },
    { id: 4, name: "Redeem By", alignRight: true }
]



function Userproducts(props) {

    const [redeemedCoupon, setredeemedCoupon] = useState(null)
    const [listRedeemCoupon, setlistRedeemCoupon] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setloading(false)
        }, 5000);
    }, [])

    const isUserRedeem = async (couponredeem) => {
        if (couponredeem.length > 0) {
            let isuserRedeem = couponredeem.filter((item) => item.userID === props.user.id)
            return isuserRedeem
        }
        return []
    }



    useEffect(() => {

        const fetchallproduct = async () => {
            const allproductdata = []
            const listAppCoupons = await getAllCoupons()
            console.log({ listAppCoupons })
            await Promise.all(listAppCoupons.map(async (item) => {
                let { firstName } = await getSingleUser(item.createdBy)
                let insert = {
                    couponID: item.id,
                    vendor: firstName,
                    couponName: item.name,
                    discount: item.amount ? `${item.coupon.amount} Rs` : `${item.percent_off}%`
                }
                let userRedeem = await isUserRedeem(item.couponRedeemed.items)

                if (userRedeem.length > 0 && item.allProducts.items.length > 0) {
                    // console.log("UserRedeem Coupon ====>", userRedeem," Product==>", item.allProducts.items)
                    let someDatata = await Promise.all(userRedeem.map(async (redeem) => {
                        let NestedData = await Promise.all(item.allProducts.items.map(async (product) => {
                            console.log("Userproduct ----", product)
                            let newData = {
                                ...insert,
                            }
                            newData.createdAt = redeem.createdAt
                            newData.ProductID = product.vendorProductID
                            let { title, brand, model } = await getVendorProductNameById(product.vendorProductID)
                            newData.ProductName = title
                            newData.ProductBrand = brand
                            newData.Productmodel = model
                            return newData
                        }))
                        return NestedData
                    }))
                    someDatata.map((sdata) => {
                        allproductdata.push(...sdata)
                    })
                    setredeemedCoupon(allproductdata)
                }
            }))
        }
        fetchallproduct()

    }, [])

    useEffect(() => {
        try {
            const structureData = async () => {
                const unique = [...new Set(redeemedCoupon.filter((item) => item.ProductName != undefined).map(item => item.ProductID))]
                const grouped = groupBy(redeemedCoupon, coupon => coupon.ProductID);
                unique.map((item) => {
                    let glist = grouped.get(item)
                    let insert = {
                        id: item,
                        name: glist[0].ProductName,
                        brand: glist[0].ProductBrand,
                        model: glist[0].Productmodel,
                        coupons: glist
                    }
                    setlistRedeemCoupon(state => [...state, insert])
                })
                setloading(false)
            }
            if (redeemedCoupon?.length) {
                setloading(true)
                structureData()
            }
        } catch (error) {
            console.log("Error", error)
            setloading(false)
        }

    }, [redeemedCoupon])

    const AccordionWrapper = ({ title, children, brand, model }) => {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{title}</Typography>
                    <div className='d-flex justify-content-end w-75 align-items-center'>
                        <div className='d-flex'>
                            <Typography className='header-title'>Brand:{' '}</Typography>
                            <Typography>{brand}</Typography>
                        </div>
                        <div className='d-flex'><Typography className='header-title'>Model:{' '}</Typography><Typography>{model}</Typography> </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        )
    }

    const TabelHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {TabelColoumn.map((item) => (
                        <TableCell key={item.id} align={item.alignRight ? 'center' : ''} >{item.name}</TableCell>
                    ))}
                </TableRow>
            </TableHead>

        )
    }


    if (loading) {
        return (<div className='d-flex h-100 justify-content-center align-items-center'><CircularProgress color="secondary" /></div>)
    }

    return (
        <div className='tableContainer'>
            {listRedeemCoupon.length > 0 ? listRedeemCoupon?.map((redeemcoupon) => (
                <AccordionWrapper title={redeemcoupon.name} brand={redeemcoupon.brand} model={redeemcoupon.model}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TabelHeader />
                            <TableBody>
                                {redeemcoupon.coupons.map((item) => {
                                    let dateis = new Date(item.createdAt)
                                    return (
                                        <TableRow key={item.couponID}>
                                            <TableCell component="th" scope="row">
                                                {item.couponName}
                                            </TableCell>
                                            <TableCell align="center">{item.discount}</TableCell>
                                            <TableCell align="center">{moment(dateis).format("YYYY-MM-DD hh:mm A")}</TableCell>
                                            <TableCell align="center">{item.vendor}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionWrapper>
            )) : <div className='nodata'>
                <h3>No Data Found</h3>
            </div>
            }
        </div>
    )
}

export default Userproducts
