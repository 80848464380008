import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmationModal from "../../../common/components/modalConfirmation";
import CustomModal from '../CustomModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CreateQuestion from './create-question';
import DoxSnackBar from '../../../common/components/DoxSnackBar';
import EnhancedTableHead, { EnhancedTableToolbar, getComparator, stableSort } from '../../../common/components/Tables/EnhancedTableHead';
import { deleteQuestion, listQuestion } from '../../containers/curd/vendorQuestion';
import { Questiontypes as types } from '../../../utils/constant'

const headCells = [
  { id: 'question', numeric: false, disablePadding: true, label: 'Question' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'options', numeric: false, disablePadding: false, label: 'Options' },
];


EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};




EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function QuestionList(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(null);
  const [createdItem, setCreatedItem] = useState(null);
  const [updatedItem, setUpdatedItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmationModal, setConfirmationModal] = useState({
    title: `Are you sure, You want to delete this!`,
    open: false,
    no: true,
    yes: false
  });
  useEffect(() => {
    setConfirmationModal({ ...confirmationModal, title: `Are you sure, You want to delete ${selected.length && selected.length > 1 ? `these ${selected.length} records!` : `this record!`}` })
  }, [selected])


  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    links: [],
    severity: "error",
  });

  let accountType = [];
  if (props.user.accountType === 'SuperAdmin' || props.user.accountType === 'SuperAdminUser') {
    accountType = ['VendorAdmin']
  } else if (props.user.accountType === 'VendorAdmin' || props.user.accountType === 'VendorAdminUser') {
    accountType = ['VendorAdmin', 'VendorAdminUser']
  }
  useEffect( () => {

    const fetchListVendors = async () => {
      setLoading(true)
      let vendors = await listQuestion(props.user.id, accountType);
      setRows(vendors)
      setLoading(false);
    }
    fetchListVendors();
  }, [])

  useEffect(() => {
    setRows(rows => {
      if (!createdItem) return rows;
      let newItem = {
        ...createdItem
      }
      return [newItem, ...rows]
    })

    setCreatedItem(null);
  }, [createdItem])

  useEffect(() => {
    setRows(rows => {
      if (!updatedItem) return rows;
      let index = rows.map((data, index) => data.id === updatedItem.id ? index : undefined).filter(item => item != undefined);
      rows[index[0]] = updatedItem;
      return rows;
    })
    setUpdatedItem(null);
    setEditable(null);
    setSelected([]);
  }, [updatedItem])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDeleteConfirm = async () => {
    setConfirmationModal((values) => ({ ...values, open: true }));
  }

  const handleDelete = async () => {
    setLoadingDelete(true);
    let deleted = rows.filter(({ id }) => selected.includes(id));
    await deleteQuestion(deleted);
    setRows((rows) => rows.filter(({ id }) => !selected.includes(id)));
    setSelected([]);
    setLoadingDelete(false)
  }

  const handleEdit = () => {
    let item = rows.find(({ id }) => id === selected[0]);
    setOpen(true);
    setEditable(item);
  }

  const questionType = (value) => {
    if(!value){
      return value;
    }
    let arr = types.find(item => item.id === value).value
    return arr;
  }

  return (
    <>
      {!loading ? <>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <DoxSnackBar snackBarDetails={snackBarDetails} />
            <EnhancedTableToolbar numSelected={selected.length} user={props.user} setOpen={setOpen} handleDelete={handleDeleteConfirm} handleEdit={handleEdit} loadingDelete={loadingDelete} title="Question Management" />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row?.question}
                          </TableCell>
                          <TableCell align="left">{questionType(row?.type)}</TableCell>
                          <TableCell align="left">
                            {row?.options
                            ?JSON.parse(row.options).map((item,i)=>{
                              let last = i === (JSON.parse(row?.options).length-1)
                              return<span key={i}>
                                {" "}
                                {item}
                                {!last && (
                                  <span>,</span>
                                )}
                              </span>
                            })
                            :''
                            }
                          </TableCell>
                          

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={headCells.length+1} align="center">
                      {rows.length === 0 && (
                        "No data found"
                      )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </div>

        <CustomModal fullWidth open={open} setOpen={(bool) => { setOpen(bool); setEditable(null) }} title={`Question > ${editable ? " Update Question" : "Add New Question"} `}>
          <CreateQuestion user={props.user} closeModal={() => { setOpen(false); setEditable(null) }} setCreatedItem={setCreatedItem} editable={editable} setUpdatedItem={setUpdatedItem} setSnackBarDetails={setSnackBarDetails} />
        </CustomModal>
      </> : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>
      }
      <ConfirmationModal
        items={selectedItem}
        settings={confirmationModal}
        close={success => {
          if (success) {
            handleDelete()
          }
          setConfirmationModal({ ...confirmationModal, open: false });
        }}
      ></ConfirmationModal>
    </>
  );
}

