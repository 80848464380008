import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

/* eslint-disable no-console */
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  checkIfLoopEnding,
  convertAnswerToState,
  getLoopQuestionCondition,
  getNextQuestion,
  getUnSavedUserSession,
  getUserNextQuestion,
  getUserSession,
  isConditionSession,
  postProcessQuestion,
  replaceQuestion,
  replaceUserQuestion
} from "../../common/services/question-engine";
import { getDbColumnName, isNullOrEmpty, removeDuplicateAnswereQuestion } from "../../common/utils/common";

import ConfirmationModal from "../../common/components/modalConfirmation";
import CreateIcon from "@material-ui/icons/Create";
import FormikCheckboxGroupField from "../../common/components/FormikCheckboxGroupField";
import FormikDatePicker from "../../common/components/FormikDatePicker";
import FormikRadioGroupField from "../../common/components/FormikRadioGroupField";
import FormikSelect from "../../common/components/FormikSelect";
import FormikTextField from "../../common/components/FormikTextField";
import Layout from "../../common/components/layout.js";
import Modal from "../../common/components/userExitConfirmationModal";
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

const modalInitialValue = {
  open: false,
  no: true,
  yes: false
}

const SymptomConditionFormContainer = props => {
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [userResponses, setUserResponses] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [lastQuestionResponse, setLastQuestionResponse] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [initialValue, setInitialValue] = useState({ questionResponse: "" });
  const [step, setStep] = useState(1);
  const [editQuestion, setEditQuestion] = useState(null);
  const [updateEditQuestion, setUpdateEditQuestion] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(modalInitialValue);
  const [session, setSession] = useState(uuidv4());
  const [previousUserResponse, setPreviousUserResponse] = useState(null);
  const [customResponse, setCustomResponse] = useState([]);
  const [isBodyPart, setIsBodyPart] = useState([]);
  const [customIndex, setCustomIndex] = useState(0);
  const [rowId, setRowId] = useState([]);
  const [userData, setUserData] = useState([]);
  const [conditionName, setConditionName] = useState([]);
  const [conditionIndex, setConditionIndex] = useState(1);
  const [quesConditionIndex, setQuesConditionIndex] = useState(0);
  const [bodyPartIndex, setBodyPartIndex] = useState(0);
  const [currentId, setCurrentId] = useState(1);
  const [loopQuestionId, setLoopQuestionId] = useState('');
  const moment = require("moment");
  const history = useHistory();
  const [bodyPartResponses, setBodyPartResponses] = useState([]);
  const [userPathSwitchModal, setUserPathSwitchModal] = useState(modalInitialValue);
  const [deleteConditionModal, setDeleteConditionModal] = useState(modalInitialValue);
  const [addConditionModal, setAddConditionModal] = useState(modalInitialValue);
  const [addAndDelConditionModal, setAddAndDelConditionModal] = useState(modalInitialValue);
  const [deletePainModal, setDeletePainModal] = useState(modalInitialValue);
  const [addPainModal, setAddPainModal] = useState(modalInitialValue);
  const [oldConditions, setOldConditions] = useState([]);
  const [userNewConditions, setUserNewConditions] = useState([]);
  const [editSource, setEditSource] = useState(null);

  let isPathSwitching = false

  const newConditions = []
  useEffect(() => {
    async function onLoad() {
      getQuestions();
      // getPreviousSession();
    }
    onLoad();
  }, [props?.user?.id]);

  useEffect(() => {
    if (userResponses && userResponses.length > 0 && lastQuestionResponse) {
      proceedToNextQuestion();
    }
  }, [lastQuestionResponse]);

  async function proceedToNextQuestion() {
    const nextQuestion = await getUserNextQuestion(
      userResponses,
      lastQuestionResponse,
      questions,
      customResponse,
      customIndex,
      responses,
      rowId,
      bodyPartIndex,
      setBodyPartIndex,
      conditionIndex,
      setConditionIndex,
      loopQuestionId,
      setLoopQuestionId,
      editQuestion,
      answeredQuestions,
      currentId,
      bodyPartResponses,
      isBodyPart
    );
    if (nextQuestion) {
      const nextques = {
        ...nextQuestion,
        userResponseRowId: userResponses[0]?.id || rowId[0]
      }
      setCurrentQuestion(nextques);
      return;
    }
    if (onLastQuestion() && !nextQuestion) setStep(step + 1);
  }
  // useEffect(() => {
  //   if (responses && Object.keys(responses).length > 0) {
  //     proceed();
  //     updateProgress();
  //   }
  // }, [responses]);

  useEffect(() => {
    if (currentQuestion) {
      if (!editQuestion) calculateInitialValues(answeredQuestions);
      else calculateInitialValues(editQuestion)

      // if (previousUserResponse) {
      //   continueAsUser();
      // }
    }
  }, [currentQuestion, userResponses[0]]);

  // useEffect(() => {
  //   continueAsUser();
  // }, [previousUserResponse]);

  // consider adding an onboarding boolean to field to questions table
  // return all onboarding questions, map to array, cycle through them
  async function getQuestions() {
    // get question by questionID
    const response = await API.graphql(
      graphqlOperation(queries.listQuestions, {
        filter: {
          onboarding: {
            eq: true
          }
        }
      })
    );
    if (response && response.data && response.data.listQuestions) {
      // sort by questionID
      let questions = response.data.listQuestions.items.sort((a, b) => {
        return a.questionID.localeCompare(b.questionID);
      });

      questions = questions.map(q => postProcessQuestion({ ...q }));
      setQuestions(questions);
      setCurrentQuestion({ ...questions[0] });
    }
  }

  function setSessionToResume(response) {
    let top_session_id = response.sessionID;

    let time = moment(response.createdAt);
    if (top_session_id && time) {
      setConfirmationModal({
        open: true,
        title: `You have a unsaved session on
        ${time.format("MM-DD-YYYY")} ${time.format(
          "HH:mm"
        )}. Do you want to resume it?`
      });
      setSession(top_session_id);
    }
  }
  async function getPreviousSession() {
    // Get all the userResponses by user id
    const response = await getUnSavedUserSession(props.user.id);
    if (response && response.sessionID && response.createdAt) {
      const sessionToResume = await getUserSession(response.sessionID);
      if (sessionToResume) {
        let conditionSession = isConditionSession(sessionToResume);
        if (conditionSession !== null) {
          if (conditionSession && props.location.pathname === "/addCondition") {
            setSessionToResume(response);
          } else if (
            !conditionSession &&
            props.location.pathname === "/addSymptom"
          ) {
            setSessionToResume(response);
          }
        }
      }
    }
  }
  // This function is used to resume old session
  async function handleResumeSession() {
    // Get all the previous answered question
    let items = await getUserSession(session);
    if (items && items.length > 0) {
      // Got session data..convert this to map like {questionId: <userresponse>}
      let userResponses = items.reduce((acc, current) => {
        acc[current.questionID] = current;
        return acc;
      }, {});
      setPreviousUserResponse(userResponses);
    }
  }

  //This function is used to get question id
  function getQuestionId(question) {
    let questionId = question.questionID;
    if (question.loopingOnAnswer) {
      questionId = `${questionId}_${getLoopQuestionCondition(
        question.loopingOnAnswer
      )}`;
    }
    return questionId;
  }

  // This function sets NULL values to the PAIN affected questions
  function nullifyResponses(userId, ques, quesResponse, source) {
    if (ques && (ques.questionID === "DOX-0019" || ques.questionID === "DOX-0013") &&
      !(ques?.answer instanceof Date) && !Array.isArray(ques?.answer) &&
      ques?.answer?.split(", ").includes("Pain") &&
      quesResponse && !quesResponse.includes("Pain")
    ) {
      setEditSource(source);
      setDeletePainModal({
        open: true,
        title: `You have deselected PAIN. You will loose PAIN response`,
        button1: `Delete Pain Data & Proceed`,
      });
      return true
    } else if (ques && (ques.questionID === "DOX-0019" || ques.questionID === "DOX-0013") &&
      !(ques?.answer instanceof Date) && ques?.answer?.includes("Pain") &&
      quesResponse && !quesResponse.includes("Pain")
    ) {
      setEditSource(source);
      setDeletePainModal({
        open: true,
        title: `You have deselected PAIN. You will loose PAIN response`,
        button1: `Delete Pain Data & Proceed`,
      });
      return true
    }
    return false
  }

  async function deletePainResponse() {
    let requiredRes = JSON.parse(userResponses[0]['dox020']) || []
    requiredRes.splice(requiredRes.indexOf("Pain"), 1)
    const expectedResponses = [
      {
        quesID: "DOX-0024",
        newRes: []
      },
      {
        quesID: "DOX-0028",
        newRes: []
      },
      {
        quesID: "DOX-0020",
        newRes: requiredRes,
      }
    ];
    expectedResponses.map(async res => {
      await updateUserResponses(
        res.quesID,
        userResponses[0]['id'],
        res.newRes
      ).then(data => {
        const bodyResIndex = rowId.findIndex(x => x === currentQuestion.userResponseRowId)
        if (bodyResIndex > -1) bodyPartResponses.splice(bodyResIndex, 1, [])
      })
        .catch(error => console.warn("something went wrong while submiting response :", error))
    })
  }

  // Delete current session
  async function deleteCurrentSession(id) {
    await API.graphql(
      graphqlOperation(mutations.deleteUserResponses, {
        input: { id }
      })
    )
  }

  // Save userresponse when path changed
  async function saveUserWhenPathChanges(quesRes) {
    const newSession = uuidv4()
    setSession(newSession)
    let savedUserResponse = await saveUserResponse(
      currentQuestion.questionID,
      quesRes,
      newSession
    );
    if (savedUserResponse?.data?.createUserResponses?.id) {
      setUserResponses([savedUserResponse.data.createUserResponses]);
      setResponses({ ...responses, [currentQuestion.questionID]: quesRes });
      setLastQuestionResponse({
        ...currentQuestion,
        questionId: currentQuestion.questionID,
        questionResponse: quesRes
      });
    }
  }

  // This function will check if we are switching path, and will handle the response required
  async function switchingPath(quesResponse) {
    if (currentQuestion.questionID === "DOX-0001" || currentQuestion.questionID === "DOX-0003") {
      if ((editQuestion && quesResponse?.includes("Yes") || quesResponse?.includes("No"))) {
        const quesIndex = editQuestion.findIndex(x => x.questionID === currentQuestion['questionID'])
        if (quesIndex > -1 && !editQuestion[quesIndex]?.answer?.includes(quesResponse)) {
          isPathSwitching = true;
          setUserPathSwitchModal({
            open: true,
            title: `You're switching the Path`,
            button1: `Delete Previous Response & Start A New One`,
            button2: `Save Previous Response & Start a New One`,
            colorDanger: `secondary`,
            lineH: true,
          });
          return true
        }
      }
    } return false
  }

  // delete condition responses
  async function deleteConditionResponse(conditions) {
    // deleting the old condition's response
    if (conditions && conditions.length > 0) {
      for (let condition of conditions) {
        const condIndex = conditionName.length > 0 ? conditionName.indexOf(condition) : 0
        if (rowId?.length > 1) {
          try {
            await API.graphql(
              graphqlOperation(mutations.deleteUserResponses, {
                input: { id: rowId[condIndex] }
              })
            ).then(data => {
              if (updateEditQuestion) {
                const answeredQuesToBeDeleted = updateEditQuestion.filter(x => x.userResponseRowId === rowId[condIndex])
                for (let ques of answeredQuesToBeDeleted) {
                  const deleteQuesIndex = updateEditQuestion.findIndex(y => y.questionID === ques.questionID && y.userResponseRowId === ques.userResponseRowId)
                  updateEditQuestion.splice(deleteQuesIndex, 1)
                }
              }
              rowId.splice(condIndex, 1)
              conditionName.splice(condIndex, 1)
            })
          } catch (err) {
            console.warn("Something went wrong while deleting the existing conditions")
          }
        }
      }
    }
  }

  // handling condition addition and deletion
  async function editConditions(quesResponse, source) {
    setEditSource(source)
    let oldLocalRes = [];
    const conditionAnsweredQuestion = editQuestion?.filter(x => x.questionID === "DOX-0002")
    if (!conditionAnsweredQuestion || conditionAnsweredQuestion.length === 0) {
      // addConditions(quesResponse, true);
      return { newConditions, oldConditions, firstTime: true }
    }
    if (currentQuestion.questionID === "DOX-0002") {
      const oldResponse = conditionAnsweredQuestion[0].answer
      const newResponse = quesResponse
      if (newResponse && newResponse.length > 0) {
        newResponse.map(x => {
          if (oldResponse.indexOf(x) === -1) newConditions.push(x)
        })
        setUserNewConditions(newConditions)
      }
      if (oldResponse && oldResponse.length > 0) {
        oldResponse.map(y => {
          if (newResponse.indexOf(y) === -1) {
            oldLocalRes.push(y)
          }
        })
        setOldConditions(oldLocalRes)
      }

      // check if user is save and existing the condition response
      if (newConditions.length > 0 && oldLocalRes.length > 0) {
        setAddAndDelConditionModal({
          open: true,
          title: `You have deselected and added new condition(s). Old condtion(s) response will be deleted. Please provide your response for newly selected conditions`,
          button1: `Proceed with Filling Data & delete old condition(s) response`,
        });
        return { newConditions, addAndDelModal: true }
      } else if (newConditions.length > 0 && source !== 'next') {
        setAddConditionModal({
          open: true,
          title: `You have added new condition(s). Please click on NEXT button, and provide your response for newly selected condition(s)`,
          button3: `OK`,
        });
        return { newConditions }
      } else if (newConditions.length === 0 && oldLocalRes.length > 0) {
        setDeleteConditionModal({
          open: true,
          title: `You have deselected condition(s)`,
          button1: `Delete Condition Data & Proceed`,
          lineH: true,
        });
        return { oldLocalRes }
      } else {
        return { newConditions, oldConditions, firstTime: false }
      }
    } else return { newConditions, oldConditions, firstTime: false }
  }

  // saving user response when he wants update the current question and then switch to last page
  async function saveAndExitResponse(quesID, userId, quesResponse) {
    const updateExistingQuestion = true;
    let ques = editQuestion.filter(x => x.questionID === quesID)

    // checking if user is switching from path_1 to path_2 or vice versa
    await switchingPath(quesResponse, userId)
    if (isPathSwitching) return

    // checking if pain loop is not selected earlier, but now we are selecting pain while editing.
    // So, we are showing alert message to user, to either deselect PAIN or go ahead with PAIN loop first.
    if (!(ques[0]?.answer instanceof Date) &&
      !("Years" in ques[0]?.answer) &&
      !ques[0]?.answer?.includes("Pain") &&
      quesResponse && quesResponse.includes("Pain")) {

      setAddPainModal({
        open: true,
        title: `You have choosen PAIN as a symptom.`,
        button2: `Proceed with Filling Data`,
      });
      return
    }

    // checking if pain is selected but we are moving to last page without responding to body part questions
    // So, we are showing alert message to user, to first list down where he is having pain.
    if (editQuestion.find(x => x.questionID === "DOX-0019") &&
      editQuestion[editQuestion.findIndex(x => x.questionID === "DOX-0019")]?.answer?.includes("Pain") &&
      !editQuestion.find(x => x.questionID === "DOX-0024") &&
      !editQuestion.find(x => x.questionID === "DOX-0016")
    ) {
      setAddPainModal({
        open: true,
        title: `You selected PAIN as a symptom. Please first list down where you've PAIN in your body`,
        button3: `OK`,
      });
      return
    }

    // checking if pain loop is answered, but now we ain't selecting pain while editing. So, we can nullify the response
    if (nullifyResponses(userId, ques[0], quesResponse)) return

    // checking if user is editing conditions
    const { oldLocalRes } = await editConditions(quesResponse)
    if ((newConditions && newConditions.length > 0) || (oldLocalRes && oldLocalRes.length > 0)) return

    // update current question response
    await handleSaveAndExitResponse(quesID, userId, quesResponse, updateExistingQuestion)
  }

  // This function updates the user response when save and exit
  async function handleSaveAndExitResponse(quesID, userId, quesResponse, updateExistingQuestion) {
    await updateUserResponses(
      quesID,
      userId,
      quesResponse
    ).then(res => {
      if (res?.data?.updateUserResponses?.id) {
        setResponses({ ...responses, [currentQuestion.questionID]: quesResponse });
        saveAnsweredQuestion(currentQuestion, responses, conditionName, currentId, bodyPartIndex, quesResponse, updateExistingQuestion)

        if (currentQuestion.listType === "CONDITION" &&
          currentQuestion.responseType === "LIST" && editSource === "next"
        ) {
          setLastQuestionResponse({
            ...currentQuestion,
            questionId: currentQuestion.questionID,
            questionResponse: { default: currentQuestion.nextQuestion.default },
            loopQuestionResponse: quesResponse
          });
          return
        }
        if (editSource === "next" || updateExistingQuestion === "save and proceed") {
          setLastQuestionResponse({
            ...currentQuestion,
            questionId: currentQuestion.questionID,
            questionResponse: quesResponse
          });
          return
        }
        setStep(step + 1);
      }
    }).catch(error => {
      console.warn("something went wrong while submiting response :", error)
      setStep(step + 1);
    });
  }

  function showSaveAndExitButton(quesRes) {
    let showButton = true;
    if (currentQuestion.questionID === "DOX-0014") {
      let ques = editQuestion.filter(x => x.questionID === "DOX-0014" && x.userResponseRowId === userResponses[0].id)
      if (ques[0] && ques[0].answer.length !== quesRes.length) return showButton = false;
      else if (ques[0] && ques[0].answer.length === quesRes.length && !ques[0].answer.every(x => quesRes.includes(x))) return showButton = false;
    } else if (currentQuestion.questionID === "DOX-0028" || currentQuestion.questionID === "DOX-0024") {
      return showButton = false;
    } else if (!rowId.every(x => editQuestion.find(y => y.userResponseRowId === x))) return showButton = false;
    return showButton;
  }

  // This function is called when resume in progress
  function continueAsUser() {
    // We need to construct question id
    if (
      currentQuestion &&
      previousUserResponse &&
      getQuestionId(currentQuestion) in previousUserResponse
    ) {
      let answer = previousUserResponse[currentQuestion.questionID].resp;
      if (answer) {
        answer = JSON.parse(answer);
      }
      let responseToSubmit = { questionResponse: answer };
      if (currentQuestion.responseType === "DATE") {
        answer = moment(answer).toDate();
        responseToSubmit = { questionResponse: answer };
      } else if (currentQuestion.responseType === "MULTIPLELISTS") {
        responseToSubmit = answer;
      }

      submitResponse(responseToSubmit, true, true);
    } else {
      // We are setting this to null so that when user click back or submit it does not look
      // this means all the session is resumed
      setPreviousUserResponse(null);
    }
  }
  // This function is used to save user responses
  async function saveUserResponse(questionID, resp, newSession) {
    const columnName = getDbColumnName(questionID);
    if (!columnName) return null;
    const response = await API.graphql(
      graphqlOperation(mutations.createUserResponses, {
        input: {
          questionID: questionID,
          // resp: JSON.stringify(resp),
          [columnName]: JSON.stringify(resp),
          userID: props.user.sub,
          sessionID: newSession || session
        }
      })
    );
    rowId.push(response?.data?.createUserResponses?.id)
    return response;
  }
  // This function is used to update user responses
  async function updateUserResponses(questionID, id, resp) {
    const columnName = getDbColumnName(questionID);
    if (!columnName) return null;

    const response = await API.graphql(
      graphqlOperation(mutations.updateUserResponses, {
        input: {
          id,
          [columnName]: JSON.stringify(resp)
        }
      })
    );
    return response;
  }

  async function submitResponse(
    data,
    proceed,
    resume = false,
    resetForm = null
  ) {
    // Check if it is list type we might want to keep both label and value
    if (currentQuestion && currentQuestion.responseType === "LIST") {
      let selectedList = [];
      for (let selection of data.questionResponse) {
        if (typeof selection === "string") {
          selectedList.push(
            currentQuestion.options.find(o => o.value === selection)
          );
        } else if (selection && selection.value) {
          selectedList.push(
            currentQuestion.options.find(o => o.value === selection.value)
          );
        }
      }
      data.questionResponse = selectedList;
    } else if (
      currentQuestion &&
      currentQuestion.responseType === "MULTIPLELISTS"
    ) {
      data.questionResponse = { ...data };
    }

    // if (currentQuestion && "loopOnQuestion" in currentQuestion) {
    //   let responseToCurrentQuestion = responses[currentQuestion.questionId];
    //   if (responseToCurrentQuestion) {
    //     responseToCurrentQuestion[currentQuestion.loopingOnAnswer] =
    //       data.questionResponse;
    //   } else {
    //     responseToCurrentQuestion = {
    //       [currentQuestion.loopingOnAnswer]: data.questionResponse
    //     };
    //   }
    // }
    // Save this question in answered question
    let thisQuestion = {
      ...currentQuestion,
      processedQuestion: replaceQuestion(currentQuestion),
      answer: data.questionResponse
    };
    if (!resume) {
      let questionId = getQuestionId(currentQuestion);
      // Save user responses
      let saveuser = await saveUserResponse(questionId, data.questionResponse);
      if (
        saveuser &&
        saveuser.data &&
        saveuser.data.createUserResponses &&
        saveuser.data.createUserResponses.id
      ) {
        thisQuestion.saveUserResponseID = saveuser.data.createUserResponses.id;
      }
    }

    setAnsweredQuestions([...answeredQuestions, thisQuestion]);
    // Reset form if present
    if (resetForm) {
      resetForm();
    }
    // Go to next question
    // save user response in currentQuestion
    if (proceed) {
      setResponses({
        ...responses,
        [currentQuestion.questionID]: data.questionResponse
      });
    } else {
      // go to dashaboard
      formFinished();
    }
  }

  // edit bodypart questions
  async function editBodyPart(bodyParts) {
    setIsBodyPart(bodyParts)
    try {
      await API.graphql(
        graphqlOperation(queries.getUserResponses, {
          id: currentQuestion.userResponseRowId || userResponses[0].id
        })
      ).then(async data => {
        if (data?.data?.getUserResponses['dox024']) {
          const previousRes = JSON.parse(data.data.getUserResponses['dox024'])
          if (currentId > bodyPartResponses.length) bodyPartResponses.push(bodyParts)
          else if (bodyPartResponses && bodyPartResponses.length > 0 && currentId <= bodyPartResponses.length) {
            bodyPartResponses.splice(currentId - 1, 1, bodyParts)
          }
          // checking for old bodypart response and pushing those into new response array object
          for (let x of previousRes) {
            if (bodyParts.includes(x['label'])) {
              customResponse.push(x)
            }
          }
          // checking for new bodypart response and pushing those into new response array object
          for (let x of bodyParts) {
            if (!previousRes.find(y => x === y['label'])) {
              customResponse.push({ label: x, value: "" })
            }
          }
          // saving new bodypart response in DB
          let updatedUserResponse = await updateUserResponses(
            currentQuestion.nextQuestion.default,
            userResponses[0].id,
            customResponse
          );
          if (updatedUserResponse?.data?.updateUserResponses?.id) {
            setUserResponses([updatedUserResponse.data.updateUserResponses]);
            setResponses({ ...responses, [currentQuestion.questionID]: data.questionResponse });
            setLastQuestionResponse({
              ...currentQuestion,
              questionId: currentQuestion.questionID,
              questionResponse: { default: currentQuestion.nextQuestion.default },
              loopQuestionResponse: data.questionResponse
            });
          }
        }
      })
    } catch (err) {
      console.warn("Getting ERROR while while fetching userResponse :", err)
    }
  }

  // saving user responses into answered questions
  function saveAnsweredQuestion(currentQuestion, responses, conditionName, currentId, bodyPartIndex, questionResponse, updateExistingQuestion) {
    let thisQuestion = {
      ...currentQuestion,
      processedQuestion: replaceUserQuestion(currentQuestion, responses, conditionName, currentId, bodyPartIndex, userResponses[0]),
      answer: questionResponse,
      userResponseRowId: userResponses[0]?.id || rowId[0]
    };
    setAnsweredQuestions([...answeredQuestions, thisQuestion]);

    // updating condition names with array of condition
    const conditionQuesIndex = answeredQuestions.findIndex(x => x.questionID === "DOX-0002")
    if (conditionName && conditionName.length > 0 && conditionQuesIndex >= 0) {
      answeredQuestions[conditionQuesIndex]['answer'] = conditionName
    }

    if (updateExistingQuestion) {
      const index = updateEditQuestion.findIndex(question => question.questionID === thisQuestion.questionID && question.userResponseRowId === thisQuestion.userResponseRowId)
      updateAnsweredQuestion(index, thisQuestion)
    }
  }

  // This function will update existing answered question
  function updateAnsweredQuestion(index, thisQuestion) {
    const quesToBeUpdated = updateEditQuestion.find(question => question.questionID === thisQuestion.questionID && question.userResponseRowId === thisQuestion.userResponseRowId)
    updateEditQuestion.splice(0, index + 1, thisQuestion)
    let i;

    // if PAIN is deselected, deleting related response
    if (quesToBeUpdated && (quesToBeUpdated.questionID === "DOX-0019" || quesToBeUpdated.questionID === "DOX-0013") &&
      !(quesToBeUpdated?.answer instanceof Date) && quesToBeUpdated?.answer?.includes("Pain") &&
      !thisQuestion.answer.includes("Pain")
    ) {
      const quesToRemove = ["DOX-0014", "DOX-0016", "DOX-0024", "DOX-0028"]
      quesToRemove.forEach(x => {
        const answersToRemove = updateEditQuestion.filter(z => z.questionID === x)
        if (answersToRemove && answersToRemove.length > 1) {
          answersToRemove.forEach(r => {
            i = updateEditQuestion.findIndex(y => y.questionID === r.questionID && y.userResponseRowId === currentQuestion.userResponseRowId)
            if (i > -1) updateEditQuestion.splice(i, 1)
          })
        } else {
          i = updateEditQuestion.findIndex(y => y.questionID === x && y.userResponseRowId === currentQuestion.userResponseRowId)
          if (i > -1) updateEditQuestion.splice(i, 1)
        }
      })
    }

    const finalAnswredList = [...answeredQuestions, ...updateEditQuestion]
    setAnsweredQuestions(finalAnswredList)
  }

  // submiting user reponse
  async function submitUserResponse(
    data,
    proceed,
    resume = false,
    resetForm = null
  ) {
    // setting correct response structure here
    if (
      currentQuestion &&
      currentQuestion.responseType === "MULTIPLELISTS"
    ) {
      delete data["questionResponse"]
      data.questionResponse = { ...data };
    }

    // checking if user is switching from path_1 to path_2 or vice versa
    // if (userResponses && userResponses[0]?.id && data.questionResponse.length > 0) {
    // if(switchingPath(data.questionResponse, userResponses[0]['id'])) return 
    // switchingPath(data.questionResponse, userResponses[0]['id']);

    // if (await switchingPath(data.questionResponse, userResponses[0]['id'])) {
    //   return
    // }
    // }

    // Save user responses
    if (userResponses && userResponses.length === 0) {
      let savedUserResponse = await saveUserResponse(
        currentQuestion.questionID,
        data.questionResponse
      );
      if (savedUserResponse?.data?.createUserResponses?.id) {
        setUserResponses([savedUserResponse.data.createUserResponses]);
        setResponses({ ...responses, [currentQuestion.questionID]: data.questionResponse });
        setLastQuestionResponse({
          ...currentQuestion,
          questionId: currentQuestion.questionID,
          questionResponse: data.questionResponse
        });
      }
    }

    // Nullify PAIN affected question response if we deselect PAIN as a symptom
    if (userResponses && userResponses[0]?.id && currentQuestion?.answer && data.questionResponse.length > 0) {
      if (nullifyResponses(userResponses[0]['id'], currentQuestion, data.questionResponse, "next")) return
      setEditSource(null)
    }

    // If user hasn't selected PAIN, then pushing empty array into bodyPartResponses. So, we can handle PAIN responses for multiple conditions
    if (currentQuestion &&
      currentQuestion.questionID === "DOX-0013" &&
      !(data.questionResponse.includes("Pain")) &&
      bodyPartResponses.length < currentId
    ) {
      bodyPartResponses.push([])
    }

    //creating rows if loop present
    if (currentQuestion &&
      currentQuestion.responseType === "LIST" &&
      currentQuestion.listType === "CONDITION" &&
      data.questionResponse.length > 1
    ) {
      const { firstTime, newConditions, oldConditions, addAndDelModal } = await editConditions(data.questionResponse, "next")
      if (addAndDelModal) return
      setEditSource(null)
      let conditionResponse = firstTime ? data.questionResponse : newConditions

      // add new conditions here
      for (let response of conditionResponse) {
        if (response === conditionResponse[0] && firstTime) continue
        let savedUserResponse = await saveUserResponse(
          currentQuestion.questionID,
          response
        );
        if (savedUserResponse?.data?.createUserResponses?.id) {
          if (!rowId.includes(savedUserResponse?.data?.createUserResponses?.id)) rowId.push(response?.data?.createUserResponses?.id)
          const quesIdsToBeUpdated = ["DOX-0001", "DOX-0003"]
          for (let quesID of quesIdsToBeUpdated) {
            let updatedUserResponse = await updateUserResponses(
              quesID,
              savedUserResponse?.data?.createUserResponses?.id,
              responses[quesID]
            );
            if (updatedUserResponse?.data?.updateUserResponses?.id) {
              setUserResponses([updatedUserResponse.data.updateUserResponses]);
              setResponses({ ...responses, [currentQuestion.questionID]: conditionResponse });
              setLastQuestionResponse({
                ...currentQuestion,
                questionId: currentQuestion.questionID,
                questionResponse: { default: currentQuestion.nextQuestion.default },
                loopQuestionResponse: conditionResponse
              });
            }
          }
        }
      }
      // deleting response
      if (oldConditions.length > 0) await deleteConditionResponse(oldConditions)
    }
    // normal work flow start
    if (
      currentQuestion &&
      !isNullOrEmpty(currentQuestion, "loop") &&
      currentQuestion.loop.includes("goto") &&
      currentQuestion.responseType === "MULTIPLECHOICE" &&
      customResponse.length !== customIndex
    ) {
      //updating bodyParts data
      let bodyResponse = JSON.parse(userResponses[0]?.dox024)
      bodyResponse[customIndex].value = data.questionResponse
      setCustomIndex(customIndex + 1)
      let updatedUserResponse = await updateUserResponses(
        currentQuestion.questionID,
        userResponses[0].id,
        bodyResponse
      );
      if (updatedUserResponse?.data?.updateUserResponses?.id) {
        setUserResponses([updatedUserResponse.data.updateUserResponses]);
        setResponses({ ...responses, [currentQuestion.questionID]: data.questionResponse });
        setLastQuestionResponse({
          ...currentQuestion,
          questionId: currentQuestion.questionID,
          questionResponse: { default: currentQuestion.nextQuestion.default },
          loopQuestionResponse: data.questionResponse
        });
      }
    } else if (currentQuestion.responseType === "LIST" &&
      currentQuestion.listType === "BODYPART"
    ) {
      if (customResponse.length > 0) {
        setCustomIndex(0)
        customResponse.splice(0);
      }
      const bodyParts = Object.values(data.questionResponse)
      if (editQuestion && editQuestion.length > 0 &&
        editQuestion.find(x => x.questionID === currentQuestion.questionID && x.userResponseRowId === currentQuestion.userResponseRowId)) {
        editBodyPart(bodyParts)
      } else {
        setIsBodyPart(bodyParts)
        if (bodyPartResponses[currentId - 1]) bodyPartResponses.splice(currentId - 1, 1, bodyParts)
        else bodyPartResponses.push(bodyParts)
        bodyParts.forEach(bodyPart => {
          customResponse.push({ label: bodyPart, value: '' })
        })
        let updatedUserResponse = await updateUserResponses(
          currentQuestion.nextQuestion.default,
          userResponses[0].id,
          customResponse
        );
        if (updatedUserResponse?.data?.updateUserResponses?.id) {
          setUserResponses([updatedUserResponse.data.updateUserResponses]);
          setResponses({ ...responses, [currentQuestion.questionID]: data.questionResponse });
          setLastQuestionResponse({
            ...currentQuestion,
            questionId: currentQuestion.questionID,
            questionResponse: { default: currentQuestion.nextQuestion.default },
            loopQuestionResponse: data.questionResponse
          });
        }
      }
    } else if (userResponses && userResponses.length > 0) {
      if (currentQuestion.listType === "CONDITION" &&
        currentQuestion.responseType === "LIST"
      ) {
        if (editQuestion && editQuestion.length > 0) {
          const { oldLocalRes } = await editConditions(data.questionResponse, "next");
          if (oldLocalRes && oldLocalRes.length > 0) return
          setEditSource(null)
        }
        if (data.questionResponse.length > 1) {
          setConditionName(data.questionResponse)
          data.questionResponse = data.questionResponse[0]
        }
      }
      let updatedUserResponse = await updateUserResponses(
        currentQuestion.questionID,
        userResponses[0].id,
        data.questionResponse
      );
      if (updatedUserResponse?.data?.updateUserResponses?.id) {
        setUserResponses([updatedUserResponse.data.updateUserResponses]);
        setResponses({ ...responses, [currentQuestion.questionID]: data.questionResponse });
        setLastQuestionResponse({
          ...currentQuestion,
          questionId: currentQuestion.questionID,
          questionResponse: data.questionResponse
        });
        //switching to new row if present
        if (currentQuestion && isNullOrEmpty(currentQuestion, "nextQuestion") &&
          !isNullOrEmpty(currentQuestion, "loop") &&
          currentQuestion.loop.includes("end") &&
          rowId.length > 1
        ) {
          if (currentId === rowId.length) {
            saveAnsweredQuestion(currentQuestion, responses, conditionName, currentId, bodyPartIndex, data.questionResponse)
            return;
          }
          setCurrentId(currentId + 1)
          setQuesConditionIndex(quesConditionIndex + 1)
          const userRes = await API.graphql(
            graphqlOperation(queries.getUserResponses, {
              id: rowId[currentId]
            }))
          setUserResponses([userRes.data.getUserResponses]);
        }
      }
    }
    saveAnsweredQuestion(currentQuestion, responses, conditionName, currentId, bodyPartIndex, data.questionResponse)
    resetForm();
  }
  // This function is used to find question by id
  function findQuestionById(questionID) {
    return questions.find(q => q.questionID === questionID);
  }
  //final submission query
  async function finalSubmissionQuery(id) {
    let userId = id
    try {
      await API.graphql(
        graphqlOperation(queries.getUserResponses, {
          id: userId
        })).then(async (response) => {
          if (response.data?.getUserResponses?.id) userId = response.data.getUserResponses.id
          delete response.data.getUserResponses.id
          delete response.data.getUserResponses.updatedAt
          // delete response.data.getUserResponses.createdAt
          response.data.getUserResponses.beingTreated = false
          await API.graphql(
            graphqlOperation(mutations.createConditionsAndSymptoms, {
              input: response.data.getUserResponses
            })
          ).then(async () => {
            await API.graphql(
              graphqlOperation(mutations.deleteUserResponses, {
                input: { id: userId }
              })
            );
          })
          setStep(step + 1);
        });
    } catch (error) {
      console.warn("Error while submitting form :", error);
    }
  }
  // User Final Submission
  async function finalUserSubmission() {
    if (rowId.length > 1) {
      for (let id of rowId) {
        finalSubmissionQuery(id);
      }
      return
    }
    finalSubmissionQuery(userResponses[0].id);
  }
  // Submit final response
  async function finalSubmission() {
    let setOfQuestions = [];

    // get all the questions for which we need to create one row
    for (let question of answeredQuestions) {
      if (question) {
        // Check if question needs to store separate row
        if (question.tags && question.tags.find(t => t.saveSeparateRow)) {
          // Check if this question needs to be saved
          let answer = question.answer;
          let columnToSave = question.tags.find(t => t.ConditionsAndSymptoms);
          // check if answered
          if (answer && columnToSave) {
            let setOfAnswers = [];
            if (typeof answer == "string") setOfAnswers.push(answer);
            if (Array.isArray(answer)) {
              for (let a of answer) {
                if (typeof a === "object" && a.label) {
                  setOfAnswers.push(a.label);
                } else {
                  setOfAnswers.push(a);
                }
              }
            }
            for (let a of setOfAnswers) {
              setOfQuestions.push({
                userID: props.user.id,
                active: true,
                beingTreated: false,
                [columnToSave.ConditionsAndSymptoms]: a
              });
            }
          }
        }
      }
    }

    // For each final question answered set other attributes
    for (let questionToCreate of setOfQuestions) {
      // get all the questions for which we need to create one row
      for (let question of answeredQuestions) {
        if (question) {
          // Check if question needs to store separate row
          if (question.tags && !question.tags.find(t => t.saveSeparateRow)) {
            let columnsToSave = question.tags.filter(
              t => t.ConditionsAndSymptoms
            );
            for (let columnToSave of columnsToSave) {
              // Check if this question needs to be saved
              let answer = question.answer;
              // If we need to save the attribute
              if (columnToSave && answer) {
                let answerToSave = convertAnswerToState(
                  answer,
                  columnToSave,
                  questionToCreate
                );
                // Sometimes we might want to append answer to save field in db. Ex DOX-0013 DOX-0021
                if (columnToSave.ConditionsAndSymptoms in questionToCreate) {
                  if (
                    Array.isArray(
                      questionToCreate[columnToSave.ConditionsAndSymptoms]
                    )
                  ) {
                    questionToCreate[columnToSave.ConditionsAndSymptoms].push(
                      ...answerToSave
                    );
                  }
                } else {
                  questionToCreate[
                    columnToSave.ConditionsAndSymptoms
                  ] = answerToSave;
                }
              }
            }
          }
        }
      }
    }

    // for each set of questions save them
    if (setOfQuestions && setOfQuestions.length > 0) {
      let promises = [];
      let conditionName = "";
      for (let q of setOfQuestions) {
        if (
          q.condOrSympName &&
          q?.linkedSymp &&
          q.linkedSymp.indexOf("Pain") > -1
        ) {
          conditionName = q.condOrSympName;
        }
        if (q.bodyPartLoc) {
          q.isCond = false;
          q.isDiagCond = false;
          q.linkedCond = conditionName;
        }
        promises.push(
          API.graphql(
            graphqlOperation(mutations.createConditionsAndSymptoms, {
              input: q
            })
          )
        );
      }
      await Promise.all(promises);
    }
    await saveUserResponse("summary", "completed");

    setStep(step + 1);
  }

  // This function is used to update the progress
  function updateProgress() {
    let totalQuestions = questions ? questions.length : 0;
    if (totalQuestions) {
      let answeredQuestions = responses ? Object.keys(responses).length : 0;
      setProgress(
        parseFloat(((answeredQuestions / totalQuestions) * 100).toFixed(2))
      );
    }
    return 0;
  }

  // This function tells is there is no next question
  function isLastQuestion() {
    if (currentQuestion && !isNullOrEmpty(currentQuestion, "nextQuestion")) {
      return false;
    }
    // check if loop
    if (
      currentQuestion &&
      !isNullOrEmpty(currentQuestion, "loop") &&
      currentQuestion.loop.includes("end") &&
      !currentQuestion.loop.end
    ) {
      return false;
    }
    // check if loop and it is end of loop
    if (
      currentQuestion &&
      !isNullOrEmpty(currentQuestion, "loop") &&
      currentQuestion.loop.includes("end") &&
      currentQuestion.loop.end &&
      !checkIfLoopEnding(currentQuestion, responses, answeredQuestions)
    ) {
      return false;
    }
    return true;
  }

  function onLastQuestion() {
    if (currentQuestion && !isNullOrEmpty(currentQuestion, "nextQuestion")) {
      return false;
    }
    // check if loop
    if (
      currentQuestion &&
      !isNullOrEmpty(currentQuestion, "loop") &&
      currentQuestion.loop.includes("end") &&
      JSON.parse(currentQuestion.loop).end &&
      rowId.indexOf(userResponses[0].id) !== rowId.length - 1
    ) {
      return false;
    }
    return true;
  }

  async function proceed() {
    // based of current question take the decision
    let nextQuestion = await getNextQuestion(
      questions,
      currentQuestion,
      responses,
      answeredQuestions
    );
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
      return;
    }
    setStep(step + 1);
  }

  async function back() {
    if (
      "previousQuestion" in currentQuestion &&
      currentQuestion.previousQuestion
    ) {
      setCurrentQuestion(currentQuestion.previousQuestion);
      // const index = answeredQuestions.length - 1;
      // if ("saveUserResponseID" in answeredQuestions[index]) {
      //   await API.graphql(
      //     graphqlOperation(mutations.deleteUserResponses, {
      //       input: {
      //         id: answeredQuestions[index].saveUserResponseID
      //       }
      //     })
      //   );
      // }
      // setAnsweredQuestions(answeredQuestions.slice(0, index));
    }
  }
  function formFinished() {
    history.push("/");
  }

  function checkBodyPartValue(questions) {
    // checking the index of the bodyPart which we are editing
    // bodyPart's name are present in customResponse array
    if (bodyPartResponses && bodyPartResponses.length > 0 &&
      //  (questions.listType === "BODYPART" || questions.responseType === "MULTIPLECHOICE") ) {
      questions.responseType === "MULTIPLECHOICE") {
      bodyPartResponses.map((bodyPartResponse, index) => {
        if (index !== 0) setQuesConditionIndex(index - 1)
        if (questions.responseType === "MULTIPLECHOICE" && bodyPartResponse.length > 0) {
          bodyPartResponse.forEach((bodyPart, childIndex) => {
            const nameIndex = questions.processedQuestion.search(bodyPart)
            if (nameIndex > -1) {
              setCustomIndex(childIndex)
              setBodyPartIndex(childIndex)
            }
          })
        }
      }
      )
    } else {
      setQuesConditionIndex(0)
      setCustomIndex(0)
      setBodyPartIndex(0)
    }
  }

  // go to clicked question
  function edit(questions, index) {
    setStep(step - 1);
    setEditQuestion([...answeredQuestions]);
    setUpdateEditQuestion([...answeredQuestions]);
    setCurrentQuestion(questions);
    setAnsweredQuestions(answeredQuestions.slice(0, index));
    setIsBodyPart([])
    setLoopQuestionId('')

    // checking the index of the condition name which we are editing
    if (conditionName && conditionName.length > 0) {
      conditionName.map(async (condition) => {
        // const nameIndex = questions.processedQuestion.search(condition)
        let index;
        if (!questions.userResponseRowId) {
          const nameIndex = questions?.processedQuestion?.search(condition)
          if (nameIndex > -1) index = conditionName.indexOf(condition)
        }
        else index = rowId.indexOf(questions.userResponseRowId)
        setCurrentId(index + 1)
        setConditionIndex(index + 1)
        checkBodyPartValue(questions)
        const userResp = await API.graphql(
          graphqlOperation(queries.getUserResponses, {
            id: rowId[index]
          }))
        setUserResponses([userResp.data.getUserResponses]);
      })
    } else {
      checkBodyPartValue(questions, JSON.parse(userResponses[0]?.dox024))
    }

    // setResponses({...responses,[questions.questionID]: questions.answer});
  }

  function renderSummaryRows() {
    try {
      // removing duplicates
      const uniqueAnsweredQuestions = removeDuplicateAnswereQuestion(answeredQuestions)

      // Create an array for summary of questions
      let summaryOfQuestions = [];
      for (let question of uniqueAnsweredQuestions) {
        if (question) {
          let answer = question.answer;
          let answerToDisplay = Object.assign(answer ?? [""]);
          if (Array.isArray(answer)) {
            answerToDisplay = [];
            for (let a of answer) {
              if (typeof a === "object" && a.label) {
                answerToDisplay.push(a.label);
              } else {
                answerToDisplay.push(a);
              }
            }
            answerToDisplay = answerToDisplay.join(", ");
          } else if (question.responseType === "DATE") {
            answerToDisplay = `${answer.getMonth() +
              1}/${answer.getDate()}/${answer.getFullYear()}`;
          } else if (question.responseType === "MULTIPLELISTS") {
            answerToDisplay = [];
            for (let key in answer) {
              answerToDisplay.push(`${key}=${answer[key]}`);
            }
            answerToDisplay = answerToDisplay.join(", ");
          }
          summaryOfQuestions.push({ ...question, answer: answerToDisplay });
        }
      }

      return (
        <React.Fragment>
          <tbody>
            {summaryOfQuestions.map((question, index) => {
              return (
                <tr key={index}>
                  <td>{question?.processedQuestion}</td>
                  <td>{question?.answer}</td>
                  <td className="text-center">
                    <Tooltip arrow title="Edit Details">
                      <IconButton
                        onClick={e => edit(question, index)}
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </React.Fragment>
      );
    } catch (error) {
      console.warn(error)
      return null;
    }
  }

  function renderThankYou() {
    return (
      <React.Fragment>
        <Card className="card-box text-center mb-4">
          <div className="card-header pr-2">
            <div className="card-header--title font-weight-bold text-center">
              Thank You
            </div>
          </div>
          <CardContent className="p-3 text-center">
            <div>
              Thank you for choosing Doximple to help take control of your
              health!
            </div>
          </CardContent>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="mb-3"
            onClick={() => history.goBack()}
          >
            Ok
          </Button>

        </Card>
      </React.Fragment>
    );
  }
  function renderSummary() {
    return (
      <React.Fragment>
        <Card className="card-box mb-4">
          <div className="card-header pr-2">
            <div className="card-header--title">Review</div>
          </div>
          <CardContent className="p-3">
            <div className="table-responsive">
              <table className="mb-0 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Questions</th>
                    <th className="text-left">Answers</th>
                    <th className="text-left">Actions</th>
                  </tr>
                </thead>

                {renderSummaryRows()}
              </table>
            </div>
          </CardContent>
          <div className="card-footer d-flex justify-content-center">
            {/* <Button
              onClick={e => {
                setStep(step - 1);
                answeredQuestions.pop();
              }}
              variant="contained"
              color="primary"
              size="large"
              className="mr-2"
            >
              Back
            </Button> */}
            <div>
              {/* <Link to="/"> */}
              {/* <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className="mr-2"
                  onClick={ () => {
                    setStep(step+1)
                  }}
                >
                  Save and Return Later
                </Button> */}
              {/* </Link> */}
              <Button
                onClick={async e => {
                  await finalUserSubmission();
                }}
                size="large"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
  function renderQuestion() {
    return (
      <Layout>
        {/* <LocationTabs></LocationTabs> */}
        <LinearProgress variant="determinate" value={progress} />
        {currentQuestion && (
          <Formik
            validateOnMount={true}
            initialValues={initialValue}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await submitUserResponse(data, true, false, resetForm);
              setUserData(data);
              //resetForm();
              setSubmitting(false);
            }}
            validate={values => {
              const errors = {};
              if (currentQuestion.responseType === "MULTIPLELISTS") {
                // atleast one of the value is present
                let atleastOneSelected = false;
                for (let key of Object.keys(values)) {
                  if (values[key]) {
                    atleastOneSelected = true;
                  }
                }
                if (!atleastOneSelected) {
                  errors[currentQuestion.lists[0]] =
                    "Select atleast one of the option";
                }
              } else if (currentQuestion.responseType === "MULTITEXT") {
                if (values.questionResponse) {
                  errors.questionResponse = [];
                  let atleastOneError = false;
                  let regex = /^[a-zA-Z ]*$/;
                  for (let i = 0; i < values.questionResponse.length; i++) {
                    let v = values.questionResponse[i];
                    errors.questionResponse[i] = "";
                    if (!v) {
                      atleastOneError = true;
                      errors.questionResponse[i] = "Enter an answer";
                    } else if (v.length > 20) {
                      atleastOneError = true;
                      errors.questionResponse[i] = "Max 20 characters allowed";
                    } else if (!regex.test(v)) {
                      atleastOneError = true;
                      errors.questionResponse[i] = "Only letters are allowed";
                    }
                  }
                  if (!atleastOneError) {
                    delete errors.questionResponse;
                  }
                }
              } else if (
                typeof values.questionResponse === "string" &&
                !values.questionResponse
              ) {
                errors.questionResponse = "Select an answer";
              } else if (
                values.questionResponse &&
                Array.isArray(values.questionResponse) &&
                values.questionResponse.length === 0
              ) {
                errors.questionResponse = "Select an answer";
              }

              return errors;
            }}
            enableReinitialize={true}
          >
            {/* {(values, isSubmitting, onSubmit, resetForm, isValid)} */}
            {formik => {
              return (
                <Form>
                  <Layout>
                    <div>
                      <h1
                        css={css`
                          // color: #a8d8f0;
                          padding-top: 20px;
                          padding-bottom: 10px;
                          text-align: center;
                        `}
                      >
                        {replaceUserQuestion(currentQuestion, responses, conditionName, currentId, bodyPartIndex, userResponses[0])}
                      </h1>
                    </div>
                    <div id="wrapper">
                      {currentQuestion.responseType === "MULTIPLECHOICE" && (
                        <FormikRadioGroupField
                          name="questionResponse"
                          margin="normal"
                          options={currentQuestion.options}
                        />
                      )}
                      {currentQuestion.responseType === "MULTIPLELISTS" && (
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          {currentQuestion.lists.map((value, index) => (
                            <Grid item xs={12} key={index}>
                              <FormikSelect
                                label={value}
                                name={value}
                                value={formik.values}
                                options={currentQuestion.options[value]}
                                allowEmpty={true}
                              ></FormikSelect>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {currentQuestion.responseType === "MULTITEXT" && (
                        <FieldArray
                          name="questionResponse"
                          render={arrayHelpers => (
                            <div>
                              {formik.values.questionResponse &&
                                Array.isArray(formik.values.questionResponse) &&
                                formik.values.questionResponse.length > 0 ? (
                                formik.values.questionResponse.map(
                                  (option, index) => (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      key={index}
                                    >
                                      <FormikTextField
                                        name={`questionResponse.${index}`}
                                        margin="normal"
                                      ></FormikTextField>
                                      <Button
                                        type="button"
                                        variant="outlined"
                                        color="inherit"
                                        className="text-google ml-2"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        } // remove a friend from the list
                                      >
                                        -
                                      </Button>

                                      <Button
                                        type="button"
                                        color="primary"
                                        className="ml-2"
                                        variant="contained"
                                        onClick={() =>
                                          arrayHelpers.insert(index, "")
                                        } // insert an empty string at a position
                                      >
                                        +
                                      </Button>
                                    </div>
                                  )
                                )
                              ) : (
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {/* show this when user has removed all friends from the list */}
                                  Add a Symptom
                                </Button>
                              )}
                            </div>
                          )}
                        />
                      )}

                      {currentQuestion.responseType === "TEXT" && (
                        <FormikTextField
                          name="questionResponse"
                          margin="normal"
                          fullWidth
                        ></FormikTextField>
                      )}
                      {currentQuestion.responseType === "DATE" && (
                        <FormikDatePicker
                          value={formik.values.questionResponse ? new Date(moment(formik.values.questionResponse)) : new Date()}
                          name="questionResponse"
                          format="MM/dd/yyyy"
                          maxDate={new Date(Date.now())}      /* ristricting user to select future dates */
                        ></FormikDatePicker>
                      )}
                      {currentQuestion.responseType === "LIST" && (
                        <FormikCheckboxGroupField
                          name="questionResponse"
                          margin="normal"
                          options={currentQuestion.options}
                          row={false}
                          fullWidth
                          multiple
                        />
                      )}
                    </div>
                    <br />
                    <div className="d-flex align-items-center justify-content-center">
                      {!currentQuestion.previousQuestion && (
                        <Button
                          component={Link}
                          to="/"
                          variant="outlined"
                          color="primary"
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      )}

                      {/* {currentQuestion.previousQuestion && (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={e => back()}
                        >
                          &larr; Back
                        </Button>
                      )} */}
                      {currentQuestion.previousQuestion && (
                        <Button
                          onClick={e => submitResponse(formik.values, false)}
                          // onClick={() => showModal()}
                          variant="outlined"
                          color="primary"
                          className="ml-2"
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          Save and Return Later
                        </Button>
                      )}
                      <Button
                        className="ml-2"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {onLastQuestion() ? "Submit" : "Next"}
                      </Button>
                      {editQuestion && editQuestion.find(x => currentQuestion.questionID === x.questionID && currentQuestion.userResponseRowId === x.userResponseRowId) &&
                        showSaveAndExitButton(formik.values.questionResponse) &&
                        (
                          <Button
                            onClick={() => {
                              let questionResp = formik.values.questionResponse
                              if (currentQuestion.listType?.includes("Years,Months,Days")) questionResp = formik.values
                              saveAndExitResponse(currentQuestion.questionID, userResponses[0].id, questionResp)
                            }}
                            variant="outlined"
                            color="secondary"
                            className="ml-2"
                            disabled={formik.isSubmitting || !formik.isValid}
                          >
                            Save and Exit Editing
                          </Button>
                        )}
                    </div>
                    <Modal
                      settings={deleteConditionModal}
                      close={async success => {
                        if (success === 'delete and proceed') {
                          await deleteConditionResponse(oldConditions)
                          const index = conditionName.indexOf(formik.values.questionResponse[0])
                          await handleSaveAndExitResponse(currentQuestion.questionID, rowId[index], formik.values.questionResponse, true)
                        }
                        setDeleteConditionModal({ ...deleteConditionModal, open: false });
                      }}
                    />
                    <Modal
                      settings={addConditionModal}
                      close={() => {
                        setAddConditionModal({ ...addConditionModal, open: false });
                      }}
                    />
                    <Modal
                      settings={addAndDelConditionModal}
                      close={async success => {
                        if (success === 'delete and proceed') {
                          setConditionName(formik.values.questionResponse)
                          for (let response of userNewConditions) {
                            let savedUserResponse = await saveUserResponse(
                              currentQuestion.questionID,
                              response
                            );
                            if (savedUserResponse?.data?.createUserResponses?.id) {
                              if (!rowId.includes(savedUserResponse?.data?.createUserResponses?.id)) rowId.push(response?.data?.createUserResponses?.id)
                              const quesIdsToBeUpdated = ["DOX-0001", "DOX-0003"]
                              for (let quesID of quesIdsToBeUpdated) {
                                await updateUserResponses(
                                  quesID,
                                  savedUserResponse?.data?.createUserResponses?.id,
                                  responses[quesID]
                                );
                              }
                            }
                          }
                          // deleting response
                          if (oldConditions.length > 0) {
                            await deleteConditionResponse(oldConditions)
                            await API.graphql(
                              graphqlOperation(queries.getUserResponses, {
                                id: rowId[0]
                              })
                            ).then(data => {
                              setUserResponses([data.data.getUserResponses]);
                              setResponses({ ...responses, [currentQuestion.questionID]: formik.values.questionResponse });
                              setLastQuestionResponse({
                                ...currentQuestion,
                                questionId: currentQuestion.questionID,
                                questionResponse: { default: currentQuestion.nextQuestion.default },
                                loopQuestionResponse: formik.values.questionResponse
                              });
                            })
                          }
                        }
                        setAddAndDelConditionModal({ ...addAndDelConditionModal, open: false });
                      }}
                    />
                    <Modal
                      settings={deletePainModal}
                      close={async success => {
                        if (success === 'delete and proceed') {
                          await deletePainResponse()
                          await handleSaveAndExitResponse(currentQuestion.questionID, userResponses[0].id, formik.values.questionResponse, true)
                        }
                        setDeletePainModal({ ...deletePainModal, open: false });
                      }}
                    />
                    <Modal
                      settings={addPainModal}
                      close={async success => {
                        if (success === 'save and proceed') {
                          await handleSaveAndExitResponse(currentQuestion.questionID, userResponses[0].id, formik.values.questionResponse, success)
                        }
                        setAddPainModal({ ...addPainModal, open: false });
                      }}
                    />
                    <Modal
                      settings={userPathSwitchModal}
                      close={async success => {
                        if (success === 'delete and proceed') {
                          try {
                            rowId.forEach(async id => await deleteCurrentSession(id))
                            await saveUserWhenPathChanges(formik.values.questionResponse);
                            setUserPathSwitchModal({ ...userPathSwitchModal, open: false });
                          } catch (err) {
                            setUserPathSwitchModal({ ...userPathSwitchModal, open: false });
                          }
                        } else if (success === 'save and proceed') {
                          await saveUserWhenPathChanges(formik.values.questionResponse);
                          setUserPathSwitchModal({ ...userPathSwitchModal, open: false });
                          return
                        } else {
                          setUserPathSwitchModal({ ...userPathSwitchModal, open: false });
                          return
                        }
                      }}
                    />
                  </Layout>
                </Form>
              );
            }}
          </Formik>
        )}
        <ConfirmationModal
          settings={confirmationModal}
          close={success => {
            if (success) {
              handleResumeSession();
            } else {
              setSession(uuidv4());
            }
            setConfirmationModal({ ...confirmationModal, open: false });
          }}
        ></ConfirmationModal>
      </Layout>
    );
  }

  function getEmptyInitialValues() {
    let initialValue = {};
    if (currentQuestion.responseType === "MULTIPLELISTS") {
      for (let l of currentQuestion.lists) {
        initialValue[l] = "0";
      }
    } else {
      //LIST
      initialValue.questionResponse = "";
      if (currentQuestion.responseType === "LIST") {
        initialValue.questionResponse = [];
      }
      if (currentQuestion.responseType === "MULTITEXT") {
        initialValue.questionResponse = [];
      }
    }
    return initialValue;
  }
  function calculateInitialValues(questionsAnswered) {
    let initialValue;
    initialValue = getEmptyInitialValues();
    // We also need to check if question is already answered then take it from responses given by user
    let questionAnswered = questionsAnswered.filter(
      q => q.questionID === currentQuestion.questionID && q.userResponseRowId === userResponses[0]?.id
    );

    // if (currentQuestion.listType === "CONDITION" && 
    //   currentQuestion.responseType === "LIST" && 
    //   rowId.length > 1
    //   ) {
    //     initialValue.questionResponse = ""
    //   } 
    if (
      questionAnswered &&
      questionAnswered.length > 0 &&
      questionAnswered[0].answer
    ) {

      if (
        currentQuestion &&
        !isNullOrEmpty(currentQuestion, "loop") &&
        currentQuestion.loop.includes("goto") &&
        currentQuestion.responseType === "MULTIPLECHOICE" &&
        customResponse.length !== customIndex
      ) {

        // checking bodyPart loop is answered while use hit on edit button 
        // else we are nullifying the intial value if the bodyPart loop is running
        if (editQuestion && bodyPartResponses && bodyPartResponses.length > 0 && questionAnswered.length >= bodyPartIndex &&
          questionAnswered[bodyPartIndex]?.responseType === "MULTIPLECHOICE") {
          const nameIndex = questionAnswered[bodyPartIndex]?.processedQuestion?.search(bodyPartResponses[quesConditionIndex][bodyPartIndex])
          if (nameIndex > -1) {
            initialValue.questionResponse = questionAnswered[bodyPartIndex].answer;
          }
        } else {
          initialValue.questionResponse = "";
        }
      } else {
        // This means user has already answered the question and now coming using back button or resume or edit
        // changing the structure of response if the Y/M/D is answered
        if ("Years" in initialValue && "Months" in initialValue && "Days" in initialValue) {
          initialValue = { ...questionAnswered[0].answer }
        } else {
          initialValue.questionResponse = questionAnswered[0].answer;
        }
      }
    }
    setInitialValue(initialValue)
    // setInitialValue(()=>currentQuestion.responseType == 'DATE' ? new Date():initialValue);
    return initialValue;
  }

  return (
    <div>
      <>
        <h1
          css={css`
            padding-top: 40px;
            padding-bottom: 30px;
            text-align: center;
          `}
        >
          Symptom Form
        </h1>
        {step === 1 && renderQuestion()}
        {step === 2 && renderSummary()}
        {step === 3 && renderThankYou()}
      </>
    </div>
  );
};

export default SymptomConditionFormContainer;
