import React, { createRef, useEffect } from 'react';
import {
    FormGroup,
    Grid,
    TextField
  } from "@material-ui/core";

export default function OtpInputField({name, value, onChange, onKeyUp, focus,tabIndex,setCurrentInput}) {

    const inputRef = createRef();

    useEffect(() => {
        if(focus){
            inputRef.current.focus();
        }
    },[focus])
  return (
      <>
    <Grid item xs={3} direction="column" >
        <FormGroup>
            <TextField 
                variant="outlined"
                name={name}
                value={value}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onFocus={(e) => {
                    setCurrentInput(tabIndex)
                  }}
                className="otpInput"
                autoComplete="off"
                inputRef={inputRef}
                inputProps={{ 
                    tabIndex: {tabIndex},
                    maxLength: "1",
                    
                }}
            />
        </FormGroup>
        {tabIndex < 3 && (
            <FormGroup>
                <span className="divider-otp">-</span>
            </FormGroup>
        )}
    </Grid>
    
     </>
  );
}