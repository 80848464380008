import React, { useState } from 'react'
import { Auth } from "aws-amplify";

const useAuthServices = () => {
    const [loading, setLoading] = useState(false)

    const resendEmail = async(email) => {
        setLoading(true)
        try{
          await Auth.resendSignUp(email)
          return true
        }catch(error){
          console.log('Error', error)
        }finally{
            setLoading(false)
        }
    }

  return {
      resendEmail,
      loading
  }
}

export default useAuthServices
