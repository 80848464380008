export const muiTheme = {

    primary: '#5383ff',
    primaryLight: '#495368',
    primaryDark: '#09142a',
    secondary: '#3d4977',
    inheritDefault1: '#fefefe',
    inheritDefault2: '#f8f9ff',

    second: '#070919',
    indigo: '#7420ff',
    purple: '#793de6',
    pink: '#fc26a4',
    red: '#f83245',
    orange: '#f4772e',
    yellow: '#ffc926',
    green: '#1bc943',
    teal: '#18e1a5',
    cyan: '#27dcf3',

}


export const Questiontypes = [
    { id: 'TEXT', value: 'Input' },
    { id: 'SINGLE', value: 'Radio' },
    { id: 'MULTIPLE', value: 'Checkbox' },
    { id: 'FILE', value: 'File' },
    { id: 'DATE', value: 'Date' },
    { id: 'TIME', value: 'Time' },
    { id: 'SELECT', value: 'Dropdown' },
    { id: 'RANGE', value: 'Range Picker' }
  ]
