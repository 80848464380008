import { Button, Dialog } from "@material-ui/core";
import { Cancel, Clear, Close, Delete } from "@material-ui/icons";
import React, { Fragment, useEffect, useState } from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const ConfirmationModal = props => {
  const [modal, setModal] = useState(props.settings.open);
  useEffect(() => {
    setModal(props.settings.open);
  }, [props.settings.open]);
  const toggle = success => {
    setModal(!modal);
    if ("close" in props) {
      props.close(success);
    }
  };
  const defaultConfirm = "Yes, delete it!";

  return (
    <Fragment>
      <Dialog open={modal} onClose={() => toggle(false)}>
        <div className="text-center p-4">
          <p>
            {props.settings?.confirm ? (
              <ErrorOutlineIcon style={{ color: 'red', fontSize: '60px' }} />
            ) : (
              <Cancel style={{ color: 'red', fontSize: '60px' }} />
            )}
            </p>
          <h5 className=" mt-2">{props.settings.title}</h5>
          <h5 className=" mt-2">{props.settings.title2}</h5>
          
          {/* <ul style={{ textAlign: 'left' }}>
            {props.items && props.items.length != 0 && props.items.map(item => {
              return <li>{item.name}</li>
            })}
          </ul> */}

          <div className="pt-2">
            <Button
              onClick={() => toggle(true)}
              variant="contained"
              style={{ background: "red", color: 'white' }}
              className="mx-1"
            >
              <span className="btn-wrapper--label">{props.settings?.confirm ? props.settings?.confirm: defaultConfirm}</span>
            </Button>

            <Button
              onClick={() => toggle(false)}
              color="secondary"
              variant="outlined"
              className="mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmationModal;
