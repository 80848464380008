import React from 'react'
import { useUser } from "../../providers/UserProvider";

const AmazonLogin = (props) => {

    const { signInAmazon } = useUser()

    const handleAmazon = async (e) => {
        console.log("Work 1")
        props.setSocialLoading(true);
        try {
          props.setAmazonSubmitting(true);
          console.log("Work 2")
          await signInAmazon();
          props.setAmazonSubmitting(false);
        } catch (err) {
          console.log("Work 3")
          props.setAmazonSubmitting(false);
          props.setFailedAttempt(props.failedAttempt + 1);
          props.setSocialLoading(false);
          let message = "Unknown error has occured";
          if (err && err.message) {
            message = err.message;
          }
          props.setSnackBarDetails({
            message,
            severity: "error",
            links: false
          });
        }
      }

    return (
        <a
            className="my-2 mt-2 mb-2 ml-4"
            type="button"
            href
            id="LoginWithAmazon"
            onClick={handleAmazon}
        >
            <img border="0" alt="Login with Amazon"
                src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_312x64.png"
                width="210" height="43" />
        </a>
    )
}

export default AmazonLogin