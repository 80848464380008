import React from 'react'
import { useUser } from '../providers/UserProvider';
import { listCampaign } from '../user/containers/curd/campaign';
import { listCoupons } from '../user/containers/curd/coupon';
import { listCustomerCategories } from '../user/containers/curd/customerCategory';
import { listCustomerCategoriesRelation } from '../user/containers/curd/customerCategoryRelation';

const useVendorService = () => {
    const { user } = useUser();

    const GetuniqueData = (arr2, matches) => {
        return arr2.filter((obj, index) => {
            return index === arr2.findIndex(o => obj[matches] === o[matches]);
        });
    }

    const couponNotRedeemed = async() => {
        const listCoupon = await listCoupons()
        return listCoupon.filter((item) => {
            return item.campaignID !== null && (item.couponRedeemed.items?.findIndex((id)=>  (id.redeemBy === user.id)))
        })
    }

    const showNonredeemCampaign = async() => {
        //Get the list of category list in which current user exits
        const customerCategoryList = await listCustomerCategoriesRelation(user.id)

        // Get the list of campaign
        const listCampaignIds = await listCampaign()

        // Here we are filter the category list and make the user to have unique customerCategoryList
        const getunquieCustomerCategory = GetuniqueData(customerCategoryList, "customerCategoryID")

        // Filter the campaign which have only bitly link campaign
        const getbitlycampaign = listCampaignIds.filter((item) => item.triggerID === "3")

        // List down the campaigns which have current user customerCategoryID
        const campaignwithCustomeID = getbitlycampaign.filter((campaign) => getunquieCustomerCategory.some((ucid) => JSON.parse(campaign.customerCategoryID).includes(ucid.customerCategoryID) && campaign.isActive === true && campaign.isFuture === true))
        
        // List of the Coupons which is not redeem by the current user yet
        const Usercoupon = await couponNotRedeemed()

        // List down the latest campaign which have unredeemed coupons
        const GetLatestCampaign = campaignwithCustomeID.filter((campaign) => Usercoupon.some((coupon) => JSON.parse(campaign.coupons)[0] === coupon.id))
        
        if(GetLatestCampaign.length < 1) return[]
        // Take out the 1 campaign from GetLatestCampaign
        return GetLatestCampaign.reverse()
    }



  return {
    showNonredeemCampaign,
    GetuniqueData
  }
}

export default useVendorService