import * as Yup from 'yup';
import {
    Button,
    FormGroup,
    Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { allUserList, Create, listVendors, Update, updateAllUsersByCompanyName } from '../../containers/curd/vendors';
import Auth from '@aws-amplify/auth';
import FormikTextFieldPasswordGenerator from '../../../common/components/FormikTextFieldPasswordGenerator';
const validationSchemaCommon = {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
        .required("Email is required")
        .email("Please enter a valid email address"),
    mobile: Yup.string()
        .required("Mobile number is required")
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Mobile number is not valid"
        ).min(7, "Mobile number is not valid")
        .max(12, "Mobile number is not valid"),
}
const validationSchema = Yup.object({
    ...validationSchemaCommon,
    password: Yup.string()
        .required("Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    companyName: Yup.string().required("Company Name is required").test(
        'unique-compnay-name',
        'This Company name is already taken, please specify another one',
        async value => !!value && await allUserList(value)
    )

})

const validationSchemaEdit = Yup.object({
    ...validationSchemaCommon,
    companyName: Yup.string().required()

})

const useStyles = makeStyles({
    root: {
        border: '1.5px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 10,
        padding: "10px 14px 18.5px 14px"
    },
});

function CreateVendors({ user, closeModal, setCreatedItem, editable, setUpdatedItem, setSnackBarDetails }) {
    const [isLoading, setIsLoading] = useState(false);
    const [companyName, setCompanyName] = useState([]);
    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        companyName: '',
        redeemCode: ''
    });
    
    let accountType = [];
    if (user.accountType === 'SuperAdmin' || user.accountType === 'SuperAdminUser') {
      accountType = ['SuperAdminUser']
    } else if (user.accountType === 'VendorAdmin' || user.accountType === 'VendorAdminUser') {
      accountType = ['VendorAdmin', 'VendorAdminUser']
    }
    const random = async(values) => {
        let rand = 0;
        let exists = true;
        
        while (exists) {
            rand = Math.floor(1000 + Math.random() * 9000);
            let vendors = await listVendors(user.id, user.accountType, rand, values['companyName']);
            if(vendors.length > 0){
                exists = true;
            }else{
                exists = false;
            }
        }
        return rand.toString();
    }

    const handleRedeemCodeGenerator = async(values) => {
        let newRedeemCode = await random(values);
        setInitialValues({ ...values,  redeemCode: newRedeemCode })
    }

    useEffect(() => {
        setInitialValues((values) => {
            // if (!editable) return {...values, redeemCode: random()};
            if (!editable) return values;
            let { firstName, lastName, mobile, email, password, accountType, companyName,redeemCode } = editable;
            return {
                firstName, lastName, mobile, email, password, accountType, companyName,redeemCode
            }
        })

    }, [editable])
    

    const handlePasswordGenerator = (e, values) => {
        let keys = {
            upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            lowerCase: "abcdefghijklmnopqrstuvwxyz",
            number: "0123456789",
            symbol: "@#$%&*"
        }
        let getKey = [
            function upperCase() {
                return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
            },
            function lowerCase() {
                return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
            },
            function number() {
                return keys.number[Math.floor(Math.random() * keys.number.length)];
            },
            function symbol() {
                return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
            }
        ];
        let pass = "";
        while (12 > pass.length) {
            let keyToAdd = getKey[Math.floor(Math.random() * getKey.length)];
            pass += keyToAdd();
        }
        setInitialValues({ ...values, password: pass })
    }
    const classes = useStyles();
    const handleResetButton = (e) => {
        setInitialValues((values) => {
            if (!editable) return setInitialValues({
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                password: '',
                companyName: '',
                redeemCode: ''
            });
            let { firstName, lastName, mobile, email, password, accountType, companyName, redeemCode } = editable;
            return {
                firstName, lastName, mobile, email, password, accountType, companyName, redeemCode
            }
        })
    }
    return (
        <div>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={!editable ? validationSchema : validationSchemaEdit}
                onSubmit={async (values) => {
                    try {
                        if(!values['redeemCode']){
                            setSnackBarDetails({
                                message: 'Please generate redeem code',
                                severity: "error",
                                links: [],
                            });
                            return;
                        }
                        if (!editable) {
                            const userData = await Auth.signUp({
                                username: values.email,
                                password: values.password
                            });
                            delete values['password'];
                            let { data } = await Create({ ...values, createdBy: user.id, id: userData.userSub, accountType: 'VendorAdmin', isAccountActive: true, isDeleted: false, isPasswordSet: false });
                            setCreatedItem(data.createUsers)
                        }
                        else {
                            delete values['password'];
                            let { data } = await Update({ ...values, id: editable.id })
                            setUpdatedItem(data.updateUsers);
                            if (editable.companyName != values.companyName) {
                                updateAllUsersByCompanyName(editable.companyName, values.companyName)
                            }
                        }

                        setInitialValues({
                            firstName: '',
                            lastName: '',
                            email: '',
                            mobile: '',
                            password: '',
                            companyName: '',
                            redeemCode: ''
                        })
                        setSnackBarDetails({
                            message:
                                `Vendor ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        closeModal()
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form>
                        <Grid container spacing={2} >
                            <Grid container item xs={6} direction="column" >
                                <FormGroup>
                                    <FormikTextField
                                        name="firstName"
                                        margin="normal"
                                        label="First Name"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid container item xs={6} direction="column" >
                                <FormGroup>
                                    <FormikTextField
                                        name="lastName"
                                        margin="normal"
                                        label="Last Name"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <FormGroup>
                            <FormikTextField
                                disabled={editable}
                                name="email"
                                margin="normal"
                                label="Email"
                            />
                        </FormGroup>
                        {!editable && <FormGroup style={{ display: 'inline' }}>
                            <FormikTextFieldPasswordGenerator
                                variant="outlined"
                                name="password"
                                type={editable ? "password" : "text"}
                                label="Password"
                                placeholder="Password"
                                margin="normal"
                                disabled={editable}
                                className={classes.textField}

                            >
                            </FormikTextFieldPasswordGenerator>
                            <Button
                                type="button"
                                className="mt-4 ml-4"
                                variant="contained"
                                disabled={editable}
                                onClick={(e) => handlePasswordGenerator(e, values)}
                            >
                                Generator
                            </Button>
                        </FormGroup>}
                        <FormGroup style={{ display: 'inline' }}>
                            {(!editable || editable?.redeemCode===null) && (
                                <>
                                <FormikTextFieldPasswordGenerator
                                    variant="outlined"
                                    type={'text'}
                                    name="redeemCode"
                                    label="Redeem Code"
                                    placeholder="Redeem Code"
                                    margin="normal"
                                    disabled={true}
                                    className={classes.textField}
                                >
                                </FormikTextFieldPasswordGenerator>
                                <Button
                                    type="button"
                                    className="mt-4 ml-4"
                                    variant="contained"
                                    disabled={false}
                                    onClick={(e) => handleRedeemCodeGenerator(values)}
                                >
                                    Generator
                                </Button>
                                </>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField
                                name="mobile"
                                margin="normal"
                                label="Mobile Number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField
                                name="companyName"
                                margin="normal"
                                label="Company Name"
                            />
                            {/* <Field name='companyName' component={FormikAutocomplete} label="Company Name"
                                options={companyName}
                                textFieldProps={{ fullWidth: true, margin: 'normal', variant: 'outlined' }}
                            /> */}
                        </FormGroup>
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid || isLoading}
                        >
                            {editable ? 'Update' : 'Submit'}
                        </Button>
                        {!editable && (
                        <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            onClick={(e) => handleResetButton(e)}
                        >
                            Reset
                        </Button>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateVendors
