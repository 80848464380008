import API, { graphqlOperation } from "@aws-amplify/api";
import * as moment from "moment";
import * as queries from "../../graphql/queries";
import { isNullOrEmpty } from "../utils/common";
export const listEnumValues = `query ListEnumValues($enum: String!) { enum: __type(name: $enum) { enumValues { name } } }`;

function processEnum(name) {
  if (name) {
    return name.replace(/_/g, " ");
  }
  return name;
}
export function mapListItemToOptionFormat(d) {
  let name = d.name;
  name = processEnum(name);
  return { label: name, value: d.name };
}
export async function getList(listType) {
  function compare(a, b) {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  }
  if (listType === "Years") {
    let options = [];
    for (let i = 1; i < 20; i++) {
      options.push({ label: `${i}`, value: `${i}` });
    }
    options.push({ label: `20+`, value: `20+` });
    return options;
  }

  if (listType === "Months") {
    let options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ label: `${i}`, value: `${i}` });
    }
    return options;
  }

  if (listType === "Days") {
    let options = [];
    for (let i = 1; i <= 30; i++) {
      options.push({ label: `${i}`, value: `${i}` });
    }
    return options;
  }

  // let listDataResponse = await API.graphql(
  //   graphqlOperation(listEnumValues, {
  //     enum: listType,
  //   })
  // );
  const listDataResponse = await API.graphql(
    graphqlOperation(queries.listTrackedItems, {
      filter: {
        cat: {
          eq: listType
        }
      }
    })
  );

  if (
    listDataResponse &&
    !isNullOrEmpty(listDataResponse, "data") &&
    !isNullOrEmpty(listDataResponse.data, "listTrackedItems") &&
    !isNullOrEmpty(listDataResponse.data.listTrackedItems, "items")
  ) {
    listDataResponse.data.listTrackedItems.items.sort(compare);
    return listDataResponse.data.listTrackedItems.items.map(d => {
      return { label: d.name, value: d.name };
    });
  }
  return [];
}

export function getLoopQuestionCondition(condition) {
  let valueToReplace = condition;
  if (typeof condition === "object" && !isNullOrEmpty(condition, "label")) {
    valueToReplace = condition.label;
  }
  return valueToReplace;
}
export function replaceQuestion(question) {
  if (question && question.loopingOnAnswer) {
    let replaceWith = getLoopQuestionCondition(question.loopingOnAnswer);

    let valueToReplace = "{Condition}";
    // Check if question has any tags
    if (question?.tags?.length > 0) {
      let tag = question.tags.find(t => t.replaceInLoop);
      if (tag) {
        valueToReplace = tag.replaceInLoop;
      }
    }
    return question.question.Question.replace(valueToReplace, replaceWith);
  }
  return question?.question?.Question;
}

export function replaceUserQuestion(
  question,
  response,
  conditionName,
  currentId,
  bodyPartIndex,
  userResponses
) {
  if (question && question.processedQuestion) {
    return question?.processedQuestion;
  } else if (
    question &&
    question.question &&
    question.question.Question &&
    userResponses
  ) {
    let valueToReplace = checkValueToReplace(question.question.Question);
    let replaceWith;
    if ("dox002" in userResponses || "dox016" in userResponses) {
      replaceWith = checkValueToReplaceWith(
        response,
        conditionName,
        currentId,
        valueToReplace,
        bodyPartIndex,
        userResponses
      );
      if (Array.isArray(replaceWith))
        replaceWith = replaceWith[0]
          ?.replace("[", "")
          .replace('"', "")
          .replace('"', "")
          .replace("]", "");
      else
        replaceWith = replaceWith
          ?.replace("[", "")
          .replace('"', "")
          .replace('"', "")
          .replace("]", "");
    }
    return question.question.Question.replace(valueToReplace, replaceWith);
  }
  return question?.question?.Question;
}

export function checkValueToReplace(condition) {
  let valueToReplace;
  if (condition.includes("{Condition}")) {
    valueToReplace = "{Condition}";
  }
  if (condition.includes("{bodyPartLoc}")) {
    valueToReplace = "{bodyPartLoc}";
  }
  return valueToReplace;
}

export function checkValueToReplaceWith(
  response,
  conditionName,
  currentId,
  valueToReplace,
  bodyPartIndex,
  userResponses
) {
  let replaceWith;
  if (
    valueToReplace === "{bodyPartLoc}" &&
    Array.isArray(JSON.parse(userResponses["dox024"]))
  ) {
    // if(valueToReplace === "{bodyPartLoc}" && Array.isArray(JSON.parse(userResponses['dox024']))) {
    replaceWith = getLoopQuestionCondition(
      JSON.parse(userResponses["dox024"])[bodyPartIndex]
    );
  } else if (
    conditionName.length < 1 &&
    Array.isArray(userResponses["dox002"])
  ) {
    // }else if (conditionName.length < 1 && Array.isArray(JSON.parse(userResponses['dox002']))) {
    replaceWith = userResponses["dox002"];
  } else if (conditionName.length < 1) {
    replaceWith = userResponses["dox002"];
  } else {
    replaceWith = conditionName[currentId - 1];
  }
  return replaceWith;
}

// This function is used to convert dropdown data from db to UI
function convertToOptions(json) {
  let options = [];
  for (let key in json) {
    options.push({ label: json[key], value: json[key] });
  }
  return options;
}

// This function is used to do some processing based on data returned from database
export function postProcessQuestion(currentQuestion) {
  if (currentQuestion) {
    currentQuestion.questionResponse = "";
    currentQuestion.question = JSON.parse(currentQuestion.question);
    // if (currentQuestion.tags) {
    //   currentQuestion.tags = JSON.parse(currentQuestion.tags);
    // }
    currentQuestion.responseType = currentQuestion.responseType[0];
    // if (!isNullOrEmpty(currentQuestion, "loop")) {
    //   currentQuestion.loop = JSON.parse(currentQuestion.loop);
    // }
    if (typeof currentQuestion.possibleResponses === "string") {
      let possibleResponses = JSON.parse(currentQuestion.possibleResponses);

      if (currentQuestion.responseType === "MULTIPLECHOICE") {
        currentQuestion.options = convertToOptions(possibleResponses);
      }
    }
    if (!isNullOrEmpty(currentQuestion, "nextQuestion")) {
      currentQuestion.nextQuestion = JSON.parse(currentQuestion.nextQuestion);
    }
    if (currentQuestion.responseType === "LIST") {
      currentQuestion.questionResponse = [];
    }
    if (currentQuestion.responseType === "MULTITEXT") {
      currentQuestion.options = [];
    }
  }
  return currentQuestion;
}

export function checkIfLoopEnding(
  currentQuestion,
  responses,
  answeredQuestions
) {
  // This means we are on last question in loop so we need to check if any more conditions to loop or end the loop
  let totalResponsesNeeded =
    responses[getLoopResponse(currentQuestion.loop, responses)].length;
  let currentTotalResponses = answeredQuestions.filter(
    a => a.questionID === currentQuestion.loop.startQuestion
  );
  if (currentTotalResponses.length < totalResponsesNeeded) {
    return false;
  }
  return true;
}

function checkAnyKeyMatches(keys, resKey, getQuestion) {
  let result = keys
    .map(key => {
      if (key.toLowerCase() === resKey.toLowerCase()) {
        return getQuestion.nextQuestion[key.toLowerCase()];
      }
    })
    .filter(Boolean);
  if (result.length === 0) return false;
  return result[0];
}

function checkForCondition(keys, resKey, getQuestion) {
  if (checkAnyKeyMatches?.(keys, resKey, getQuestion)) {
    return checkAnyKeyMatches(keys, resKey, getQuestion);
  } else if ("any" in getQuestion.nextQuestion) {
    return getQuestion.nextQuestion["any"];
  } else if ("default" in getQuestion.nextQuestion) {
    return getQuestion.nextQuestion["default"];
  }
}

export async function getUserNextQuestion(
  userResponses,
  lastQuestionResponse,
  questions,
  customResponse,
  customIndex,
  responses,
  rowId,
  bodyPartIndex,
  setBodyPartIndex,
  conditionIndex,
  setConditionIndex,
  loopQuestionId,
  setLoopQuestionId,
  editQuestion,
  answeredQuestions,
  currentId,
  bodyPartResponses,
  isBodyPart
) {
  const getQuestion = questions.find(
    question => question.questionID === lastQuestionResponse.questionId
  );

  let keys = [];
  if (getQuestion && !isNullOrEmpty(getQuestion, "nextQuestion")) {
    keys = Object.keys(getQuestion.nextQuestion);
  }
  let nextQuestionId = keys
    ?.map(key => {
      // checking if we have selected bodyParts and setting next questionId value to complete the loop
      if (
        bodyPartResponses.length > 0 &&
        bodyPartResponses[currentId - 1] &&
        bodyPartResponses[currentId - 1].length > 1 &&
        customIndex !== bodyPartResponses[currentId - 1].length &&
        getQuestion.responseType === "MULTIPLECHOICE"
      ) {
        setBodyPartIndex(bodyPartIndex + 1);
        return getQuestion.questionID;
      }
      // checking if the lastQuestion response is of Date type
      else if (lastQuestionResponse.responseType === "DATE") {
        // if (lastQuestionResponse.questionResponse instanceof Date) {
        return getQuestion.nextQuestion["default"];
      }
      // checking if the lastQuestion response is of array type but NO response should be present
      else if (
        Array.isArray(lastQuestionResponse.questionResponse) &&
        lastQuestionResponse.questionResponse.length === 0
      ) {
        return getQuestion.nextQuestion[key];
      }
      // checking if the lastQuestion response is of array type and the length is more than 0
      else if (
        Array.isArray(lastQuestionResponse.questionResponse) &&
        lastQuestionResponse.questionResponse.length > 0
      ) {
        return lastQuestionResponse.questionResponse.map(resKey => {
          return checkForCondition(keys, resKey, getQuestion);
        });
      }
      // checking if the lastQuestion response is of object type
      else if (
        lastQuestionResponse.questionResponse instanceof Object &&
        Object.values(lastQuestionResponse.questionResponse).length > 0
      ) {
        return Object.values(lastQuestionResponse.questionResponse).map(
          resKey => {
            return checkForCondition(keys, resKey, getQuestion);
          }
        );
      } else if (
        key.toLowerCase() ===
        lastQuestionResponse?.questionResponse.toLowerCase()
      ) {
        return getQuestion.nextQuestion[key];
      }
      // checking if response is NULL
      else if (
        isNullOrEmpty(lastQuestionResponse, "questionResponse") &&
        !isNullOrEmpty(getQuestion, "nextQuestion")
      ) {
        return getQuestion.nextQuestion[key];
      }
    })
    .filter(Boolean);
  // checking for the NULL nextQuestion and condition loop is present
  if (
    getQuestion &&
    isNullOrEmpty(getQuestion, "nextQuestion") &&
    !isNullOrEmpty(getQuestion, "loop") &&
    getQuestion.loop.includes("startQuestion") &&
    rowId.length > 1 &&
    rowId.length !== conditionIndex
  ) {
    nextQuestionId.push(JSON.parse(getQuestion.loop).startQuestion);
    setConditionIndex(conditionIndex + 1);
    setLoopQuestionId("");
    setBodyPartIndex(0);
  }

  if (nextQuestionId.length === 0) return null;
  let id = {};

  //check if loop is present for pain
  if (
    Array.isArray(nextQuestionId[0]) &&
    nextQuestionId[0].length > 1 &&
    loopQuestionId === ""
  ) {
    nextQuestionId = nextQuestionId[0].filter((item, pos) => {
      return nextQuestionId[0].indexOf(item) == pos;
    });
    if (nextQuestionId.length > 1) {
      nextQuestionId = nextQuestionId.map(quesId => {
        return quesId.replace("DOX-", "");
      });
      const minQuestionId = "DOX-00" + Math.min.apply(Math, nextQuestionId);
      const maxQuestionId = "DOX-00" + Math.max.apply(Math, nextQuestionId);
      setLoopQuestionId(maxQuestionId);
      id = minQuestionId;
    } else {
      id = nextQuestionId[0];
    }
  } else if (isBodyPart.length < 1 && loopQuestionId !== "") {
    id = loopQuestionId;
    setLoopQuestionId("");
  } else if (Array.isArray(nextQuestionId[0]) && nextQuestionId[0].length < 2) {
    id = nextQuestionId[0][0];
  } else id = nextQuestionId[0];
  // checking if we have already answered the bodyPart loop, use to switch condition while using edit feature
  let response;
  if (
    editQuestion &&
    lastQuestionResponse.questionId === "DOX-0015" &&
    lastQuestionResponse?.previousQuestion?.questionResponse.includes("Pain")
  ) {
    // (lastQuestionResponse.questionId === "DOX-0015" || lastQuestionResponse.questionId === "DOX-0028")) {
    response = editQuestion?.find(
      question =>
        question.questionID === "DOX-0016" || question.questionID === "DOX-0024"
    );
  }
  if (
    response &&
    editQuestion?.some(
      question => question.questionID === response.questionID
    ) &&
    !answeredQuestions?.some(
      question => question.questionID === response.questionID
    )
  ) {
    keys = [];
    id = response.questionID;
  }
  // checking if ID is any how array typeof, then selecting its 0 index value
  if (Array.isArray(id) && id.length > 0) {
    id = id[0];
  }

  // finding next question
  const getNextQuestion = questions.find(
    question => question.questionID === id
  );
  if (getNextQuestion.responseType === "LIST" && getNextQuestion.listType) {
    if (!getNextQuestion.listType.includes("SELECTED")) {
      getNextQuestion.options = await getList(getNextQuestion.listType);
    } else if (getNextQuestion.listType.includes("SELECTED")) {
      let responseQuestionList = getNextQuestion.listType
        .replace("SELECTED_", "")
        .split(",");
      getNextQuestion.options = [];
      for (let questionId of responseQuestionList) {
        if (questionId && questionId.trim() in responses) {
          questionId = questionId.trim();
          //check responses has value string and not NULL
          if (
            typeof responses[questionId] === "string" &&
            responses[questionId] !== ""
          ) {
            getNextQuestion.options.push({
              label: responses[questionId],
              value: responses[questionId]
            });
          } else {
            for (let r of responses[questionId]) {
              if (typeof r === "string") {
                getNextQuestion.options.push({
                  label: r,
                  value: r
                });
              } else {
                getNextQuestion.options.push({ ...r });
              }
            }
          }
        }
      }
    }
  }
  if (
    getNextQuestion.responseType === "MULTIPLELISTS" &&
    getNextQuestion.listType
  ) {
    let lists = getNextQuestion.listType.split(",");
    getNextQuestion.lists = [];
    getNextQuestion.options = {};
    getNextQuestion.questionResponse = {};
    for (let list of lists) {
      list = list.trim();
      getNextQuestion.lists.push(list);
      getNextQuestion.questionResponse[list] = "";
      getNextQuestion.options[list] = await getList(list);
    }
  }
  let newCurrentQuestion = {
    ...getNextQuestion,
    previousQuestion: lastQuestionResponse
  };
  return newCurrentQuestion;
}

// This function is used to get next question based on current question
// It takes current question and answers till now as input and return next question
export async function getNextQuestion(
  testQuestions,
  currentQuestion,
  responses,
  answeredQuestions
) {
  // based of current question take the decision
  // Check if we have to loop example {start: true, end: true, answer:'DOX-0002',goto:'DOX-0008'}
  if (
    currentQuestion &&
    !isNullOrEmpty(currentQuestion, "loop") &&
    !isNullOrEmpty(currentQuestion.loop, "end")
  ) {
    // This means we are on last question in loop so we need to check if any more conditions to loop or end the loop
    let loopEnding = checkIfLoopEnding(
      currentQuestion,
      responses,
      answeredQuestions
    );

    if (!loopEnding) {
      currentQuestion.nextQuestion = { default: currentQuestion.loop.goto };
    }
  }
  // Check if we have next question
  if (currentQuestion && "nextQuestion" in currentQuestion) {
    let nextQuestionId = null;
    if (
      !isNullOrEmpty(currentQuestion, "nextQuestion") &&
      currentQuestion.nextQuestion &&
      !isNullOrEmpty(currentQuestion.nextQuestion, "default")
    ) {
      nextQuestionId = currentQuestion.nextQuestion.default;
    }
    switch (currentQuestion.responseType) {
      case "MULTIPLECHOICE":
        if (
          !isNullOrEmpty(currentQuestion, "nextQuestion") &&
          currentQuestion.nextQuestion &&
          !isNullOrEmpty(currentQuestion.nextQuestion, "dependsOn") &&
          currentQuestion.nextQuestion.dependsOn === "selection" &&
          responses[currentQuestion.questionID] in currentQuestion.nextQuestion
        ) {
          nextQuestionId =
            currentQuestion.nextQuestion[responses[currentQuestion.questionID]];
        }
        break;
      case "LIST":
        if (
          !isNullOrEmpty(currentQuestion, "nextQuestion") &&
          currentQuestion.nextQuestion &&
          !isNullOrEmpty(currentQuestion.nextQuestion, "dependsOn") &&
          currentQuestion.nextQuestion.dependsOn === "selection"
        ) {
          let answerFound = false;
          // This means there is path based on what was selected
          for (let possibleAns in currentQuestion.nextQuestion) {
            for (let ans of responses[currentQuestion.questionID]) {
              let ansToCompare = "";
              if (typeof ans === "string") {
                ansToCompare = ans.toLowerCase();
              } else if (ans.label) {
                ansToCompare = ans.label.toLowerCase();
              }

              if (ansToCompare && ansToCompare === possibleAns) {
                nextQuestionId = currentQuestion.nextQuestion[possibleAns];
                answerFound = true;
                break;
              }
            }
          }
          // Check if any is the answer
          if (!answerFound && "any" in currentQuestion.nextQuestion) {
            nextQuestionId = currentQuestion.nextQuestion.any;
          }
        }
        break;
      default:
        break;
    }

    if (
      !isNullOrEmpty(currentQuestion, "nextQuestion") &&
      currentQuestion.nextQuestion &&
      !isNullOrEmpty(currentQuestion.nextQuestion, "dependsOn") &&
      currentQuestion.nextQuestion.dependsOn === "prevQ" &&
      currentQuestion.previousQuestion &&
      currentQuestion.previousQuestion.questionID in
        currentQuestion.nextQuestion
    ) {
      nextQuestionId =
        currentQuestion.nextQuestion[
          currentQuestion.previousQuestion.questionID
        ];
    }
    if (nextQuestionId) {
      let nextQuestion = testQuestions.find(
        q => q.questionID === nextQuestionId
      );

      if (nextQuestion) {
        nextQuestion = { ...nextQuestion };
        if (nextQuestion.responseType === "LIST" && nextQuestion.listType) {
          if (!nextQuestion.listType.includes("SELECTED")) {
            nextQuestion.options = await getList(nextQuestion.listType);
          } else if (nextQuestion.listType.includes("SELECTED")) {
            let responseQuestionList = nextQuestion.listType
              .replace("SELECTED_", "")
              .split(",");
            nextQuestion.options = [];

            for (let questionId of responseQuestionList) {
              if (questionId && questionId.trim() in responses) {
                questionId = questionId.trim();
                // It can be array or simple response
                if (typeof responses[questionId] === "string") {
                  nextQuestion.options.push({
                    label: responses[questionId],
                    value: responses[questionId]
                  });
                } else {
                  for (let r of responses[questionId]) {
                    if (typeof r === "string") {
                      nextQuestion.options.push({
                        label: r,
                        value: r
                      });
                    } else {
                      nextQuestion.options.push({ ...r });
                    }
                  }
                }
              }
            }
          }
        }

        if (
          nextQuestion.responseType === "MULTIPLELISTS" &&
          nextQuestion.listType
        ) {
          let lists = nextQuestion.listType.split(",");
          nextQuestion.lists = [];
          nextQuestion.options = {};
          nextQuestion.questionResponse = {};
          for (let list of lists) {
            list = list.trim();
            nextQuestion.lists.push(list);
            nextQuestion.questionResponse[list] = "";
            nextQuestion.options[list] = await getList(list);
          }
        }

        let newCurrentQuestion = {
          ...nextQuestion,
          previousQuestion: currentQuestion
        };

        if (
          !isNullOrEmpty(nextQuestion, "loop") &&
          !isNullOrEmpty(nextQuestion.loop, "answer")
        ) {
          let questionIdOfLooping = getLoopResponse(
            nextQuestion.loop,
            responses
          );
          let startQuestion = nextQuestion.questionID;
          if (nextQuestion.loop.startQuestion) {
            startQuestion = nextQuestion.loop.startQuestion;
          }
          let currentTotalResponses = answeredQuestions.filter(
            a => a.questionID === startQuestion
          );
          let index = 0;
          if (startQuestion === nextQuestion.questionID) {
            index = currentTotalResponses.length;
          } else {
            index = currentTotalResponses.length - 1;
          }

          newCurrentQuestion.loopingOnAnswer =
            responses[questionIdOfLooping][index];
        }
        return newCurrentQuestion;
      }
    }
  }
}

export function getLoopResponse(loop, responses) {
  let questionIdToConsider = null;
  if (responses) {
    for (let ans of loop?.answer) {
      if (ans in responses) {
        questionIdToConsider = ans;
      }
    }
  }
  return questionIdToConsider;
}

const columnTypeMapping = {
  isCond: "boolean",
  isDiagCond: "boolean",
  trackIt: "boolean",
  diagDate: "date",
  durCreatedAt: "datetime",
  linkedSymp: "arrOfString"
};

// This function is used to convert answer into save state as per column type
export function convertAnswerToState(answer, column, question) {
  if (column.ConditionsAndSymptoms in columnTypeMapping) {
    switch (columnTypeMapping[column.ConditionsAndSymptoms]) {
      case "boolean":
        // Now we need to check type of answer
        if (typeof answer === "string") {
          if (answer === "Yes") {
            answer = true;
          } else {
            answer = false;
          }
        } else if (Array.isArray(answer)) {
          let answerToSave = false;
          for (let a of answer) {
            if (typeof a === "string" && a === question.condOrSympName) {
              answerToSave = true;
            } else if (a.label && a.label === question.condOrSympName) {
              answerToSave = true;
            }
          }
          return answerToSave;
        }
        break;
      case "date":
        // AWSDate format take it in format YYYY-MM-DD
        if (typeof answer === "object") {
          return moment(answer).format("YYYY-MM-DD");
        }
        break;
      case "datetime":
        // AWSDateTime format take it in format YYYY-MM-DDThh:mm:ss.sssZ
        if (column.ConditionsAndSymptoms === "durCreatedAt") {
          return moment.utc().format("YYYY-MM-DDThh:mm:ss.sssZ");
        }
        break;
      case "arrOfString":
        let resultArray = [];
        if (Array.isArray(answer)) {
          for (let a of answer) {
            if (a.label) {
              resultArray.push(a.label);
            } else if (typeof a === "string") {
              resultArray.push(a);
            }
          }
        } else if (typeof answer === "string") {
          resultArray.push(answer);
        }
        return resultArray;
        break;
    }
  }

  // check if column mapping
  if ("mappedColumn" in column && typeof answer === "object") {
    return answer[column.mappedColumn];
  }
  return answer;
}

export async function getUnSavedUserSession(userId) {
  // Get all the userResponses by user id
  const response =
    false &&
    (await API.graphql(
      graphqlOperation(queries.getUserResponses, {
        filter: {
          userID: {
            eq: userId
          }
        },
        sort: {
          field: "createdAt",
          direction: "desc"
        }
      })
    ));
  if (
    response &&
    response.data &&
    response.data.searchUserResponses &&
    response.data.searchUserResponses.items &&
    response.data.searchUserResponses.items.length > 0
  ) {
    const responseArray = response.data.searchUserResponses.items;
    const top_session_id = responseArray[0].sessionID;
    let resArray = responseArray.filter(id => id.sessionID === top_session_id);
    if (
      !resArray.find(
        e => e.questionID === "summary" && JSON.parse(e.resp) === "completed"
      )
    ) {
      return resArray[0];
    }
    return null;
  }
}

// This is recursive function to get all user session data
export async function getUserSession(sessionID, items = [], nextToken = null) {
  // Get all the previous answered question
  let params = {
    filter: { sessionID: { eq: sessionID } }
  };
  if (nextToken) {
    params.nextToken = nextToken;
  }
  let response = await API.graphql(
    graphqlOperation(queries.listUserResponses, params)
  );
  if (response && response.data && response.data.listUserResponses) {
    if (
      response.data.listUserResponses.items &&
      response.data.listUserResponses.items.length > 0
    ) {
      items.push(...response.data.listUserResponses.items);
    }

    if (response.data?.listUserResponses?.nextToken) {
      await getUserSession(
        sessionID,
        items,
        response.data?.listUserResponses?.nextToken
      );
    }
  }
  return items;
}

// This function returns if condition session
export function isConditionSession(questionResp) {
  const array = questionResp.filter(id => id.questionID === "DOX-0001");
  if (array && array.length > 0) {
    const quesResp = JSON.parse(array[0].resp);
    if (quesResp) {
      if (quesResp === "Yes") {
        return true;
      } else {
        return false;
      }
    }
  }
  return null;
}
