import React from "react";
import Select from "react-select";

export default function ReactSelect({
  isMulti,
  options,
  label,
  selectedOption,
  onChange,
  ...rest
}) {
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      borderRadius: 10,
      marginTop: 16,
      marginBottom: 8,
    }),
  };
  return (
    <>
      <div className="react-select-div" {...rest}>
        <Select
          isMulti={isMulti ? true : false}
          value={selectedOption}
          onChange={onChange}
          options={options}
          placeholder={label}
          styles={customStyles}
          isClearable
        />
      </div>
    </>
  );
}
