import {Box} from "@material-ui/core";
import QuestionWorkflowList from "../components/vendor/question-workflow-list";
import React from 'react'
const VendorQuestionWorkflow = (props) => 
    <Box container>
        
        <QuestionWorkflowList user={props.user}/>
       
    </Box>

export default VendorQuestionWorkflow;

