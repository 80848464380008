import * as Yup from 'yup';
import {
    Button,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import FormikSelectField from '../../../common/components-V2/FormikSelectField';
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { createQuestion, updateQuestion } from '../../containers/curd/vendorQuestion';
import { Questiontypes as types } from '../../../utils/constant'
import TypeSelectionForm from './components/TypeSelectionForm'

function CreateCoupon({ user, closeModal, setCreatedItem, editable, setUpdatedItem, setSnackBarDetails}) {
    const [initialValues, setInitialValues] = useState({
        question: '',
        type: '',
    });
    
    const defaultOption = undefined;
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [createOptions, setCreateOptions] = useState([]);
    const [uploadLimit, setUploadLimit] = useState(10)

    const optionLimit = 20;

    const handleChangeCreate = (create) => {
        let uniqueCreate = create.filter((value, index, self)=>{
            return self.findIndex(item => item.value === value.value) === index
        })
        if(uniqueCreate.length > optionLimit){
            return false;
        }
        setSelectedOption(uniqueCreate);
    }

    const resetSelectedOption = () => {
        setSelectedOption(defaultOption);
    }


    useEffect( () => {

        const fetchList = async () => {

            setLoading(true)

            let selected = editable?.options?JSON.parse(editable?.options) : []
            let selectoption = selected.map(item=>{
                return {
                    value: item,
                    label: item
                }
            })
            let selectedValue;
            switch (editable?.type) {
                case 'FILE':
                    selectedValue = editable?.fileSize
                    break;
                case 'DATE':
                    selectedValue = {
                        minDate:editable.minDate, 
                        maxDate: editable.maxDate 
                    }
                    break;
                case 'TIME':
                    selectedValue = editable?.formTime
                    break;
                case 'RANGE':
                    selectedValue = {
                        minRange:editable?.minRange,
                        maxRange:editable?.maxRange
                    }
                    break;
                default:
                    break;
            }

            console.log(`Let's check selectedValue `, selectedValue)
            setSelectedOption(selectedValue ?? selectoption);
                
            setLoading(false);
        }
        fetchList();
    }, [editable?.options])

    useEffect(() => {
        setInitialValues((values) => {
            if (!editable) return values;
            let { question, type } = editable;
            return {
                question,
                type
            }
        })
        
        
    }, [editable])

    const validationSchema = Yup.object({
        question: Yup.string().required("Question is required."),
        type: Yup.string().required("Type is required."),
    })


    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        setSubmitted(true);
                        
                        if(values.type ===''){
                            return;
                        }
                        // if(values.type !== 'TEXT' && selectedOption.length < 2){
                        //     return;
                        // }
                        values.options = JSON.stringify(selectedOption.map(item=>item.value))
                        if(values.type === 'FILE') values.fileSize = uploadLimit
                        delete values.rangeDate
                        if (!editable) {
                            let data = await createQuestion({ ...values, createdBy: user.id });
                            setCreatedItem(data);
                            // couponId = data.createCoupon.id;
                        }
                        else {
                            let data = await updateQuestion({ ...values, id: editable.id })
                            setUpdatedItem(data);
                        }

                        resetSelectedOption();
                        setSnackBarDetails({
                            message:
                                `Question ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        setSubmitted(false);
                        closeModal()
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                        
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form>
                        <FormGroup>
                            <FormikTextField
                                name="question"
                                margin="normal"
                                label="Question"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormikSelectField
                                name="type"
                                margin="normal"
                                label="Question Type"
                                options={types}
                                error={submitted && values.type===''}
                            />
                        </FormGroup>
                        <TypeSelectionForm 
                            values={values} 
                            createOptions={createOptions} 
                            handleChangeCreate={handleChangeCreate} 
                            selectedOption={selectedOption}
                            submitted={submitted}
                            setUploadLimit={setUploadLimit}
                            uploadLimit={uploadLimit}
                        />
                        
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                        >
                            Submit
                        </Button>
                        
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateCoupon
