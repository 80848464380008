
import React, { Fragment, useEffect, useState } from 'react'
import Scanner from './Scanner';
import './Scan.css'
import { Button, CircularProgress } from '@material-ui/core';


const BarcodeScanner = (props) => {
    const [scan, setScan] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [noApi, setNoApi] = useState(false);
    const [step, setStep] = useState(0)
    const [tooManyRequest, setTooManyRequest] = useState(false);
    const startScanning = () => {
        setScan(true)
        setStep(1)
    }

    // one state in InitialValue = vendor: 'VendorAdmin'
    // barcode_number: parsedRes.products[0].barcode_number,
    const _onBarcodeDetect = async (barcode) => {
        setSpinner(true)
        let url = process.env.REACT_APP_BARCODE_LOOKUP_URL_PATH + barcode + process.env.REACT_APP_BARCODE_LOOKUP_URL_EXTENSION + process.env.REACT_APP_BARCODE_LOOKUP_API_KEY
        let product = await fetch(url)
            .then(res => {
                if (res.status !== 200) {
                    return {
                        resStatus: res.status
                    }
                } else if (res.status === 200) {
                    return res.json()
                }
            })
            .then(async (parsedRes) => {
                if (parsedRes.resStatus === 0) {
                    setNoApi(true)
                } else if (parsedRes.resStatus === 404) {
                    setInvalid(true)
                } else if (parsedRes.resStatus === 429) {
                    setTooManyRequest(true)
                } else {
                    props.setopen(true)
                    props.setInitialValues({
                        title: parsedRes.products[0].title,
                        model: parsedRes.products[0].model,
                        category: parsedRes.products[0].description,
                        Manufacturer: parsedRes.products[0].manufacturer,
                        brand: parsedRes.products[0].brand,
                        format: parsedRes.products[0].format,
                        multipack: parsedRes.products[0].multipack,
                        price: 10,
                        description: parsedRes.products[0].description,
                    })
                }
            }).catch(err => console.warn(err))
        setSpinner(false)
        setScan(false)
    }
    let componentRendered;
    if (step === 0) {
        componentRendered = <Fragment>
            <div className='divMargin'>
                <Button
                    onClick={() => startScanning()}
                    color="secondary" variant="contained">{'Start Scanning Product'}</Button>
            </div>
        </Fragment>
    }

    if (invalid) {
        componentRendered =
            <Fragment>
                <div className='divMargin'>
                    <div className='invalid'>
                        <h1>Invalid Barcode!</h1>
                    </div>
                    <Button
                        type="button"
                        onClick={() => startScanning()}
                        variant="outlined">
                        {'Try Again'}</Button>
                </div>
            </Fragment>
    } else if (noApi) {
        componentRendered =
            <Fragment>
                <div className='divMargin'>
                    <div className='invalid'>
                        <h6>You need an <a href="https://www.barcodelookup.com/api" target="_blank">API key</a> to run this App.</h6>
                    </div>
                    <Button
                        type="button"
                        onClick={() => startScanning()} color="secondary" variant="outlined">
                        {'Try Again'}</Button>
                </div>
            </Fragment>
    } else if (tooManyRequest) {
        componentRendered =
            <Fragment>
                <div className='divMargin'>
                    <div className='invalid'>
                        <h6>Too Many Requests</h6>
                    </div>
                </div>
            </Fragment>
    }


    
    if (scan && step === 1) {
        componentRendered = <div className="scannerCam">
            <Scanner onBarcodeDetect={_onBarcodeDetect}
                noCamera={() => console.warn('there was no camera')} />
        </div>
    }
  
    if (spinner) {
        componentRendered = <CircularProgress />
    }


    return (
        <Fragment>
            <div className="scannerContainer" ml="auto">
                {componentRendered}
            </div>
        </Fragment>
    )

}

export default BarcodeScanner