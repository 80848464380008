import {
    Button,
    CircularProgress,
    FormGroup,
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { sendSMSTwilio } from "../../../utils/commonFunctions";
import { getCustomerCategory } from "../../containers/curd/customerCategory";



function SendSmsMulti({ setSmsLoader, user, closeModal, editable, setUpdatedItem, setSnackBarDetails}) {
    
    const [initialValues, setInitialValues] = useState({
        message:'',
        mobiles: [
            {
                mobile: ''
            }
        ],
    });

    const getCustomerNumbers = async(id) => {
        
        let category = await getCustomerCategory(id);
        let relations = category?.customerCategoryRelations?.items || [];
        
        // let uniqueRelations = relations.filter((value, index, self)=>{
        //     return self.findIndex(item => item.userID === value.userID) === index
        // })
        // let numberOnly = uniqueRelations.filter(item => item?.user?.mobile );
        let numbers = relations.map(item => { return { mobile: item?.user?.mobile || '' }});
        return numbers;
    }


    useEffect(() => {
        const initialize = async() => {

            let numbers = await getCustomerNumbers(editable?.id);
            
            setInitialValues((values) => {
                if (!editable) return values;
                let { mobile, message } = editable;
                return {
                    message, mobiles: numbers
                }
            })
        }

        initialize();        
    }, [editable])




    const validationSchema = Yup.object().shape({
        message: Yup.string().required("Message is required."),
        mobiles: Yup.array()
        .of(
            Yup.object().shape({
                mobile: Yup.string().required("Required this field")
            }).nullable(true)
        )
        .required('Must have Mobiles').nullable(true)
        .min(1, 'Minimum of 1 mobile'),
    })

    const sendSms = async(values) => {
        try {
  
            const to = values.mobile.replace(/[^0-9\+]/g, "");
            const message = values.message;
            
            let resp = await sendSMSTwilio(to, message);
                        
        } catch (error) {
            setSnackBarDetails({
                message: error.message,
                severity: "error",
                links: [],
            });
            
        }
    }

    const handleSubmit = async(values) => {
        try {
                        
            // const to = values.mobile.replace(/[^0-9\+]/g, "");
            setSmsLoader(true);
            const message = values.message;
            const mobiles = values.mobiles.map(item => item.mobile )
            await Promise.all(
                mobiles.map( async(item) => {
                    await sendSms({
                        mobile: item,
                        message: message
                    });
                })
            )
            setSmsLoader(false);
            setSnackBarDetails({
                message:
                    `Sms sent successfully`,
                severity: "success",
                links: [],
            });
            closeModal();
        } catch (error) {
            setSmsLoader(false);
            setSnackBarDetails({
                message: error.message,
                severity: "error",
                links: [],
            });
            
        }
    }

    
    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form>
                        <FieldArray name="mobiles">
                            {({ insert, remove, push }) => (
                                <>
                                    {values.mobiles.map((friend, index) => {
                                    return(
                                        <FormGroup key={index}>
                                            <FormikTextField
                                                name={`mobiles.${index}.mobile`}
                                                margin="normal"
                                                label="Mobile"
                                                // disabled={true}
                                            />
                                        </FormGroup>
                                    )})}
                                </>
                            )}
                        </FieldArray>
                        
                        <FormGroup>
                            <FormikTextField
                                name="message"
                                margin="normal"
                                label="Message"
                            />
                        </FormGroup>
                        
                        {!setSmsLoader ? <CircularProgress /> :<Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                        >
                            Submit
                        </Button>}
                        
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SendSmsMulti
