import * as Yup from "yup";

import {
  Button,
  Card,
  CardContent,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormikRadioGroupField from "../../common/components/FormikRadioGroupField";
import Layout from "../../common/components/layout";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { createNotificationResponse } from "./curd/questions";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { updateNotificationsLogFromQuestions } from "./curd/notifications";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withCron from "../HOC/withCron";
import withQuestions from "../HOC/withQuestions";

const DATE_FORMAT = "DD/MM/YYYY";

const useStyles = makeStyles({
  root: {
    height: "100%",
    padding: "3rem",
  },
  child: {
    marginTop: "3rem",
  },
  inner: {
    padding: "auto 4rem",
  },
});
function Questions(props) {
  const matches = useMediaQuery("(max-width:500px)");
  const classes = useStyles();
  const history = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [finalQuesData, setFinalQuesData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [endQuestionsResponse, setEndQuestionsResponse] = useState(false);
  const [upToDate, setUpToDate] = useState(true);
  const [expired, setExpired] = useState(true);

  const callSchedule = async () => {
    if (props.getSchedule) {
      let { frequency, updatedAt, isPending } = props.getSchedule;
      let time = await props.scheduleTime(frequency, updatedAt);
      props.scheduleTask(
        (status) => {
          setUpToDate(!status);
          // if (props.questions.length === 0) {
          //     setUpToDate(status)
          // }
        },
        isPending ? 0 : time
      );
    }
  };
  const getExpired = () => {
    const date = moment(props?.user?.timestamp || Date.now());
    let days = moment(Date.now()).diff(date, "days");
    setExpired(days > 2);
  };
  useEffect(() => {
    if (props.user && !props.user.id) history.push("/");
    history.replace(props.location.pathname);
    getExpired();
  }, []);

  useEffect(() => {
    const fetchCallSchedule = async () => {
      if (!expired) await callSchedule();
    };
    fetchCallSchedule();
  }, [props.getSchedule, expired]);

  useEffect(() => {
    const fetchIpToDate = async () => {
      if (!upToDate) await props.init();
    };
    fetchIpToDate();
  }, [upToDate]);

  useEffect(() => {
    setFinalQuesData(props.questions);
  }, [props.questions]);
  useEffect(() => {
    setIsLoading(props.loadQuestions);
  }, [props.loadQuestions]);
  useEffect(() => {
    setCurrentQuestion((values) =>
      finalQuesData.length ? finalQuesData[currentIndex] : values
    );
  }, [finalQuesData, currentIndex]);

  const validationSchema = Yup.object({
    questionResponse: Yup.string().required("Please select a pain state."),
  });
  let localStorageNotification =
    localStorage.getItem("notification") !== "undefined"
      ? JSON.parse(localStorage.getItem("notification"))
      : {};

  return (
    <>
      <div className={matches ? "" : classes.root}>
        {!props.getSchedule ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          </>
        ) : (
          <>
            {!endQuestionsResponse && !upToDate ? (
              <>
                <Typography
                  className="text-center"
                  variant="h1"
                  color="primary"
                >
                  Questions
                </Typography>
                <hr className="mb-5" />
                {!isLoading ? (
                  currentQuestion ? (
                    <div>
                      {!currentQuestion.pain ? (
                        <Typography className="text-center" variant="h1">
                          #{currentIndex + 1}. How are you feeling today in
                          regard to your condition :{" "}
                          {currentQuestion.label ?? currentQuestion.label}?
                        </Typography>
                      ) : (
                        <Typography className="text-center" variant="h1">
                          #{currentIndex + 1}. Please rate your{" "}
                          {currentQuestion.label} pain today.
                        </Typography>
                      )}
                      <Formik
                        initialValues={{ questionResponse: "" }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, formik) => {
                          try {
                            let { data } = await createNotificationResponse({
                              user: props.user.id,
                              label:
                                currentQuestion.label ??
                                currentQuestion.symptom,
                              questionID: currentQuestion.id,
                              response: values.questionResponse,
                            });
                            formik.resetForm();
                            setCurrentIndex((i) => {
                              if (i < finalQuesData.length - 1) return ++i;
                              setEndQuestionsResponse(true);
                              return i;
                            });
                            if (
                              localStorageNotification.length != 0 &&
                              currentIndex === finalQuesData.length - 1
                            ) {
                              await updateNotificationsLogFromQuestions(
                                localStorageNotification
                              );
                              localStorage.removeItem("notification");
                            }
                          } catch (error) {
                            console.warn(error);
                          }
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <Layout>
                                <FormGroup className="d-flex align-items-center align-items-sm-start">
                                  <FormikRadioGroupField
                                    name="questionResponse"
                                    margin="normal"
                                    style={{ width: "max-content" }}
                                    options={[
                                      { label: "Severe", value: "Severe" },
                                      {
                                        label: "Unmanageable",
                                        value: "Unmanageable",
                                      },
                                      {
                                        label: "Distracting",
                                        value: "Distracting",
                                      },
                                      {
                                        label: "Uncomfortable",
                                        value: "Uncomfortable",
                                      },
                                      { label: "Minimal", value: "Minimal" },
                                      { label: "No Pain", value: "No Pain" },
                                    ]}
                                    row={false}
                                  />
                                  <p className="text-danger">
                                    {" "}
                                    {formik.touched.questionResponse &&
                                      formik.errors.questionResponse}
                                    &nbsp;
                                  </p>
                                </FormGroup>
                                <div className="d-flex justify-content-center align-items-center">
                                  <Button
                                    type="reset"
                                    className="mr-2"
                                    variant="contained"
                                    color="secondary"
                                  >
                                    Reset
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Layout>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  ) : props.isAnswered ? (
                    <UpToDateMessage expired={expired} />
                  ) : (
                    <NoQuestions />
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <CircularProgress />
                  </div>
                )}
              </>
            ) : (
              <>
                {upToDate && !expired && (
                  <UpToDateMessage
                    expired={expired}
                    upToDate={upToDate}
                    updatedAt={props?.getSchedule?.updatedAt}
                  />
                )}
                {!upToDate && !expired && <ThanksMessage />}
               {expired && <UpToDateMessage expired={expired}/>}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default withCron(withQuestions(Questions));

function NoQuestions() {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <Typography variant="h3">
        There are no conditions and symptoms in tracked.
      </Typography>
      <div className="d-flex justify-content-between w-50 mt-5">
        <Button
          component={Link}
          to="/addCondition"
          variant="contained"
          color="primary"
        >
          Add Conditions
        </Button>
        <Button
          component={Link}
          to="/addSymptom"
          variant="contained"
          color="primary"
        >
          Add Symptoms
        </Button>
      </div>
    </div>
  );
}

function UpToDateMessage({ expired, upToDate, updatedAt }) {
  return (
    <Card>
      <CardContent className="d-flex justify-content-center flex-column align-items-center">
        <Typography variant="h3" className="text-center">
          {expired ? "Sorry!" : "Thank You!"}
        </Typography>
        <Typography variant="h5" className="text-center mt-3">
          No questions for today.
        </Typography>
        <Typography className="my-3">
          {upToDate && updatedAt
            ? `(Last updated on: ${moment(updatedAt).format(DATE_FORMAT)})`
            : ""}
        </Typography>
        <Button component={Link} to="/" variant="contained" color="primary">
          Home
        </Button>
      </CardContent>
    </Card>
  );
}

function ThanksMessage() {
  return (
    <Card>
      <CardContent className="d-flex justify-content-center flex-column align-items-center">
        {" "}
        <Typography variant="h5" className="text-center mt-3">
          Thank you for submitting the response. All set for today!.
        </Typography>
        <br />
        <Button component={Link} to="/" variant="contained" color="primary">
          Home
        </Button>
      </CardContent>
    </Card>
  );
}
