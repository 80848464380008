import {
  createPreference,
  listPreferences,
} from "../containers/curd/preference";
import { getDayByLabel, uniqArray } from "../../utilities";
import {
  listNotificationResponse,
  listQuestions,
} from "../containers/curd/questions";

import React, { useEffect, useState } from "react";
import { getAllTrackedConditionAndSymptomsByUser } from "../../common/services/cond-and-symp";
import moment from "moment";

const withQuestions = (ComposedComponent) => {
  const Questions = (props) => {
    const [preference, setPreference] = useState(null);
    const [condSymptoms, setCondSymptoms] = useState(null);
    const [finalQuesData, setFinalQuesData] = useState([]);
    const [notificationResponse, setNotificationResponse] =
      useState(null);
    const [notificationResponseLast, setNotificationResponseLast] =
      useState(null);
    const [prevNotificationResponse, setPrevNotificationResponse] =
      useState(null);
    const [scheduleTime, setScheduleTime] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lastSubmittedResponseCount, setLastSubmittedCount] =
      useState(0);
    const [userId, setUserId] = useState(props.user.id);
    const [isAnswered, setIsAnswered] = useState(false);

    async function fetchListPreference() {
      try {
        let { data } = await listPreferences(userId);
        if (!data.listPreferences.items[0]) {
          await createPreference({
            user: userId,
            frequency: "Daily",
            questions: "1",
            sendedNotifications: 0,
            notification_on: {
              email: false,
              sms: false,
              app: true,
            },
            remind_me: "Once per day",
          });
        }
        setPreference(
          data.listPreferences.items[0]
            ? data.listPreferences.items[0]
            : {
                user: userId,
                frequency: "Daily",
                questions: "1",
                sendedNotifications: 0,
                notification_on: {
                  email: false,
                  sms: false,
                  app: true,
                },
                remind_me: "Once per day",
              }
        );
      } catch (error) {
        console.warn(error);
      }
    }

    async function handleLastSubmitted(preference) {
      let notifItems = await listNotificationResponse(userId);
      let last_submitted = notifItems.filter(({ createdAt }) => {
        const created_at = moment(createdAt).format('L');
        const today = moment().format('L');
        const diff = moment(today).diff(created_at, "days");
        const day = getDayByLabel(preference.frequency);
        return diff < day;
      });
      setNotificationResponseLast(uniqArray(last_submitted, 'label'));
      setLastSubmittedCount(last_submitted.length);
      setNotificationResponse(notifItems[0] ?? { updatedAt: new Date() });
      setPrevNotificationResponse(notifItems[1] ?? { updatedAt: new Date() });
    }

    useEffect(() => {
      (async () => {
        await fetchListPreference();
      })();
    }, []);

    useEffect(() => {
      (async () => {
        preference && (await handleLastSubmitted(preference));
      })();
    }, [preference]);

    console.log({notificationResponseLast});
    const init = async () => {
      try {
        setIsLoading(true);
        let res = await listConditionsAndSymptomss(
          userId,
          notificationResponse?.questionID,
          notificationResponse?.label
        );
        setCondSymptoms(res);
        return true;
      } catch (error) {
        console.warn(error);
        return false;
      }
    };

    const listConditionsAndSymptomss = async (userId, questionID, label) => {
      let items = await getAllTrackedConditionAndSymptomsByUser(userId);
      return items.filter((item) => {
        if (!isCondition(item)) {
          return true;
        }
        if (item.trackIt == null || item.trackIt) {
          return true;
        }
        return false;
      });
    };
    const isCondition = (element) => {
      return (
        element["dox001"]?.includes("Yes") && element["dox003"]?.includes("Yes")
      );
    };

    const symptomsToQuestions = (element) => {
      let dox020 = element["dox020"] ? JSON.parse(element["dox020"]) : [];
      let dox024 = element["dox024"] ? JSON.parse(element["dox024"]) : [];
      let symptoms = dox020.map((sym) => ({ id: element.id, label: sym }));
      let bodyParts = dox024.map(({ label }) => ({
        id: element.id,
        pain: true,
        label: label,
      }));
      if (bodyParts.length) {
        symptoms = symptoms.filter(({ symptom }) => symptom != "Pain");
        symptoms = symptoms.concat(bodyParts);
      }
      return symptoms;
    };

    const getNotificationsData = (
      condSymptoms = [],
      noOfPreferenceQues
    ) => {
      setIsLoading(false);
      let items = [];
      var modifiedArr = [];
      modifiedArr = condSymptoms;
      for (let index = 0; index < modifiedArr.length; index++) {
        const element = modifiedArr[index];
        if (isCondition(element)) {
          let cond = JSON.parse(element["dox002"]);
          items.push({
            id: element.id,
            label: typeof cond === "string" ? cond : cond[0],
          });
        } else {
          let symptoms = symptomsToQuestions(element);
          items = items.concat(symptoms);
        }
      }
      items = uniqArray(items, "label");
      if (notificationResponseLast?.length) {
        items = items.filter(({ label }) => {
          const exist = notificationResponseLast?.find(
            (item) => item.label == label
          );
          return !exist;
        });
      }
      items = Array.from(new Set(items.map(({ label }) => label))).map(
        (label) => {
          return items.find((item) => item.label == label);
        }
      );
      console.log({items});
      return items.slice(0, parseInt(noOfPreferenceQues));
    };

    useEffect(() => {
      const fetchQuestions = async () => {
        let ques = 0;
        let time = 0;
        if (preference && notificationResponse) {
          time = await props.scheduleTime(
            preference.frequency,
            notificationResponse.updatedAt
          );
          if (time > 0 && lastSubmittedResponseCount < preference.questions) {
            ques = preference.questions - lastSubmittedResponseCount;
          }
        }

        setScheduleTime((values) => {
          if (preference && notificationResponse)
            return {
              frequency: preference.frequency,
              updatedAt: notificationResponse.updatedAt,
              isPending: ques ? true : false,
            };
          return values;
        });

        let finalResult = [];
        if (preference && condSymptoms && notificationResponse) {
            finalResult =  getNotificationsData(
              condSymptoms,
              ques ? ques : preference.questions
            );
          }

        setIsAnswered(()=>(!finalResult.length && condSymptoms?.length));
        setFinalQuesData((values) => finalResult.length ? finalResult : values);
      };
      fetchQuestions();
    }, [preference, condSymptoms, notificationResponse]);
    console.log({finalQuesData});
    return (
      <ComposedComponent
        {...props}
        questions={finalQuesData}
        getSchedule={scheduleTime}
        loadQuestions={isLoading}
        isAnswered={isAnswered}
        init={init}
      />
    );
  };
  return Questions;
};
export default withQuestions;
