export function toggle(collection, value) {
  const arr = [...collection];
  const index = arr.findIndex((i) => i === value);
  if (index === -1) {
    arr.push(value);
  } else {
    arr.splice(index, 1);
  }
  return arr;
}

export function separateConditionsAndSymptoms(collection) {
  let conditions = [];
  let symptoms = [];
  let emptyResponses = [];
  collection.map( item => {
    if (item["dox001"] === null) {
      emptyResponses.push(item)
    }else if (item["dox001"].includes("No")) {
      symptoms.push(item)
    }else if (item["dox001"].includes("Yes") && item["dox003"].includes("No")) {
      symptoms.push(item)
    }else if (item["dox001"].includes("Yes") && item["dox003"] === null) {
      emptyResponses.push(item)
    }else {
      conditions.push(item)
    }
  })
  return {conditions, symptoms, emptyResponses}
}
