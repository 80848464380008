import { CardGiftcardOutlined, CardMembership, CropFree, Folder, ShoppingBasket } from "@material-ui/icons";

import AssessmentIcon from '@material-ui/icons/Assessment';
import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ChatIcon from "@material-ui/icons/ChatOutlined";
import CodeIcon from "@material-ui/icons/Code";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GradeTwoTone from "@material-ui/icons/GradeTwoTone";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined";
import MailIcon from "@material-ui/icons/MailOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  TrendingUpIcon: TrendingUpIcon,
  AssessmentIcon: AssessmentIcon,
  FormatListBulletedIcon,
  CardMembership,
  Folder,
  CropFree,
  ShoppingBasket,
  CardGiftcardOutlined,
  PeopleAltIcon,
  ContactSupportIcon,
  ShoppingCartIcon,
  GroupIcon,
  GroupAddIcon
};

export default [
  {
    // label: "Menu",
    content: JSON.parse(
      `[
        {
          "label": "Dashboard",
          "icon": "TrendingUpIcon",
          "description": "This is dashboard.",
          "to": "/dashboard"
        },
  {
    "label": "My items",
    "icon": "ViewModuleIcon",
    "content": [
      {
        "label": "Conditions",
        "description": "This is a dashboard page example built using this template.",
        "to": "/conditions"
      },
      {
        "label": "Symptoms",
        "description": "This is an example dashboard created using build-in elements and components.",
        "to": "/symptoms"
      },
      {
        "label": "Products",
        "description": "Example of a Dashboard page built with this UI framework.",
        "to": "/user-products"
      }
    ]
  },
  {
    "label": "Questions",
    "icon": "ErrorIcon",
    "description": "Example of a Question page built with this UI framework.",
    "to": "/questions"
  },

  {
    "label": "Analytics",
    "icon": "DashboardTwoToneIcon",
    "description": "Example of a Analytics page built with this UI framework.",
    "to": "/analytics"
  },
  {
    "label": "Account Settings",
    "icon": "SettingsIcon",
    "description": "Settings.",
    "content":[
      {
        "label": "Profile",
        "icon": "PersonIcon",
        "description": "Example of a Profile page built with this UI framework.",
        "to": "/profile"
      },
      {
      "label": "Preferences",
      "icon": "FormatListBulletedIcon",
      "description": ".",
      "to": "/preferences"
    },
    {
      "label": "Settings",
      "icon": "SettingsIcon",
      "description": "Example of a Setting page built with this UI framework.",
      "to": "/settings"
    }
  ]
  },
  {
    "label": "Rewards",
    "icon": "CardGiftcardOutlined",
    "description": "Products",
    "content":[
      {
        "label": "Coupons",
        "icon": "CardMembership",
        "description": "Coupons",
        "to": "/coupons"
      }
    ]
  },
  {
    "label": "Products",
    "icon": "ShoppingCartIcon",
    "description": "Products",
    "to": "/products"
  },
  {
    "label": "Question Management",
    "icon": "ContactSupportIcon",
    "description": "Question Management",
    "to": "/vendor-questions"
  },
  {
    "label": "Question Workflow",
    "icon": "ContactSupportIcon",
    "description": "Question Workflow",
    "to": "/vendor-question-workflow",
    "links":"/vendor-question-workflow,/vendor-question-workflow/add,/vendor-question-workflow/edit"
  },
  {
    "label": "Campaigns",
    "icon": "ContactSupportIcon",
    "description": "Campaigns",
    "to": "/campaigns",
    "links":"/campaigns,/campaign/add,/campaign/edit"
  },
  {
    "label": "User Management",
    "icon": "PeopleAltIcon",
    "description": "User Management",
    "to": "/users"
  },
  {
    "label": "Vendor Management",
    "icon": "PeopleAltIcon",
    "description": "User Management",
    "to": "/vendors"
  },
  {
    "label": "Customer Management",
    "icon": "GroupIcon",
    "description": "Customer Management",
    "to": "/customers"
  },
  {
    "label": "Customer Category",
    "icon": "GroupAddIcon",
    "description": "Customer Category",
    "to": "/customer-categories"
  }
  
]`,
      (key, value) => {
        if (key === "icon") {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
