import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogBox = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      height: props => props.fullHeigth ? "450px" : "",
    },
  },
}))(Dialog);

export default function CustomModal(props) {
  const { open, setOpen, setChange, title, children, fullHeigth ,...rest } = props;

  return (
    <div>
      <DialogBox
        {...rest}
        fullHeigth={fullHeigth}
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {title && (
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => {
              setChange && setChange(false);
              setOpen(false);
            }}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent dividers>{children}</DialogContent>
      </DialogBox>
    </div>
  );
}
