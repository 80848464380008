import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const Create = async input => {
  return await API.graphql(graphqlOperation(mutations.createUsers, { input }));
};

export const Update = async input => {
  return await API.graphql(graphqlOperation(mutations.updateUsers, { input }));
};

// export const UpdatePassword = async (input) => {
//     return await API.graphql(graphqlOperation(mutations.updateUsers, { input }));
// }

export const DeleteVendors = async input => {
  let promises = [];
  for (let index = 0; index < input.length; index++) {
    const id = input[index];
    let {
      data: { updateUsers }
    } = await API.graphql(
      graphqlOperation(mutations.updateUsers, {
        input: { id, isDeleted: true }
      })
    );
    promises.push(updateUsers);
  }
  return Promise.all(promises);
};

export const listVendors = async (
  userId,
  accountType,
  rand = null,
  companyName = null,
  nextToken,
  limit,
  items = []
) => {
  let filter = {};
  const user = JSON.parse(localStorage.getItem("doximple_user"));
  if (accountType.length > 1) {
    filter = {
      or: [
        { accountType: { eq: accountType[0] } },
        { accountType: { eq: accountType[1] } }
      ]
    };
  }
  if (accountType.length == 1) {
    filter = {
      accountType: { eq: accountType[0] }
    };
  }

  if(rand){
    filter['redeemCode'] = { eq: rand.toString() }
  }
  if(companyName){
    filter['companyName'] = { eq: companyName.toString() }
  }

  if (user?.accountType === "SuperAdmin") {
    filter = {
      ...filter,
      createdBy: {
        eq: userId
      }
    };
  }

  if (
    user?.accountType === "VendorAdminUser" ||
    user?.accountType === "VendorAdmin"
  ) {
    filter = {
      ...filter,
      companyName: {
        eq: user.companyName
      }
    };
  }

  if (user?.accountType === "SuperAdminUser") {
    filter = {
      ...filter
    };
  }

  let params = {
    limit: 30
  };
  if (Object.keys(filter).length) {
    params.filter = filter;
  }

  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listUsers }
  } = await API.graphql(graphqlOperation(queries.listUsers, params));
  items.push(...listUsers.items);
  if (listUsers.nextToken) {
    await listVendors(userId, accountType, rand, companyName, listUsers.nextToken, limit, items);
  }
  return items;
};

export const listVendorsByAccountType = async (
  accountType,
  rand = null,
  companyName = null,
  nextToken,
  limit,
  items = []
) => {
  let filter = {};
  const user = null;
  if (accountType.length > 1) {
    filter = {
      or: [
        { accountType: { eq: accountType[0] } },
        { accountType: { eq: accountType[1] } }
      ]
    };
  }
  if (accountType.length == 1) {
    filter = {
      accountType: { eq: accountType[0] }
    };
  }

  if(rand){
    filter['redeemCode'] = { eq: rand.toString() }
  }
  if(companyName){
    filter['companyName'] = { eq: companyName.toString() }
  }

  let params = {
    limit: 30
  };
  if (Object.keys(filter).length) {
    params.filter = filter;
  }

  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listUsers }
  } = await API.graphql(graphqlOperation(queries.listUsers, params));
  items.push(...listUsers.items);
  if (listUsers.nextToken) {
    await listVendorsByAccountType(accountType, rand, companyName, listUsers.nextToken, limit, items);
  }
  return items;
};

export const getVendorList = async (nextToken, items = []) => {
  let params = {
    filter: {
      accountType: {
        eq: "Vendor"
      }
    }
  };
  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listUsers }
  } = await API.graphql(graphqlOperation(queries.listUsers, params));

  if (listUsers.nextToken) {
    items.push(...listUsers.items);
    await listVendors(listUsers.nextToken, items);
  }
  return items;
};

export const getUserListCount = async (
  accountType,
  userId,
  nextToken,
  items = []
) => {
  let filter = {};
  const user = JSON.parse(localStorage.getItem("doximple_user"));
  if (accountType.length > 1) {
    filter = {
      or: [
        { accountType: { eq: accountType[0] } },
        { accountType: { eq: accountType[1] } }
      ]
    };
  } else {
    filter = {
      accountType: { eq: accountType[0] }
    };
  }

  if (
    user?.accountType === "SuperAdmin" ||
    user?.accountType === "VendorAdmin"
  ) {
    filter = {
      createdBy: {
        eq: userId
      },
      ...filter
    };
  }

  if (
    user?.accountType === "SuperAdminUser" ||
    user?.accountType === "VendorAdminUser"
  ) {
    filter = {
      ...filter,
      companyName: {
        eq: user.companyName
      }
    };
  }

  let params = {
    filter: filter
  };
  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listUsers }
  } = await API.graphql(graphqlOperation(queries.listUsers, params));

  if (listUsers.nextToken) {
    items.push(...listUsers.items);
    await listVendors(accountType, userId, listUsers.nextToken, items);
  }
  return items;
};

export const allUserList = async (value, nextToken) => {
  let params = {
    filter: {
      companyName: {
        eq: value
      }
    }
  };

  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listUsers }
  } = await API.graphql(graphqlOperation(queries.listUsers, params));
  return listUsers.items.length > 0 ? false : true;
};

export const updateAllUsersByCompanyName = async (
  oldValue,
  newValue,
  nextToken,
  items = []
) => {
  let params = {
    filter: {
      companyName: {
        eq: oldValue
      }
    }
  };
  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listUsers }
  } = await API.graphql(graphqlOperation(queries.listUsers, params));
  if (listUsers.nextToken) {
    items.push(...listUsers.items);
    await updateAllUsersByCompanyName(
      oldValue,
      newValue,
      listUsers.nextToken,
      items
    );
  }

  let promises = [];
  if (items.length != 0) {
    for (let index = 0; index < items.length; index++) {
      const id = items[index].id;
      let {
        data: { updateUsers }
      } = await API.graphql(
        graphqlOperation(mutations.updateUsers, {
          input: { id, companyName: newValue }
        })
      );
      promises.push(updateUsers);
    }
  }
  return Promise.all(promises);
};

export const getSingleUser = async (input) => {
  let {
    data: { getUsers }
  } = await API.graphql(
    graphqlOperation(queries.getUsers, { id: input })
  );
  return getUsers
}

export const checkUserExistByEmail = async(email) => {
  let params = {
    filter: {
      email: {
        eq: email
      }
    }
  };

  let {
    data: { listUsers }
  } = await API.graphql(
    graphqlOperation(queries.listUsers, params)
  );

  console.log('UserGeting', listUsers)
  
  if(listUsers.items.length > 0){
    return true
  }else{
    return false
  }
}