import CreateProduct from '../../components/product/create-product';
import ListProducts from '../../components/product/list-products';
import React from 'react'

function Products(props) {
    return (
        <>
            <ListProducts user={props.user} />
        </>
    )
}

export default Products
