import { Avatar, Box, Button } from "@material-ui/core";
import React, { Fragment } from "react";

import { Link } from 'react-router-dom';
import avatar2 from "../../assets/images/avatars/avatar7.png";
import clsx from "clsx";
import { connect } from "react-redux";
import { useUser } from "../../providers/UserProvider";

const SidebarUserbox = props => {
  const { sidebarToggle, sidebarHover } = props;
  const { user } = useUser();

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover
        })}
      >
        <Avatar
          alt="Remy Sharp"
          src={avatar2}
          className="app-sidebar-userbox-avatar"
        />
        <Box className="app-sidebar-userbox-name">
          <Box>
            <b className="text-capitalize">
              {user?.firstName} {user?.lastName}
            </b>
          </Box>
          <Box className="app-sidebar-userbox-description">{user?.email}</Box>
          <Box className="app-sidebar-userbox-description"><b>{user?.accountType ? user?.accountType : 'User'}</b></Box>
          <Box className="app-sidebar-userbox-btn-profile">
            <Link to="/profile">
              <Button
                size="small"
                color="secondary"
                variant="contained"
                className="text-white"
              >
                View profile
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(SidebarUserbox);
