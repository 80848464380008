import { Button, Card } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import CircularProgress from '@material-ui/core/CircularProgress';
import EditSymptomConditionFormContainer from './condition-symptom-container.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from "../../common/components/userExitConfirmationModal";
import { Plus } from "react-feather";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {deleteUnTrackedUserResponses} from "../../common/utils/common"
import { getAllUnTrackedConditionsByUser } from "../../common/services/cond-and-symp.js";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const modalInitialValue = {
  open: false,
  no: true,
  yes: false,
  resId: '',
  resIndex: ''
}
const moment = require("moment");
const DATE_FORMAT = "MM/DD/YYYY";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy]< a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > [a.orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
const headCells = [
  {
    id: "dox002",
    numeric: false,
    disablePadding: true,
    label: "Conditions"
  },
  { id: "noOfSympts", numeric: true, disablePadding: false, label: "Symptoms" },
  {
    id: "treatment",
    numeric: true,
    disablePadding: false,
    label: "Treatments"
  },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Created At" },
  { id: "updatedAt", numeric: false, disablePadding: false, label: "Last updated" },
  { id: "trending", numeric: true, disablePadding: false, label: "Trending" },
  { id: "actions", disableSort: true, numeric: true, disablePadding: false, label: "Action" }
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.id ==='dox002' ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className="font-weight-bold"
          >
            {!headCell?.disableSort ? <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
                      ) : null}
                  </TableSortLabel>
                  :
                 <span> { headCell.label}</span>
                  }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const UntrackedConditionList = props => {
  const classes = useStyles();
  const [summaryCondition, setSummaryCondition] = useState([]);
  const [order, setOrder] = useState("desc");
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [deleteResponseModal, setDeleteResponseModal] = useState(modalInitialValue);
  const [isEditing, setIsEditing] = useState(false)
  const [editableResponse, setEditableResponse] = useState(null)
  const [allUnTrackedSymptoms, setAllUnTrackedSymptoms] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    loadUnTrackedConditions();
  }, [props?.user?.id, isEditing]);

  // This function is used to load tracked conditions
  async function loadUnTrackedConditions() {
    let getData = await getAllUnTrackedConditionsByUser(props.user.id);
      setAllUnTrackedSymptoms(getData);
    if (getData && getData.length > 0) {
      getData = getData.map(data => {
          let dox013 = data["dox013"]? JSON.parse(data["dox013"]):[];
          let dox021 = data["dox021"]? JSON.parse(data["dox021"]):[];
        return {
          ...data,
          dox002: JSON.parse(data["dox002"]),
          dox013,
          dox021,
          createdAt: new Date(data?.createdAt).getTime(),
          updatedAt: new Date(data?.updatedAt).getTime(),
          noOfSympts : [...dox013, ...dox021].length
        };
      });
    }
    setIsLoading(false);
    setSummaryCondition(getData || []);
  }

  function handleEdit(index, resp) {
    setIsEditing(true)
    setEditableResponse(resp)
  }

  function handleDelete(index, resp) {
    setDeleteResponseModal({
      ...deleteResponseModal, 
      open: true, 
      resId: resp['id'],
      resIndex: index,
      title: `Please be sure, this action would permanently delete your current response.`,
      button1: `Delete`,
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, summaryCondition.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderList() {
    return (
      <Fragment>
        {!isLoading ? (
          <TableBody>
            {summaryCondition && summaryCondition.length > 0 ? (
              stableSort(summaryCondition, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                (resp, index) => {
                  return (
                    <TableRow key={index} className="border">
                      <TableCell align="left">{resp?.dox002}</TableCell>
                      <TableCell align="right">
                        {resp?.noOfSympts}
                      </TableCell>
                      <TableCell align="right">1</TableCell>
                      <TableCell align="right"> {moment(resp.createdAt).format(DATE_FORMAT)}</TableCell>
                      <TableCell align="right"> {moment(resp.updatedAt).format(DATE_FORMAT)}</TableCell>
                      <TableCell align="right"> 1</TableCell>
                      <TableCell align="right">
                        <button className="text-primary cursor-pointer" style={{border: 'none', background: 'none'}} onClick={() => handleEdit(index, resp)}>
                          <FontAwesomeIcon icon={["fas", "edit"]} />
                        </button>
                        &nbsp;|&nbsp;
                        <button className="text-danger cursor-pointer" style={{border: 'none', background: 'none'}} onClick={() => handleDelete(index, resp)}>
                          <FontAwesomeIcon icon={["fas", "trash"]} />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            ) : 
              <TableRow>
                <TableCell className="text-muted" colSpan="7" style={{ margin: 10, textAlign: "center" }}>
                No Conditions!
            </TableCell>
              </TableRow>
            }
            { emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        ):
        <TableBody>
          <TableRow>
                <TableCell colSpan="7" style={{ margin: 10, textAlign: "center" }}>
                  <CircularProgress />
                </TableCell> 
              </TableRow>
        </TableBody>
        }
      </Fragment>
    );
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
const history = useHistory();
  return (
    <>
      { isEditing ? <EditSymptomConditionFormContainer goBack={()=>setIsEditing(false)} editableResponse={editableResponse} allUnTrackedSymptoms={allUnTrackedSymptoms} checkPlace={'onConditions'} />
      : <Fragment>
        <Card className="card-box mb-4">
          <div className="card-header">
            <div className="card-header--title d-flex justify-content-between align-items-center">
              <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                Conditions list
              </h4>
              <Button variant="contained" color="primary" onClick={()=>history.push('/addCondition')}><Plus />Add New</Button>
            </div>
          </div>
          <div className="card-body px-0 pt-2 pb-3">
            <TableContainer className="table table-hover table-borderless table-alternate text-nowrap mb-0">
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {renderList()}
              </Table>
            </TableContainer>
            <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={summaryCondition.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
            <Modal 
              settings={deleteResponseModal}
              close={async success => {
                if (success === 'delete and proceed') {
                  await deleteUnTrackedUserResponses(deleteResponseModal['resId']).then(() => {
                    setSummaryCondition((values) => values.filter(({id})=> id !== deleteResponseModal['resId']));
                  })
                }
                setDeleteResponseModal({ ...deleteResponseModal, open: false });
              }}
            />
          </div>
        </Card>
      </Fragment>}
    </>
  );
};

export default UntrackedConditionList;
