import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import * as customeQueries from '../curd/customQueries/queries';

import API, { graphqlOperation } from "@aws-amplify/api";

export const createCoupon = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.createCoupon, { input })
  );
}


export const listCoupons = async (userId, uniqueCoupon) => {
  let params = {};

  params.filter = {
    createdBy: {
      eq: userId
    }
  }

  if(uniqueCoupon){
    params.filter.campaignID={
      attributeExists:false
    }
  }


  let { data: { listCoupons } } = await API.graphql(
    graphqlOperation(customeQueries.listCoupons, params)
  );
  return listCoupons.items;
}

export const getAllCoupons = async () => {
  let params = {};

  let { data: { listCoupons } } = await API.graphql(
    graphqlOperation(customeQueries.listCoupons, params)
  );
  return listCoupons.items;
}

export const deleteCoupons = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    const id = coupons[index];
    let { data: { deleteCoupon } } = await API.graphql(
      graphqlOperation(mutations.deleteCoupon, { input: { id } })
    )
    promises.push(deleteCoupon);
  }
  return Promise.all(promises);
}
export const updateCoupon = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.updateCoupon, { input })
  )
}

export const listCouponsByProduct = async (userId, productID) => {
  let params = {
    filter: {
      createdBy: {
        eq: userId
      },
      productID : {
        eq: productID
      }
    }
  };
  let { data: { listCoupons } } = await API.graphql(
    graphqlOperation(customeQueries.listCoupons, params)
  );
  return listCoupons.items;
}

export const getCoupon = async (input) => {
  let { data: { getCoupon } } = await API.graphql(
    graphqlOperation(queries.getCoupon, { id: input })
  );
  return getCoupon;
}