import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

import moment from "moment";

export const listQuestions = async () => {
  return await API.graphql(graphqlOperation(queries.listQuestions));
};

export const createNotificationResponse = async input => {
  return await API.graphql(
    graphqlOperation(mutations.createNotificationResponse, { input })
  );
};

export const listNotificationResponse = async (
  userId,
  nextToken,
  items = []
) => {
  let params = {
    filter: {
      user: {
        eq: userId
      }
    }
  };
  if (nextToken) params.nextToken = nextToken;
  let {
    data: { listNotificationResponses }
  } = await API.graphql(
    graphqlOperation(queries.listNotificationResponses, params)
  );
  items.push(...listNotificationResponses.items);
  if (listNotificationResponses.nextToken) {
    await listNotificationResponse(
      userId,
      listNotificationResponses.nextToken,
      items
    );
  }
  return items.sort((i, j) => {
    if (i.updatedAt < j.updatedAt) return 1;
    if (i.updatedAt > j.updatedAt) return -1;
    return 0;
  });
};
