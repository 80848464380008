import { AppBar, Avatar, Badge, Box, Button, List, ListItem, Menu, withStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import projectLogo from "../../assets/dox-logo.png";
import { useUser } from "../../providers/UserProvider";
import avatar4 from "../../assets/images/avatars/defaultavatar.png";
import {
    setSidebarToggle,
    setSidebarToggleMobile
} from "../../reducers/ThemeOptions";
import HeaderLogo from "../HeaderLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledBadge = withStyles({
    badge: {
        backgroundColor: "var(--success)",
        color: "var(--success)",
        boxShadow: "0 0 0 2px #fff",
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""'
        }
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0
        }
    }
})(Badge);
const SetPasswordHeader = props => {

    const {
        headerShadow,
        headerFixed,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const { signOut } = useUser();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Fragment>
            <AppBar
                color="secondary"
                className={clsx("app-header", {
                    "app-header-collapsed-sidebar": props.isCollapsedLayout
                })}
                position={headerFixed ? "fixed" : "absolute"}
                elevation={headerShadow ? 11 : 3}
            >
                <Box className="app-header-toolbar">
                    <Box className="header-logo-wrapper" title="Doximple">
                        <img
                            className="app-header-logo-img"
                            alt="Doximple"
                            src={projectLogo}
                        />
                        <Box className="header-logo-text">Doximple</Box>
                    </Box>
                    <Box className="d-flex align-items-center">
                        <Button
                            color="inherit"
                            onClick={handleClick}
                            className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
                        >
                            <Box>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    variant="dot"
                                >
                                    <Avatar sizes="44" alt="Dustin Watson" src={avatar4} />
                                </StyledBadge>
                            </Box>
                            <span className="pl-1 pl-xl-3">
                                <FontAwesomeIcon icon={["fas", "angle-down"]} className="opacity-5" />
                            </span>
                        </Button>

                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            getContentAnchorEl={null}
                            open={Boolean(anchorEl)}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "center"
                            }}
                            onClose={handleClose}
                            className="ml-2"
                        >
                            <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                                    <ListItem button onClick={signOut}>Logout</ListItem>

                                </List>
                            </div>
                        </Menu>
                    </Box>
                </Box>
            </AppBar>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    headerShadow: state.ThemeOptions.headerShadow,
    headerFixed: state.ThemeOptions.headerFixed,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
    setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordHeader);
