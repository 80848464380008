import * as Yup from 'yup';
import {
    Button,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { createQuestionWorkflow, updateQuestionWorkflow } from '../../containers/curd/vendorQuestionWorkflow';
import { useEffect } from 'react';
import ReactSelect from '../../../common/components/ReactSelect';
import SortableList from '../../../common/components/SortableList/SortableList';

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    
})

const useStyles = makeStyles({
    root: {
        border: '1.5px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 10,
        padding: "10px 14px 18.5px 14px"
    },
    error: {
        color: '#f83245'
    }
});
function CreateQuestionWorkflow(props) {
    const classes = useStyles();
    const history = useHistory();
    const redirect = history?.location?.redirect;
    const { 
        user, 
        editable, 
        setSnackBarDetails, 
        vendorList = [],
        selectedOption,
        setSelectedOption, 
        createOptions, 
        selectedQuestions, 
        setSelectedQuestions,
        setOpenPreview,
        setEditable
    } = props
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name:''
    });
    const [isPreview, setIsPreview] = useState(false);
    const defaultOption = [];
    const handleChangeOption = (selectedOption) => {
        setSelectedQuestions(prev=>[...prev, selectedOption])
        setSelectedOption(null);
    }

    const resetSelectedOption = () => {
        setSelectedOption(defaultOption);
        setSelectedQuestions([])
        setSubmitted(false)
    }

    const resetForm = (e) => {
        resetSelectedOption();
    }

    
    

    useEffect(() => {
        setInitialValues((values) => {
            if (!editable) return values;
            let { name } = editable;
            return {
                name
            }
        })
        
        
    }, [editable])

    const handleSortable = (e) => {
        setSelectedQuestions(e);
    }

    const filteredData = (createOptions, selectedQuestions) => {

        const ques = selectedQuestions.map(item=>item.value);
        if(!createOptions){
            return createOptions;
        }
        return createOptions.filter(item=>{
            return !ques.includes(item.value);
        })
    }


    const preview = (e) => {
        setIsPreview(true);


    }

    const openPreview = (e) => {
        setOpenPreview(true);
    }


    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        setSubmitted(true);
                        let updateddata = null;
                        if(selectedQuestions.length === 0){
                            return;
                        }
                        values.questions = JSON.stringify(selectedQuestions.map(item=>item.value))
                        if (!editable) {
                            let data = await createQuestionWorkflow({ ...values, createdBy: user.id });
                            updateddata = data;
                        }
                        else {
                            let data = await updateQuestionWorkflow({ ...values, id: editable.id })
                            updateddata = data;
                        }
                        setSnackBarDetails({
                            message:
                                `Question Workflow ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        setEditable(updateddata);
                        if(isPreview){
                            openPreview();
                            return;
                        }
                        if(redirect){
                            history.push(`${redirect}?type=${editable ? "updated" : "created"}`);
                            return;
                        }
                        history.push(`/vendor-question-workflow?type=${editable ? "updated" : "created"}`)
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form>

                        <FormGroup>
                            <FormikTextField
                                name="name"
                                margin="normal"
                                label="Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ReactSelect
                                isMulti={false}
                                name="questions"
                                label="Questions"
                                options={filteredData(createOptions, selectedQuestions)}
                                onChange={handleChangeOption}
                                selectedOption={selectedOption}
                            />
                            {(submitted && selectedQuestions.length === 0) && (
                                <p className={classes.error}>Question is required.</p>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <SortableList 
                                items={selectedQuestions} 
                                onChange={handleSortable}
                                getName={(option) => option.label }
                            />
                        </FormGroup>
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid || isLoading}
                        >
                            {editable ? 'Update' : 'Submit'}
                        </Button>
                        <Button
                            type="submit"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            color="primary"
                            onClick={preview}
                            disabled={!isValid || isLoading}
                        >
                            Save & Preview
                        </Button>
                        {!editable && <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            onClick={resetForm}
                        >
                            Reset
                        </Button>}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateQuestionWorkflow

