import { makeStyles } from '@material-ui/core'
import React from 'react'
import FormikTextField from '../../../../common/components/FormikTextField'

const useStyles = makeStyles({
    container:{
        display:'flex',
        alignItems:'center',
        marginTop:'10px',
        width:'100%'
    },
    label:{
        fontSize:'15px',
        fontWeight:'600',
        marginRight:'10px',
        marginBottom:'0px',
        width:'25%',
    }
})

const TimePicker = (props) => {
    const {values, name, selectedOption} = props
    const classes = useStyles()
  return (
    <div className={classes.container}>
        <label className={classes.label}>Select Time</label>
        <FormikTextField
            name={name}
            margin="normal"
            type='time'
            className='w-100'
            // value={selectedOption ?? values.formTime}
        />
    </div>
  )
}

export default TimePicker