import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import AccountCircle from "@material-ui/icons/AccountCircle";
// import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TemporaryDrawer from "./drawer.js";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
// import logoText from "../../assets/dox-text.png";
import { useUser } from "../../providers/UserProvider";

const queryString = require('query-string');

// const navigateHome = () => {
//   navigate("/");
// };

const useStyles = makeStyles((theme) => ({
  typographyStyles: {
    flex: 1,
    fontSize: 18,
    fontWeight: 400,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const Header = (props) => {
  const { user, signOut } = useUser();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const classes = useStyles();
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
  } = props;

  // functions to support menu
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuId = "primary-search-account-menu";

  function renderLoggedHeader() { }
  return (
    // wrapping in div allowing for sections for desktop and mobile
    <Fragment>
      <AppBar
        color="secondary"
        position={headerFixed ? "fixed" : "absolute"}
        elevation={headerShadow ? 11 : 3}
      >
        <Toolbar>
          {user !== null && (
            <div>
              <TemporaryDrawer
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                {/* <TemporaryDrawer /> */}
              </TemporaryDrawer>
            </div>
          )}
          <Typography className={classes.typographyStyles}>Doximple</Typography>
          {user === null && (
            <div>
              {/* <Link to="/signIn"> */}
              <Button
                component={Link}
                to={`/signIn?coupon=${query.coupon || ''}&campaign=${query.campaign || ''}`}
                color="inherit"
                size="large"
              >
                Login
              </Button>
              {/* </Link> */}
            </div>
          )}

          {/* <div className={classes.grow} /> */}
          {/* <div className={classes.sectionDesktop}>       */}
          {/* {user === null && (
          <Link
            css={css`
              color: #e9f5fb;
              font-size: 1rem;
              line-height: 1;
              margin: 0 0.5rem 0 0;
              padding: 0.25rem;
              text-decoration: none;
            `}
            to="/signIn"
          >
            Sign In
          </Link>
        )} */}

          {user !== null && (
            <>
              <div>
                <IconButton
                  aria-label="show 3 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={3} color="primary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </div>

              <div>
                {/* <Link to="/profile"
            //   css={css`
            //     color: #e9f5fb;
            //     font-size: 1rem;
            //     line-height: 1;
            //     margin: 0 0.5rem 0 0;
            //     padding: 0.25rem;
            //     text-decoration: none;
            //   `}
            //   to="/profile"
            // >
            //   Profile
            > */}

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id={menuId}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                >
                  {/* <Link to="/profile"> */}
                  <MenuItem
                    onClick={handleMenuClose}
                    component={Link}
                    to={"/profile"}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleMenuClose}
                    component={Link}
                    to={"/profile"}
                  >
                    Account
                  </MenuItem>
                  {/* </Link> */}

                  <MenuItem onClick={signOut}>Sign Out</MenuItem>
                </Menu>
                {/* </Link> */}
                {/* <Link
              css={css`
                color: #1295d8;
                font-size: 1rem;
                line-height: 1;
                margin: 0 0.5rem 0 0;
                padding: 0.25rem;
                text-decoration: none;
              `}
              to="/"
              onClick={signOut}
            >
              Sign Out
            </Link> */}
              </div>
            </>
          )}
          {/* </nav> */}
          {/* </header> */}
          {/* </div> */}
        </Toolbar>
      </AppBar>
      {/* {renderMenu} */}
    </Fragment>
  );
};

export default Header;
