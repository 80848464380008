import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Snackbar } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  myAlert: {
    color: "#ffffff",
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DoxSnackBar = ({ ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (props.snackBarDetails.message) {
      setOpen(true);
    }
  }, [props.snackBarDetails]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert severity={props.snackBarDetails.severity} onClose={handleClose}>
        {props.snackBarDetails.message}
        {props.snackBarDetails.links &&
          props.snackBarDetails.links.map((link,index) => {
            return (
                <Link key={link.name} className={classes.myAlert} to={link.route}>
                  {" "}
                  {link.name}
                </Link>
            );
          })}
      </Alert>
    </Snackbar>
  );
};

export default DoxSnackBar;
