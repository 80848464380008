import {
  Badge,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import React, { Fragment, useRef, useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import avatar1 from "../../assets/images/avatars/avatar1.jpg";
import useNotificationsCount from "../../user/components/useNotificationsCount";
import { listNotifications, updateNotificationsLog } from '../../user/containers/curd/notifications';
import { useUser } from "../../providers/UserProvider";
import moment from "moment";
import { notificationMessage , notificationType} from "../../common/utils/constants";
import styles from './styles.module.css'
import { useHistory, useLocation } from "react-router";
const queryString = require('query-string');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function HeaderDots() {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [notifications, setNotifications] = useState(0);
  const [notificationIsRead, setNotificationIsRead] = useState([]);
  const [notificationsList, setNotificationList] = useState([]);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user, signOut } = useUser();
  const DATE_FORMAT = "MM-DD-YYYY";
  const TIME_FORMAT = "HH:MM";

  const location = useLocation();
  const query = queryString.parse(location.search);

  const handleClick1 = event => {
    updateList();
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTimeout(() => {
      getList()
    }, query.isLogin ? 10000 : 1000)
  }, [])

  const updateList = async () => {
    if (notificationIsRead.length != 0) {
      await updateNotificationsLog(notificationIsRead)
    }
  }



  const getList = async () => {
    setLoading(true)
    let id = user?.sub;
    if(!id) {
      id = user.id
    }
    let notify = await listNotifications(id, token);
    setNotificationList(prevData => [...prevData, ...notify.items])
    setToken(notify.token);
    setHasMore(notify.token != undefined)
    setLoading(false)
    let notificationCt = await notify.items.filter(item => item.isRead === false);
    let notificationCt1 = await notify.items.filter(item => (item.isResponded === false && item.expired === false));
    setNotificationIsRead(notificationCt)
    localStorage.setItem('notification', JSON.stringify(notificationCt1[notificationCt1.length - 1]))
    setNotifications(count => count + notificationCt.length)
  }

  const ref = useRef()
  const lastElementRef = useCallback(node => {
    if (loading) return
    if (ref.current) ref.current.disconnect()
    ref.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && hasMore) {
        if (notificationIsRead.length != 0) {
          await updateNotificationsLog(notificationIsRead)
          setNotifications(0)
        }
        getList();
      }
    })
    if (node) ref.current.observe(node)
  }, [loading, hasMore])



  const HandleNotificationsComponent = (props) => {
    const { expired, isResponded, link, type, message } = props;
    return (
      (() => {
        if (isResponded == false && expired == false) {
          if(type == notificationType.couponUpdated){
            return (
              <div><span style={{ color: "green" }}>{notificationMessage.couponUpdated}</span>, <a href={link} style={{ color: "blue" }}> click here </a> to access.</div>
            )
          }
          if(type == notificationType.couponCreated){
            return (
              <div><span style={{ color: "green" }}>{notificationMessage.couponCreated}</span>, <a href={link} style={{ color: "blue" }}> click here </a> to access.</div>
            )
          }
          if(type == notificationType.campaignCreated){
            return (
              <div><span style={{ color: "green" }}>{notificationMessage.campaignCreated}</span>, <a href={link} style={{ color: "blue" }}> click here </a> to access.</div>
            )
          }
          if(type == notificationType.userLoggedIn){
            return (
              <div>Please <a href={link} style={{ color: "blue" }}> click here </a><span style={{ color: "green" }}>{notificationMessage.newUserCampaign}</span></div>
            )
          }
          return (
            <div>Please <a href={link} style={{ color: "blue" }}> click here </a> to respond to the questions.</div>
          )
        } else if (expired == true && isResponded == false) {
          return (
            <div>Sorry, you missed this day to respond to the question. <span style={{ color: "tomato" }}>This link is expired now.</span></div>
          )
        } else {
          return (
            <div><span style={{ color: "green" }}>Great! You have already responded to all the questions for this day</span>. Keep doing the daily progress and win exciting coupons and rewards.</div>
          )
        }
      })()
    )
  }


  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
        <Hidden smDown>
          <Box component="span" pr="2">
            <IconButton
              onClick={handleClick1}
              color="inherit"
              className={`btn-inverse mx-1 d-50 ${notifications>0 && styles.notificationbutton}`}
            >
              {notifications > 0 && <div className="badge badge-pill badge-warning badge-header">
                {notifications}
              </div>}
              <NotificationsActiveTwoToneIcon />
            </IconButton>
            <Popover
              open={open1}
              anchorEl={anchorEl1}
              onClose={handleClose1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              classes={{
                paper: "app-header-dots"
              }}
            >
              <div className="popover-custom-xl overflow-hidden">
                <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                  <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                  <div className="bg-composed-wrapper--content text-light px-2 py-4">
                    <h4 className="font-size-xl font-weight-bold mb-2">
                      Notifications
                    </h4>
                  </div>
                </div>
                <div className="mx-2">
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    className="notificationIndicator"
                    onChange={handleChange}
                  >
                    <Tab className="py-3" label="Timeline" />
                  </Tabs>
                </div>
                <div className="height-280">
                  <PerfectScrollbar>
                    <TabPanel value={value} index={0}>
                      <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                        {notificationsList.length != 0 && notificationsList.sort(function (a, b) 
                        { 
                          return Date.parse(b.createdAt) - Date.parse(a.createdAt) })
                          .map((item, index) => {
                            const date = new Date(item.createdAt)
                            return( 
                            <div className="timeline-item" ref={lastElementRef} key={item.id}>
                              <div className="timeline-item-offset">{moment(item.createdAt).format(DATE_FORMAT)}<br />{date.getHours()}:{date.getMinutes().toString().padStart(2, '0')}</div>
                              <div className="timeline-item--content">
                                <div className="timeline-item--icon"></div>
                                <h4 className="timeline-item--label mb-2 font-weight-bold">
                                  <HandleNotificationsComponent
                                    expired={item.expired}
                                    isResponded={item.isResponded}
                                    link={item.link}
                                    type={item.type}
                                  />
                                </h4>
                              </div>
                            </div>
                          )
                          }
                         
                        )}
                        <div>{loading && 'Loading...'}</div>

                      </div>
                    </TabPanel>

                  </PerfectScrollbar>
                </div>
              </div>
            </Popover>
          </Box>
        </Hidden>
      </div>
    </Fragment>
  );
}
