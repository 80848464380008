import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createCouponRedeem = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.createCouponRedeem, { input })
  );
}


export const getCouponRedeem = async (userId, couponId = null) => {
    let params = {
        filter: {
            userID: {
                eq: userId
            },
        },
    }
    if(couponId){
        params.filter.couponID = {
            eq: couponId
        }
    }
    let items = await API.graphql(
        graphqlOperation(queries.listCouponRedeems, params)
    )
    return items;
}

export const getCouponRedeemByCouponCreatedBy = async ( userId) => {
    let params = {
        filter: {
            couponCreatedBy: {
                eq: userId
            },
        },
    }
    let items = await API.graphql(
        graphqlOperation(queries.listCouponRedeems, params)
    )
    return items;
}


export const deleteCouponRedeemByCouponId = async(userId, couponId) => {

    let params = {
        filter: {},
        limit:30
    };
    params.filter.couponID = {
        eq: couponId
    }
    params.filter.couponCreatedBy = {
        eq: userId
    }


    try {

        let { data: { listCouponRedeems } } = await API.graphql(
            graphqlOperation(queries.listCouponRedeems, params)
        )

        const toBeDeleted = listCouponRedeems.items.map((item) => item.id)
        for (let i = 0; i <= toBeDeleted.length; i++) {
            if(toBeDeleted[i]) {
                let payload = {
                    input:{
                        id:toBeDeleted[i]
                    }
                }
                await API.graphql(graphqlOperation(mutations.deleteCouponRedeem, payload))
            }   
        }
    } catch (error) {
        console.log("Error:", error)
    }

}
