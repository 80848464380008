import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
  } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
      backgroundColor: 'red',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    },
});

function Signinloader(props) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
          <CircularProgress />
      </div>
  )
}

export default Signinloader