import React, { useEffect, useState } from 'react'
import SortableItem from './SortableItem'
import styles from './SortableList.module.css';

export default function SortableList({items, getName, onChange}) {
  const [sortItems, setSortItems] = useState(items);

  useEffect(() => {
    setSortItems(items);
  }, [items])
  
  const handleSorted = (e) => {
    setSortItems([...e]);
  }

  const handleRemove = (i) => {
    let sortitem  = sortItems;
    sortitem.splice(i, 1);
    setSortItems([...sortitem]);
  }

  useEffect(() => {
    onChange(sortItems);
  }, [sortItems])
  
  return (
    <>
    {sortItems.length > 0 && (
      <ul className={styles.sortableList}>
          {sortItems.map((item, i)=>(
              <SortableItem
                  key={i}
                  onSortItems={handleSorted}
                  onRemove={handleRemove}
                  items={sortItems}
                  sortId={i}
                  index={i}
              >{getName(item)}</SortableItem>
          ))}
      </ul>
    )}
    </>
  )
}
