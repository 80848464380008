import React, { useEffect,useState } from "react";
import {
  Grid,
} from "@material-ui/core";
import OtpInputField from "./OtpInutField";

export default function OtpInput({ numInputs, onChange }) {
    const [otp, setOtp] = useState([]);
    const [currentInput, setCurrentInput] = useState(0);
    useEffect(() => {

        let arr = []
        for(let i = 0; i < numInputs; i++) {
            arr.push('');
        }
        setOtp(arr);

    },[numInputs])

    useEffect(() => {
        let str = '';
        otp.map(item=>{
            if(item !== ''){
                str += item;
            }
        })
        onChange(str);
    },[otp])

      
  const handleChange = (i,e) => {

      const { name, value } = e.target;
      if (isInputValueValid(value)) {
        let old = [];
        otp.map(item=>{
            old.push(item);
        })
        old[i] = value
        setOtp(old);
    }
  };

  const changeCodeAtFocus = (i,value) => {
    let old = [];
    otp.map(item=>{
        old.push(item);
    })
    old[i] = value
    setOtp(old);
    
  };
  const BACKSPACE = 8;
  const LEFT_ARROW = 37;
  const RIGHT_ARROW = 39;
  const DELETE = 46;
  const SPACEBAR = 32;

  const inputfocus = (i,e) => {
    const { name, value } = e.target;

    if (e.keyCode === BACKSPACE || e.key === "Backspace") {
      changeCodeAtFocus(i,"");
      focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === "Delete") {
      changeCodeAtFocus(i,"");
    } else if (e.keyCode === LEFT_ARROW || e.key === "ArrowLeft") {
      focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === "ArrowRight") {
      focusNextInput();
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === " " ||
      e.key === "Spacebar" ||
      e.key === "Space"
    ) {
        e.preventDefault();
    } else {
      if (isInputValueValid(value)) {
        focusNextInput();
      }
    }
  };
  const focusNextInput = () => {
    setCurrentInput((prev) => (prev < 3 ? prev + 1 : prev));
  };
  const focusPrevInput = () => {
    setCurrentInput((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const isInputValueValid = (value) => {
    const isTypeValid = !isNaN(parseInt(value, 10));

    return isTypeValid && value.trim().length === 1;
  };
  return (
    <Grid container spacing={2}>
        {otp.map((item,i)=>(
            <>
            <OtpInputField
                key={i}
                name="otp"
                value={item.value}
                onChange={handleChange.bind(this,i)}
                onKeyUp={inputfocus.bind(this,i)}
                setCurrentInput={setCurrentInput}
                focus={currentInput === i}
                tabIndex={i}
            />
            </>
        ))}
    </Grid>
  );
}
