import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles, withStyles } from '@material-ui/core';



const useStyles = makeStyles({
    root: {
        marginLeft: '6px',
        marginBottom: '0px',
        '& .MuiTypography-root': {
            color: 'black'
        },
        background:'#fff'
    },
    options: {
        padding: '0px 12px',
        background:'#fff'
    },
    detailStyle: {
        padding: '8px 44px 16px',
        display: 'block'
    },
    Formcontrolroot: {
        width: '100%'
    }
})
const ReactMultiSelect = ({ products, selectedOption, setSelectedOption }) => {
    const [open, setopen] = useState(false)
    const [expanded, setExpanded] = React.useState(false);
    const [selectedBrand, setselectedBranch] = useState([])
    const [allselect, setallselect] = useState(false)
    const [autoselect, setautoselect] = useState(false)
    const classes = useStyles()
    const colourStyles = {
        option: styles => ({
            ...styles,
            background: '#fff !important',
            padding: '0px 12px'
        }),
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function allvaluePrresent(arr) {
        return arr.every(element => element === true);
    }


    const Accordion = withStyles({
        root: {
            boxShadow: 'none',
            background:'#fff'
        }
    })(MuiAccordion)

    const AccordionSummary = withStyles({
        root: {
            margin: '0px',
            '& .MuiAccordionSummary-content': {
                margin: '0px'
            },
            '& .MuiFormControlLabel-root': {
                marginBottom: '0px'
            },
            background:'#fff'
        },

    })(MuiAccordionSummary)

    const handlecheckData = (e, value, item) => {
        // console.log("Working, ", value, item)
        if (selectedOption.includes(value)) {
            const getData = selectedOption.filter((item, i) => {
                if (item !== value) {
                    return item
                }
            })
            // console.log("Working 2", getData)
            setSelectedOption(getData)
        } else {
            setSelectedOption(state => [...state, value])
            // console.log("working 3",selectedOption)
        }
        e.stopPropagation();
        e.preventDefault()
        // console.log("working 4",selectedOption)
    }

    const autoBrandSelect = () => {
        let total_product = 0
        for (let index = 0; index < products.length; index++) {
            const { value, id } = products[index];
            let isAllSelect = value.map((item) => {
                total_product++
                return selectedOption.includes(item.id)
            })
            console.log("Allselect", isAllSelect)
            if (allvaluePrresent(isAllSelect) && isAllSelect.length > 0) {
                setselectedBranch(state => [...state, id])
            }
        }
        if(total_product === selectedOption.length){
            setallselect(true)
        }
        setautoselect(true)
    }

    useEffect(() => {
        if (selectedOption.length > 0 && !autoselect) {
            console.log("Rinning")
            autoBrandSelect()
        }
    }, [selectedBrand, selectedOption])

    const handleBrandSelect = (e, items, id) => {
        if (selectedBrand.includes(id)) {
            let allProducts = items.filter((val, i) => {
                const index = selectedOption.indexOf(val.value);
                if (index > -1) {
                    selectedOption.splice(index, 1)
                }
            })
            const allBrand = selectedBrand.map((item, i) => {
                if (item != id) {
                    return item
                }
            })
            setselectedBranch(allBrand)
        } else {
            let allProducts = items.filter((val, i) => {
                let index = selectedOption.indexOf(val.value);
                if (index < 0) {
                    setSelectedOption(state => [...state, val.value])
                }
            })
            // console.log("Check again", allProducts)
            setselectedBranch(state => [...state, id])
        }
        e.stopPropagation();
        e.preventDefault()
    }


    const handleSelectAll = () => {
        console.log(products)
        if (allselect) {
            setallselect(false)
            setSelectedOption([])
            setselectedBranch([])
        } else {
            setautoselect(false)
            for (let index = 1; index < products.length; index++) {
                const product = products[index]
                // console.log("product", product)
                product.value.map((item) => {
                    if (!selectedOption.includes(item.id)) {
                        setSelectedOption(state => [...state, item.id])
                    }
                })
            }
            setallselect(true)
        }

    }


    const toggleCheck = (id) => {
        let selected = selectedOption.length
        if(selectedBrand.includes(id)){
            selected =  selected - 1
        }
        if(products.length-1 === selected){
            setallselect(true)
        }else{
            setallselect(false)
        }
    }

    const CustomOption = ({ children, ...props }) => {
        return (
            <components.Option key={props.data.id ? props.data.id : 111} classes={{ options: classes.options }} {...props}>
                {props.data.isAllSelect ?
                    <FormGroup onClick={() => handleSelectAll()}>
                        <FormControlLabel classes={{ root: classes.root }} control={<Checkbox checked={allselect} />} label="Select All" />
                    </FormGroup> :
                    <Accordion onChange={handleChange(props.label)} expanded={expanded === props.label}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls={`additional-actions${props.data.id}-content`}
                            id={`additional-actions${props.data.id}-header`}>
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => {
                                    handleBrandSelect(event, props.value, props.data.id)
                                }}
                                onChange={()=>toggleCheck(props.data.id)}
                                control={<Checkbox checked={selectedBrand.includes(props.data.id)} />}
                                label={props.label}
                            />
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.detailStyle }}>
                            {
                                Array.isArray(props.value) ? props.value.map((item) => {
                                    return (
                                        <FormControlLabel
                                            aria-label="Subdata"
                                            classes={{ root: classes.Formcontrolroot }}
                                            onClick={(e) => handlecheckData(e, item.value, item)}
                                            control={<Checkbox checked={selectedOption.includes(item.value)} />}
                                            label={item.label}
                                        />
                                    )
                                }) : props.value
                            }
                        </AccordionDetails>
                    </Accordion>}
            </components.Option>
        );
    };
    const onMenuOpen = () => setopen(true);
    const onMenuClose = () => setopen(false);

    return (
        <Select 
            styles={colourStyles} 
            value={""} 
            placeholder="Select Products" 
            onFocus={onMenuOpen}
            onBlur={onMenuClose}
            components={{ Option: CustomOption }} 
            menuIsOpen={open} 
            options={products} 
        />
    )
}

export default ReactMultiSelect