import React from 'react'
import FormikSelectField from '../../../common/components-V2/FormikSelectField'
import FormikCheckboxGroupField from '../../../common/components/FormikCheckboxGroupField'
import FormikRadioGroupField from '../../../common/components/FormikRadioGroupField'
import FormikTextField from '../../../common/components/FormikTextField'
import TimePicker from '../vendor/components/TimePicker'
import DateSelection from './DateSelection'
import UploadFile from './UploadFile'

const CampaignOptions = (props) => {
    const { currentQuestion, options, formik, values, setUploadedImg, uploadedImg } = props
    if (currentQuestion.type === 'SINGLE') {
        return (
            <>
                <FormikRadioGroupField
                    name="questionResponse"
                    margin="normal"
                    style={{ width: "max-content" }}
                    options={options}
                    row={false}
                />
                <p className="text-danger">
                    {" "}
                    {formik.touched.questionResponse &&
                        formik.errors.questionResponse}
                    &nbsp;
                </p>
            </>
        )
    } else if (currentQuestion.type === 'MULTIPLE') {
        return (
            <>
                <FormikCheckboxGroupField
                    name="questionResponse"
                    margin="normal"
                    style={{ width: "max-content" }}
                    options={options}
                    row={false}
                    multiple
                />
                <p className="text-danger error-box">
                    {" "}
                    {formik.touched.questionResponse &&
                        formik.errors.questionResponse}
                    &nbsp;
                </p>
            </>
        )
    } else if (currentQuestion.type === 'TEXT') {
        return (
            <FormikTextField
                name="questionResponse"
                margin="normal"
                style={{ width: "100%" }}
                row={false}
            />
        )
    } else if (currentQuestion.type === 'DATE') {
        return (
            <DateSelection currentQuestion={currentQuestion} />
        )
    } else if (currentQuestion.type === 'TIME') {
        return (
            <TimePicker currentQuestion={currentQuestion} name="questionResponse" />
        )
    } else if (currentQuestion.type === 'SELECT') {
        const getArrOpt = JSON.parse(currentQuestion.options)
        const options = getArrOpt.map((item, index) => {
            return { value: item, id: item }
        });
        return (
            <FormikSelectField
                name="questionResponse"
                margin="normal"
                label="Select Options"
                options={options}
            />
        )
    } else if (currentQuestion.type === 'FILE') {
        return (<>
            <UploadFile setUploadedImg={setUploadedImg} uploadedImg={uploadedImg} />
            <p className="text-danger error-box">
                {" "}
                {formik.touched.questionResponse &&
                    formik.errors.questionResponse}
                &nbsp;
            </p>
        </>)
    } else if (currentQuestion.type === 'RANGE') {
        return (
            <>
                <FormikTextField
                    name="Range"
                    margin="normal"
                    style={{ width: "100%" }}
                    row={false}
                    type="number"
                />
                <p className="text-danger error-box">
                    {" "}
                    {formik.touched.questionResponse &&
                        formik.errors.questionResponse}
                    &nbsp;
                </p>
            </>
        )
    }
}

export default CampaignOptions