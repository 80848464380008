import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import { Button, Checkbox } from '@material-ui/core';


const options = [
    { label: 'Swedish', value: 'sv' },
    { label: 'English', value: 'en' },
    { label: 'Hindi', value: 'hn' },
];


const useStyles = makeStyles({
    option: {
        backgroundColor: '#FFFFFF !important',
        color: '#000000 !important',
        padding: '0px 12px !important',
        borderBottom: '1px solid lightgray'
    },
    addproductbtn: {
        margin: '10px 30px',
        width: 'calc(100% - 60px)'
    }
});

const Option = (props) => {

    const styles = useStyles()

    return (
        <div>
            <components.Option className={styles.option} {...props}>
                <Checkbox
                    checked={props.isSelected}
                    onChange={() => console.log("Checked ??", props.isSelected)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const AddProduct = (props) => {

    const styles = useStyles()
    const { selectProps } = props;

    return (
        <components.MenuList  {...props}>
            {props.children}
            <Button
                className={styles.addproductbtn}
                color="primary"
                variant="contained"
                onClick={() => selectProps.selectProps.openModal(true)}
                type="submit">
                Add New Product
            </Button>
        </components.MenuList>
    )

}

const Multiselect = ({ setselectedOption, selectedOption, openModal, options }) => {

    const handleChange = (selected) => {
        setselectedOption(selected)
    }

    const filterColors = (inputValue) => {
        return options.filter((i) => {
            return i.title.toLowerCase().includes(inputValue.toLowerCase()) || i.model.toLowerCase().includes(inputValue.toLowerCase()) || i.brand.toLowerCase().includes(inputValue.toLowerCase())
        }).map((item, index) => ({ label: item.title, value: item.id }))
    };

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterColors(inputValue));
            }, 1000);
        });

    const styles = useStyles()
    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            closeMenuOnSelect={false}
            selectProps={{ openModal }}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{
                Option,
                MenuList: AddProduct
            }}
            placeholder="Search Product from the existing database or Add New"

            loadOptions={promiseOptions}
            onChange={handleChange}
            value={selectedOption}
        />
    )
}

export default Multiselect