import {Box} from "@material-ui/core";
import ListCoupons from "../components/coupon/list-coupons";
import UserListCoupons from "../components/coupon/user-list-coupons";
import React from 'react'
const Coupons = (props) => 
    <Box container>
        {props?.user?.accountType === 'User'
        ?
        (<UserListCoupons user={props.user}/>)
        :
        (<ListCoupons user={props.user}/>)
        }
    </Box>

export default Coupons;

