import * as Yup from "yup";
import * as mutations from "../../graphql/mutations";

import API, { graphqlOperation } from "@aws-amplify/api";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from "aws-amplify";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormikCheckboxField from "../../common/components/FormikCheckboxField";
import FormikTextField from "../../common/components/FormikTextField";
import Header from "../../common/components/header";
import Layout from "../../common/components/layout";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import ReCAPTCHA from "react-google-recaptcha";
import awsconfig from "../../aws-exports";
import hero9 from "../../assets/images/hero-bg/hero-9.jpg";
import { withStyles } from "@material-ui/core/styles";
import FormikCheckboxFieldV2 from "../../common/components-V2/FormikCheckboxField";
import Divider from '@material-ui/core/Divider';
import { useUser } from "../../providers/UserProvider";
import GoogleLogin from "../../common/components/GoogleLogin";
import AmazonLogin from "../../common/components/AmazonLogin";
import CustomModal from "../../user/components/CustomModal";
import GreenTick from '../../assets/images/green-tick.png'
import useAuthServices from '../../hooks/useAuthServices'

const useStyles = makeStyles({
  divider: {
    width: '46%'
  },
  ortext: {
    margin: '0px 10px',
    fontWeight: '600'
  },
  signInBtn:{
    maxWidth:'250px',
    width:'100%',
    height:'50px'
  },
  clossBtn:{
    position:'absolute',
    right:"20px",
    cursor:'pointer'
  }
});


const queryString = require("query-string");

Auth.configure(awsconfig);

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
  termsChecked: false,
  recaptcha: ""
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Password does not match", function (value) {
      return this.parent.password === value;
    }),
  recaptcha: Yup.string().required(),
  termsChecked: Yup.bool().oneOf(
    [true],
    "You must accept the terms and conditions"
  )
});
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const SignUpContainer = props => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('')
  const [open, setopen] = useState(false)
  const [googleSubmitting, setGoogleSubmitting] = useState(false);
  const [amazonSubmitting, setAmazonSubmitting] = useState(false);
  const [failedAttempt, setFailedAttempt] = useState(0);
  const [socialLoading, setSocialLoading] = useState(false);
  const classes = useStyles();
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    links: [],
    severity: "error"
  });
  const localemail = localStorage.getItem('user_email')
  const { signInGoogle, signInAmazon } = useUser()
  const history = useHistory();

  React.useEffect(()=>{
    if(localemail){
      initialValues.email = localemail
    }
    // setopen(true)
  }, [])

  async function handleSubmit(values, formikData) {
    const { email, password } = values;
    const { resetForm, setFieldValue } = formikData;
    try {
      const userData = await Auth.signUp({
        username: email,
        password
      });

      let { data } = await API.graphql(
        graphqlOperation(mutations.createUsers, {
          input: {
            id: userData.userSub,
            email,
            accountType: "User",
            isPasswordSet: true,
            isDeleted: false,
            isAccountActive: true
          }
        })
      );
      await API.graphql(
        graphqlOperation(mutations.createPreference, {
          input: {
            user: data.createUsers.id,
            frequency: "Daily",
            questions: "1",
            sendedNotifications: 0,
            notification_on: {
              email: false,
              sms: false,
              app: true
            },
            remind_me: "Once per day"
          }
        })
      );
      setopen(true)
      resetForm();
    } catch (err) {
      let message = "Unknown error has occured";
      let links = [];
      if (err && err.message) {
        message = err.message;
      }
      if (err && err.code === "UsernameExistsException") {
        links = [
          { name: "Login", route: "/signIn" },
          { name: "Reset Password", route: "/resetPassword" }
        ];
      }
      setSnackBarDetails({ message, severity: "error", links });
    }
  }

  const {loading, resendEmail} = useAuthServices()

  const handleResendEmail = async(email) => {
    const isSend = await resendEmail(email)
    if(isSend){
      setSnackBarDetails({ message:"Email Send successfully", severity: "success", link:[] });
    }
  }

  return (
    <>
      <Header></Header>
      <DoxSnackBar snackBarDetails={snackBarDetails}></DoxSnackBar>
      <React.Fragment>
        <div className="app-wrapper min-vh-100 bg-white">
          <div className="app-main min-vh-100">
            <div className="app-content p-0">
              <div className="app-inner-content-layout--main">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content">
                    <Grid
                      container
                      spacing={0}
                      className="min-vh-100"
                      style={{ marginTop: "3rem" }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={5}
                        className="d-flex align-items-center"
                      >
                        <div className="hero-wrapper w-100 bg-plum-plate h-100">
                          <div className="flex-grow-1 w-100 d-flex align-items-center">
                            <div
                              className="bg-composed-wrapper--image"
                              style={{ backgroundImage: "url(" + hero9 + ")" }}
                            />
                            <div className="bg-composed-wrapper--bg bg-premium-dark opacity-5" />
                            <div className="bg-composed-wrapper--content p-5">
                              <div className="d-flex align-items-center">
                                <span className="px-4 h-auto py-1 badge badge-second badge-pill">
                                  Register page
                                </span>
                                <Tooltip
                                  arrow
                                  title="Create your own register or login pages using the included elements."
                                  placement="right"
                                >
                                  <span className="text-white-50 pl-3">
                                    <FontAwesomeIcon
                                      icon={["far", "question-circle"]}
                                    />
                                  </span>
                                </Tooltip>
                              </div>
                              <div className="text-white mt-3">
                                <h1 className="display-4 my-3 font-weight-bold">
                                  Why should you create an account?
                                </h1>
                                <p className="font-size-md mb-0 text-white-50">
                                  A free hour, when our power of choice is
                                  untrammelled and when nothing prevents.
                                </p>
                                <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        lg={7}
                        className="d-flex align-items-center"
                      >
                        <Container maxWidth="sm" style={{ marginTop: "3rem" }}>
                          <h3 className="display-4 font-weight-bold">
                            Create account
                          </h3>
                          <p className="font-size-lg text-black-50">
                            Fill in the fields below and you'll be good to go.
                          </p>
                          <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={(values, formikData) => {
                              const { setSubmitting } = formikData;
                              setSubmitting(true);
                              handleSubmit(values, formikData).then(res => {
                                setEmail(values.email)
                                setSubmitting(false);
                              });
                            }}
                            validationSchema={validationSchema}
                          >
                            {({
                              isValid,
                              isSubmitting,
                              setFieldValue,
                              values,
                              touched,
                              errors
                            }) => {
                              return (
                                <Form className="SignUp">
                                  <div className="mb-3">
                                    <FormikTextField
                                      variant="outlined"
                                      name="email"
                                      label="Email"
                                      placeholder="Email"
                                      margin="normal"
                                      fullWidth
                                    >
                                      <InputAdornment position="start">
                                        <MailOutlineTwoToneIcon />
                                      </InputAdornment>
                                    </FormikTextField>
                                  </div>
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          Password must be at least 8
                                          characters, and consist of at least
                                          one of each of the following:
                                        </Typography>
                                        <ul>
                                          <li>uppercase letter</li>
                                          <li>lowercase letter</li>
                                          <li>special character</li>
                                          <li>number</li>
                                        </ul>
                                      </React.Fragment>
                                    }
                                  >
                                    <div className="mb-3">
                                      <FormikTextField
                                        variant="outlined"
                                        name="password"
                                        label="Password"
                                        placeholder="Password"
                                        type="password"
                                        margin="normal"
                                        fullWidth
                                      >
                                        <InputAdornment position="start">
                                          <LockTwoToneIcon />
                                        </InputAdornment>
                                      </FormikTextField>
                                    </div>
                                  </HtmlTooltip>
                                  <div className="mb-3">
                                    <FormikTextField
                                      variant="outlined"
                                      name="confirmPassword"
                                      label="Confirm Password"
                                      placeholder="Confirm Password"
                                      type={showPassword ? "text" : "password"}
                                      margin="normal"
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() =>
                                                setShowPassword(bool => !bool)
                                              }
                                              onMouseDown={event =>
                                                event.preventDefault()
                                              }
                                            >
                                              {showPassword ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                    >
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    </FormikTextField>
                                  </div>
                                  {/* <FormikCheckboxField
                                    name="termsChecked"
                                    color="secondary"
                                    trueValue={true}
                                    falseValue={false}
                                    // value={values.termsChecked}
                                    controlLabel={
                                      <React.Fragment>
                                        I agree to{" "}
                                        <Link to="/terms" target="_blank" rel="noopener">
                                          Terms & Conditions
                                        </Link>
                                      </React.Fragment>
                                    }
                                  // validate={validateTermsAndCondition}
                                  ></FormikCheckboxField> */}
                                  <Field
                                    color="secondary"
                                    type="checkbox"
                                    name="termsChecked"
                                    id="termsChecked"
                                    className={
                                      "form-check-input " +
                                      (errors.termsChecked &&
                                        touched.termsChecked
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  &nbsp;&nbsp;
                                  <label
                                    htmlFor="termsChecked"
                                    className="form-check-label"
                                  >
                                    I agree to{" "}
                                    <Link
                                      to="/terms"
                                      target="_blank"
                                      rel="noopener"
                                    >
                                      Terms & Conditions
                                    </Link>
                                  </label>
                                  <ErrorMessage
                                    name="termsChecked"
                                    component="div"
                                    style={{
                                      color: "tomato",
                                      paddingLeft: "16px"
                                    }}
                                  />
                                  <br />
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    direction="column"
                                  >
                                    <Grid item className="h-100">
                                      <ReCAPTCHA
                                        sitekey="6LfuQcMUAAAAAEQJ2zjBL718HF8qGy_KzDJTuW30"
                                        onChange={response =>
                                          setFieldValue("recaptcha", response)
                                        }
                                      />
                                    </Grid>
                                    <Grid item className="d-flex w-100 mx-auto justify-content-center">
                                      {isSubmitting ? (
                                        <CircularProgress />
                                      ) : (
                                        <Button
                                          type="submit"
                                          className={classes.signInBtn}
                                          disabled={!isValid || isSubmitting}
                                          variant="contained"
                                          color="secondary"
                                          size="large"
                                        >
                                          Create Account
                                        </Button>
                                      )}
                                    </Grid>

                                    <div className="d-flex align-items-center justify-content-center w-100">
                                      <Divider className={classes.divider} />
                                      <div className={classes.ortext}>OR</div>
                                      <Divider className={classes.divider} />
                                    </div>
                                    <div className="d-flex justify-content-center my-2 ml-4">
                                      <div className="text-center mr-3">
                                        {googleSubmitting
                                          ? (<CircularProgress />
                                          ) : (
                                            <GoogleLogin
                                              setGoogleSubmitting={setGoogleSubmitting}
                                              setFailedAttempt={setFailedAttempt}
                                              setSnackBarDetails={setSnackBarDetails}
                                              failedAttempt={failedAttempt}
                                            />
                                          )}
                                      </div>

                                      <div className="text-center ml-3">
                                        {amazonSubmitting
                                          ? (<CircularProgress />
                                          ) : (
                                            <AmazonLogin 
                                              setAmazonSubmitting={setAmazonSubmitting} 
                                              setFailedAttempt={setFailedAttempt}
                                              setSnackBarDetails={setSnackBarDetails}
                                              failedAttempt={failedAttempt} 
                                              setSocialLoading={setSocialLoading}
                                            />
                                          )}
                                      </div>
                                    </div>
                                    <Grid item>
                                      Already a member?{" "}
                                      <Link
                                        to={
                                          query.coupon
                                            ? `/signIn?coupon=${query.coupon}`
                                            : "/signIn"
                                        }
                                      >
                                        Sign In
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Container>
                      </Grid>
                    </Grid>
                  </div>
                  <CustomModal open={open} onBackdropClick={()=>setopen(true)} setOpen={(bool) => { setopen(bool); }} fullWidth>
                      <div>
                        <IconButton onClick={()=>setopen(false)} className={classes.clossBtn} aria-label="delete"><CloseIcon/></IconButton>
                        <img src={GreenTick} className="w-25 mx-auto d-block img-fluid" />
                        <h3 className="text-center">Verify your email address</h3>
                        <p className="text-center mx-4">You have signed up successfully, next step is to verify your email address following through the email that we have just sent to your registered email address.</p>
                        <div className="d-flex justify-content-center">
                         { loading ? <CircularProgress /> : <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            loading={loading}
                            className="my-2 mx-1"
                            onClick={()=> {handleResendEmail(email)}}
                          > Resend Email </Button>}
                        </div>
                        
                      </div>
                  </CustomModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default SignUpContainer;
