import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../../common/components/layout";
import API from "@aws-amplify/api";
import awsconfig from "../../aws-exports";

API.configure(awsconfig);

class SymptomContainer extends Component {
  state = {
    symptomGroup: null
  };

  componentDidMount() {
    this.getSymptomGroup(this.props.id);
  }

  getSymptomGroup = async () => {};

  componentDidUpdate() {}

  componentWillUnmount = () => {};

  render() {
    const { symptomGroup } = this.state;
    return (
      <Layout>
        <h1>Symptom</h1>
        <Link to="/">Dashboard</Link>
        <pre>
          <code>{JSON.stringify(symptomGroup, null, 4)}</code>
        </pre>
      </Layout>
    );
  }
}

export default SymptomContainer;
