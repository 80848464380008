import {
    Button,
    CircularProgress,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik, useFormik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { sendSMSTwilio } from "../../../utils/commonFunctions";



function SendSms({ setSmsLoader, user, closeModal, editable, setUpdatedItem, setSnackBarDetails }) {
    const [initialValues, setInitialValues] = useState({
        mobile: '',
        message: '',
    });

    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setInitialValues((values) => {
            console.log("Values is coming", values)
            if (!editable) return values;
            let { mobile, message } = editable;
            return {
                mobile, message
            }
        })

    }, [editable])


    const validationSchema = Yup.object({
        mobile: Yup.string().required("Mobile is required."),
        message: Yup.string().required("Message is required."),
    })

    const handleSubmit = async (values) => {
        try {
            setLoader(true);
            const to = values.mobile.replace(/[^0-9\+]/g, "");
            const message = values.message;

            let resp = await sendSMSTwilio(to, message);
            // console.log("~ handleSubmit ~ resp", resp)
            setLoader(false);
            setSnackBarDetails({
                message:
                    `Sms sent successfully`,
                severity: "success",
                links: [],
            });
            closeModal();
        } catch (error) {
            setLoader(false);
            setSnackBarDetails({
                message: error.message,
                severity: "error",
                links: [],
            });

        }
    }


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => handleSubmit(values)}>

                {({ handleSubmit, values, handleChange, handleBlur }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <FormikTextField
                                name="mobile"
                                margin="normal"
                                label="Mobile"
                                values={values.mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField
                                name="message"
                                margin="normal"
                                label="Message"
                                values={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>

                        {loader ? <CircularProgress /> : <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>}

                    </Form>)}
            </Formik>

        </div>
    )
}

export default SendSms
