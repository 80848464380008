import { Button, Dialog, ButtonGroup } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  margin: {
    margin: '10px',
  },
}));

const UserExitConfirmationModal = props => {
  const [modal, setModal] = useState(props.settings.open);
  const {button1, button2, button3, colorDanger} = props.settings
  useEffect(() => {
    setModal(props.settings.open);
  }, [props.settings.open]);
  const toggle = success => {
    setModal(!modal);
    if ("close" in props) {
      props.close(success);
    }
  };
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog open={modal} onClose={() => toggle(false)}>
        <div className="text-center p-5">
          <h4 className="font-weight-bold mt-4">{props.settings.title}</h4>

          <div className="pt-4">
            <ButtonGroup>
              {button1 && <Button
                onClick={() => toggle('delete and proceed')}
                variant="contained"
                color={colorDanger || 'primary'}
                className={classes.margin}
              >
                <span className="btn-wrapper--label">{button1}</span>
              </Button>}

              {button2 && <Button
                onClick={() => toggle('save and proceed')}
                color="primary"
                variant="contained"
                className={classes.margin}
              >
                <span className="btn-wrapper--label">{props.settings.button2}</span>
              </Button>}
            </ButtonGroup>

            <Button
              onClick={() => toggle(false)}
              color="primary"
              variant="outlined"
              className={classes.margin}
            >
              <span className="btn-wrapper--label">{button3 || 'Cancel'}</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default UserExitConfirmationModal;
