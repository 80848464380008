import {
    Button,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import React, { useEffect, useState } from 'react'



function BitlyLink({ user, closeModal, editable, setUpdatedItem, setSnackBarDetails}) {
    const [initialValues, setInitialValues] = useState({
        bitlyLink:'',
    });


    useEffect(() => {
        setInitialValues((values) => {
            if (!editable) return values;
            let { bitlyLink } = editable;
            return {
                bitlyLink
            }
        })
        
        
    }, [editable])


    
    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async (values) => {
                    try {
                        
                        if(values.bitlyLink ===''){
                            return;
                        }
                        

                        setSnackBarDetails({
                            message:
                                `Link copied successfully`,
                            severity: "success",
                            links: [],
                        });
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                        
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form>
                        <FormGroup>
                            <FormikTextField
                                name="bitlyLink"
                                margin="normal"
                                label="Bitly Link"
                                disabled={true}
                            />
                        </FormGroup>
                        
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            onClick={() => {navigator.clipboard.writeText(values.bitlyLink)}}
                        >
                            Copy Link
                        </Button>
                        
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default BitlyLink
