import * as queries from '../../graphql/queries';
import API, { graphqlOperation } from '@aws-amplify/api'
import { Button, Card, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { createCouponScanned, deleteRelationByCouponId, getCouponScanned, getCouponScannedByCouponCreatedBy } from './curd/couponScanned';

import {
  getUnSavedUserSession,
  getUserSession,
  isConditionSession
} from "../../common/services/question-engine.js";
import { listVendors } from './curd/vendors';
import { useHistory, useLocation } from "react-router";
import CustomModal from '../components/CustomModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../common/components/layout.js";
import { Link } from "react-router-dom";
// import SymptomList from "../components/symptom-list";
import QrReader from "react-qr-reader";
import atob from 'atob';
import { css } from "@emotion/react";
import { getAllTrackedConditionAndSymptomsByUser } from "../../common/services/cond-and-symp.js";
import { isNullOrEmpty } from "../../common/utils/common";
import { useUser } from '../../providers/UserProvider';
import { listCoupons } from './curd/coupon';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { getCouponRedeemByCouponCreatedBy } from './curd/couponRedeem';
import { getCampaign, listCampaignByTrigger } from './curd/campaign';
import { createWorkflowQuestionResponse, listWorkflowQuestionResponseByUser } from './curd/workflowQuestionResponse';
import btoa from 'btoa';
import { notificationMessage, notificationType, triggerType } from '../../common/utils/constants';
import { getCustomerCategory } from './curd/customerCategory';
import Alert from '@material-ui/lab/Alert';
import {getSingleUser} from '../containers/curd/vendors'
import { deleteTempWorkflowById, listTempWorkflowById } from './curd/tempQuestionResponse';
import { getAllRewards, openInNewTab, sendNotification } from '../../utils/commonFunctions';
import { listVendorProduct } from './curd/vendorProducts';
import { deleteCustomerCategoryRelation } from '../../graphql/mutations';
import useVendorService from '../../hooks/useVendorService';
const queryString = require('query-string');

const initialCards = [
  {
    type: "condition",
    title: "Conditions",
    link: "addCondition",
    count: 0,
    dashboard: "conditions",
    resume: false
  },
  {
    type: "symptom",
    title: "Symptoms",
    link: "addSymptom",
    count: 0,
    dashboard: "symptoms",
    resume: false
  },
  { type: "treatment", title: "Products", link: "", count: 0 },
  // { type: "products", title: "Products", link: "", count: 0 },
  // { type: "services", title: "Services", link: "", count: 0 },
  // { type: "provider", title: "Providers", link: "", count: 0 }
];

const AdminCards = [
  {
    type: "total_users",
    title: "Total Users",
    count: 0,
    dashboard: "users",
    resume: false
  },
  {
    type: "total_activate_users",
    title: "Total Active Users",
    count: 0,
    dashboard: "users",
    resume: false
  },

  {
    type: "total_vendors",
    title: "Total Vendors",
    count: 0,
    dashboard: "vendors",
    resume: false
  },
  {
    type: "total_active_vendors",
    title: "Total Active Vendors",
    count: 0,
    dashboard: "vendors",
    resume: false
  },
];

const VendorCards = [
  {
    type: "total_users",
    title: "Total Users",
    count: 0,
    dashboard: "users",
    resume: false
  },
  {
    type: "total_activate_users",
    title: "Total Active Users",
    count: 0,
    dashboard: "users",
    resume: false
  },

  {
    type: "total_products",
    title: "Total Products",
    count: 0,
    dashboard: "products",
    resume: false
  },
  {
    type: "total_coupons",
    title: "Total Coupons",
    count: 0,
    dashboard: "coupons",
    resume: false
  },
  {
    type: "total_coupon_issued",
    title: "Total Coupons Issued",
    count: 0,
    dashboard: "coupons",
    resume: false
  },
  {
    type: "total_coupons_redeemed",
    title: "Total Coupons Redeemed",
    count: 0,
    dashboard: "coupons",
    resume: false
  },

];

const TempConditionNames = [];
const ConditionNames = [];
let AllSymptomsNames = [];
let AdditionalSymptomsNames = [];

const UserDashBoardContainer = props => {
  const { user } = useUser();
  const location = useLocation();
  const history = useHistory()
  const query = queryString.parse(location.search);
  const [result, setResult] = useState("No result");
  const [openCamera, setOpenCamera] = useState(false);
  const [cards, setCards] = useState(initialCards);
  const [cardSuperAdmin, setSuperAdminCards] = useState(AdminCards);
  const [cardVendorAdmin, setVendorAdminCards] = useState(VendorCards);
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [campaignLink, setCampaignLink] = useState(null);
  const [checkedCampaign, setCheckedCampaign] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isemail, setisemail] = useState(false)
  const [couponexistmodal, setcouponexistmodal] = useState(false)
  const getsettleId = localStorage.getItem('temp_ques_id')
  const {showNonredeemCampaign} = useVendorService()
  const [rewards, setRewards] = useState([])
  const [rewardModal, setRewardModal] = useState(false)

  useEffect(() => {
      if (query.campaign && user?.accountType === "User") {
        history.push(`/campaign-questions?campaign=${query.campaign}&isBitly=true`);
      }
  }, [query.campaign, user?.accountType])

  useEffect(() => {
    const getCouponData = async () => {
      if (query.coupon) {
        let coupon = atob(query.coupon)
        if (user?.accountType === "User") {
          let { data: { getCoupon: couponData } } = await API.graphql(graphqlOperation(queries.getCoupon, { id: coupon }))
          console.log("CouponData", couponData)
          setCoupon(couponData);
        }
        // history.replace('/dashboard');
      }
    }
    getCouponData();
  }, [query.coupon])
  
  const deleteAllFromLocalStorage = async() => {
    let keysToRemove = ["temp_ques_id", "user_email"];
    keysToRemove.forEach((k) =>
      localStorage.removeItem(k))
  }

  const fetchNewReward = async() => {
    if(user?.accountType !== "User") return
    const campaignUser = await showNonredeemCampaign()
    console.log('CampaignUser', campaignUser)
    if(query.isLogin){
      try {
        const Responce = await Promise.all(campaignUser.map(async(item) => {
           await sendNotification(user.id, notificationMessage.newUserCampaign, notificationType.userLoggedIn, item.bitlyLink)
        }))
      } catch (error) {
        console.log('Error', error)
      }
    
    }
  } 

  useEffect(() => {
    fetchNewReward()
  }, [])

  useEffect(()=>{
    if(query.isLogin){
      setRewardModal(true)
    }
  }, [rewards])

  useEffect(async()=>{
    let campaignID = ''
    const fetchTempWorkflowList = async(listId) => {
      const getList = await listTempWorkflowById(listId, true)
      campaignID = getList[0]?.campaignID
      for(let i = 0; i < getList.length; i++){
        let newdata = {
          campaignID: getList[i]?.campaignID,
          workflowID: getList[i]?.workflowID,
          questionID: getList[i].questionID,
          userID: user.id,
          answer: getList[i].answer
        }
        console.log(newdata)
        let data = await createWorkflowQuestionResponse(newdata);
      }
      console.log("Hello CampaignId", campaignID)
      let deleteData = await deleteTempWorkflowById(getList)
     if(deleteData){
        setisemail(true)
        deleteAllFromLocalStorage()
        // localStorage.removeItem('temp_ques_id')
        // localStorage.removeItem('user_email')
      }
    }

    

    if(getsettleId && user?.accountType === "User"){
      console.log("Start here")
      await fetchTempWorkflowList(JSON.parse(getsettleId))
      const couponId = await getCampaign(campaignID)
      console.log("CouponId", couponId.coupons)
      let rewards = couponId ? JSON.parse(couponId.coupons) : []
      console.log("Reward", rewards[0])
      if(rewards.length > 1){
        await getAllRewards(rewards, user)
        return
      }
      let { data: { getCoupon: couponData } } = await API.graphql(graphqlOperation(queries.getCoupon, { id: rewards[0] }))
      console.log("Testing 1", couponData)
      setCoupon(couponData);
    }
  }, [user?.accountType, getsettleId])


  useEffect(() => {
    setOpen(coupon ? true : false)
    setCouponScanned(coupon);
  }, [coupon])

  useEffect(() => {
    if(query?.couponexist){
      setcouponexistmodal(true)
    }
  }, [query?.couponexist])

  useEffect(() => {
    if(checkedCampaign === true && open === false){
      if(campaignLink){
        history.push(campaignLink);
      }else{
        history.replace('/dashboard');
      }
    }
  }, [open])

  const handleOk = (e) => {
    if(campaignLink && !getsettleId){
      history.replace('/dashboard');
      history.push(campaignLink);
      return;
    }
    history.replace('/dashboard');
  }
  
  const checkCampaign = async(trigger, createdBy) => {

    let campaigns = await listCampaignByTrigger(trigger, createdBy);
    if(campaigns.length > 0){
      for(let i = 0; i < campaigns.length; i++) {
        
        let questions = campaigns[i]?.workflow?.questions
        ? JSON.parse(campaigns[i]?.workflow?.questions)
        : []
        let users = campaigns[i]?.users ? JSON.parse(campaigns[i]?.users) : [];
        if(users.length === 0){
          let categoryId = campaigns[i]?.customerCategoryID;
          let category = await getCustomerCategory(categoryId);
          let relations = category?.customerCategoryRelations?.items || [];
              
          let uniqueRelations = relations.filter((value, index, self)=>{
              return self.findIndex(item => item.userID === value.userID) === index
          })
          users = uniqueRelations.map(item => item?.userID);
        }

        if(users.includes(user.id)){
          let responses = await listWorkflowQuestionResponseByUser(
            user?.id, 
            campaigns[i]?.id, 
            campaigns[i]?.workflowID
            )
            
          if(responses.length < questions.length && !isemail) {
            setCampaignLink(`/campaign-questions?campaign=${btoa(campaigns[i].id)}`);
            setCheckedCampaign(true);
            return;
          }
        }
      }
    }
  }

  const setCouponScanned = async(couponData) => {

    if(couponData){
      let existing = await getCouponScanned(user.id, couponData.id);
      console.log("Testing 1", existing?.length === 0)
      if(existing?.length === 0){

        let input = {
          couponID: couponData.id,
          userID:user.id,
          couponCreatedBy:couponData.createdBy,
        }
        let { data } = await createCouponScanned(input);
      }else{
        console.log("Working coupon exist")
      }
      await checkCampaign(triggerType.couponScanned, couponData.createdBy);
    }
  }
  
  useEffect(() => {
    async function onLoad() {
      let old = [];
      cards.map(item=>{
        old.push(item);
      })
      for(let i=0; i<old.length; i++){
        old[i].count = 0
      }
      setCards(old);
      let response = await getUnSavedUserSession(props.user.sub);
      if (response && response.sessionID && response.createdAt) {
        const questionResp = await getUserSession(response.sessionID);
        if (questionResp) {
          let conditionSession = isConditionSession(questionResp);
          if (conditionSession !== null) {
            if (conditionSession) {
              cards[0] = { ...cards[0], resume: true };
              setCards([...cards]);
            } else {
              cards[1] = { ...cards[1], resume: true };
              setCards([...cards]);
            }
          }
        }
      }
      const result = await getAllTrackedConditionAndSymptomsByUser(
        props.user.sub
      );
      if (result && result.length > 0) {
        let sCount = 0;
        let cCount = 0;

        for (let i of result) {
          // dox002 respresenting conditionName column
          if (!isNullOrEmpty(i, "dox002") && ConditionNames.indexOf(JSON.parse(i.dox002)) === -1) {
            TempConditionNames.push(JSON.parse(i.dox002))
          }
          //dox019 respresenting symptomName column for path 2
          if (!isNullOrEmpty(i, "dox019")) {
            JSON.parse(i.dox019).map(res => {
              if (AllSymptomsNames.indexOf(res) === -1) {
                AllSymptomsNames.push(res)
              }
            })
          }
          //dox015 respresenting additonal symptomName column for path 2
          if (!isNullOrEmpty(i, "dox015")) {
            JSON.parse(i.dox015).map(res => {
              if (AdditionalSymptomsNames.indexOf(res) === -1) {
                AdditionalSymptomsNames.push(res)
              }
            })
          }
        }
        // getting unique condition names
        TempConditionNames.flat(2).forEach(tempCondition => {
          if (ConditionNames.indexOf(tempCondition) === -1) {
            ConditionNames.push(tempCondition)
          }
        })
        if (ConditionNames.length > 0) cCount = ConditionNames.length
        if (AllSymptomsNames.length > 0 && AdditionalSymptomsNames.length >= 0) sCount = AllSymptomsNames.length + AdditionalSymptomsNames.length
        cards[0].count = cCount;
        cards[1].count = sCount;
        setCards([...cards]);
      }
    }
    onLoad();
  }, [props?.user?.id]);


  // for SuperAdmins
  useEffect(() => {
    async function onLoad() {
     
      let accountType = [];
      if (props.user.accountType === 'SuperAdmin' || props.user.accountType === 'SuperAdminUser') {
        accountType = ['SuperAdminUser']
        let old = [];
        AdminCards.map(item=>{
          old.push(item);
        })
        for(let i=0; i<old.length; i++){
          old[i].count = 0
        }
        setSuperAdminCards(old);
      } else if (props.user.accountType === 'VendorAdmin' || props.user.accountType === 'VendorAdminUser') {
        accountType = ['VendorAdmin', 'VendorAdminUser']
        let old = [];
        VendorCards.map(item=>{
          old.push(item);
        })
        for(let i=0; i<old.length; i++){
          old[i].count = 0
        }
        setVendorAdminCards(old);
      }
      let responseUser = await listVendors(props.user.id, accountType);
      if (props?.user?.accountType === 'SuperAdmin' || props?.user?.accountType === 'SuperAdminUser') {
        let responseVendor = await listVendors(props.user.id, ['VendorAdmin']);
        if (responseUser.length !== 0) {
          cardSuperAdmin[0] = { ...AdminCards[0], count: responseUser.length, resume: true };
          setSuperAdminCards([...cardSuperAdmin]);
        }

        if (responseUser.length !== 0) {
          let activeUser = responseUser.filter(item => (item.isAccountActive === true && (item.isDeleted === false || item.isDeleted === null)));
          cardSuperAdmin[1] = { ...AdminCards[1], count: activeUser.length, resume: true };
          setSuperAdminCards([...cardSuperAdmin]);
        }

        if (responseVendor.length !== 0) {
          cardSuperAdmin[2] = { ...AdminCards[2], count: responseVendor.length, resume: true };
          setSuperAdminCards([...cardSuperAdmin]);
        }

        if (responseVendor.length !== 0) {
          let activeVendor = responseVendor.filter(item => (item.isAccountActive === true && (item.isDeleted === false || item.isDeleted === null)));
          cardSuperAdmin[3] = { ...AdminCards[3], count: activeVendor.length, resume: true };
          setSuperAdminCards([...cardSuperAdmin]);
        }
      }

      if (props?.user?.accountType === 'VendorAdmin' || props?.user?.accountType === 'VendorAdminUser') {
        let id = props.user.sub;
        if(!id){
          id = props.user.id;
        }
        let products = await listVendorProduct(id, props?.user?.accountType)
        if(props.user.accountType === "VendorAdminUser"){
          const {createdBy} = await getSingleUser(id)
          id = createdBy
        }
        let coupons = await listCoupons(id)
        let { data: { listCouponScanneds: couponScans } } = await getCouponScannedByCouponCreatedBy(id);
        couponScans = couponScans.items || [];
        let { data: { listCouponRedeems: couponRedeems } } = await getCouponRedeemByCouponCreatedBy(id);
        couponRedeems = couponRedeems.items || [];
        if (responseUser.length !== 0) {
          cardVendorAdmin[0] = { ...VendorCards[0], count: responseUser.length, resume: true };
          setVendorAdminCards([...cardVendorAdmin]);
        }

        if (responseUser.length !== 0) {
          let activeUser = responseUser.filter(item => (item.isAccountActive === true && (item.isDeleted === false || item.isDeleted === null)));
          cardVendorAdmin[1] = { ...VendorCards[1], count: activeUser.length, resume: true };
          setVendorAdminCards([...cardVendorAdmin]);
        }

        if (products.length !== 0) {
          cardVendorAdmin[2] = { ...VendorCards[2], count: products.length, resume: true };
          setVendorAdminCards([...cardVendorAdmin]);
        }
        
        if (coupons.length !== 0) {
          cardVendorAdmin[3] = { ...VendorCards[3], count: coupons.length, resume: true };
          setVendorAdminCards([...cardVendorAdmin]);
        }

        if (couponScans.length !== 0) {
          cardVendorAdmin[4] = { ...VendorCards[4], count: couponScans.length, resume: true };
          setVendorAdminCards([...cardVendorAdmin]);
        }

        if (couponRedeems.length !== 0) {
          cardVendorAdmin[5] = { ...VendorCards[5], count: couponRedeems.length, resume: true };
          setVendorAdminCards([...cardVendorAdmin]);
        }
      }
      setLoading(false);
    }
    onLoad()
  }, [props?.user?.id])

  function handleScan(data) {
    if (data) {
      setResult(data);
      setOpenCamera(false);
    }
  }
  function handleError(err) {
    console.error(err);
  }

  function toggleCamera() {
    setOpenCamera(!openCamera);
  }

  function goToCoupon() {
    history.push('/coupons')
  }

  function renderCard(card) {
    return (
      <Grid item xs={12} sm={6} lg={4} key={card.type}>
        <Card className="card-box border-0 card-shadow-first p-4 mb-4">

          <div className="d-flex align-items-center justify-content-between">
            <div className="mr-auto d-flex align-items-center justify-content-between">
              <div className="d-40 rounded-circle bg-first text-white text-center font-size-lg mr-3">
                <FontAwesomeIcon icon={["far", "keyboard"]} />
              </div>
              <div className="text-black-50">{card.title}</div>
            </div>
            <div>
              <Link to={card.link}>
                <Button color="primary" variant="contained">
                  Add
                </Button>
              </Link>
            </div>
          </div>
          <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <CircularProgress />
              </div>
            ) : (
            <div>
              {card && card.dashboard && (
                <Link to={card.dashboard} variant="outlined">
                  {card.count}
                </Link>
              )}
              {card && !card.dashboard && (
                <Link to="#" variant="outlined">
                  {card.count}
                </Link>
              )}
            </div>
          )}
          </div>
          <div className="text-black-50 text-center opacity-6 pt-3">
            {card && card.resume && (
              <Link to={card.link}>
                <Button color="primary" variant="contained">
                  Pickup where you left off
                </Button>
              </Link>
            )}
          </div>
        </Card>
      </Grid>
    );
  }

  const renderSuperAdminCard = (card) => {
    return (<Grid item xs={12} sm={6} lg={4} key={card.type}>
      <Card className="card-box border-0 card-shadow-first p-4 mb-4">

        <div className="d-flex align-items-center justify-content-between">
          <div className="mr-auto d-flex align-items-center justify-content-between">
            <div className="d-40 rounded-circle bg-first text-white text-center font-size-lg mr-3">
              <FontAwesomeIcon icon={["far", "user"]} />
            </div>
            <div className="text-black-50">{card.title}</div>
          </div>
        </div>
        <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          ):(
            <div>
              {card && card.dashboard && (
                <Link to={card.dashboard} variant="outlined">
                  {card.count}
                </Link>
              )}
              {card && !card.dashboard && (
                <Link to="#" variant="outlined">
                  {card.count}
                </Link>
              )}
            {/* // <div className="text-black-50">{card.count}</div> */}
            </div>
          )}
        </div>
      </Card>
    </Grid>)
  }
  return (
    <>
      <h1
        css={css`
          color: #05293b;
          font-weight: 300;
          padding-top: 40px;
          padding-left: 15px;
          text-align: center;
          padding-bottom: 15px;
        `}
      >
        Dashboard
      </h1>
      {(props.user.accountType === 'User' || props.user.accountType === '' || props.user.accountType === null) && <Grid container spacing={4}>
        {cards.map(card => {
          {
            return renderCard(card);
          }
        })}
      </Grid>}

      {(props.user.accountType === 'SuperAdmin' || props.user.accountType === 'SuperAdminUser') && <Grid container spacing={4}>
        {cardSuperAdmin.map(card => {
          {
            return renderSuperAdminCard(card);
          }
        })}
      </Grid>}

      {(props.user.accountType === 'VendorAdmin' || props.user.accountType === 'VendorAdminUser') && <Grid container spacing={4}>
        {cardVendorAdmin.map(card => {
          {
            return renderSuperAdminCard(card);
          }
        })}
      </Grid>}

      {(props.user.accountType === 'User' || props.user.accountType === '' || props.user.accountType === null) && <Layout>
        <button
          css={css`
            color: #a8d8f0;
            text-decoration: none;
            padding: 10px;
            border-radius: 0.3em;
            width: 100%;
            font-weight: 300;
            border: 1px solid #a8d8f0;
            background-color: #05293b;
          `}
          onClick={toggleCamera}
        >
          Scan Your Medicine
        </button>
        {openCamera === true && (
          <div>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
            <p>{result}</p>
          </div>
        )}
        {result !== "No result" && <p>QR Result: {result}</p>}
      </Layout>}
      <CustomModal fullWidth={false} open={open} setOpen={(bool) => { setOpen(bool); setCoupon(null) }}>
        <div className="p-2">

        <Typography align="center" variant="h1" color="inherit">
          <CardGiftcardIcon style={{color: 'red',fontSize: '64px'}}/> 
        </Typography>
          <div className="mt-2">
            <Typography align="center" variant="h4" color="inherit">Congratulations! You have won a discount coupon.</Typography>
          </div>
          <div className="mt-4">
            <Typography variant="subtitle1">Name: {coupon?.name}</Typography>
            <Typography variant="subtitle1">Discount: {coupon?.amount_off ? '$' + coupon?.amount_off: coupon?.percent_off + "%"}</Typography>
          </div>
          <div className="pt-2 text-center">
              <Button
                onClick={() => setOpen(false)}
                variant="contained"
                style={{ background: "red", color: 'white' }}
                className="mx-1"
              >
                <span className="btn-wrapper--label" onClick={handleOk}>Ok!</span>
              </Button>
            </div>
        </div>

      </CustomModal>
      <CustomModal fullWidth={false} open={couponexistmodal} setOpen={(bool) => { setcouponexistmodal(bool); }}>
        <div className="p-2">

        <Typography align="center" variant="h1" color="inherit">
          <CardGiftcardIcon style={{color: 'red',fontSize: '64px'}}/> 
        </Typography>
          <div className="mt-2">
            <Typography align="center" variant="h4" color="inherit">
              {props.user.accountType !== "User" ? `Only user's are allowed to avail this offer` : `Sorry! It looks like that you have already availed this offer`}
            </Typography>
          </div>
          {props.user.accountType === "User" && <div className="pt-2 text-center">
              <Button
                onClick={() => setcouponexistmodal(false)}
                variant="contained"
                style={{ background: "red", color: 'white' }}
                className="mx-1"
              >
                <span className="btn-wrapper--label" onClick={goToCoupon}>Check Coupons</span>
              </Button>
            </div>}
        </div>

      </CustomModal>
    </>
  );
};

export default UserDashBoardContainer;
