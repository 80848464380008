import React, { Component } from "react";
import Header from "../../common/components/header";
// import { firestore } from "../../firebase";
import Layout from "../../common/components/layout";
import { Link } from "react-router-dom";

class TermsAndConditions extends Component {
  render() {
    return (
      <>
        <Header></Header>
        <Layout>
          <section>
            <p>
              <b>Terms and Conditions</b>
            </p>
            <br />
            <p>
              <b>CONDITIONS OF USE</b>
            </p>
            <p>
              Use of the website ("website" or "Site") of Doximple, Inc.
              ("Doximple") constitutes agreement to our terms of use (the
              "Terms"). We encourage you to review the following information
              carefully.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>TRADEMARKS</b>
            </p>
            <p>
              Doximple and other marks indicated on our site are trademarks
              owned exclusively by Doximple, Inc. The use of these trademarks
              and trade dress is prohibited if used in connection with the sale
              of any product that is not Doximple's in any manner that seeks to
              disparage or discredit Doximple or in any manner that may cause
              confusion among our customers.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>COPYRIGHT</b>
            </p>
            <p>
              All content on this website is the exclusive property of Doximple.
              Said content includes text, graphics, logos, icons, images, audio
              and video clips, digital downloads, and software. Our content is
              protected by United States and international copyright laws and we
              reserve all rights contained therein. In case of dispute or
              infringement, we will rigorously defend our rights to this
              material.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>REGISTRATION INFORMATION</b>
            </p>
            <p>
              By becoming a Registrant you represent and warrant that (a) all
              registration information you submit is truthful and accurate; and
              (b) you will maintain the accuracy of such information. Our
              collection and use of any information you provide to us in
              connection with your Registration is subject to the Terms
              including, without limitation, our Privacy Policy located at{" "}
              <Link to={{ pathname: "https://www.doximple.com/legal." }} target="_blank">www.doximple.com/legal.</Link>
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>PROTECTING YOUR ACCOUNT</b>
            </p>
            <p>
              Some portions of this website permit username and passwords to be
              used. You are responsible for protecting your unique username and
              password and you agree to be responsible for all activities
              performed under your user account.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>PASSWORDS</b>
            </p>
            <p>
              When you register to become a Registrant, you will be asked to
              choose a user name and password. You are solely responsible for
              maintaining the confidentiality of this information. You are not
              permitted to allow anyone else to use your user name and password
              to log into the Site.
            </p>
            <p>
              You must notify us immediately if you suspect any unauthorized use
              of your account or access to your password. You are solely
              responsible for any and all use of your account so please be
              vigilant in protecting its confidentiality.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>LICENSE AND SITE ACCESS</b>
            </p>
            <p>
              Doximple grants you (the user) a limited license to access and
              make personal use of this website. This license does not include
              the right to download any material (other than routine page
              caching), modify any material, or any portion thereof, without the
              express written consent of Doximple. Additionally, you will not in
              any way access, use, or copy any portion of this site or its
              features to directly or indirectly develop, promote, distribute,
              sell or support any product or service that is competitive with
              Doximple’s products and services or to disparage Doximple or its
              products or services. You agree not to rent, retransmit, disclose,
              publish, sell, assign, lease, sublicense, market, or transfer our
              site or any portion thereof or use it in any manner not expressly
              authorized by the Terms. You agree not to copy, reverse engineer,
              translate, port, modify or make derivative works of any portion of
              this site. Tampering with this site, misrepresenting the identity
              or age of a user, using buying agents or conducting fraudulent
              activities on this site are prohibited. Any unauthorized use of
              this website voids the limited license granted by Doximple.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>CONTENT SUBMITTED BY SITE USERS </b>
            </p>
            <p>
              We may, but are not obligated to, allow you to upload content for
              display on the Site. This may be in the form of responses to blog
              posts, participation in support forums or other discussion threads
              and other through other means. If we allow this feature, please
              choose carefully the text, files, images, photos, video, sounds,
              musical works, works of authorship, applications, or any other
              materials and information (collectively, “Content”) you post on
              the Site, provide to other Users and/or otherwise make available
              to us and through the Site. Your content may not include any form
              of Prohibited Content, as outlined below. Despite this
              prohibition, information, materials, products or services provided
              by other Users (for instance, in their profile or displayed on the
              Site in areas in which users can post content) may, in whole or in
              part, be unauthorized, impermissible or otherwise violate the
              Terms, and we assume no responsibility or liability for this
              material. As we state in more detail below, your use of the Site
              and its content is at your own risk. If you become aware of misuse
              of the Site or its features by any person, please click on the
              “Contact Us” link on the Site pages and follow the directions as
              to how to contact us.
              <br />
              We reserve the right, in our sole discretion, to reject, refuse to
              post or remove any posting (including private messages) by you, or
              to deny, restrict, suspend, or terminate your access to all or any
              part of the Site at any time, for any or no reason, with or
              without prior notice or explanation, and without liability. We
              expressly reserve the right to remove your profile and/or deny,
              restrict, suspend, or terminate your access to all or any part of
              the Site if we determine, in our sole discretion, that you have
              violated the Terms, pose a threat to us, our suppliers and/or our
              Users or for any other purpose we determine in our sole
              discretion. <br />
              Doximple does not claim any ownership rights in the Content that
              you post on this site (if applicable). After posting your Content
              to this Site, you continue to retain any such rights that you may
              have in your Content, subject to the license herein. <br />
              By your submission you represent and warrant that you own or
              otherwise control all of the rights to the content that you post;
              that the Content is accurate; that use of the Content you supply
              does not violate this policy and will not cause injury to any
              person or entity; and that you will indemnify Doximple or its
              affiliates for all claims resulting from Content you supply.
              Doximple reserves the right to remove any content at its sole
              discretion. Doximple may reject, refuse to post or delete any
              Content for any or no reason, including, without limitation,
              Content that in the sole judgment of Doximple violates the Terms,
              including our Privacy Policy and/or our Acceptable Use Policy
              ("Prohibited Content"). We assume no responsibility for monitoring
              the Site for inappropriate Content or conduct. If at any time
              Doximple chooses, in its sole discretion, to monitor the Site, we
              nonetheless assume no responsibility for the Content, no
              obligation to modify or remove any inappropriate Content, and no
              responsibility for the conduct of the User submitting any such
              Content. <br />
              You are solely responsible for your use of the Site, the Content
              that you post on or through the Site, and any material or
              information that you transmit to other Users and for your
              interactions with other Users.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>POSSIBLE LEGAL ACTIONS AGAINST YOU</b>
            </p>
            <p>
              You understand that we may pursue legal remedies against you if
              you violate these Terms. YOU AGREE TO INDEMNIFY, DEFEND AND HOLD
              HARMLESS DOXIMPLE, ITS OFFICERS, EMPLOYEES OR AGENTS, OR
              CONTRIBUTORS TO THIS WEBSITE FROM ALL CLAIMS, LIABILITY, FINES AND
              PENALTIES, AND COSTS (INCLUDING ATTORNEYS’ FEES) ARISING FROM YOUR
              USE OF THE WEBSITE THAT VIOLATES THESE TERMS.{" "}
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>PRODUCTS AND SERVICES </b>
            </p>
            <p>
              Please note that references to or descriptions or images of
              products or services should not be interpreted as endorsements of
              such products or services and such products or services may be
              made available by Doximple or by third parties. Resale of products
              or services purchased through our website is specifically
              prohibited. We reserve the right to refuse to sell products to you
              if it reasonably appears to us that you intend to resell such
              products. Verification of information may be required prior to our
              acceptance of any order. We further reserve the right to limit
              quantities of items purchased by each customer or to refuse to
              provide any customer with any such items. Your properly completed
              and delivered order form constitutes your offer to purchase the
              goods or services referenced in your order. Your order shall be
              deemed to be accepted only if and when Doximple or its supplier or
              vendor sends an order acceptance and shipping notice email to your
              email address. <br />
              Price, quantity, availability of any product or service, and
              shipping methods and shipping rates, and any other information
              regarding any products or services, are subject to change without
              notice. It is your responsibility to ascertain and obey all
              applicable local, state, federal and foreign laws (including
              minimum age requirements) regarding the possession, use and sale
              of any products or services we offer. By submitting any
              information in connection with purchasing any products or
              services, you grant to us the right to provide such information to
              third parties for purposes of facilitating such purchase.
              Verification of information may be required prior to the
              acknowledgment or completion of any transaction. <br />
              You agree to pay all charges incurred by you or on your behalf in
              purchasing any products or services through us, at the prices in
              effect when such charges are incurred, including all shipping and
              handling charges. In addition, you are responsible for any taxes
              applicable to your transactions. If any payment due is not made by
              you, Doximple may, in addition to its other remedies, at its sole
              discretion and without notice to you, (a) suspend its performance
              under this Agreement and your access to and use of our website, or
              (b) terminate this Agreement and your access to and the use of the
              services we provide . If legal action is necessary to collect fees
              or charges due from you, then you will reimburse Doximple for all
              expenses incurred in collecting the fees and charges, including
              all attorney fees and other legal expenses.{" "}
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>NO UNLAWFUL OR PROHIBITED USE </b>
            </p>
            <p>
              As a condition of using the website, you agree that you will not
              use the website or associated communications in any way that is
              prohibited by law or these Terms. These prohibitions include, but
              are not limited to, the following: <br />
              You must not use the website in any way that may damage,
              overburden, or impair the website or its availability, or in any
              way that is illegal, fraudulent or harmful or related to any
              illegal, fraudulent or harmful activity. <br />
              You must not attempt to use the website, email, the Doximple
              online form, or any other method or technology to gain
              unauthorized access to any Doximple data or systems or any other
              data or systems. This includes any attempt to log into the website
              or any Doximple data or systems using the credentials of another
              person. <br />
              You must not use the website or email with Doximple to copy,
              store, host, transmit, send, use, publish or distribute any
              material that consists of (or is linked to) any malicious computer
              software. <br />
              You must not copy, modify, distribute, sell or lease any part of
              the website or its content or its software, nor may you reverse
              engineer or attempt to extract the source code of that software.{" "}
              <br />
              You must not conduct any systematic or automated data collection
              activities (including, without limitation, scraping, data mining,
              data extraction and data harvesting) on or in relation to our
              website. <br />
              You must not use the website for any purposes related to marketing
              or any other commercial or organizational purpose without our
              prior express written consent.
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>LIMITATION OF LIABILITY </b>
            </p>
            <p>
              No Assurances or Warranties The information on this website is not
              guaranteed to be correct, complete or up-to-date, and it is
              subject to change without notice. <br />
              WE PROVIDE THE WEBSITE "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY
              LAW, WE HEREBY DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES OR
              GUARANTEES, INCLUDING ALL STATUTORY WARRANTIES, WITH RESPECT TO
              THE WEBSITE AND RELATED COMMUNICATIONS, INCLUDING, WITHOUT
              LIMITATION, ANY WARRANTIES THAT THE WEBSITE OR ASSOCIATED
              COMMUNICATIONS ARE MERCHANTABLE, OF SATISFACTORY QUALITY,
              ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING.
              WE EXPRESSLY DISCLAIM ALL LIABILITY TO ANY PERSON REGARDING THE
              CONSEQUENCES OF ANYTHING DONE OR OMITTED TO BE DONE WHOLLY OR
              PARTLY IN RELIANCE UPON THE USE OR CONTENTS OF THE WEBSITE. WE DO
              NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE WEBSITE
              OR ASSOCIATED COMMUNICATIONS AT TIMES OR LOCATIONS OF YOUR
              CHOOSING. WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY BE
              OBTAINED FROM THE USE OF THE WEBSITE OR ASSOCIATED COMMUNICATIONS
              WILL BE EFFECTIVE, RELIABLE OR ACCURATE OR WILL MEET YOUR
              REQUIREMENTS. <br />
              You expressly agree that your use of www.[YOUR URL].com is at your
              own risk. Doximple cannot warrant that this site, our servers, or
              email communications affiliated with the use of this website, are
              free from viruses..
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>REDEMPTION OF FREE PRODUCT AND COUPON OFFERS</b>
            </p>
            <p>
              {" "}
              From time to time, Doximple offers its clients special offers and
              discount codes to be used in conjunction with this website. These
              offers are frequently disseminated via this website and through
              email to those who have registered for our email database. They
              are sometimes posted on our home page (www.Doximple.com) or
              announced via other means of communication in conjunction with the
              promotion of the Doximple product collection, including radio and
              TV broadcasts, internet forums and various online websites. We
              encourage our clients to redeem these special offers, but we
              respectfully ask that you not exploit them. Doximple, at its sole
              discretion, will determine if all purchasing criteria has been met
              to qualify for stated discount or free product. If you
              successfully circumvent the software safeguards of this website in
              order to receive several free products with your order, then we
              will (at our sole discretion) remove any extraneous free product
              from your order or cancel the order in its entirety. You will be
              informed of any order changes via the phone number or email
              address listed with your order. Please, play fair…{" "}
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>NO PRACTICE OF MEDICINE </b>
            </p>
            <p>
              You understand and agree that we are not engaged in the practice
              of medicine, either through the website or any associated
              communications or services, and that the website is not a
              substitute for professional medical advice, diagnosis, or
              treatment. You are encouraged to seek professional diagnosis and
              treatment for medical conditions. YOU AGREE THAT WE HAVE NO
              RESPONSIBILITY TO PROVIDE MEDICAL ADVICE OR TAKE ANY MEDICALLY
              RELATED ACTION IN RESPONSE TO ANY INFORMATION YOU PROVIDE ON OR
              THROUGH THE WEBSITE. We do not recommend or endorse any specific
              devices, wearable equipment or technologies, physicians or other
              providers, clinical studies, treatments, tests, procedures,
              opinions, products, or other information that may appear on the
              website or on links reached through the website.{" "}
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>APPLICABLE LAW </b>
            </p>
            <p>
              By visiting www.Doximple.com, you agree that the laws of the
              Commonwealth of Virginia, without regard to principles of conflict
              of laws, will govern these Conditions of Use and any dispute of
              any sort that might arise between you and Doximple. If we are
              unable to resolve a dispute ourselves, any dispute relating in any
              way to the website shall be submitted to confidential arbitration
              in Washington, D.C., except that to the extent you have in any
              manner violated or threatened to violate our intellectual property
              rights, we may seek injunctive or other appropriate relief in any
              federal or state court. Arbitration under these Terms shall be
              conducted in accordance with the prevailing rules and procedures
              of the American Arbitration Association, with each party being
              responsible for its own costs and fees, and the joint costs of
              arbitration being shared equally by the parties. The arbitrator’s
              award shall be final and binding and may be entered as a judgment
              in any court of competent jurisdiction. To the fullest extent
              permitted by applicable law, no arbitration or claim under these
              Terms may be joined to any other arbitration or claim, including
              any arbitration or other legal proceeding involving any other
              current or former user of the website, and no class action
              proceedings will be permitted. By agreeing to arbitration in these
              Terms, you understand and agree that you are relinquishing (i) any
              right to pursue claims or have disputes resolved in a court of
              law, (ii) any right to compulsory discovery, and (iii) any right
              to the appeal of any adverse arbitration decision. You agree that,
              regardless of any law or statute, any claim or cause of action you
              may have against us must be brought within one year after the
              claim or cause of action arises or is known or should reasonably
              have been known to you, or the claim or cause of action will be
              forever barred.{" "}
            </p>
          </section>
          <br />
          <section>
            <p>
              <b>CHANGES IN POLICY </b>
            </p>
            <p>
              Doximple reserves the right to modify, alter, delete and update
              these policies at any time it sees fit. Such alterations do not
              nullify Doximple's rights if infringements or breaches occurred
              under a pervious version of these conditions.
            </p>
          </section>
        </Layout>
      </>
    );
  }
}

export default TermsAndConditions;
