import { Box, Button, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import CreateCampaign from '../../components/vendor/create-campaign';
import { ArrowBackOutlined } from "@material-ui/icons";
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import DoxSnackBar from '../../../common/components/DoxSnackBar';
import { useState } from 'react';
import { listQuestionWorkflow } from '../curd/vendorQuestionWorkflow';
import CustomModal from '../../components/CustomModal';
import { listCoupons } from '../curd/coupon';
import { getCampaign, updateCampaign } from '../curd/campaign';
import { listTriggerType } from '../curd/triggerType';
import BitlyLink from '../../components/vendor/bitly-link';
import { getCustomerCategory, listCustomerCategories } from '../curd/customerCategory';
import CampaignPreview from '../../components/vendor/campaign-preview';
import ConfirmationModal from '../../../common/components/modalConfirmation';
import { sendSMSTwilio } from '../../../utils/commonFunctions';
import BackdropLoader from '../../../common/components/BackdropLoader';
import { createNotificationsLog } from '../curd/notifications';
import btoa from 'btoa';
import { notificationMessage, notificationType } from '../../../common/utils/constants';

function AddCampaign(props) {
    const { id } = useParams();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get("type");

    const [vendorList, setVendorList] = useState([])
    const [editable, setEditable] = useState(null);
    const [oldCategory, setoldCategory] = useState(null)
    const [open, setOpen] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const history = useHistory();
    const [snackBarDetails, setSnackBarDetails] = useState({
        message: null,
        links: [],
        severity: "error",
    });
    const [initialValues, setInitialValues] = useState({
        name: '',
        
    });
    const [selectedOption, setSelectedOption] = useState([]);
    const [createOptions, setCreateOptions] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);

    const [trigger, setTrigger] = useState([]);

    const [editor, setEditor] = useState('');
    const [edit, setEdit] = useState(false);
    const demoUser = [
        {
            value: '1',
            label: 'user 1'
        },
        {
            value: '2',
            label: 'user 2'
        },

    ]
    const [couponScannedUser, setCouponScannedUser] = useState(demoUser);
    const [selectedUser, setSelectedUser] = useState([]);
    const [coupon, setCoupon] = useState([]);
    const [oldcoupon, setoldCoupon] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState([]);

    const [toggleConfirmationModal, setToggleConfirmationModal] = useState({
        title: `Enabling the campaign toggle will make it live and send the notification/sms to all the users associated to the campaign!`,
        title2: `Are you sure to make it live?`,
        confirm: `Ok`,
        open: false,
        no: true,
        yes: false
    });
    const [smsLoader, setSmsLoader] = useState(false);


    useEffect( () => {
        const fetchCampaign = async () => {
            if(id){
                let vendors = await getCampaign(id);
                let introScreen = vendors?.introScreen || '';
                setEditor(introScreen)
                setEdit(true);
                setEditable(vendors)
            }
        }
        fetchCampaign();
    }, [id])

    useEffect( () => {

        const fetchList = async () => {
            let vendors = await listQuestionWorkflow(props.user.id);
            let selectoption = vendors.map(item=>{
                return {
                    value: item.id,
                    label: item.name
                }
            })
            setCreateOptions(selectoption)

            let selected = []
            selectoption.map(item=>{
                if(item.value === editable?.workflowID){
                    selected.push(item);
                }
            })
            if(selected.length > 0){
                setSelectedOption(selected[0])
            }else{
                setSelectedOption([])
            }

            let triggers = await listTriggerType();
            let selecttriggers = triggers.map(item=>{
                return {
                    id: item.id,
                    value: item.name
                }
            })
            setTrigger(selecttriggers)
            
        }
        fetchList();
    }, [editable?.workflowID])

    useEffect( () => {

        const fetchCategoryList = async () => {
            
            let list = await listCustomerCategories(props.user.id);

            try{
               if(list){
                let arr =  list.map(item=>{
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setCategories(arr);
            
                let selectedItems = []
                arr?.find(item=>{
                        if(editable?.customerCategoryID === undefined) return
                         const check = JSON.parse(editable?.customerCategoryID)?.includes(item?.value)
                         if(check){
                            selectedItems.push(item)
                         }
                    }
                )
                setSelectedCategory(selectedItems)
                setoldCategory(selectedItems)
            }  
            }catch(err){
                console.log("Error", err)
            }
           
        }
        fetchCategoryList();
    }, [editable?.customerCategoryID])


    useEffect( () => {

        const fetchList = async () => {

            let coupons = await listCoupons(props.user.id);
            
            let allcoupons = coupons.map(item=> {
                console.log({campaignId:item.campaignID})
                if(item.campaignID === null) return {value: item.id, label: item.name}
            }).filter((item) => item !== undefined)
            
            setCoupon(allcoupons)


            let selectoptions = coupons.map(item=> {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        
            let coupon = editable?.coupons?JSON.parse(editable?.coupons):[];
            let selected = []
            selectoptions.map(item=>{
                if(coupon.includes(item.value)){
                    selected.push(item);
                }
            })
            setSelectedCoupon(selected)
            setoldCoupon(selected)
            
        }
        fetchList();
    }, [editable?.coupons])

    useEffect( () => {

        if(type){
  
          setSnackBarDetails({
            message:
                `Workfow ${type === "updated" ? "updated" : "created"} successfully`,
            severity: "success",
            links: [],
          });
          history.push(history.location.pathname)
        }
       
    }, [type])

    const defaultOption = [];

    const resetForm = (e) => {

        setSelectedCategory(null);
        setSelectedOption(defaultOption);
        setSelectedUser(defaultOption);
        setSelectedCoupon(defaultOption);
    }
    
    const addWorkflow = async (e) => {
        history.push({ 
            pathname: '/vendor-question-workflow/add',
            redirect: history.location.pathname
        });
    }

    const getCustomersAndSendMessage = async(row) => {
        const { id, customerCategoryID, bitlyLink } = row;
    
        let link;
        if(row?.triggerID === `3`){
          link = `/campaign-questions?campaign=${btoa(id)}&isBitly=true`;
        }else{
          link = `/campaign-questions?campaign=${btoa(id)}`;
        }
    
        if(customerCategoryID){
            let category = await getCustomerCategory(customerCategoryID);
            let relations = category?.customerCategoryRelations?.items || [];
            
            let uniqueRelations = relations.filter((value, index, self)=>{
                return self.findIndex(item => item.userID === value.userID) === index
            })
    
            
    
            let idsOnly = uniqueRelations.map(item => item?.user?.id);
            await Promise.all(
              idsOnly.map( async(item) => {
                  await sendNotification(
                    item, 
                    notificationMessage.campaignCreated,
                    notificationType.campaignCreated,
                    link
                  );
              })
            )
    
            if(bitlyLink){
    
              let numberOnly = uniqueRelations.filter(item => item?.user?.mobile );
              let numbers = numberOnly.map(item => item?.user?.mobile);
              
              await Promise.all(
                  numbers.map( async(item) => {
                      await sendSms({
                          mobile: item,
                          message: bitlyLink
                      });
                  })
              )
            }
            return true;
        }
        return false;
    }
    
    const sendSms = async(values) => {
        try {
                        
            const to = values.mobile.replace(/[^0-9\+]/g, "");
            const message = values.message;
            
            let resp = await sendSMSTwilio(to, message);
            
            
        } catch (error) {
            setSnackBarDetails({
                message: error.message,
                severity: "error",
                links: [],
            });
            
        }
    }

    const closeToggleConfirm = async (success) => {
        let row = editable;
        if (success) {
            delete row.trigger;
            delete row.workflow;
            delete row.customerCategory;
            if(row.isActive && !row.isNotified){
                row.isNotified = true;
                setSmsLoader(true);
                console.log("Running, send the sms")
                await getCustomersAndSendMessage(row);
            }
            let data = await updateCampaign({ ...row, id: row.id })
            setEditable(data);
            setSmsLoader(false);
            // setRows((rows) => rows.map(item=>row.id === item.id ? data : item));
        }else{
            row.isActive = false;
            let data = await updateCampaign({ ...row, id: row.id })
            setEditable(data);
            // setRows((rows) => rows.map(item=>row.id === item.id ? data : item));

        }
        
        setToggleConfirmationModal({ ...toggleConfirmationModal, open: false });
    }
      
    const openToggleConfirm = () => {
        setToggleConfirmationModal((values) => ({ ...values, open: true }));
    }

    const sendNotification = async(userId, message, type, link) => {
        try {
    
          let data= {
              user: userId,
              message: message,
              type: type,
              link: link,
              expired: false,
              isRead: false,
              isResponded: false,
          }
          await createNotificationsLog(data);
            
        } catch (error) {
            setSnackBarDetails({
                message: error.message,
                severity: "error",
                links: [],
            });
            
        }
    }

    return (
        <>
            <Box>
                <Card className='mh-500'style={{minHeight:700}}>
                    <DoxSnackBar snackBarDetails={snackBarDetails} />
                    <CardHeader component={() => <div className="m-3 d-flex justify-content-between align-items-center">
                        <Link to='/campaigns'><Typography color="secondary" className="m-0" variant="h4"><ArrowBackOutlined /> {id ? "Edit Campaign" : "Add Campaign"}</Typography></Link>

                        <div className='divMargin'>
                            <Button
                                onClick={addWorkflow}
                                color="secondary" variant="contained">{'Add Workflow'}</Button>
                        </div>
                    </div>} />
                    <CardContent>
                        <CreateCampaign
                            setSelectedOption={setSelectedOption}
                            selectedOption={selectedOption} 
                            createOptions={createOptions} 
                            categories={categories}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            coupon={coupon}
                            setCoupon={setCoupon}
                            selectedCoupon={selectedCoupon}
                            setSelectedCoupon={setSelectedCoupon}
                            editable={editable} 
                            user={props.user} 
                            oldCategory={oldCategory}
                            oldcoupon={oldcoupon}
                            location={props.location} 
                            vendorList={vendorList} 
                            initialValues={initialValues} 
                            setSnackBarDetails={setSnackBarDetails} 
                            trigger={trigger}
                            resetForm={resetForm}
                            setOpen={setOpen}
                            setEditable={setEditable}
                            editor={editor}
                            setEditor={setEditor}
                            edit={edit}
                            setEdit={setEdit}
                            setOpenPreview={setOpenPreview}
                            setToggleConfirmationModal={setToggleConfirmationModal}
                            openToggleConfirm={openToggleConfirm}
                        />
                    </CardContent>
                </Card>
            </Box>
            <CustomModal  fullWidth open={open} setOpen={(bool) => { setOpen(bool); history.push(`/campaigns`) }} title={`Bitly Link`}>
                <BitlyLink user={props.user} closeModal={() => { setOpen(false); history.push(`/campaigns`) }} editable={editable} setSnackBarDetails={setSnackBarDetails} />
            </CustomModal>
            <CustomModal  fullWidth open={openPreview} setOpen={(bool) => { setOpenPreview(bool); history.push(`/campaigns`) }} title={`Campaign Preview`}>
                <CampaignPreview user={props.user} closeModal={() => { setOpenPreview(false); history.push(`/campaigns`) }} editable={editable} setSnackBarDetails={setSnackBarDetails} />
            </CustomModal>
            <ConfirmationModal
                settings={toggleConfirmationModal}
                close={closeToggleConfirm}
            ></ConfirmationModal>
            <BackdropLoader open={smsLoader}/>
        </>
    )
}

export default AddCampaign
