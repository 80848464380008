import React, { memo, useEffect, useState } from "react";
import { CircularProgress, FormGroup } from "@material-ui/core";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import { Add, Edit, Delete } from "@material-ui/icons";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import ConfirmationModal from "../../../common/components/modalConfirmation";
import CustomModal from "../CustomModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CreateProduct from "./create-product";
import DoxSnackBar from "../../../common/components/DoxSnackBar";
import * as Yup from 'yup';
import { listVendors } from "../../containers/curd/vendors";
import EnhancedTableHead, {
  getComparator,
  stableSort,
} from "../../../common/components/Tables/EnhancedTableHead";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import AssignCoupon from "./assign-coupon";
import { getCoupon } from "../../containers/curd/coupon";
import { deleteVendorProduct, listVendorProduct, updateVendorProduct } from '../../containers/curd/vendorProducts'
import { Form, Formik } from "formik";
import FormikTextField from "../../../common/components/FormikTextField";


const headCells = [
  { id: "title", numeric: false, disablePadding: true, label: "Name" },
  { id: "category", numeric: false, disablePadding: false, label: "Category" },
  { id: "brand", numeric: false, disablePadding: false, label: "Brand" },
  { id: "model", numeric: false, disablePadding: false, label: "Model" },
  { id: "price", numeric: false, disablePadding: false, label: "Price (US $)" },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "couponAssigned",
    numeric: false,
    disablePadding: false,
    label: "Coupons Assigned",
  },
];

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDelete,
    setOpen,
    handleEdit,
    loadingDelete,
    title,
    user,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 && (
        <>
          <Tooltip
            title="Delete"
            style={{
              display:
                user.accountType === "SuperAdminUser" ||
                  user.accountType === "VendorAdminUser" ||
                  user.isDeleted
                  ? "none"
                  : "",
            }}
          >
            {loadingDelete ? (
              <IconButton area-label="loading">
                <CircularProgress size="1.5rem" />
              </IconButton>
            ) : (
              <IconButton aria-label="delete" onClick={handleDelete}>
                <Delete style={{ color: "red" }} />
              </IconButton>
            )}
          </Tooltip>
          {numSelected == 1 && (
            <Tooltip
              title="Edit"
              style={{
                display:
                  user.accountType === "SuperAdminUser" ||
                    user.accountType === "VendorAdminUser" ||
                    user.isDeleted
                    ? "none"
                    : "",
              }}
            >
              <IconButton aria-label="edit" onClick={handleEdit}>
                <Edit style={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}

      <Tooltip
        title="Add Staff"
        style={{
          display:
            user.accountType === "SuperAdminUser" ||
              user.accountType === "VendorAdminUser" ||
              user.isDeleted
              ? "none"
              : "",
        }}
      >
        <Link to="/products/add">
          <Button variant="contained" color="primary">
            <Add /> Add
          </Button>
        </Link>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const validationSchema = Yup.object({
  rate: Yup.number().typeError("Price must be an integer value.").required("Price is required.")
})

export default function ListProducts(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const type = urlParams.get("type");
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(null);
  const [createdItem, setCreatedItem] = useState(null);
  const [updatedItem, setUpdatedItem] = useState(null);
  const [showAssignCoupon, setShowAssignCoupon] = useState(false);
  const [assignCoupon, setAssignCoupon] = useState(false);
  const [sendCoupons, setsendCoupons] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRate, setopenRate] = useState(false)
  const [initialValues, setInitialValues] = useState({
    rate: 19
  })
  const [confirmationModal, setConfirmationModal] = useState({
    title: "Are you sure, You want to delete this!",
    open: false,
    no: true,
    yes: false,
  });
  useEffect(() => {
    setConfirmationModal({
      ...confirmationModal,
      title: `Are you sure, You want to delete ${selected.length && selected.length > 1
        ? `these ${selected.length} products!`
        : `this product!`
        }`,
    });
  }, [selected]);

  const [vendorList, setVendorList] = useState([]);
  useEffect(() => {
    getVendorLists();
  }, []);

  const getVendorLists = async () => {
    let data = await listVendors(props.user.id, props.user.accountType);
    setVendorList(data);
  };

  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    links: [],
    severity: "error",
  });

  const getCouponName = async (id) => {
    let someData = await getCoupon(id);
    if (someData) return someData;
  };

  async function ShowCoupons(items) {
    if (items) {
      let couponeData = items.map((item) => {
        return item.couponID;
      });
      let allCouponame = await Promise.all(
        couponeData.map(async (item) => {
          let { name } = (await getCouponName(item)) || {};
          return name;
        })
      );
      // console.log("Some Data1 ====>", allCouponame);
      const filtedData = allCouponame.filter((item) => {
        return item != undefined;
      });
      // console.log("Some Data ====>", filtedData);
      return filtedData;
    }
    return;
  }

  const fetchListProducts = async () => {
    setLoading(true);
    let products = await listVendorProduct(props.user.id, props.user.accountType);
    console.log("Working is herer", products)
    const appendRowData = await Promise.all(products?.map(async(product) => {
      const someData = await ShowCoupons(product.allCoupons.items);
      return {
        ...product,
        rowdata:someData
      }
    }))

    setRows(appendRowData);
    setLoading(false);
  };

  useEffect(() => {
    fetchListProducts();
  }, []);

  useEffect(() => {
    setRows((rows) => {
      if (!createdItem) return rows;
      let newItem = {
        ...createdItem,
      };
      return [newItem, ...rows];
    });

    setCreatedItem(null);
  }, [createdItem]);

  useEffect(() => {
    if (type) {
      setSnackBarDetails({
        message: `Product ${type === "updated" ? "updated" : "created"
          } successfully`,
        severity: "success",
        links: [],
      });
      history.push(`/products`);
    }
  }, [type]);

  useEffect(() => {
    if (!updatedItem) return rows;
    fetchListProducts();
  }, [updatedItem]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, index) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log("newSelected", newSelected)
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDeleteConfirm = async () => {
    setConfirmationModal((values) => ({ ...values, open: true }));
  };

  // handled with vendor table
  const handleDelete = async () => {
    setLoadingDelete(true);
    console.log("Selected", selected)
    let data = await deleteVendorProduct(selected);
    if (data.length != 0) {
      let datas = data.map((item) => {
        return item.id;
      });
      setRows((rows) => {
        let items = rows.filter((row) => !datas.includes(row.id));
        return items;
      });
      setSnackBarDetails({
        message: `product deleted successfully`,
        severity: "success",
        links: [],
      });
    }

    setSelected([]);
    setLoadingDelete(false);
  };

  const handleEdit = () => {
    const isProductEditable = rows.find(({ product, id }) => id === selected[0]);
    try {
      history.push(
        `products/edit?item=${encodeURIComponent(JSON.stringify({ ...isProductEditable, rowid: isProductEditable.id }))}`
      );
    } catch (error) {
      console.log("Error", error)
    }
  };
  // done

  const handleAssignCoupon = (e) => {
    setEditable(e);
    setShowAssignCoupon(true);
    setsendCoupons(e)
  };


   const GetRowRedner =  memo(function RenderRow({ row, index, isItemSelected, labelId }) {
 
    const { product, rate, rowdata } = row

    if (rowdata) {
      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => handleClick(event, row.id, index)}
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">
            {row.title}
          </TableCell>
          <TableCell align="left">{row.category}</TableCell>
          <TableCell align="left">{row.brand}</TableCell>
          <TableCell align="left">{row.model}</TableCell>
          <TableCell align="left">$ {rate}</TableCell>
          <TableCell align="left">{row.description}</TableCell>
          <TableCell align="left">
            <AddIcon
              onClick={() => {
                handleAssignCoupon(row)
              }}
              titleAccess="Assign Coupons"
              color="primary"
            />
            {rowdata.length > 0 && `[ ${rowdata.join(', ')} ]`}
          </TableCell>
        </TableRow>
      );
    }
  });
  //Done


  return (
    <>
      {!loading ? (
        <>
          <div className={classes.root}>
            <DoxSnackBar snackBarDetails={snackBarDetails} />
            {/* Handled with new product table */}
            <Paper className={classes.paper}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                user={props.user}
                setOpen={setOpen}
                handleDelete={handleDeleteConfirm}
                handleEdit={handleEdit}
                loadingDelete={loadingDelete}
                title={"Products"}
              />
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    user={props.user}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headCells={headCells}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <GetRowRedner
                            key={row.id}
                            row={row}
                            index={index}
                            isItemSelected={isItemSelected}
                            labelId={labelId}
                          />
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell
                          colSpan={headCells.length + 1}
                          align="center"
                        >
                          {rows.length === 0 && "No data found"}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            {/* Done */}
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </div>
          <CustomModal
            fullWidth
            open={open}
            setOpen={(bool) => {
              setOpen(bool);
              setEditable(null);
            }}
            title={editable ? "Product > Update " : "Product > Add New"}
          >
            <CreateProduct
              user={props.user}
              closeModal={() => {
                setOpen(false);
                setEditable(null);
              }}
              setCreatedItem={setCreatedItem}
              editable={editable}
              setUpdatedItem={setUpdatedItem}
              setSnackBarDetails={setSnackBarDetails}
              vendorList={vendorList}
            />
          </CustomModal>
          <CustomModal
            fullWidth
            open={openRate}
            setOpen={setopenRate}
            title="Update the price">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async(values) => {
                const {data} = await updateVendorProduct(values.rate, selected[0])
                console.log("selected", selected[0])
                console.log("Response", data)    
                if(data){
                  setopenRate(false)
                  fetchListProducts()
                  setSnackBarDetails({
                    message: `Price Updated Successfully`,
                    severity: "success",
                    links: [],
                  });
                }
              }}   
            >
              {({ values, errors, isValid, handleReset }) => (
                <Form>
                  <FormGroup>
                    <FormikTextField fullWidth className="mb-2" name="rate" label="Price" />
                  </FormGroup>
                  <Button
                    type="submit"
                    className="mt-4 mb-2"
                    variant="contained"
                    disabled={!isValid}
                    color="primary">
                    Submit
                  </Button>
                </Form>
              )}

            </Formik>
          </CustomModal>
          <CustomModal
            fullWidth
            fullHeigth
            open={showAssignCoupon}
            setOpen={(bool) => {
              setShowAssignCoupon(bool);
              setAssignCoupon(null);
            }}
            title="Assign Coupon"
          >
            <AssignCoupon
              user={props.user}
              closeModal={() => {
                setShowAssignCoupon(false);
                setEditable(null);
              }}
              rowdata={sendCoupons}
              setCreatedItem={setCreatedItem}
              editable={editable}
              setUpdatedItem={setUpdatedItem}
              setSnackBarDetails={setSnackBarDetails}
            />
          </CustomModal>
        </>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      )}
      <ConfirmationModal
        items={selectedItem}
        settings={confirmationModal}
        close={(success) => {
          if (success) {
            handleDelete();
          }
          setConfirmationModal({ ...confirmationModal, open: false });
        }}
      ></ConfirmationModal>
    </>
  );
}
