import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import * as customqueries from '../curd/customQueries/queries'
import API, { graphqlOperation } from "@aws-amplify/api";


export const createVendorProduct = async (input) => {

    try {
        for (let index = 0; index < input.length; index++) {
            console.log("This is input", index + 1, input[index])
            let saveData = input[index]
            saveData.isDeleted = false
            await API.graphql(graphqlOperation(mutations.createVendorProduct, { input: saveData }))
        }
        return true
    } catch (error) {
        console.log("Error", error)
        throw new Error(error.message)
    }
}

export const updateVendorProduct = async (input) => {
    try {
        let response = await API.graphql(graphqlOperation(mutations.updateVendorProduct, { input }))
        return response
    } catch (error) {
        console.log("Error", error)
    }
}


export const deleteVendorProduct = async (input) => {
    let promises = [];
    for (let index = 0; index < input.length; index++) {
        const id = input[index];
        const payload = {
            id,
            isDeleted: true
        }

        let { data: { updateVendorProduct } } = await API.graphql(
            graphqlOperation(mutations.updateVendorProduct, { input: payload })
        )
        promises.push(updateVendorProduct);
    }
    return Promise.all(promises);
}

export const listVendorProduct = async (userId, isAdmin) => {
    let params;
    try {
        if (isAdmin === 'SuperAdmin') {
            params = {
                filter: {
                    isDeleted: false
                },
                limit: 30
            }
        } else if (isAdmin === "VendorAdminUser") {
            params = {
                filter: {
                    vendor: {
                        eq: userId
                    },
                    isDeleted: false
                },
                limit: 30
            }
        } else {
            params = {
                filter: {
                    vendorID: {
                        eq: userId
                    },
                    isDeleted: {
                        eq: false
                    }
                },
                limit: 30
            }
        }
        // if (nextToken) params.nextToken = nextToken;
        let { data: { listVendorProducts } } = await API.graphql(graphqlOperation(customqueries.listVendorProducts, params));
        console.log("ListVendorProduct", listVendorProducts)
        // items.push(...listProducts.items);
        // if (listProducts.nextToken) {
        //     await listProducts(userId, isAdmin, listProducts.nextToken, items);
        // }
        return listVendorProducts.items;
    } catch (error) {
        console.log("Error vendor list", error)
    }
}


export const getVendorProductNameById = async (productID) => {
    let params = {
        filter: {
            id: {
                eq: productID
            }
        },
    }
    let { data: { listVendorProducts } } = await API.graphql(graphqlOperation(customqueries.listVendorProducts, params));
    return { 
        title: listVendorProducts?.items[0]?.title, 
        brand: listVendorProducts?.items[0]?.brand, 
        model: listVendorProducts?.items[0]?.model 
    }
}