import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createQuestionWorkflow = async (input) => {
  
  let { data: { createMasterQuestionWorkflow } } = await API.graphql(
    graphqlOperation(mutations.createMasterQuestionWorkflow, { input: {...input, isDeleted: false }})
  );
  return createMasterQuestionWorkflow;
}
export const listQuestionWorkflow = async (userId) => {
  let params = {
    filter: {
      createdBy: {
        eq: userId
      },
      isDeleted: {
        eq: false
      }
    }
  };
  let { data: { listMasterQuestionWorkflows } } = await API.graphql(
    graphqlOperation(queries.listMasterQuestionWorkflows, params)
  );
  return listMasterQuestionWorkflows.items;
}

export const deleteQuestionWorkflow = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    const id = coupons[index];
    id['isDeleted'] = true;
    let { data: { updateMasterQuestionWorkflow } } = await API.graphql(
      graphqlOperation(mutations.updateMasterQuestionWorkflow, { input: { ...id } })
    )
    promises.push(updateMasterQuestionWorkflow);
  }
  return Promise.all(promises);
}

export const updateQuestionWorkflow = async (input) => {
  input['isDeleted'] = false;
  let { data: { updateMasterQuestionWorkflow } } = await API.graphql(
    graphqlOperation(mutations.updateMasterQuestionWorkflow, { input })
  );
  return updateMasterQuestionWorkflow;
}

export const getSingleQuestionWorkflow = async (input) => {
  let { data: { getMasterQuestionWorkflow } } = await API.graphql(
    graphqlOperation(queries.getMasterQuestionWorkflow, { id: input })
  );
  return getMasterQuestionWorkflow;
}
