import { FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
  },
  label: {
    backgroundColor: "white",
    transform: "translate(10px, 7px)",
  },
  select: {
    marginTop: "15px",
    padding: "15px",
    paddingTop: "15px",
    borderRadius: "10px"
  },
}));

const FormikSelect = ({ children, render, ...props }) => {
  const { label, options, ...rest } = props;
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Field as="select" {...rest} className={classes.select}>
        <option value={rest.value}>{rest.value[rest.name]}</option>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>{option.label}</option>
          );
        })}
      </Field>
    </FormControl>
  );
};

export default FormikSelect;
