/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://rj7oidx6hnf3pdaqofnoc34oja.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-z4enh4oegjervnlwcmzkuf2swa",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://lhitb9qnwk.execute-api.us-east-2.amazonaws.com/sampledev",
            "region": "us-east-2"
        }
    ],
    "aws_content_delivery_bucket": "newoxpp-20191124215613-hostingbucket-sampledev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3t41eaczcn3u8.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-2:6ed3a24f-5027-4017-8403-e617e24f0758",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_PooBvw58c",
    "aws_user_pools_web_client_id": "41v0o11fcuu9lqqf4t5g4sn8qq",
    "oauth": {
        "domain": "newdoxappcdb600fc-cdb600fc-sampledev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/signInloader/,https://dev.doximple.com/signInloader/",
        "redirectSignOut": "http://localhost:3000/signIn/,https://dev.doximple.com/signIn/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "b5736332fc274a77b697dbd7310ce057",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "doximple53103-sampledev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
