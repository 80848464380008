import {Box} from "@material-ui/core";
import ListCustomer from "../components/customer/list-customer";
import React from 'react'
const Customer = (props) => 
    <Box container>
        <ListCustomer user={props.user}/>
    </Box>

export default Customer;

