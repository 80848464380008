import { Box, Button, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from "@material-ui/icons";
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { listVendors } from '../curd/vendors';
import CreateProduct from '../../components/product/create-product';

function EditProducts(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url = urlParams.get('item');
    const item = JSON.parse(url);
    const [vendorList, setVendorList] = useState([])
    const history = useHistory();
    const [snackBarDetails, setSnackBarDetails] = useState({
        message: null,
        links: [],
        severity: "error",
    });
    const [initialValues, setInitialValues] = useState({
        id: item.id,
        title: item.title,
        barcode_number: item.barcode_number,
        model: item.model,
        category: item.category,
        Manufacturer: item.Manufacturer,
        brand: item.brand,
        format: item.format,
        multipack: item.multipack,
        price: item.price,
        description: item.description,
        vendor: item.vendor,
    });
    useEffect(() => {
        getVendorLists();
    }, [])
    const getVendorLists = async () => {
        let data = await listVendors(props.user.id, props.user.accountType);
        setVendorList(data)
    }

    return (
        <>
            <Box>
                <Card>
                    <CardHeader component={() => <div className="m-3 d-flex justify-content-between align-items-center">
                        <Typography className="m-0" variant="h4">Edit Product</Typography>
                        <Link to='/products'><Button variant="contained" color="primary" ><KeyboardArrowLeft />Back</Button></Link>
                    </div>} />
                    <CardContent>
                        <CreateProduct editable={true} rowid={item.rowid} user={props.user} location={props.location} vendorList={vendorList} initialValues={initialValues} setInitialValues={setInitialValues} setSnackBarDetails={setSnackBarDetails} />
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default EditProducts
