import * as Yup from "yup";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Switch,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from "../../../common/components/FormikTextField";
import FormikTextarea from "../../../common/components/FormikTextarea";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormikSelectField from "../../../common/components-V2/FormikSelectField";
import { useHistory } from "react-router";
import { useState } from "react";
import ReactSelect from "../../../common/components/ReactSelect";
import { createCampaign, updateCampaign } from "../../containers/curd/campaign";
import btoa from "btoa";
import { getBitlyLink, sendSMSTwilio } from "../../../utils/commonFunctions";
import { getCustomerCategory } from "../../containers/curd/customerCategory";
import Editor from "../../../common/components/Editor";
import { Label } from "aws-amplify-react";
import { updateCoupon } from "../../containers/curd/coupon";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required."),
  triggerID: Yup.string().required("Trigger is required."),
});

const useStyles = makeStyles({
  root: {
    border: "1.5px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 10,
    padding: "10px 14px 18.5px 14px",
  },
  error: {
    color: "#f83245",
  },
});
function CreateCampaign(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    user,
    editable,
    setSnackBarDetails,
    vendorList = [],
    selectedOption,
    setSelectedOption,
    createOptions,
    couponScannedUser,
    setCouponScannedUser,
    selectedUser,
    setSelectedUser,
    coupon,
    setCoupon,
    selectedCoupon,
    setSelectedCoupon,
    trigger,
    resetForm,
    setEditable,
    setOpen,
    categories,
    selectedCategory,
    setSelectedCategory,
    editor,
    setEditor,
    edit,
    setEdit,
    setOpenPreview,
    setToggleConfirmationModal,
    openToggleConfirm,
    oldCategory,
    oldcoupon
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const [showSentCategory, setShowSentCategory] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [newCustomer, setnewCustomer] = useState([])
  const [isFutureNotification, setIsFutureNotification] = useState("");
  const [message, setmessage] = useState(
    "Dear Customer, We would like to offer you a new reward. Please click {here} to get started."
  );
  const [initialValues, setInitialValues] = useState({
    name: "",
    triggerID: "",
    bitlyLink: "",
  });

  useEffect(() => {
    if (selectedCategory) {
      setShowSentCategory(selectedCategory);
    }
  }, [selectedCategory]);

  const [sendBitlyLink, setSendBitlyLink] = useState(false);

  const handleSendBitlyLink = (e) => {
    const value = e.target.checked;
    setSendBitlyLink(value);
  };

  const defaultOption = [];
  const handleChangeOption = (selectedOption) => {
    setSelectedOption(selectedOption);
  };


  const handleChangeCategoryOption = (selectedOption) => {
    if (selectedOption) {
      const results = selectedOption?.filter(({ value: id1 }) => !oldCategory?.some((el) => el.value === id1))
      setnewCustomer(results)
      if (results.length == 0) {
        setShowSent(true);
      } else {
        setShowSent(false);
      }
    }
    setSelectedCategory(selectedOption);
  };
  const handleChangeCouponOption = (selectedOption) => {
    if(Array.isArray(selectedOption)){
      setSelectedCoupon(selectedOption);
    }else{
      setSelectedCoupon([selectedOption]);
    }
  };


  const resetSelectedOption = () => {
    setSelectedOption(defaultOption);
    setSelectedUser(defaultOption);
    setSelectedCoupon(defaultOption);
  };

  const types = [
    { id: "COUPON_SCANNED", value: "Coupon Scanned" },
    { id: "COUPON_REDEEMED", value: "Coupon Redeemed" },
    { id: "BITLY_LINK_CLICKED", value: "Bitly Link Clicked" },
  ];

  useEffect(() => {
    setInitialValues((values) => {
      if (!editable) return values;
      let { name, triggerID, bitlyLink } = editable;
      return {
        name,
        triggerID,
        bitlyLink,
      };
    });
    setIsFutureNotification((values) => {
      if (!editable) return values;
      if(editable?.isFuture){
        return true
      }
      return values
    })
  }, [editable]);

  const generateBitly = async (id) => {
    let link = `https://dev.doximple.com/campaign-questions?campaign=${btoa(
      id
    )}&isBitly=true`;
    let bitlylink = await getBitlyLink(link);
    return bitlylink;
  };

  const getCustomersAndSendMessage = async (categoryid, bitlyLink) => {
    if (categoryid && bitlyLink) {
      let relations = []
      for (let i = 0; i < categoryid.length; i++) {

        let category = await getCustomerCategory(categoryid[i].value);
        relations.push(...category.customerCategoryRelations?.items)

      }
      // let relations = category?.customerCategoryRelations?.items || [];
      let currentMessage = message
      let messageContent = currentMessage.replace("{here}", bitlyLink)
      let uniqueRelations = relations.filter((value, index, self) => {
        return self.findIndex((item) => item.userID === value.userID) === index;
      });
      let numberOnly = uniqueRelations.filter((item) => item?.user?.mobile);
      let numbers = numberOnly.map((item) => item?.user?.mobile);

      await Promise.all(
        numbers.map(async (item) => {
          await sendSms({
            mobile: item,
            message: messageContent,
          });
        })
      );
      return true;
    }
    return false;
  };

  const updateCampaignId = async(campaignId) => {
    console.log(campaignId)
    //remove old coupons 
    console.log("Old coupons", oldcoupon, "Selected Couponms", selectedCoupon)
    await Promise.all(oldcoupon?.map(async(coupon) => {
      console.log("Coupons", coupon.value, campaignId)
      let dataToUpdate = {}
      dataToUpdate.campaignID = null;
      await updateCoupon({...dataToUpdate, id:coupon.value})
    }))

    // add new coupons to this id
    await Promise.all(selectedCoupon?.map(async(coupon) => {
      console.log("Coupons", coupon.value, campaignId)
      let dataToUpdate = {}
      dataToUpdate.campaignID = campaignId;
      await updateCoupon({...dataToUpdate, id:coupon.value})
    }))
  }


  const sendSms = async (values) => {
    try {
      const to = values.mobile.replace(/[^0-9\+]/g, "");
      const message = values.message;
      let resp = await sendSMSTwilio(to, message);
    } catch (error) {
      setSnackBarDetails({
        message: error.message,
        severity: "error",
        links: [],
      });
    }
  };

  const handleEditorChange = (e) => {
    setEditor(e);
  };

  const preview = (e) => {
    setIsPreview(true);
  };

  const openPreview = (e) => {
    setOpenPreview(true);
  };

  const handleFutureNotification = (e) => {
    const value = e.target.checked;
    setIsFutureNotification(value)
  }

  const handleChangeStatus = async (row) => {
    row.isActive = !row.isActive;
    delete row.trigger;
    delete row.workflow;
    delete row.customerCategory;
    if (!row.isNotified) {
      if (row.isActive) {
        // setSelectedRow(row);
        openToggleConfirm();
      } else {
        let data = await updateCampaign({ ...row, id: row.id });
        setEditable(data);
        // setRows((rows) => rows.map(item=>row.id === item.id ? data : item));
      }
    } else {
      let data = await updateCampaign({ ...row, id: row.id });
      setEditable(data);
      //   setRows((rows) => rows.map(item=>row.id === item.id ? data : item));
    }
  };

  const handlemessage = (e) => {
    setmessage(e.target.value);
  };


  console.log({selectedCoupon})
  const results = selectedCoupon?.filter(({ value: id1 }) => !oldcoupon?.some((el) => el.value === id1))
  console.log("Result ===>", isFutureNotification)
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            setSubmitted(true);
            setIsLoading(true)
            if (!selectedOption.value) {
              return;
            }
            values.workflowID = selectedOption.value;
            if (selectedCoupon.length === 0) {
              return;
            }
            values.coupons = JSON.stringify(
              selectedCoupon.map((item) => item.value)
            );

            values.introScreen = editor;
            values.customerCategoryID = JSON.stringify(selectedCategory.map((item) => item.value))
            let updateddata = null;
            let iscampaignlive = false
            if (!editable) {
              let data = await createCampaign({
                ...values,
                isActive: false,
                createdBy: user.id,
                isFuture : isFutureNotification
              });
              updateddata = data;
              await updateCampaignId(data.id)
              let bitlyLink = "";
              if (values.triggerID === "3" && values.bitlyLink === "") {
                bitlyLink = await generateBitly(data.id);
                values.bitlyLink = bitlyLink;
                values.message = message.replace("{here}", bitlyLink)
                values.isFuture = isFutureNotification
                let updated = await updateCampaign({ ...values, id: data.id });
                updateddata = updated;
              }
              //bitly
            } else {
              if (values.triggerID === "3" && values.bitlyLink === "") {
                let bitlyLink = await generateBitly(editable.id);
                values.bitlyLink = bitlyLink;
                values.message = message.replace("{here}", bitlyLink)
              }
              if(values.triggerID !== "3" && values.bitlyLink !== ""){
                values.bitlyLink = ''
              }
              let data = await updateCampaign({ ...values, isFuture : isFutureNotification, id: editable.id });
              await updateCampaignId(editable.id)
              // sendBitlyLink
              iscampaignlive = data.isActive
              updateddata = data;
            }
            if (values.triggerID === "3" && !showSent && sendBitlyLink && editable) {
              let customers = await getCustomersAndSendMessage(
                selectedCategory,
                values.bitlyLink
              );
            }
            if(editable && !iscampaignlive) {
              setSnackBarDetails({
                message: `Updated successfully, SMS can’t be send as the campaign is not LIVE yet`,
                severity: "success",
                links: [],
              });
            }else{
              setSnackBarDetails({
                message: `Campaign ${
                  editable ? "updated" : "created"
                } successfully`,
                severity: "success",
                links: [],
              });
            }
            
            setEditable(updateddata);
            if (isPreview) {
              openPreview();
              return;
            }
            if (
              values.triggerID === "3" &&
              updateddata &&
              updateddata.bitlyLink !== ""
            ) {
              setOpen(true);
              return;
            }
            history.push(`/campaigns?type=${editable ? "updated" : "created"}`);
          } catch (error) {
            setSnackBarDetails({
              message: error.message,
              severity: "error",
              links: [],
            });
          } finally{
            setIsLoading(false)
          }
        }}
      >
        {({ values, errors, touched, isValid, handleReset }) => (
          <Form>
            <FormGroup>
              <FormikTextField
                name="name"
                margin="normal"
                label="Campaign name"
              />
            </FormGroup>
            <FormGroup>
              <FormikSelectField
                name="triggerID"
                margin="normal"
                label="Select Trigger"
                options={trigger}
                error={submitted && values.triggerID === ""}
              />
            </FormGroup>
            {editable && values.bitlyLink !== "" && values.triggerID === "3" && (
              <FormGroup>
                <FormikTextField
                  name="bitlyLink"
                  margin="normal"
                  label="Bitly Link"
                  disabled={true}
                />
              </FormGroup>
            )}
            <FormGroup>
              <ReactSelect
                isMulti={false}
                name="workflow"
                label="Workflow"
                options={createOptions}
                onChange={handleChangeOption}
                selectedOption={selectedOption}
              />
              {submitted && selectedOption === "" && (
                <p className={classes.error}>Workflow is required.</p>
              )}
            </FormGroup>
            <FormGroup>
              <ReactSelect
                isMulti={values?.triggerID === "1" ? true : false}
                name="coupon"
                label="Select Coupon"
                options={coupon}
                onChange={handleChangeCouponOption}
                selectedOption={selectedCoupon}
              />
              {submitted && selectedCoupon.length === 0 && (
                <p className={classes.error}>Coupon is required.</p>
              )}
            </FormGroup>
            {((values.triggerID === "3" ))&& (<FormGroup>
              <ReactSelect
                isMulti={true}
                name="customerCategoryID"
                label="Select Customer Category"
                options={categories}
                onChange={handleChangeCategoryOption}
                selectedOption={selectedCategory}
              />
            </FormGroup>)}
            {(values.triggerID === "3") && (
                <>
                <div className="d-flex">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendBitlyLink}
                          onChange={handleSendBitlyLink}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Send bitly link via sms to all selected users in the category"
                    />
                  </FormGroup>
                  <FormGroup className="ml-4">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isFutureNotification}
                          onChange={handleFutureNotification}
                          name="isfuturenotification"
                          color="primary"
                        />
                      }
                      label="Allow for future"
                    />
                  </FormGroup>
                  </div>
                  {sendBitlyLink && (
                    <FormGroup className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <Label>Message</Label>
                        <p className="m-0">{message.length}/160</p>
                      </div>
                      <FormikTextarea
                        name="Message"
                        margin="normal"
                        value={message}
                        onChange={handlemessage}
                        maxLength={160}
                      />
                    </FormGroup>
                  )}
                </>
              )}
            <FormGroup style={{ marginTop: 16, marginBottom: 8 }}>
              <Editor
                onChange={handleEditorChange}
                edit={edit}
                value={editor}
              />
            </FormGroup>
            {editable && (
              <FormGroup>
                <Switch
                  checked={editable?.isActive}
                  onChange={(e) => handleChangeStatus(editable)}
                  color="secondary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </FormGroup>
            )}
            <Button
              type="submit"
              className="mt-4 mb-2"
              variant="contained"
              color="primary"
              disabled={!isValid || isLoading}
            >
              {editable ? "Update" : "Submit"}
            </Button>
            <Button
              type="submit"
              className="mt-4 mb-2 ml-4"
              variant="contained"
              color="primary"
              onClick={preview}
              disabled={!isValid || isLoading}
            >
              Save & Preview
            </Button>
            {!editable && (
              <Button
                type="reset"
                className="mt-4 mb-2 ml-4"
                variant="contained"
                onClick={resetForm}
              >
                Reset
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateCampaign;
