import React from "react";
// import { css } from "@emotion/core";

// const breakpoints = [576, 768, 992, 1200];

// const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const Layout = ({ children }) => (
  <>
    <main
      css={{
        margin: "2rem auto",
        maxWidth: "550px",
        // [mq[0]]: {
        //   marginLeft: "10px",
        //   marginRight: "10px",
        //   marginTop: "10px",
        //   maxWidth: "100px"
        // },
        // [mq[1]]: {

        // }
      }}
    >
      {children}
    </main>
  </>
);

export default Layout;
