import * as Yup from 'yup';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import React, { useEffect, useState } from 'react'
import ReactSelect from '../../../common/components/ReactSelect';
import { getCouponScannedByCouponCreatedBy } from '../../containers/curd/couponScanned';
import { createCustomerCategory, updateCustomerCategory } from '../../containers/curd/customerCategory';
import { createCustomerCategoryRelation, deleteCustomerCategoryRelation } from '../../containers/curd/customerCategoryRelation';


const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
})


function CreateCategory({ user, closeModal, setCreatedItem, editable, setUpdatedItem, setSnackBarDetails }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
    });

    
    const defaultOption = {
        value: '',
        label: 'Search Product'
    }
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [submitted, setSubmitted] = useState(false);

    const demoUser = [
        {
            value: '1',
            label: 'user 1'
        },
        {
            value: '2',
            label: 'user 2'
        },

    ]
    const [couponScannedUser, setCouponScannedUser] = useState(demoUser);

    const [selectedUser, setSelectedUser] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    
    

    const handleSelectAll = (e) => {
        const value = e.target.checked;
        if(value) {
            setSelectedUser(couponScannedUser);
        }else{
            setSelectedUser([]);
        }
        setSelectAll(value);
    }

    useEffect(() => {
        if(selectedUser.length > 0 && selectedUser.length === couponScannedUser.length){
            setSelectAll(true);
        }else{
            setSelectAll(false);
        }
    },[selectedUser])
    
    const handleChangeSelectedUser = (selectedOption) => {
        setSelectedUser(selectedOption);
    }

    const resetSelectedOption = () => {
        setSelectedOption(defaultOption);
        setSelectedUser([]);
    }

    useEffect( () => {

        const fetchList = async () => {
            let { 
                data: { listCouponScanneds: couponData } 
            } = await getCouponScannedByCouponCreatedBy(user.id);
            
            let items = couponData?.items;
            if(items){
                let user = []
                items.map(item=>{
                    let name = item.user.firstName?' - '+item.user.firstName+' '+item.user.lastName:''
                    user.push({
                        value: item.userID,
                        label: item.user.email + name
                    })
                })
                let uniqueUser = user.filter((value, index, self)=>{
                    return self.findIndex(item => item.value === value.value) === index
                })
                setCouponScannedUser(uniqueUser);

                let users = editable?.customerCategoryRelations?.items.map(item => item.userID) || [];
                let selected = []
                uniqueUser.map(item=>{
                    if(users.includes(item.value)){
                        selected.push(item);
                    }
                })
                setSelectedUser(selected)
            }
        }
        fetchList();
    }, [user, editable?.users])


    useEffect(() => {
        setInitialValues((values) => {
            if (!editable) return values;
            let { name } = editable;
            return {
                name,
            }
        })
        
    }, [editable])

    const resetAll = (e) => {
        setSelectedUser([]);
        resetSelectedOption();
        setSelectAll(false);
    }

    const cancel = (e) => {
        setInitialValues({
            name: '',
        })
        resetAll();
        closeModal()
    }

    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        
                        
                        if (!editable) {
                            let data = await createCustomerCategory({ ...values, createdBy: user.id });
                            let relations = [];
                            await Promise.all(
                                selectedUser.map( async(node) => {
                                    let input = {
                                        userID: node.value,
                                        customerCategoryID: data.id
                                    }
                                    let relation = await createCustomerCategoryRelation(input);
                                    relations.push(relation);
                                })
                            )
                            let newitem = {
                                ...data,
                                customerCategoryRelations:{
                                    items: relations
                                }
                            }
                            setCreatedItem(newitem);
                        }
                        else {
                            let data = await updateCustomerCategory({ ...values, id: editable.id })
                            // delete prev
                            let listcustomerCategoryRelations = editable?.customerCategoryRelations?.items || [];
                            listcustomerCategoryRelations = listcustomerCategoryRelations.map(item => item.id);
                            await deleteCustomerCategoryRelation(listcustomerCategoryRelations);
                            let relations = [];
                            await Promise.all(
                                selectedUser.map( async(node) => {
                                    let input = {
                                        userID: node.value,
                                        customerCategoryID: data.id
                                    }
                                    let relation = await createCustomerCategoryRelation(input);
                                    relations.push(relation);
                                })
                            )
                            let newitem = {
                                ...data,
                                customerCategoryRelations:{
                                    items: relations
                                }
                            }
                           
                            setUpdatedItem(newitem);
                        }
                        

                        setInitialValues({
                            name: '',
                        })
                        resetSelectedOption();
                       
                        setSnackBarDetails({
                            message:
                                `Customer category ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        closeModal()
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                        // closeModal()
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form style={{marginBottom:'150px'}}>
                        <FormGroup>
                            <FormikTextField
                                name="name"
                                margin="normal"
                                label="Name"
                            />
                        </FormGroup>
                        <FormGroup className="d-flex flex-row justify-content-between align-items-center">
                            <ReactSelect
                                isMulti={true}
                                name="users"
                                label="Select Users"
                                options={couponScannedUser}
                                onChange={handleChangeSelectedUser}
                                selectedOption={selectedUser}
                                style={{width:'83%'}}
                            />
                            <FormControlLabel
                                style={{ marginRight: '0px' }}
                                control={
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Select all"
                            />
                        </FormGroup>
                        
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            onClick={(e)=>{setSubmitted(true)}}
                        >
                            Submit
                        </Button>
                        {editable && (
                        <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                        )}
                        {!editable && (
                        <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                            onClick={resetAll}
                        >
                            Reset
                        </Button>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateCategory
