import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createWorkflowQuestionResponse = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.createWorkflowQuestionResponse, { input })
  );
}

export const listWorkflowQuestionResponseByUser = async (userID, campaignID, workflowID) => {
  try {
    let params = {
      filter: {
        userID: {
          eq: userID
        },
        campaignID: {
          eq: campaignID
        },
        workflowID: {
          eq: workflowID
        },
      }
    };
    let { data: { listWorkflowQuestionResponses } } = await API.graphql(
      graphqlOperation(queries.listWorkflowQuestionResponses, params)
    );
    console.log("listWorkflowQuestionResponses", listWorkflowQuestionResponses)
    return listWorkflowQuestionResponses.items;
  }
  catch(err) {
    return [];
  }
}
