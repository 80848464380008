import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

/* eslint-disable no-console */
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getDbColumnName,
  getQuesId,
  isNullOrEmpty,
  removeDuplicateAnswereQuestion
} from "../../common/utils/common";
import {
  getList,
  getUserNextQuestion,
  getUserSession,
  postProcessQuestion,
  replaceUserQuestion
} from "../../common/services/question-engine";

import CreateIcon from "@material-ui/icons/Create";
import FormikCheckboxGroupField from "../../common/components/FormikCheckboxGroupField";
import FormikDatePicker from "../../common/components/FormikDatePicker";
import FormikRadioGroupField from "../../common/components/FormikRadioGroupField";
import FormikSelect from "../../common/components/FormikSelect";
import FormikTextField from "../../common/components/FormikTextField";
import Layout from "../../common/components/layout.js";
import Modal from "../../common/components/userExitConfirmationModal";
import { css } from "@emotion/react";
import { separateConditionsAndSymptoms } from "../../common/utils/functions";

const modalInitialValue = {
  open: false,
  no: true,
  yes: false
};

const EditTrackedSymptomConditionFormContainer = props => {
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [userResponses, setUserResponses] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [lastQuestionResponse, setLastQuestionResponse] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [initialValue, setInitialValue] = useState({ questionResponse: "" });
  const [step, setStep] = useState(1);
  const [editQuestion, setEditQuestion] = useState([]);
  const [updateEditQuestion, setUpdateEditQuestion] = useState([]);
  const [customResponse, setCustomResponse] = useState([]);
  const [isBodyPart, setIsBodyPart] = useState([]);
  const [customIndex, setCustomIndex] = useState(0);
  const [rowId, setRowId] = useState([]);
  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const [conditionName, setConditionName] = useState([]);
  const [conditionIndex, setConditionIndex] = useState(1);
  const [quesConditionIndex, setQuesConditionIndex] = useState(0);
  const [bodyPartIndex, setBodyPartIndex] = useState(0);
  const [currentId, setCurrentId] = useState(1);
  const [loopQuestionId, setLoopQuestionId] = useState("");
  const [bodyPartResponses, setBodyPartResponses] = useState([]);
  const [deletePainModal, setDeletePainModal] = useState(modalInitialValue);
  const [addPainModal, setAddPainModal] = useState(modalInitialValue);
  const [editSource, setEditSource] = useState(null);
  const [isPlace, setIsPlace] = useState("");

  const newConditions = [];

  useEffect(() => {
    if (isPlace !== "") {
      const onLoad = async () => {
        await getQuestions(isPlace)
          .then(res => {
            getAllPreviousResponse(
              props.editableResponse,
              props.allTrackedSymptoms,
              res
            );
          })
          .catch(e => console.warn(e));
      };
      onLoad();
    }
    setIsPlace(props.checkPlace);
  }, [isPlace]);

  useEffect(() => {
    if (userResponses && userResponses.length > 0 && lastQuestionResponse) {
      proceedToNextQuestion();
    }
  }, [lastQuestionResponse]);

  async function proceedToNextQuestion() {
    const nextQuestion = await getUserNextQuestion(
      userResponses,
      lastQuestionResponse,
      questions,
      customResponse,
      customIndex,
      responses,
      rowId,
      bodyPartIndex,
      setBodyPartIndex,
      conditionIndex,
      setConditionIndex,
      loopQuestionId,
      setLoopQuestionId,
      editQuestion,
      answeredQuestions,
      currentId,
      bodyPartResponses,
      isBodyPart
    );
    if (nextQuestion) {
      const nextques = {
        ...nextQuestion,
        userResponseRowId: userResponses[0]?.id || rowId[0]
      };
      setCurrentQuestion(nextques);
      return;
    }
    if (onLastQuestion() && !nextQuestion) setStep(step + 1);
  }

  useEffect(() => {
    if (currentQuestion) {
      if (!editQuestion) calculateInitialValues(answeredQuestions);
      else calculateInitialValues(editQuestion);
    }
  }, [currentQuestion, userResponses[0]]);

  async function getQuestions(isPlace) {
    // get question by questionID
    const response = await API.graphql(
      graphqlOperation(queries.listQuestions, {
        filter: {
          onboarding: {
            eq: true
          }
        }
      })
    );
    if (response && response.data && response.data.listQuestions) {
      // sort by questionID
      let questions = response.data.listQuestions.items.sort((a, b) => {
        return a.questionID.localeCompare(b.questionID);
      });

      questions = questions.map(q => postProcessQuestion({ ...q }));
      setQuestions(questions);
      if (isPlace === "onSymptoms") {
        const quesIn = questions.findIndex(x => x.questionID === "DOX-0019");
        const res = await getList(questions[quesIn].listType);
        questions[quesIn]["options"] = res;
        setCurrentQuestion({ ...questions[quesIn] });
      } else if (isPlace === "onConditions") {
        const quesIn = questions.findIndex(x => x.questionID === "DOX-0008");
        const res = await getList(questions[quesIn].listType);
        questions[quesIn]["options"] = res;
        setCurrentQuestion({ ...questions[quesIn] });
      } else setCurrentQuestion({ ...questions[0] });
      return questions;
    }
  }

  // This function will retrieve all the response of current session
  function getAllPreviousResponse(editableRes, allUnTrackedRes, allQuestions) {
    setUserResponses([props.editableResponse]);
    const sameSessionRes = allUnTrackedRes.filter(
      x =>
        x.userID === editableRes.userID && x.sessionID === editableRes.sessionID
    );
    const { conditions, symptoms } = separateConditionsAndSymptoms(
      sameSessionRes
    );
    const editableCondition = conditions.filter(q => q.id === editableRes.id);
    const editableSymptom = symptoms.filter(q => q.id === editableRes.id);
    constructAnsweredQuestions(
      editableCondition[0],
      editableSymptom[0],
      allQuestions,
      editableRes
    );
  }

  // This function construct the answeredQuestions
  function constructAnsweredQuestions(
    condition,
    symptom,
    allQuestions,
    editableRes
  ) {
    if (isPlace === "onSymptoms") {
      Object.entries(symptom).map(x => {
        const quesId = getQuesId(x[0]);
        const quesObj = allQuestions.filter(y => y.questionID === quesId);
        const dummyRes = {};
        if (quesObj[0]?.questionID === quesId && x[1] !== null) {
          if (x[0] === "dox024") {
            const painRes = JSON.parse(x[1]);
            setCustomResponse(painRes);

            // constructing for dox016, list of bodyparts
            const listQues = allQuestions.filter(
              y => y.questionID === "DOX-0016"
            );
            let bodyParts = [];
            painRes.forEach(bodyPart => bodyParts.push(bodyPart.label));
            listQues[0]["answer"] = bodyParts;
            listQues[0]["processedQuestion"] = replaceUserQuestion(
              listQues[0],
              dummyRes,
              conditionName,
              currentId,
              bodyPartIndex,
              editableRes
            );
            listQues[0]["userResponseRowId"] = symptom?.id || rowId[0];
            editQuestion.push(listQues[0]);

            // constructing for dox024, pain responses of the bodyparts
            painRes.forEach((res, index) => {
              const newObj = {
                ...quesObj[0],
                answer: res.value,
                processedQuestion: replaceUserQuestion(
                  quesObj[0],
                  dummyRes,
                  conditionName,
                  currentId,
                  index,
                  editableRes
                ),
                userResponseRowId: symptom?.id || rowId[0]
              };
              editQuestion.push(newObj);
            });
          } else {
            quesObj[0]["answer"] = JSON.parse(x[1]);
            quesObj[0]["processedQuestion"] = replaceUserQuestion(
              quesObj[0],
              dummyRes,
              conditionName,
              currentId,
              bodyPartIndex,
              editableRes
            );
            quesObj[0]["userResponseRowId"] = symptom?.id || rowId[0];
            // answeredQuestions.push(quesObj[0])
            // updateEditQuestion.push(quesObj[0])
            editQuestion.push(quesObj[0]);
          }
        }
      });
    } else if (isPlace === "onConditions") {
      Object.entries(condition).map(x => {
        const quesId = getQuesId(x[0]);
        const quesObj = allQuestions.filter(y => y.questionID === quesId);
        const dummyRes = {};
        if (quesObj[0]?.questionID === quesId && x[1] !== null) {
          if (x[0] === "dox024") {
            const painRes = JSON.parse(x[1]);
            setCustomResponse(painRes);

            // constructing for dox016, list of bodyparts
            const listQues = allQuestions.filter(
              y => y.questionID === "DOX-0014"
            );
            let bodyParts = [];
            painRes.forEach(bodyPart => bodyParts.push(bodyPart.label));
            listQues[0]["answer"] = bodyParts;
            listQues[0]["processedQuestion"] = replaceUserQuestion(
              listQues[0],
              dummyRes,
              conditionName,
              currentId,
              bodyPartIndex,
              editableRes
            );
            listQues[0]["userResponseRowId"] = condition?.id || rowId[0];
            editQuestion.push(listQues[0]);

            // constructing for dox024, pain responses of the bodyparts
            painRes.forEach((res, index) => {
              const newQues = allQuestions.filter(
                y => y.questionID === "DOX-0028"
              );
              const newObj = {
                ...newQues[0],
                answer: res.value,
                processedQuestion: replaceUserQuestion(
                  newQues[0],
                  dummyRes,
                  conditionName,
                  currentId,
                  index,
                  editableRes
                ),
                userResponseRowId: condition?.id || rowId[0]
              };
              editQuestion.push(newObj);
              customResponse.push(res);
            });
          } else {
            quesObj[0]["answer"] = JSON.parse(x[1]);
            quesObj[0]["processedQuestion"] = replaceUserQuestion(
              quesObj[0],
              dummyRes,
              conditionName,
              currentId,
              bodyPartIndex,
              editableRes
            );
            quesObj[0]["userResponseRowId"] = condition?.id || rowId[0];
            // answeredQuestions.push(quesObj[0])
            // updateEditQuestion.push(quesObj[0])
            editQuestion.push(quesObj[0]);
          }
        }
      });
    }
  }

  // This function sets NULL values to the PAIN affected questions
  function nullifyResponses(userId, ques, quesResponse, source) {
    if (
      ques &&
      (ques.questionID === "DOX-0019" || ques.questionID === "DOX-0013") &&
      !(ques?.answer instanceof Date) &&
      ques?.answer?.includes("Pain") &&
      quesResponse &&
      !quesResponse.includes("Pain")
    ) {
      setEditSource(source);
      setDeletePainModal({
        open: true,
        title: `You have deselected PAIN. You will loose PAIN response`,
        button1: `Delete Pain Data & Proceed`
      });
      return true;
    }
    return false;
  }

  async function deletePainResponse() {
    let requiredRes = JSON.parse(userResponses[0]["dox020"]) || [];
    requiredRes.splice(requiredRes.indexOf("Pain"), 1);
    const expectedResponses = [
      {
        quesID: "DOX-0024",
        newRes: []
      },
      {
        quesID: "DOX-0028",
        newRes: []
      },
      {
        quesID: "DOX-0020",
        newRes: requiredRes
      }
    ];
    expectedResponses.map(async res => {
      await updateConditionsAndSymptoms(res.quesID, userResponses[0]["id"], res.newRes)
        .then(data => console.warn("successfully updated :", data))
        .catch(error =>
          console.warn("something went wrong while submiting response :", error)
        );
    });
  }

  // saving user response when he wants update the current question and then switch to last page
  async function saveAndExitResponse(quesID, userId, quesResponse) {
    const updateExistingQuestion = true;
    let ques = editQuestion.filter(x => x.questionID === quesID);

    // checking if pain loop is not selected earlier, but now we are selecting pain while editing.
    // So, we are showing alert message to user, to either deselect PAIN or go ahead with PAIN loop first.
    if (
      !(ques[0]?.answer instanceof Date) &&
      !("Years" in ques[0]?.answer) &&
      !ques[0]?.answer?.includes("Pain") &&
      quesResponse &&
      quesResponse.includes("Pain")
    ) {
      setAddPainModal({
        open: true,
        title: `You have choosen PAIN as a symptom.`,
        button2: `Proceed with Filling Data`
      });
      return;
    }

    // checking if pain is selected but we are moving to last page without responding to body part questions
    // So, we are showing alert message to user, to first list down where he is having pain.
    if (
      editQuestion.find(x => x.questionID === "DOX-0019") &&
      editQuestion[
        editQuestion.findIndex(x => x.questionID === "DOX-0019")
      ]?.answer?.includes("Pain") &&
      !editQuestion.find(x => x.questionID === "DOX-0024") &&
      !editQuestion.find(x => x.questionID === "DOX-0016")
    ) {
      setAddPainModal({
        open: true,
        title: `You selected PAIN as a symptom. Please first list down where you've PAIN in your body`,
        button3: `OK`
      });
      return;
    }

    // checking if pain loop is answered, but now we ain't selecting pain while editing. So, we can nullify the response
    if (nullifyResponses(userId, ques[0], quesResponse)) return;

    // update current question response
    await handleSaveAndExitResponse(
      quesID,
      userId,
      quesResponse,
      updateExistingQuestion
    );
  }

  // This function updates the user response when save and exit
  async function handleSaveAndExitResponse(
    quesID,
    userId,
    quesResponse,
    updateExistingQuestion
  ) {
    await updateConditionsAndSymptoms(quesID, userId, quesResponse)
      .then(res => {
        if (res?.data?.updateConditionsAndSymptoms?.id) {
          setResponses({
            ...responses,
            [currentQuestion.questionID]: quesResponse
          });
          saveAnsweredQuestion(
            currentQuestion,
            responses,
            conditionName,
            currentId,
            bodyPartIndex,
            quesResponse,
            updateExistingQuestion
          );

          if (
            currentQuestion.listType === "CONDITION" &&
            currentQuestion.responseType === "LIST" &&
            editSource === "next"
          ) {
            setLastQuestionResponse({
              ...currentQuestion,
              questionId: currentQuestion.questionID,
              questionResponse: {
                default: currentQuestion.nextQuestion.default
              },
              loopQuestionResponse: quesResponse
            });
            return;
          }
          if (
            editSource === "next" ||
            updateExistingQuestion === "save and proceed"
          ) {
            setLastQuestionResponse({
              ...currentQuestion,
              questionId: currentQuestion.questionID,
              questionResponse: quesResponse
            });
            return;
          }
          setStep(step + 1);
        }
      })
      .catch(error => {
        console.warn("something went wrong while submiting response :", error);
        setStep(step + 1);
      });
  }

  function showSaveAndExitButton(quesRes) {
    let showButton = true;
    if (currentQuestion.questionID === "DOX-0014") {
      let ques = editQuestion.filter(
        x =>
          x.questionID === "DOX-0014" &&
          x.userResponseRowId === userResponses[0].id
      );
      if (ques[0] && ques[0].answer.length !== quesRes.length)
        return (showButton = false);
      else if (
        ques[0] &&
        ques[0].answer.length === quesRes.length &&
        !ques[0].answer.every(x => quesRes.includes(x))
      )
        return (showButton = false);
    } else if (
      currentQuestion.questionID === "DOX-0028" ||
      currentQuestion.questionID === "DOX-0024"
    ) {
      return (showButton = false);
    } else if (
      !rowId.every(x => editQuestion.find(y => y.userResponseRowId === x))
    )
      return (showButton = false);
    return showButton;
  }

  // This function is used to update user responses
  async function updateConditionsAndSymptoms(questionID, id, resp) {
    const columnName = getDbColumnName(questionID);
    if (!columnName) return null;

    const response = await API.graphql(
      graphqlOperation(mutations.updateConditionsAndSymptoms, {
        input: {
          id,
          [columnName]: JSON.stringify(resp)
        }
      })
    );
    return response;
  }

  // edit bodypart questions
  async function editBodyPart(bodyParts) {
    setIsBodyPart(bodyParts);
    try {
      await API.graphql(
        graphqlOperation(queries.getConditionsAndSymptoms, {
          id: currentQuestion.userResponseRowId || userResponses[0].id
        })
      ).then(async data => {
        if (data?.data?.getConditionsAndSymptoms["dox024"]) {
          const previousRes = JSON.parse(data.data.getConditionsAndSymptoms["dox024"]);
          if (currentId > bodyPartResponses.length)
            bodyPartResponses.push(bodyParts);
          else if (
            bodyPartResponses &&
            bodyPartResponses.length > 0 &&
            currentId <= bodyPartResponses.length
          ) {
            bodyPartResponses.splice(currentId - 1, 1, bodyParts);
          }
          // checking for old bodypart response and pushing those into new response array object
          for (let x of previousRes) {
            if (bodyParts.includes(x["label"])) {
              customResponse.push(x);
            }
          }
          // checking for new bodypart response and pushing those into new response array object
          for (let x of bodyParts) {
            if (!previousRes.find(y => x === y["label"])) {
              customResponse.push({ label: x, value: "" });
            }
          }
          // saving new bodypart response in DB
          let updatedUserResponse = await updateConditionsAndSymptoms(
            currentQuestion.nextQuestion.default,
            userResponses[0].id,
            customResponse
          );
          if (updatedUserResponse?.data?.updateConditionsAndSymptoms?.id) {
            setUserResponses([updatedUserResponse.data.updateConditionsAndSymptoms]);
            setResponses({
              ...responses,
              [currentQuestion.questionID]: data.questionResponse
            });
            setLastQuestionResponse({
              ...currentQuestion,
              questionId: currentQuestion.questionID,
              questionResponse: {
                default: currentQuestion.nextQuestion.default
              },
              loopQuestionResponse: data.questionResponse
            });
          }
        }
      });
    } catch (err) {
      console.warn("Getting ERROR while while fetching userResponse :", err);
    }
  }

  // saving user responses into answered questions
  function saveAnsweredQuestion(
    currentQuestion,
    responses,
    conditionName,
    currentId,
    bodyPartIndex,
    questionResponse,
    updateExistingQuestion
  ) {
    let thisQuestion = {
      ...currentQuestion,
      processedQuestion: replaceUserQuestion(
        currentQuestion,
        responses,
        conditionName,
        currentId,
        bodyPartIndex,
        userResponses[0]
      ),
      answer: questionResponse,
      userResponseRowId: userResponses[0]?.id || rowId[0]
    };
    setAnsweredQuestions([...answeredQuestions, thisQuestion]);

    // updating condition names with array of condition
    const conditionQuesIndex = answeredQuestions.findIndex(
      x => x.questionID === "DOX-0002"
    );
    if (conditionName && conditionName.length > 0 && conditionQuesIndex >= 0) {
      answeredQuestions[conditionQuesIndex]["answer"] = conditionName;
    }

    if (updateExistingQuestion) {
      const index = updateEditQuestion.findIndex(
        question =>
          question.questionID === thisQuestion.questionID &&
          question.userResponseRowId === thisQuestion.userResponseRowId
      );
      updateAnsweredQuestion(index, thisQuestion);
    }
  }

  // This function will update existing answered question
  function updateAnsweredQuestion(index, thisQuestion) {
    const quesToBeUpdated = updateEditQuestion.find(
      question =>
        question.questionID === thisQuestion.questionID &&
        question.userResponseRowId === thisQuestion.userResponseRowId
    );
    updateEditQuestion.splice(0, index + 1, thisQuestion);
    let i;

    // if PAIN is deselected, deleting related response
    if (
      quesToBeUpdated &&
      (quesToBeUpdated.questionID === "DOX-0019" ||
        quesToBeUpdated.questionID === "DOX-0013") &&
      !(quesToBeUpdated?.answer instanceof Date) &&
      quesToBeUpdated?.answer?.includes("Pain") &&
      !thisQuestion.answer.includes("Pain")
    ) {
      const quesToRemove = ["DOX-0014", "DOX-0016", "DOX-0024", "DOX-0028"];
      quesToRemove.forEach(x => {
        const answersToRemove = updateEditQuestion.filter(
          z => z.questionID === x
        );
        if (answersToRemove && answersToRemove.length > 1) {
          answersToRemove.forEach(r => {
            i = updateEditQuestion.findIndex(
              y =>
                y.questionID === r.questionID &&
                y.userResponseRowId === currentQuestion.userResponseRowId
            );
            if (i > -1) updateEditQuestion.splice(i, 1);
          });
        } else {
          i = updateEditQuestion.findIndex(
            y =>
              y.questionID === x &&
              y.userResponseRowId === currentQuestion.userResponseRowId
          );
          if (i > -1) updateEditQuestion.splice(i, 1);
        }
      });
    }

    const finalAnswredList = [...answeredQuestions, ...updateEditQuestion];
    setAnsweredQuestions(finalAnswredList);
  }

  // submiting user reponse
  async function submitUserResponse(
    data,
    proceed,
    resume = false,
    resetForm = null
  ) {
    // setting correct response structure here
    if (currentQuestion && currentQuestion.responseType === "MULTIPLELISTS") {
      delete data["questionResponse"];
      data.questionResponse = { ...data };
    }

    // Nullify PAIN affected question response if we deselect PAIN as a symptom
    if (
      userResponses &&
      userResponses[0]?.id &&
      currentQuestion?.answer &&
      data.questionResponse.length > 0
    ) {
      if (
        nullifyResponses(
          userResponses[0]["id"],
          currentQuestion,
          data.questionResponse,
          "next"
        )
      )
        return;
      setEditSource(null);
    }
    // normal work flow start
    if (
      currentQuestion &&
      !isNullOrEmpty(currentQuestion, "loop") &&
      currentQuestion.loop.includes("goto") &&
      currentQuestion.responseType === "MULTIPLECHOICE" &&
      customResponse.length !== customIndex
    ) {
      //updating bodyParts data
      let bodyResponse = JSON.parse(userResponses[0]?.dox024);
      bodyResponse[customIndex].value = data.questionResponse;
      setCustomIndex(customIndex + 1);
      let updatedUserResponse = await updateConditionsAndSymptoms(
        currentQuestion.questionID,
        userResponses[0].id,
        bodyResponse
      );
      if (updatedUserResponse?.data?.updateConditionsAndSymptoms?.id) {
        setUserResponses([updatedUserResponse.data.updateConditionsAndSymptoms]);
        setResponses({
          ...responses,
          [currentQuestion.questionID]: data.questionResponse
        });
        setLastQuestionResponse({
          ...currentQuestion,
          questionId: currentQuestion.questionID,
          questionResponse: { default: currentQuestion.nextQuestion.default },
          loopQuestionResponse: data.questionResponse
        });
      }
    } else if (
      currentQuestion.responseType === "LIST" &&
      currentQuestion.listType === "BODYPART"
    ) {
      if (customResponse.length > 0) {
        setCustomIndex(0);
        customResponse.splice(0);
      }
      const bodyParts = Object.values(data.questionResponse);
      if (
        editQuestion &&
        editQuestion.length > 0 &&
        editQuestion.find(
          x =>
            x.questionID === currentQuestion.questionID &&
            x.userResponseRowId === currentQuestion.userResponseRowId
        )
      ) {
        editBodyPart(bodyParts);
      } else {
        setIsBodyPart(bodyParts);
        bodyPartResponses.push(bodyParts);
        bodyParts.forEach(bodyPart => {
          customResponse.push({ label: bodyPart, value: "" });
        });
        let updatedUserResponse = await updateConditionsAndSymptoms(
          currentQuestion.nextQuestion.default,
          userResponses[0].id,
          customResponse
        );
        if (updatedUserResponse?.data?.updateConditionsAndSymptoms?.id) {
          setUserResponses([updatedUserResponse.data.updateConditionsAndSymptoms]);
          setResponses({
            ...responses,
            [currentQuestion.questionID]: data.questionResponse
          });
          setLastQuestionResponse({
            ...currentQuestion,
            questionId: currentQuestion.questionID,
            questionResponse: { default: currentQuestion.nextQuestion.default },
            loopQuestionResponse: data.questionResponse
          });
        }
      }
    } else if (userResponses && userResponses.length > 0) {
      if (
        currentQuestion.listType === "CONDITION" &&
        currentQuestion.responseType === "LIST"
      ) {
        if (data.questionResponse.length > 1) {
          setConditionName(data.questionResponse);
          data.questionResponse = data.questionResponse[0];
        }
      }
      let updatedUserResponse = await updateConditionsAndSymptoms(
        currentQuestion.questionID,
        userResponses[0].id,
        data.questionResponse
      );
      if (updatedUserResponse?.data?.updateConditionsAndSymptoms?.id) {
        setUserResponses([updatedUserResponse.data.updateConditionsAndSymptoms]);
        setResponses({
          ...responses,
          [currentQuestion.questionID]: data.questionResponse
        });
        setLastQuestionResponse({
          ...currentQuestion,
          questionId: currentQuestion.questionID,
          questionResponse: data.questionResponse
        });
        //switching to new row if present
        if (
          currentQuestion &&
          isNullOrEmpty(currentQuestion, "nextQuestion") &&
          !isNullOrEmpty(currentQuestion, "loop") &&
          currentQuestion.loop.includes("end") &&
          rowId.length > 1
        ) {
          if (currentId === rowId.length) {
            saveAnsweredQuestion(
              currentQuestion,
              responses,
              conditionName,
              currentId,
              bodyPartIndex,
              data.questionResponse
            );
            return;
          }
          setCurrentId(currentId + 1);
          setQuesConditionIndex(quesConditionIndex + 1);
          const userRes = await API.graphql(
            graphqlOperation(queries.getConditionsAndSymptoms, {
              id: rowId[currentId]
            })
          );
          setUserResponses([userRes.data.getConditionsAndSymptoms]);
        }
      }
    }
    saveAnsweredQuestion(
      currentQuestion,
      responses,
      conditionName,
      currentId,
      bodyPartIndex,
      data.questionResponse
    );
    resetForm();
  }

  // This function is used to find question by id
  // function findQuestionById(questionID) {
  //   return questions.find(q => q.questionID === questionID);
  // }

  //final submission query
  async function finalSubmissionQuery(id) {
    let userId = id;
    try {
      await API.graphql(
        graphqlOperation(queries.getConditionsAndSymptoms, {
          id: userId
        })
      ).then(async response => {
        if (response.data?.getConditionsAndSymptoms?.id)
          userId = response.data.getConditionsAndSymptoms.id;
        delete response.data.getConditionsAndSymptoms.id;
        delete response.data.getConditionsAndSymptoms.updatedAt;
        // delete response.data.getConditionsAndSymptoms.createdAt;
        response.data.getConditionsAndSymptoms.beingTreated = false;
        await API.graphql(
          graphqlOperation(mutations.createConditionsAndSymptoms, {
            input: response.data.getConditionsAndSymptoms
          })
        ).then(async () => {
          await API.graphql(
            graphqlOperation(mutations.deleteConditionsAndSymptoms, {
              input: { id: userId }
            })
          );
        });
        setStep(step + 1);
      });
    } catch (error) {
      console.warn("Error while submitting form :", error);
    }
  }

  // User Final Submission
  async function finalUserSubmission() {
    if (rowId.length > 1) {
      for (let id of rowId) {
        finalSubmissionQuery(id);
      }
      return;
    }
    finalSubmissionQuery(userResponses[0].id);
  }

  function onLastQuestion() {
    if (currentQuestion && !isNullOrEmpty(currentQuestion, "nextQuestion")) {
      return false;
    }
    // check if loop
    if (
      currentQuestion &&
      !isNullOrEmpty(currentQuestion, "loop") &&
      currentQuestion.loop.includes("end") &&
      JSON.parse(currentQuestion.loop).end &&
      rowId.indexOf(userResponses[0].id) !== rowId.length - 1
    ) {
      return false;
    }
    return true;
  }

  function checkBodyPartValue(questions) {
    // checking the index of the bodyPart which we are editing
    // bodyPart's name are present in customResponse array
    if (
      bodyPartResponses &&
      bodyPartResponses.length > 0 &&
      //  (questions.listType === "BODYPART" || questions.responseType === "MULTIPLECHOICE") ) {
      questions.responseType === "MULTIPLECHOICE"
    ) {
      bodyPartResponses.map((bodyPartResponse, index) => {
        if (index !== 0) setQuesConditionIndex(index - 1);
        if (
          questions.responseType === "MULTIPLECHOICE" &&
          bodyPartResponse.length > 0
        ) {
          bodyPartResponse.forEach((bodyPart, childIndex) => {
            const nameIndex = questions.processedQuestion.search(bodyPart);
            if (nameIndex > -1) {
              setCustomIndex(childIndex);
              setBodyPartIndex(childIndex);
            }
          });
        }
      });
    } else {
      setQuesConditionIndex(0);
      setCustomIndex(0);
      setBodyPartIndex(0);
    }
  }

  // go to clicked question
  function edit(questions, index) {
    setStep(step - 1);
    setEditQuestion([...answeredQuestions]);
    setUpdateEditQuestion([...answeredQuestions]);
    setCurrentQuestion(questions);
    setAnsweredQuestions(answeredQuestions.slice(0, index));
    setIsBodyPart([]);
    setLoopQuestionId("");

    // checking the index of the condition name which we are editing
    if (conditionName && conditionName.length > 0) {
      conditionName.map(async condition => {
        // const nameIndex = questions.processedQuestion.search(condition)
        let index;
        if (!questions.userResponseRowId) {
          const nameIndex = questions?.processedQuestion?.search(condition);
          if (nameIndex > -1) index = conditionName.indexOf(condition);
        } else index = rowId.indexOf(questions.userResponseRowId);
        setCurrentId(index + 1);
        setConditionIndex(index + 1);
        checkBodyPartValue(questions);
        const userResp = await API.graphql(
          graphqlOperation(queries.getConditionsAndSymptoms, {
            id: rowId[index]
          })
        );
        setUserResponses([userResp.data.getConditionsAndSymptoms]);
      });
    } else {
      checkBodyPartValue(questions, JSON.parse(userResponses[0]?.dox024));
    }
  }

  function renderSummaryRows() {
    // removing duplicates
    const uniqueAnsweredQuestions = removeDuplicateAnswereQuestion(answeredQuestions)

    // Create an array for summary of questions
    let summaryOfQuestions = [];
    for (let question of uniqueAnsweredQuestions) {
      if (question) {
        let answer = question.answer;
        let answerToDisplay = Object.assign(answer);
        if (Array.isArray(answer)) {
          answerToDisplay = [];
          for (let a of answer) {
            if (typeof a === "object" && a.label) {
              answerToDisplay.push(a.label);
            } else {
              answerToDisplay.push(a);
            }
          }
          answerToDisplay = answerToDisplay.join(", ");
        } else if (question.responseType === "DATE") {
          const date = new Date(answer);
          answerToDisplay = `${date.getMonth() +
            1}/${date.getDate()}/${date.getFullYear()}`;
        } else if (question.responseType === "MULTIPLELISTS") {
          answerToDisplay = [];
          for (let key in answer) {
            answerToDisplay.push(`${key}=${answer[key]}`);
          }
          answerToDisplay = answerToDisplay.join(", ");
        }
        summaryOfQuestions.push({ ...question, answer: answerToDisplay });
      }
    }

    return (
      <React.Fragment>
        <tbody>
          {summaryOfQuestions.map((question, index) => {
            return (
              <tr key={index}>
                <td>{question?.processedQuestion}</td>
                <td>{question?.answer}</td>
                <td className="text-center">
                  <Tooltip arrow title="Edit Details">
                    <IconButton
                      onClick={() => edit(question, index)}
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </React.Fragment>
    );
  }

  function renderThankYou() {
    return (
      <React.Fragment>
        <Card className="card-box text-center mb-4">
          <div className="card-header pr-2">
            <div className="card-header--title font-weight-bold text-center">
              Thank You
            </div>
          </div>
          <CardContent className="p-3 text-center">
            <div>
              Thank you for choosing Doximple to help take control of your
              health!
            </div>
          </CardContent>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="mb-3"
            onClick={() => props.goBack()}
          >
            Ok
          </Button>
        </Card>
      </React.Fragment>
    );
  }
  function renderSummary() {
    return (
      <React.Fragment>
        <Card className="card-box mb-4">
          <div className="card-header pr-2">
            <div className="card-header--title">Review</div>
          </div>
          <CardContent className="p-3">
            <div className="table-responsive">
              <table className="mb-0 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Questions</th>
                    <th className="text-left">Answers</th>
                    <th className="text-left">Actions</th>
                  </tr>
                </thead>

                {renderSummaryRows()}
              </table>
            </div>
          </CardContent>
          <div className="card-footer d-flex justify-content-center">
            <Button
              onClick={async () => {
                await finalUserSubmission();
              }}
              size="large"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </div>
        </Card>
      </React.Fragment>
    );
  }
  function renderQuestion() {
    return (
      <Layout>
        <LinearProgress variant="determinate" value={progress} />
        {currentQuestion && (
          <Formik
            validateOnMount={true}
            initialValues={initialValue}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await submitUserResponse(data, true, false, resetForm);
              setUserData(data);
              setSubmitting(false);
            }}
            validate={values => {
              const errors = {};
              if (currentQuestion.responseType === "MULTIPLELISTS") {
                // atleast one of the value is present
                let atleastOneSelected = false;
                for (let key of Object.keys(values)) {
                  if (values[key]) {
                    atleastOneSelected = true;
                  }
                }
                if (!atleastOneSelected) {
                  errors[currentQuestion.lists[0]] =
                    "Select atleast one of the option";
                }
              } else if (currentQuestion.responseType === "MULTITEXT") {
                if (values.questionResponse) {
                  errors.questionResponse = [];
                  let atleastOneError = false;
                  let regex = /^[a-zA-Z ]*$/;
                  for (let i = 0; i < values.questionResponse.length; i++) {
                    let v = values.questionResponse[i];
                    errors.questionResponse[i] = "";
                    if (!v) {
                      atleastOneError = true;
                      errors.questionResponse[i] = "Enter an answer";
                    } else if (v.length > 20) {
                      atleastOneError = true;
                      errors.questionResponse[i] = "Max 20 characters allowed";
                    } else if (!regex.test(v)) {
                      atleastOneError = true;
                      errors.questionResponse[i] = "Only letters are allowed";
                    }
                  }
                  if (!atleastOneError) {
                    delete errors.questionResponse;
                  }
                }
              } else if (
                typeof values.questionResponse === "string" &&
                !values.questionResponse
              ) {
                errors.questionResponse = "Select an answer";
              } else if (
                values.questionResponse &&
                Array.isArray(values.questionResponse) &&
                values.questionResponse.length === 0
              ) {
                errors.questionResponse = "Select an answer";
              }

              return errors;
            }}
            enableReinitialize={true}
          >
            {formik => {
              return (
                <Form>
                  <Layout>
                    <div>
                      <h1
                        css={css`
                          // color: #a8d8f0;
                          padding-top: 20px;
                          padding-bottom: 10px;
                          text-align: center;
                        `}
                      >
                        {replaceUserQuestion(
                          currentQuestion,
                          responses,
                          conditionName,
                          currentId,
                          bodyPartIndex,
                          userResponses[0]
                        )}
                      </h1>
                    </div>
                    <div id="wrapper">
                      {currentQuestion.responseType === "MULTIPLECHOICE" && (
                        <FormikRadioGroupField
                          name="questionResponse"
                          margin="normal"
                          options={currentQuestion.options}
                        />
                      )}
                      {currentQuestion.responseType === "MULTIPLELISTS" && (
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          {currentQuestion.lists.map((value, index) => (
                            <Grid item xs={12} key={index}>
                              <FormikSelect
                                label={value}
                                name={value}
                                value={formik.values}
                                options={currentQuestion.options[value]}
                                allowEmpty={true}
                              ></FormikSelect>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {currentQuestion.responseType === "MULTITEXT" && (
                        <FieldArray
                          name="questionResponse"
                          render={arrayHelpers => (
                            <div>
                              {formik.values.questionResponse &&
                                Array.isArray(formik.values.questionResponse) &&
                                formik.values.questionResponse.length > 0 ? (
                                formik.values.questionResponse.map(
                                  (option, index) => (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      key={index}
                                    >
                                      <FormikTextField
                                        name={`questionResponse.${index}`}
                                        margin="normal"
                                      ></FormikTextField>
                                      <Button
                                        type="button"
                                        variant="outlined"
                                        color="inherit"
                                        className="text-google ml-2"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        } // remove a friend from the list
                                      >
                                        -
                                      </Button>

                                      <Button
                                        type="button"
                                        color="primary"
                                        className="ml-2"
                                        variant="contained"
                                        onClick={() =>
                                          arrayHelpers.insert(index, "")
                                        } // insert an empty string at a position
                                      >
                                        +
                                      </Button>
                                    </div>
                                  )
                                )
                              ) : (
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  {/* show this when user has removed all friends from the list */}
                                  Add a Symptom
                                </Button>
                              )}
                            </div>
                          )}
                        />
                      )}

                      {currentQuestion.responseType === "TEXT" && (
                        <FormikTextField
                          name="questionResponse"
                          margin="normal"
                          fullWidth
                        ></FormikTextField>
                      )}
                      {currentQuestion.responseType === "DATE" && (
                        <FormikDatePicker
                          value={formik.values.questionResponse ? new Date(formik.values.questionResponse) : new Date()}
                          name="questionResponse"
                          format="MM/dd/yyyy"
                          maxDate={
                            new Date()
                          } /* ristricting user to select future dates */
                        ></FormikDatePicker>
                      )}
                      {currentQuestion.responseType === "LIST" && (
                        <FormikCheckboxGroupField
                          name="questionResponse"
                          margin="normal"
                          options={currentQuestion.options}
                          row={false}
                          fullWidth
                          multiple
                        />
                      )}
                    </div>
                    <br />
                    <div className="d-flex align-items-center justify-content-center">
                      {!currentQuestion.previousQuestion && (
                        <Button
                          component={Link}
                          to="/"
                          variant="outlined"
                          color="primary"
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      )}
                      {currentQuestion.previousQuestion && (
                        <Button
                          onClick={() => history.push("/")}
                          variant="outlined"
                          color="primary"
                          className="ml-2"
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          Save and Return Later
                        </Button>
                      )}
                      <Button
                        className="ml-2"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {onLastQuestion() ? "Submit" : "Next"}
                      </Button>
                    </div>
                    <Modal
                      settings={deletePainModal}
                      close={async success => {
                        if (success === "delete and proceed") {
                          await deletePainResponse();
                          await handleSaveAndExitResponse(
                            currentQuestion.questionID,
                            userResponses[0].id,
                            formik.values.questionResponse,
                            true
                          );
                          setBodyPartIndex(0);
                          setBodyPartResponses([]);
                        }
                        setDeletePainModal({ ...deletePainModal, open: false });
                      }}
                    />
                    <Modal
                      settings={addPainModal}
                      close={async success => {
                        if (success === "save and proceed") {
                          await handleSaveAndExitResponse(
                            currentQuestion.questionID,
                            userResponses[0].id,
                            formik.values.questionResponse,
                            success
                          );
                        }
                        setAddPainModal({ ...addPainModal, open: false });
                      }}
                    />
                  </Layout>
                </Form>
              );
            }}
          </Formik>
        )}
      </Layout>
    );
  }

  function getEmptyInitialValues() {
    let initialValue = {};
    if (currentQuestion.responseType === "MULTIPLELISTS") {
      for (let l of currentQuestion.lists) {
        initialValue[l] = "0";
      }
    } else {
      //LIST
      initialValue.questionResponse = "";
      if (currentQuestion.responseType === "LIST") {
        initialValue.questionResponse = [];
      }
      if (currentQuestion.responseType === "MULTITEXT") {
        initialValue.questionResponse = [];
      }
    }
    return initialValue;
  }
  function calculateInitialValues(questionsAnswered) {
    let initialValue;
    initialValue = getEmptyInitialValues();
    // We also need to check if question is already answered then take it from responses given by user
    let questionAnswered = questionsAnswered.filter(
      q =>
        q.questionID === currentQuestion.questionID &&
        q.userResponseRowId === userResponses[0]?.id
    );
    if (
      questionAnswered &&
      questionAnswered.length > 0 &&
      questionAnswered[0].answer
    ) {
      if (
        currentQuestion &&
        !isNullOrEmpty(currentQuestion, "loop") &&
        currentQuestion.loop.includes("goto") &&
        currentQuestion.responseType === "MULTIPLECHOICE" &&
        customResponse.length !== customIndex
      ) {
        // checking bodyPart loop is answered while use hit on edit button
        // else we are nullifying the intial value if the bodyPart loop is running
        if (
          editQuestion &&
          bodyPartResponses &&
          bodyPartResponses.length > 0 &&
          questionAnswered.length >= bodyPartIndex &&
          questionAnswered[bodyPartIndex]?.responseType === "MULTIPLECHOICE"
        ) {
          const nameIndex = questionAnswered[
            bodyPartIndex
          ]?.processedQuestion?.search(
            bodyPartResponses[quesConditionIndex][bodyPartIndex]
          );
          if (nameIndex > -1) {
            initialValue.questionResponse =
              questionAnswered[bodyPartIndex].answer;
          }
        } else {
          initialValue.questionResponse = "";
        }
      } else {
        // This means user has already answered the question and now coming using back button or resume or edit
        // changing the structure of response if the Y/M/D is answered
        if (
          "Years" in initialValue &&
          "Months" in initialValue &&
          "Days" in initialValue
        ) {
          initialValue = { ...questionAnswered[0].answer };
        } else {
          initialValue.questionResponse = questionAnswered[0].answer;
        }
      }
    }
    setInitialValue(initialValue);
    return initialValue;
  }

  return (
    <div>
      <>
        <h1
          css={css`
            padding-top: 40px;
            padding-bottom: 30px;
            text-align: center;
          `}
        >
          Symptom Form
        </h1>
        {step === 1 && renderQuestion()}
        {step === 2 && renderSummary()}
        {step === 3 && renderThankYou()}
      </>
    </div>
  );
};

export default EditTrackedSymptomConditionFormContainer;
