import * as Yup from "yup";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormikRadioGroupField from "../../common/components/FormikRadioGroupField";
import FormikCheckboxGroupField from "../../common/components/FormikCheckboxGroupField";
import Layout from "../../common/components/layout";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import atob from 'atob';
import { getCampaign } from "./curd/campaign";
import { getQuestion } from "./curd/vendorQuestion";
import { createWorkflowQuestionResponse, listWorkflowQuestionResponseByUser } from "./curd/workflowQuestionResponse";
import FormikTextField from "../../common/components/FormikTextField";
import { createCouponScanned, getCouponScanned } from "./curd/couponScanned";
import { getCoupon } from "./curd/coupon";
import { checkUserExistByEmail, getSingleUser } from "./curd/vendors";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { createTempWorkflowQuestionResponse, listTempWorkflowById } from "./curd/tempQuestionResponse";
import btoa from "btoa";
import CampaignOptions from "../components/campaign";


const queryString = require('query-string');
const DATE_FORMAT = "DD/MM/YYYY";

const useStyles = makeStyles({
  root: {
    height: "100%",
    padding: "3rem",
  },
  child: {
    marginTop: "3rem",
  },
  inner: {
    padding: "auto 4rem",
  },
});
function CampaignQuestion(props) {
  const editable = props.editable;
  const isWorkflow = props.isWorkflow;
  const location = useLocation();
  const query = queryString.parse(location.search);

  const campaignId = query?.campaign;
  const userId = query?.user;
  const isBitly = query?.isBitly;
  const couponId = query?.coupon;
  const matches = useMediaQuery("(max-width:500px)");
  const classes = useStyles();
  const history = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [bitlyCoupon, setbitlyCoupon] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [endQuestionsResponse, setEndQuestionsResponse] = useState(false);
  const [upToDate, setUpToDate] = useState(true);
  const [expired, setExpired] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [submittedQuestion, setSubmittedQuestion] = useState([]);
  const [lastSubmitted, setLastSubmitted] = useState({});
  const [user, setUser] = useState(null);
  const [coupon, setCoupon] = useState([]);
  const [email, setemail] = useState('')
  const [emailset, setemailset] = useState(false)
  const [uploadedImg, setUploadedImg] = useState(null)
  
  const checkEmail = localStorage.getItem('user_email')
  const WITH_RANGE = Yup.object().shape({
    Range: Yup.number().max(currentQuestion?.maxRange).min(currentQuestion?.minRange).required('Please enter range')
  });
  const WITHOUT_RANGE = Yup.object().shape({
    questionResponse: Yup.mixed().when('fileuploadImg',{
      is:false,
      then: Yup.mixed().required('Please enter or select the input')
    }),
    fileuploadImg: Yup.mixed()
  });
  const validationSchema = currentQuestion?.type === 'RANGE' ? WITH_RANGE : WITHOUT_RANGE
  
  useEffect(() => {
    if (checkEmail) {
      setemailset(true) 
    }
    if(user){
      setemailset(true)
    }
  }, [endQuestionsResponse])

  useEffect(() => {
    if (endQuestionsResponse) {
      setAccepted(true);
    }
  }, [endQuestionsResponse])

  const checkCoupon = async () => {
    if (campaign?.coupons) {
      let rewards = campaign?.coupons ? JSON.parse(campaign?.coupons) : [];
      let coupons = [];
      rewards.map(async (item) => {
        let couponData = await getCoupon(item);
        coupons.push(couponData);
      })
      setCoupon(coupons);
    }
  }

  useEffect(() => {
    checkCoupon();
  }, [campaign?.coupons])

  useEffect(() => {
    const checkBilty = async () => {
      if (!editable) {

        if (campaign?.triggerID) {
          if (campaign?.isActive === false || (isBitly && campaign?.triggerID !== '3')) {
            setAccepted(true);
            setExpired(true);
            setEndQuestionsResponse(true);
            setUpToDate(false);
          }
        }
      }
    }
    checkBilty();
  }, [campaign?.triggerID])

  useEffect(() => {
    const getCouponData = async () => {
      if (campaignId) {
        let decoded = atob(campaignId)
        let data = await getCampaign(decoded)
        let BitlyCouponIs = JSON.parse(data.coupons)
        setbitlyCoupon(btoa(BitlyCouponIs[0]))
        setCampaign(data);
      }
    }
    getCouponData();
  }, [campaignId])

  useEffect(() => {
    const setCouponData = async () => {
      if (editable) {
        if (isWorkflow) {

          let decoded = JSON.parse(editable?.questions);
          setQuestions(decoded);
          setCampaign(null);
          setAccepted(true);
        } else {
          setCampaign(editable);

        }
      }
    }
    setCouponData();
  }, [editable])




  useEffect(() => {
    const getQuestionData = async () => {
      if (campaign?.workflow?.questions) {
        let decoded = JSON.parse(campaign.workflow.questions);
        setQuestions(decoded);
        let userIds = '';
        if (!editable) {

          if (userId) {
            let decodedId = atob(userId)
            let data = await getSingleUser(decodedId)
            setUser(data);
            userIds = data.id;
          } else {
            setUser(props.user);
            userIds = props.user?.id;
          }
          let submitted = []

          if (user) {
            submitted = await listWorkflowQuestionResponseByUser(
              userIds,
              campaign?.id,
              campaign?.workflowID
            )
          } else {
            let getLocalIds = localStorage.getItem('temp_ques_id')

            if (getLocalIds) {
              submitted = await listTempWorkflowById(JSON.parse(getLocalIds), campaign?.id, campaign?.workflowID)
            }
          }

          if (submitted.length >= decoded.length) {
            setEndQuestionsResponse(true);
            if (submitted.length > 0) {
              setLastSubmitted(submitted[submitted.length - 1]);
            }
            setUpToDate(true);
            setExpired(false);
          }
          let quessubmitted = submitted.map(item => item.questionID);
          let sendSubmittedques = user ? quessubmitted : submitted
          setSubmittedQuestion(sendSubmittedques);
        }

      }
    }
    getQuestionData();
  }, [campaign?.workflow?.questions])

  useEffect(() => {
    const getCouponData = async () => {

      if (questions.length > 0) {

        setIsQuestionLoading(true);
        let question = questions[currentIndex]
        if (submittedQuestion.includes(question)) {
          setCurrentIndex((i) => {
            if (i < (questions.length - 1)) return ++i;
            return i;
          });
          return;
        }

        let data = await getQuestion(question)
        setCurrentQuestion(data);
        let option = data?.options ? JSON.parse(data?.options) : [];

        let mapped = option.map(item => {
          return { label: item, value: item }
        })
        setOptions(mapped);
        setIsLoading(false);
        setUpToDate(false)
        setIsQuestionLoading(false);

      }
    }
    getCouponData();
  }, [currentIndex, questions, submittedQuestion])

  const clickNext = (e) => {

    setCurrentIndex((i) => {
      if (i < questions.length - 1) return ++i;

      return i;
    });
    setIsQuestionLoading(true);
    if (currentIndex === (questions.length - 1)) {
      setExpired(false);
      setEndQuestionsResponse(true);
    }
  }

  const Redirection = async() => {
      if(user){
         window.location.replace(`/dashboard?coupon=${isBitly ? bitlyCoupon : couponId}`)
      }else{
        const IsUserExist = await checkUserExistByEmail(email) 
        if(IsUserExist){
          window.location.replace(`${process.env.REACT_APP_SERVER_URL}/signIn`);
        }else{
          window.location.replace(`${process.env.REACT_APP_SERVER_URL}/signUp`);
        }
      }  
  }

  const updateLocalStorage = async (id) => {
    let getresId = localStorage.getItem('temp_ques_id')
    if (!getresId) {
      localStorage.setItem('temp_ques_id', JSON.stringify([id]));
    } else {
      let parsedata = JSON.parse(getresId)
      parsedata.push(id)
      localStorage.setItem('temp_ques_id', JSON.stringify(parsedata));
    }
  }


  const handleSubmit = () => {
    localStorage.setItem('user_email', email)
    setemailset(true)
  }

  useEffect(()=> {
    const checkCouponExist = async() => {
      let rewards = campaign?.coupons ? JSON.parse(campaign?.coupons) : [];
      let existsarr = []
      let isCouponexist = await Promise.all(rewards.map(async(item) => {
        let exists = await getCouponScanned(user?.id, item)
        existsarr.push(...exists)
      }))

      if(existsarr.length == coupon.length && existsarr.length>0){
        history.push('/dashboard?couponexist=true')
      }
    }

    checkCouponExist()

  }, [user?.id])
  return (
    <>
      <div className={matches ? "" : classes.root}>
        {isLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          </>
        ) : (
          <>
            {accepted && (

              <>
                {!endQuestionsResponse && !upToDate ? (
                  <div className="modalinnerbox">
                    <Typography
                      className="text-center"
                      variant="h1"
                      color="primary"
                    >
                      Questions
                    </Typography>
                    <hr className="mb-2" />
                    {!isQuestionLoading ? (
                      currentQuestion ? (
                        <div>
                          <Typography className="text-center que-heading" variant="h1">
                            #{currentIndex + 1}. {" "}
                            {currentQuestion.question ?? currentQuestion.question}?
                            {currentQuestion.type === 'RANGE' && ` (${currentQuestion.minRange} - ${currentQuestion.maxRange})`}
                          </Typography>

                          <Formik
                            initialValues={{ questionResponse: "" }}
                            validationSchema={validationSchema}
                            onSubmit={async (values, formik) => {
                              try {
                                if (user) {
                                  let userId = user?.id || null;
                                  let newdata = {
                                    campaignID: campaign?.id,
                                    workflowID: campaign?.workflowID,
                                    questionID: currentQuestion.id,
                                    userID: userId,
                                    answer: uploadedImg ?? values.questionResponse ?? values.Range 
                                  }
                                  let data = await createWorkflowQuestionResponse(newdata);
                                  
                                } else {
                                  let newdata = {
                                    campaignID: campaign?.id,
                                    workflowID: campaign?.workflowID,
                                    questionID: currentQuestion.id,
                                    answer: uploadedImg ?? values.questionResponse ?? values.Range
                                  }
                            
                                  let data = await createTempWorkflowQuestionResponse(newdata)
                                  
                                  await updateLocalStorage(data.data.createTempSurveyRepsonse.id)
                                }
                                
                                formik.resetForm();
                                setUploadedImg(null)
                                setCurrentIndex((i) => {
                                  if (i < questions.length - 1) return ++i;

                                  return i;
                                });
                                setIsQuestionLoading(true);
                                if (currentIndex === (questions.length - 1)) {
                                  setExpired(false);
                                  //reward
                                  let rewards
                                  let CouponId = couponId && atob(couponId)
                                  if(CouponId){
                                    rewards = [CouponId]
                                  }else{
                                    rewards = campaign?.coupons ? JSON.parse(campaign?.coupons) : []
                                  }
                                  // 
                                  console.log("Rewards", rewards)
                                  await Promise.all(
                                    rewards.map(async (item) => {
                                      let couponData = await getCoupon(item);
                                      console.log("Testing 1", couponData)
                                      if (couponData && user) {
                                        let userId = user?.id || null;
                                        let existing = await getCouponScanned(userId, item);
                                        console.log("What existing", existing)
                                        if (existing?.length === 0) {
                                          let input = {
                                            couponID: item,
                                            userID: userId,
                                            couponCreatedBy: couponData.createdBy,
                                          }
                                          let { data } = await createCouponScanned(input);
                                        }
                                      }
                                    })

                                  )
                                  setEndQuestionsResponse(true);

                                }

                              } catch (error) {
                                console.warn(error);
                              }
                            }}
                          >
                            {(formik, values) => {
                              return (
                                <Form >
                                  <Layout>
                                    <FormGroup className="d-flex align-items-center align-items-sm-start">
                                      <CampaignOptions 
                                        currentQuestion={currentQuestion} 
                                        options={options}
                                        setUploadedImg={setUploadedImg} 
                                        formik={formik}
                                        values={values}
                                        uploadedImg={uploadedImg}
                                      />
                                    </FormGroup>
                                    {editable ? (
                                      <>
                                        {(isWorkflow && currentIndex === (questions.length - 1)) ? (
                                          <></>
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center btn-box">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              color="primary"
                                              onClick={clickNext}
                                            >
                                              Next
                                            </Button>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center btn-box">
                                        <Button
                                          type="reset"
                                          className="mr-2"
                                          variant="contained"
                                          color="secondary"
                                        >
                                          Reset
                                        </Button>
                                        <Button
                                          type="submit"
                                          variant="contained"
                                          color="primary"
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    )}
                                  </Layout>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      ) : questions.length > 0 ? (
                        <UpToDateMessage expired={expired} />
                      ) : (
                        <NoQuestions />
                      )
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {upToDate && !expired && (
                      <UpToDateMessage
                        expired={expired}
                        upToDate={upToDate}
                        updatedAt={lastSubmitted?.updatedAt}
                      />
                    )}
                    {!upToDate && !expired && !emailset && (
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                          Please Enter Your email
                        </h4>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            id="outlined-basic"
                            label="Enter Your email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            variant="outlined" />
                        </FormControl>
                        <Button
                          className="mt-2"
                          variant="contained"
                          color="primary" onClick={() => handleSubmit()}>Submit</Button>
                      </div>
                    )}
                    {!upToDate && !expired && emailset && Redirection()}
                    {expired && <UpToDateMessage expired={expired} />}
                  </>
                )}
              </>
            )}
            {!accepted && (
              <>
                <IntroMessage
                  count={questions.length}
                  setAccepted={setAccepted}
                  coupon={coupon}
                  campaign={campaign}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CampaignQuestion;

function NoQuestions() {
  return (
    <Card className="mainmodal">
      <CardContent className="d-flex justify-content-center flex-column align-items-center">
        <Typography variant="h4">
          There are no questions in this campaign.
        </Typography>
        <div className="d-flex justify-content-center w-50 mt-5">
          <Button component={Link} to="/dashboard" variant="contained" color="primary">
            Home
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

function UpToDateMessage({ expired, upToDate, updatedAt }) {
  return (
    <Card className="mainmodal">
      <CardContent className="d-flex justify-content-center flex-column align-items-center">
        <Typography align="center" variant="h1" color="inherit">
          <ThumbUpIcon style={{ color: '#3d4977', fontSize: '64px' }} />
        </Typography>
        <Typography variant="h4" className="text-center">
          {expired ? "Sorry!" : "Thank You!"}
        </Typography>
        <Typography variant="h5" className="text-center mt-2">
          This campaign is no more live, the link has been expired.
        </Typography>
        <Typography className="my-2 mb-3">
          {upToDate && updatedAt
            ? `(Last updated on: ${moment(updatedAt).format(DATE_FORMAT)})`
            : ""}
        </Typography>
        <Button component={Link} to="/dashboard" variant="contained" color="primary">
          Home
        </Button>
      </CardContent>
    </Card>
  );
}

function ThanksMessage({ editable, user, history }) {
  if (!user) {
    history.push('/dashboard')
  }
  return (
    <Card className="mainmodal">
      <CardContent className="d-flex justify-content-center flex-column align-items-center">
        {" "}
        <Typography align="center" variant="h1" color="inherit">
          <ThumbUpIcon style={{ color: '#3d4977', fontSize: '64px' }} />
        </Typography>
        <Typography variant="h4" className="text-center mt-3 mb-3">
          Thank you for submitting the response. reward given!.
        </Typography>
        {editable ? (
          <Button type="button" variant="contained" color="primary">
            Home
          </Button>
        ) : (
          <Button component={Link} to={"/dashboard"} variant="contained" color="primary">
            Home
          </Button>
        )}
      </CardContent>

    </Card>
  );
}

function IntroMessage({ count, setAccepted, coupon, campaign }) {
  return (
    <Card className="mainmodal">
      <CardContent className="d-flex justify-content-center flex-column align-items-center">
        {" "}
        <Typography variant="div" className="mt-3 mb-3">
          <div dangerouslySetInnerHTML={{ __html: campaign?.introScreen }} />
        </Typography>
        <Button onClick={(e) => setAccepted(true)} variant="contained" color="primary">
          Ok
        </Button>
      </CardContent>
    </Card>
  );
}
