import "./assets/base.scss";

import * as serviceWorker from "./serviceWorker";
import Amplify, { Analytics, Auth } from "aws-amplify";
import { default as AppSyncConfig, default as awsconfig } from "./aws-exports-to-use";
import React, { Fragment, Suspense } from "react";
import { UserProvider, useUser } from "./providers/UserProvider";
import {
  faAddressCard,
  faBell,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faClock,
  faCommentDots,
  faComments,
  faDotCircle,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGem,
  faImages,
  faKeyboard,
  faLifeRing,
  faLightbulb,
  faMap,
  faObjectGroup,
  faQuestionCircle,
  faSquare,
  faThumbsUp,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle,
  far
} from "@fortawesome/free-regular-svg-icons";
import {
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAltH,
  faAward,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBirthdayCake,
  faBomb,
  faBusAlt,
  faCameraRetro,
  faCarBattery,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCubes,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExternalLinkAlt,
  faEyeDropper,
  faFilm,
  faHeart,
  faInfoCircle,
  faLemon,
  faLink,
  faMapMarkedAlt,
  faNetworkWired,
  faPager,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuoteRight,
  faReply,
  faSave,
  faSearch,
  faShapes,
  faShareSquare,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSmile,
  faStar,
  faStarHalfAlt,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faUnlockAlt,
  faUpload,
  fas
} from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faPinterest,
  faReact,
  faSlack,
  faTwitter,
  faVuejs,
  faYoutube,
  fab
} from "@fortawesome/free-brands-svg-icons";

import AWSAppSyncClient from "aws-appsync";
import { AnimatePresence } from "framer-motion";
import { ApolloProvider } from "react-apollo";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiTheme from "./theme";
import { Provider } from "react-redux";
import { Rehydrated } from "aws-appsync-react";
import { ThemeProvider } from "@material-ui/styles";
import configureStore from "./config/configureStore";
import { library } from "@fortawesome/fontawesome-svg-core";
import { render } from "react-dom";

library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);
// required code after social cli setup
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = AppSyncConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = AppSyncConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...AppSyncConfig,
  oauth: {
    ...AppSyncConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}



Amplify.configure({ ...updatedAwsConfig });
Auth.configure(updatedAwsConfig);
Analytics.disable();

const client = new AWSAppSyncClient({
  url: AppSyncConfig.aws_appsync_graphqlEndpoint,
  region: AppSyncConfig.aws_appsync_region,
  auth: {
    type: AppSyncConfig.aws_appsync_authenticationType,
    apiKey: AppSyncConfig.aws_appsync_apiKey,
    //  credentials: () => Auth.currentCredentials() ,
    jwtToken: async () => useUser // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  }
  // offlineConfig: {
  //   keyPrefix: 'private'
  // }
  // auth: {
  //   type: AppSyncConfig.aws_appsync_authenticationType,
  //   apiKey: AppSyncConfig.aws_appsync_apiKey,
  //   jwtToken: async () => useUser, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // },
});

const store = configureStore();

const SuspenseLoading = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={"#5383ff"} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait while we load the live preview examples
          <span className="font-size-lg d-block text-dark">
            This live preview instance can be slower than a real production
            build!
          </span>
        </div>
      </div>
    </Fragment>
  );
};

render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <UserProvider>
        <ApolloProvider client={client}>
          <Rehydrated>
            <CssBaseline />
            {/* <ScrollToTop> */}
            <ThemeProvider theme={MuiTheme}>
              <AnimatePresence>
                <Suspense fallback={<SuspenseLoading />}>
                  <App />
                </Suspense>
              </AnimatePresence>
            </ThemeProvider>
            {/* </ScrollToTop> */}
          </Rehydrated>
        </ApolloProvider>
      </UserProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
