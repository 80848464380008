import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import { API, Auth, graphqlOperation, Hub } from "aws-amplify";

import React, { useEffect, useState } from "react";
import awsconfig from "../aws-exports";
import { useHistory } from "react-router-dom";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
// required code after social cli setup
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsconfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsconfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}
// Auth.configure(updatedAwsConfig);

Auth.configure(updatedAwsConfig);
// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
export const UserContext = React.createContext(null);
const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-2' });
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
const userPoolId = awsconfig.aws_user_pools_id;
// Create a "controller" component that will calculate all the data that we need to give to our
// components below via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        // case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => {setSocialUser(userData)});
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    // getUser().then(userData => setSocialUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  const setSocialUser = async(user) => {
    try {
      setLoading(true);
      let err = {};
      if (user) {
        let userAttributes = { ...user.attributes };
        let userData = await getUserData(userAttributes.sub);
        let identities = userAttributes?.identities ? JSON.parse(userAttributes?.identities) : [];
        let provider = '';
        if(identities.length > 0){
          provider = identities[0].providerType;
        }
        if(userData){

          if (userData?.isDeleted) {
            err.message = "Your account is deleted";
            throw err;
          }
          if (userData?.isAccountActive === false || userData?.isAccountActive === '') {
            err.message = "Your account does not activate";
            throw err;
          }
          userAttributes = { ...userAttributes, ...userData };

  
          if(userAttributes?.providerType == provider){
            localStorage.setItem('doximple_user', JSON.stringify(userAttributes));
            localStorage.setItem('password_user', JSON.stringify(provider));
            setUser(userAttributes);

          }else{

            let data = {
              isProvider: provider ? true: false,
              providerType: provider,
              isPasswordSet: true,
            }
  
            userAttributes = { ...userAttributes, ...data };
            await handleUpdateSubmit(userAttributes, provider);

          }
        } else {

          let data = {
            isProvider: provider ? true: false,
            providerType: provider,
            accountType: "User",
            isPasswordSet: true,
            isDeleted: false,
            isAccountActive: true
          }
          userAttributes = { ...userAttributes, ...data };

          await handleCreateSubmit(userAttributes, provider);

        }

      }
      setLoading(false);
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        err.message = "Invalid username or password";
      }
      setLoading(false);
      throw err;
    }
  }
  
  const history = useHistory();
  useEffect(() => {
    async function onLoad() {
      // attempt to fetch the info of the user that was already logged in
      try {
        setUser(() => {
          let userData = localStorage.getItem('doximple_user');
          if (userData) return JSON.parse(userData);
          return null;
          //   const user = await Auth.currentAuthenticatedUser();
          // if (user) {
          //   let userAttributes = { ...user.attributes };
          //   const userData = await getUserData(user.attributes.sub);
          //   if (userData) {
          //     userAttributes = { ...userAttributes, ...userData };
          //   }
          //   return userAttributes;
          // }
        })
      } catch (err) {
        setUser(null);
      }
    }
    onLoad();
  }, []);

  // This method is used to get user data
  const getUserData = async id => {
    const userData = await API.graphql(
      graphqlOperation(queries.getUsers, { id: id })
    );
    if (userData && userData.data && userData.data.getUsers) {
      return userData.data.getUsers;
    }
    return null;
  };


  const signIn = async (username, password) => {
    try {
      let err = {};
      let user = await Auth.signIn({ username, password });
      if (user) {
        let userAttributes = { ...user.attributes };
        let userData = await getUserData(userAttributes.sub);
        if (userData?.isDeleted) {
          err.message = "Your account is deleted";
          throw err;
        }
        if (userData?.isAccountActive === false || userData?.isAccountActive === '') {
          err.message = "Your account does not activate";
          throw err;
        }
        if (userData) {
          userAttributes = { ...userAttributes, ...userData };
        }
        localStorage.setItem('doximple_user', JSON.stringify(userAttributes));
        localStorage.setItem('password_user', JSON.stringify(password));
        setUser(userAttributes);
      }
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        err.message = "Invalid username or password";
      }
      throw err;
    }
  };

  const signInGoogle = async (username, password) => {
    try {
      let err = {};
      let user = await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        err.message = "Invalid username or password";
      }
      throw err;
    }
  };

  const signInAmazon = async (username, password) => {
    try {
      let err = {};
      let user = await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Amazon });
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        err.message = "Invalid username or password";
      }
      throw err;
    }
  };

  const handleUpdateSubmit = async function (values, provider = null) {
    try {

      let id = values?.sub;
      if(!id) {
        id = values?.id;
      }
      delete values?.sub;
      delete values?.identities;
      delete values?.email_verified;
      delete values?.owner;
      if (id && values) {
        let data = await API.graphql(graphqlOperation(mutations.updateUsers, { input: { id, ...values } }));
        let userDetail = data?.data?.updateUsers || {};
        let updateDetail = {...values, ...userDetail}
        localStorage.setItem('doximple_user', JSON.stringify(updateDetail));
        localStorage.setItem('password_user', JSON.stringify(provider));
        setUser(updateDetail);
      }
    } catch (error) {
      console.log('~ error', error)
    }
  };

  async function handleCreateSubmit(values, provider = null) {
    console.log("The values", values)
    try {
      let id = values?.sub;
      if(!id) {
        id = values?.id;
      }
      delete values?.sub;
      delete values?.identities;
      delete values?.email_verified;
      delete values?.owner;
      let { data } = await API.graphql(
        graphqlOperation(mutations.createUsers, {
          input: {
            id: id,
            ...values
          }
        })
        );
      await API.graphql(
        graphqlOperation(mutations.createPreference, {
          input: {
            user: data.createUsers.id,
            frequency: "Daily",
            questions: "1",
            sendedNotifications: 0,
            notification_on: {
              email: false,
              sms: false,
              app: true
            },
            remind_me: "Once per day"
          }
        })
      );


      let userDetail = data.createUsers || {};
      let updateDetail = {...values, ...userDetail}
      localStorage.setItem('doximple_user', JSON.stringify(updateDetail));
      localStorage.setItem('password_user', JSON.stringify(provider));
      setUser(updateDetail);
      // localStorage.setItem('doximple_user', JSON.stringify(values));
      // localStorage.setItem('password_user', JSON.stringify(provider));

      // setUser(values);
      
    } catch (err) {
      let message = "Unknown error has occured";
      let links = [];
      if (err && err.message) {
        message = err.message;
      }
      if (err && err.code === "UsernameExistsException") {
        links = [
          { name: "Login", route: "/signIn" },
          { name: "Reset Password", route: "/resetPassword" }
        ];
      }
    }
  }

 
  const signOut = () => {
    localStorage.removeItem('doximple_user');
    setUser(null);
    Auth.signOut().then(() => {
      history.push('/')
    });
  };

  const updateUser = (newAttributes) => {
    
    if(newAttributes){

      let userAttributes = localStorage.getItem('doximple_user')?JSON.parse(localStorage.getItem('doximple_user')):{};
  
      userAttributes.firstName = newAttributes.firstName;
      userAttributes.lastName = newAttributes.lastName;
      userAttributes.mobile = newAttributes.mobile;
      
      localStorage.setItem('doximple_user', JSON.stringify(userAttributes));
      setUser(userAttributes);
    }
  };

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  // const login = (usernameOrEmail, password) =>
  //   Auth.signIn(usernameOrEmail, password)
  //     .then(cognitoUser => {
  //       setUser(cognitoUser);
  //       return cognitoUser;
  //     })
  //     .catch((err) => {
  //       if (err.code === 'UserNotFoundException') {
  //         err.message = 'Invalid username or password';
  //       }
  //       throw err;
  //     });

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.

  const values = React.useMemo(() => ({ loading, user, updateUser, signOut, signIn, signInGoogle, signInAmazon }), [user, loading]);

  // Finally, return the interface that we want to expose to our other components
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      "`useUser` hook must be used within a `UserProvider` component"
    );
  }
  return context;
};


// delete users by admin

export const userdelete = async (sub) => {
  let apiName = 'AdminQueries';
  let path = '/disableUser';
  let myInit = {
    queryStringParameters: {
      "username": sub,
    },
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  let data = await API.post(apiName, path, myInit)
  return data;
}


export const getUser = async (sub) => {
  let apiName = 'AdminQueries';
  let path = '/getUser';
  let myInit = {
    queryStringParameters: {
      "username": sub,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  let data = await API.get(apiName, path, myInit)
  return data;
}
