import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    FormGroup,
    Button,
    Grid,
    CircularProgress
} from '@material-ui/core';
import { useHistory, } from 'react-router'
import * as Yup from 'yup';
import CreateProduct from '../../components/product/create-product';
import { ArrowBackOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { listVendors } from '../curd/vendors';
import BarcodeScanner from '../../../common/services/BarcodeScanner';
import DoxSnackBar from '../../../common/components/DoxSnackBar';
import Multiselect from '../../components/product/Multiselect';
import useStyles from './style/styles'
import CustomModal from '../../components/CustomModal';
import FormikTextField from '../../../common/components/FormikTextField';
import { Form, Formik } from 'formik';
import FormikTextarea from '../../../common/components/FormikTextarea';
import { createMasterProduct, listMasterProducts, listUniqueMasterProduct, listUniqueVendorProduct } from '../curd/MasterProduct';
import ProductTableList from '../../components/ProductTableList';
import { createVendorProduct } from '../curd/vendorProducts';

const validationSchema = Yup.object({
    title: Yup.string().required("Title is required."),
    category: Yup.string().required("Category is required."),
    brand: Yup.string().required("Brand is required."),
    price: Yup.number().typeError("Price must be an integer value.").required("Price is required."),
    description: Yup.string().required("Description is required."),
    Manufacturer: Yup.string().required("Manufacturer name is required."),
})


function AddProducts(props) {
    const [vendorList, setVendorList] = useState([])
    const classes = useStyles()
    const history = useHistory()
    const [selectedOption, setselectedOption] = useState([])
    const [loading, setloading] = useState(false)
    const [open, setopen] = useState(false)
    const [allproducts, setallproducts] = useState([])
    const [tableData, setTableData] = useState([])
    const [snackBarDetails, setSnackBarDetails] = useState({
        message: null,
        links: [],
        severity: "error",
    });
    const [initialValues, setInitialValues] = useState({
        title: '',
        model: '',
        category: '',
        Manufacturer: '',
        brand: '',
        format: '',
        multipack: '',
        price: '',
        description: '',
    });
    useEffect(() => {
        getVendorLists();
        getProductList()
    }, [])
    const getVendorLists = async () => {
        let data = await listVendors(props.user.id, props.user.accountType);
        setVendorList(data)
    }

    const getProductList = async () => {
        let data = await listUniqueMasterProduct(props.user.id)
        setallproducts(data)
    }

    const submitVendorproduct = async() => {
        setloading(true)
        const getStructureData = tableData.map((item) => {
            delete item.createdBy
            delete item.id
            const rate = item.price
            return {
                vendorID:props.user.id, 
                rate,
                ...item
            }
        })
        try {
            const dataCreated = await createVendorProduct(getStructureData)
            if(dataCreated){
                setSnackBarDetails({
                    message:
                        `Product added successfully`,
                    severity: "success",
                    links: [],
                });
            }
            setTableData([])
            setloading(false)
            setselectedOption([])
            history.push(`/products`);
        } catch (error) {
            setloading(false)
            setSnackBarDetails({
                message:error.message,
                severity: "error",
                links: [],
            });
        }
    }


    return (
        <>
            <Box className={classes.container}>
                <Card>
                    <DoxSnackBar snackBarDetails={snackBarDetails} />
                    <CardHeader component={() => <div className="m-3 d-flex justify-content-between align-items-center">
                        <Link to='/products'><Typography color="secondary" className="m-0" variant="h4"><ArrowBackOutlined /> Add Product</Typography></Link>
                        <BarcodeScanner user={props.user} setopen={setopen} setInitialValues={setInitialValues} />

                    </div>} />
                    <CardContent>
                        <Multiselect openModal={setopen} options={allproducts} setselectedOption={setselectedOption} selectedOption={selectedOption} />
                        {/* <CreateProduct user={props.user} location={props.location} vendorList={vendorList} initialValues={initialValues} setSnackBarDetails={setSnackBarDetails} /> */}
                        <ProductTableList selectItem={selectedOption} allproducts={allproducts} tableData={tableData} setTableData={setTableData} user={props.user} />
                    </CardContent>
                    <CustomModal fullWidth open={open} setOpen={setopen} title="Add Product">
                        <Formik
                            initialValues={initialValues}
                            validate={values => {
                                const errors = {};
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                                setloading(true)
                                try {
                                    let { data } = await createMasterProduct({ ...values, createdBy: props.user.id });
                                    console.log("Data", data.createMasterProduct)
                                    setselectedOption(state => [...state, { label: data.createMasterProduct.title, value: data.createMasterProduct.id }])
                                    setallproducts(state => [...state, data.createMasterProduct])
                                    setloading(false)
                                    setSnackBarDetails({
                                        message:
                                            `Product ${"created"} successfully`,
                                        severity: "success",
                                        links: [],
                                    });
                                    setopen(false)                                 
                                } catch (error) {
                                    setloading(false)
                                    setSnackBarDetails({
                                        message:
                                            `${error.message}`,
                                        severity: "error",
                                        links: [],
                                    });
                                }
                                
                            }}
                        >
                            {({ values, errors, isValid, handleReset }) => (
                                <Form>
                                    <FormGroup>
                                        <FormikTextField fullWidth className="mb-2" name="title" label="Product title" />
                                    </FormGroup>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormGroup>
                                                <FormikTextField fullWidth className="mb-2" name="model" label="Modal" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormGroup>
                                                <FormikTextField fullWidth className="mb-2" name="category" label="Category" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormGroup>
                                                <FormikTextField fullWidth className="mb-2" name="Manufacturer" label="Manufacturer" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormGroup>
                                                <FormikTextField fullWidth className="mb-2" name="multipack" label="Multi Pack" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormGroup>
                                                <FormikTextField fullWidth className="mb-2" name="format" label="Format" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormGroup>
                                                <FormikTextField fullWidth type="number" className="mb-2" name="price" label="Price" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <FormGroup>
                                        <FormikTextField fullWidth className="mb-2" name="brand" label="Brand" />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormikTextarea fullWidth name="description" placeholder="Description" label="Description" />
                                    </FormGroup>
                                    {loading ? <CircularProgress /> : <Button
                                        type="submit"
                                        className="mt-4 mb-2"
                                        variant="contained"
                                        disabled={!isValid}
                                        color="primary">
                                        Submit
                                    </Button>}
                                </Form>
                            )}
                        </Formik>
                    </CustomModal>
                    {selectedOption.length > 0 && <div className='w-100'>
                        {loading ? <CircularProgress className="mb-2 mr-3 float-right" /> :<Button
                            type="submit"
                            className="mb-2 mr-3 w-25 float-right"
                            variant="contained"
                            color="primary"
                            onClick={()=>submitVendorproduct()}
                        >SAVE</Button>}
                    </div>}
                </Card>
            </Box>
        </>
    )
}

export default AddProducts
