import { questionsTable } from "./constants";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";

export const isNullOrEmpty = (json, key) => {
  if (json && key in json && json[key] != null) {
    if (typeof json[key] == "string") {
      if (!(json[key] === "")) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
};

export const getDbColumnName = questionId => {
  const data = questionsTable.find(
    questions => questions.questionId === questionId
  );

  return data?.dbColumnName || "";
};

export const getQuesId = columnName => {
  const data = questionsTable.find(
    questions => questions.dbColumnName === columnName
  );
  return data?.questionId || "";
};

export const deleteUnTrackedUserResponses = async (id) => {
  await API.graphql(
    graphqlOperation(mutations.deleteUserResponses, {
      input: {id}
    })
  )
}

export const deleteTrackedUserResponses = async (id) => {
  await API.graphql(
    graphqlOperation(mutations.deleteConditionsAndSymptoms, {
      input: {id}
    })
  )
}

export const removeDuplicateAnswereQuestion = (answeredQuestionsArray) => {
  const uniqueAnsweredQuestions = []; 
  answeredQuestionsArray.map((object, i) => {
      const uniqueIndex = uniqueAnsweredQuestions.findIndex(obj => object.userResponseRowId === obj.userResponseRowId && object.questionId === obj.questionId && object.processedQuestion === obj.processedQuestion && JSON.stringify(obj.answer) === JSON.stringify(object.answer))
      if (uniqueIndex === -1) {
        uniqueAnsweredQuestions.push(object)
      }
    }
  );
  return uniqueAnsweredQuestions
}
