import * as queries from "../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

// This function returns all tracked conditions and symptoms
export async function getAllUnTrackedConditionAndSymptomsByUser(
  userid,
  items = [],
  nextToken = null,
  filter = {}
) {
  // Get all the previous answered question
  let params = {
    filter: {
      userID: {
        eq: userid
      },
      ...filter
    },
    limit: 5000
  };
  if (nextToken) {
    params.nextToken = nextToken;
  }
  let response = await API.graphql(
    graphqlOperation(queries.listUserResponses, params)
  );
  if (response && response.data && response.data.listUserResponses) {
    if (
      response.data.listUserResponses.items &&
      response.data.listUserResponses.items.length > 0
    ) {
      items.push(...response.data.listUserResponses.items);
    }

    if (response.data?.listUserResponses?.nextToken) {
      await getAllUnTrackedConditionAndSymptomsByUser(
        userid,
        items,
        response.data?.listUserResponses?.nextToken,
        filter
      );
    }
  }
  return items;
}

// This function returns all tracked conditions and symptoms
export async function getAllTrackedConditionAndSymptomsByUser(
  userid,
  items = [],
  nextToken = null,
  filter = {}
) {
  // Get all the previous answered question
  let params = {
    filter: {
      userID: {
        eq: userid
      },
      ...filter
    },
    limit: 5000
  };
  if (nextToken) {
    params.nextToken = nextToken;
  }
  let response = await API.graphql(
    graphqlOperation(queries.listConditionsAndSymptoms, params)
  );

  if (response && response.data && response.data.listConditionsAndSymptoms) {
    if (
      response.data.listConditionsAndSymptoms.items &&
      response.data.listConditionsAndSymptoms.items.length > 0
    ) {
      items.push(...response.data.listConditionsAndSymptoms.items);
    }

    if (response.data?.listConditionsAndSymptoms?.nextToken) {
      await getAllTrackedConditionAndSymptomsByUser(
        userid,
        items,
        response.data?.listConditionsAndSymptoms?.nextToken,
        filter
      );
    }
  }
  return items;
}

// This function is to filter session by type
export function getLastUserSessionByType(usersessions, type = "condition") {}

export const getAllTrackedSymptomsByUser = async userId => {
  return getAllTrackedConditionAndSymptomsByUser(userId, [], null, {
    dox001: {
      notContains: "Yes"
    },
    dox003: {
      notContains: "Yes"
    }
  });
};

export async function getAllUnTrackedConditionsByUser(
  userid,
  items = [],
  nextToken = null
) {
  // Get all the previous answered question
  return await getAllUnTrackedConditionAndSymptomsByUser(
    userid,
    items,
    nextToken,
    {
      dox001: {
        contains: "Yes"
      },
      dox003: {
        contains: "Yes"
      }
    }
  );
}

export const getAllUnTrackedSymptomsByUser = async userId => {
  return getAllUnTrackedConditionAndSymptomsByUser(userId, [], null, {
    dox001: {
      notContains: "Yes"
    },
    dox003: {
      notContains: "Yes"
    }
  });
};
// This function returns all tracked conditions
export async function getAllTrackedConditionsByUser(
  userid,
  items = [],
  nextToken = null
) {
  // Get all the previous answered question
  return await getAllTrackedConditionAndSymptomsByUser(
    userid,
    items,
    nextToken,
    {
      dox001: {
        contains: "Yes"
      },
      dox003: {
        contains: "Yes"
      }
    }
  );
}

//return single condition
export async function getTrackedConditionsById(userid) {
  let response = await API.graphql(
    graphqlOperation(queries.getConditionsAndSymptoms, { id: userid })
  );
  if (response && response.data && response.data.getConditionsAndSymptoms) {
    return response.data.getConditionsAndSymptoms;
  }
  return {};
}
