import { Checkbox, FormControl, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import FormikCheckboxGroupField from '../../../../common/components/FormikCheckboxGroupField'
import FormikDatePicker from '../../../../common/components/FormikDatePicker'

const useStyles = makeStyles({
  container: {
    marginTop: '10px',
    paddingLeft:'20px'
  },
  rangecontainer: {
    display:'flex',
    gap:'30px',
  },
  checkboxcontainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center'
  }
})

const DateOption = (props) => {
  const {values, selectedOption} = props
  const classes = useStyles()
  const [isRangeSet, setIsRangeSet] = useState(null)

  const initialMinValue = values.minDate ?? selectedOption.minDate ?? new Date() 
  const initialMaxValue = values.maxDate ?? selectedOption.maxDate ?? new Date() 

  return (
    <div className={classes.container}>
      {/* This is for min date selection */}
      <FormikCheckboxGroupField
        name="rangeDate"
        margin="normal"
        options={[{ label: 'Min Date', value: 'min' }, { label: 'Max Date', value: 'max' }]}
        row={true}
        onChange={(value) => setIsRangeSet(value)}
        fullWidth
        multiple
        className={classes.checkboxcontainer}
      />

      <div className={classes.rangecontainer}>
        {isRangeSet?.includes('min') && <FormikDatePicker
          name="minDate"
          format="MM/dd/yyyy"
          label="Select min date"
          value={initialMinValue}
        />}
        {isRangeSet?.includes('max') && <FormikDatePicker
          name="maxDate"
          format="MM/dd/yyyy"
          label="Select max date"
          value={initialMaxValue}
        />}
      </div>
    </div>
  )
}

export default DateOption