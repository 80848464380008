import React, { useState } from 'react'
import moment from 'moment';
function withCron(ComposedComponent) {
  const Cron = (props) => {
    const [timer, setTimer] = useState(1000);

    const scheduleTime = async (frequency, updatedAt) => {
      let mills = toMills(frequency, moment(updatedAt).format('MM/DD/YYYY'));
      return getDays(mills);
    }

    const toMills = (frequency, updatedAt) => {
      let freq_days = frequency == 'Daily' ? 1 : frequency == "Weekly" ? 7 : 2;
      let set_date = dateAfterDays(updatedAt, freq_days);
      let mills = new Date(set_date.toDateString()).valueOf() - new Date(Date.now()).valueOf();
      return mills;
    }
    const scheduleTask = (task, daysTime) => {
      setTimer(daysTime);
      if (daysTime <= 0)
        task(true);
      else task(false);
    }

    const dateAfterDays = function (preDate, days) {
      let date = new Date(preDate);
      date.setDate(date.getDate() + days);
      return date;
    }

    const getDays = duration => duration / (1000 * 60 * 60 * 24);

    return (
      <ComposedComponent time={getDays(timer)} {...props} scheduleTask={scheduleTask} scheduleTime={scheduleTime} />
    )
  }
  return Cron;

}

export default withCron
