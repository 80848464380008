import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CropFree } from "@material-ui/icons";
import CustomModal from "../CustomModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import QrCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import btoa from "btoa";
import moment from "moment";
import DoxSnackBar from "../../../common/components/DoxSnackBar";
import EnhancedTableHead, {
  EnhancedTableToolbar,
  getComparator,
  stableSort,
} from "../../../common/components/Tables/EnhancedTableHead";
import { getCouponScanned } from "../../containers/curd/couponScanned";

const format = "MM/DD/YYYY";

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "discount", numeric: true, disablePadding: false, label: "Discount" },
  {
    id: "max_redemptions",
    numeric: true,
    disablePadding: false,
    label: "Max redemptions",
  },
  {
    id: "totalRedeemCount",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "Total Redeem Count",
  },
  { id: "from", numeric: true, disablePadding: false, label: "Valid from" },
  { id: "to", numeric: true, disablePadding: false, label: "Valid to" },
  {
    id: "assignProduct",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "Product",
  },
  {
    id: "qr1",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "QR",
  },
];

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  expired: {
    color: "red",
  },
}));

export default function UserListCoupons(props) {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showQr, setShowQr] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    links: [],
    severity: "error",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let coupons = await getCouponScanned(props.user.id);

      const getFilteredData = coupons.filter((item,i) => item.coupon !== null)
      setRows(getFilteredData);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleQrCode = (coupon) => {
    setShowQr(true);
    setQrCode(
      `${process.env.REACT_APP_SERVER_URL}/coupon-redeem?coupon=${btoa(
        coupon.id
      )}`
    );
  };
  const getDiscount = (row) => {
    return row?.amount_off ? "$" + row?.amount_off : row?.percent_off + "%";
  };

  const currentDate = new Date();

  const RedeemCode = ({ row, totalRedeem}) => {
    let startDate = Date.parse(
      moment(row?.coupon?.expiry?.from).format(format)
    );
    let endDate = Date.parse(moment(row?.coupon?.expiry?.to).format(format));
    let max_redemptions = row?.coupon?.max_redemptions;
    let total_Redeems = totalRedeem?.length || 0;
    let currentDates = Date.parse(currentDate);

    if (currentDates >= startDate && currentDates <= endDate) {
      if (total_Redeems >= max_redemptions) {
        return (
          <span className={classes.expired}>Max Redeem Limit Reached</span>
        );
      }
      return (
        <CropFree
          onClick={() => handleQrCode(row)}
          titleAccess="QR Code"
          color="primary"
        />
      );
    } else {
      return <span className={classes.expired}>Expired</span>;
    }
  };
  return (
    <>
      {!loading ? (
        <>
          <div className={classes.root}>
            <DoxSnackBar snackBarDetails={snackBarDetails} />
            <Paper className={classes.paper}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                user={props.user}
                setOpen={setOpen}
                handleDelete={null}
                handleEdit={null}
                loadingDelete={null}
                title={"Coupons"}
              />
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    user={props.user}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={null}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headCells={headCells}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .filter((item, index) => item.coupon !== null)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const UserRedeem = row?.couponRedeems?.items?.filter(
                          (item) => item.userID == props.user.id
                        );
                        console.log("UserRedeem", UserRedeem);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="normal"
                            >
                              {row?.coupon?.name}
                            </TableCell>
                            <TableCell align="right">
                              {getDiscount(row?.coupon)}
                            </TableCell>
                            <TableCell align="right">
                              {row?.coupon?.max_redemptions ?? "--"}
                            </TableCell>
                            <TableCell align="center">
                              <span>{UserRedeem?.length}</span>
                            </TableCell>
                            <TableCell align="right">
                              {moment(row?.coupon?.expiry?.from).format(format)}
                            </TableCell>
                            <TableCell align="right">
                              {moment(row?.coupon?.expiry?.to).format(format)}
                            </TableCell>
                            <TableCell align="center">
                              <span>{row?.coupon?.product?.title}</span>
                            </TableCell>
                            <TableCell align="right">
                              <RedeemCode row={row} totalRedeem={UserRedeem} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell
                          colSpan={headCells.length + 1}
                          align="center"
                        >
                          {rows.length === 0 && "No data found"}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </div>

          <CustomModal
            open={showQr}
            setOpen={(bool) => {
              setShowQr(bool);
              setQrCode("");
            }}
            title="QR Code"
          >
            <QrCode size={200} value={qrCode} />
          </CustomModal>
        </>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
