import * as Yup from "yup";
import * as mutations from '../../graphql/mutations';

import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import { Form, Formik } from "formik";
/* eslint-disable no-console */
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import FormikTextField from "../../common/components/FormikTextField";
import awsconfig from "../../aws-exports";
import { UserContext } from '../../providers/UserProvider';
import {  listVendorsByAccountType } from '../containers/curd/vendors';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);



class RedeemPin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      redeemCode: this.props.redeemCode ? this.props.redeemCode : "",
      snackBarDetails: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  static contextType = UserContext;

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async function (values) {
    try {
      let id = this.props?.sub;
      if(!id) {
        id = this.props.id;
      }
      let redeemCode = await this.random();
      if (redeemCode) {
        let data = await API.graphql(graphqlOperation(mutations.updateUsers, { input: { id, redeemCode } }));
        this.context.updateUser(data?.data?.updateUsers);
        this.setState({
          redeemCode,
          snackBarDetails: {
            message: "New Pin Updated",
            severity: "success",
            links: []
          }
        })
      }
    } catch (error) {
      this.setState({
        "snackBarDetails": {
          message: error.message,
          severity: "error",
          links: []
        }
      })
    }
  };
  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .max(50, 'less than or equal 50 character'),
  });

  
  random = async() => {

    let accountType = [];
    accountType = ['VendorAdmin', 'VendorAdminUser']
    
    let rand = 0;
    let exists = true;
    while (exists) {
        rand = Math.floor(1000 + Math.random() * 9000);
        let vendors = await listVendorsByAccountType(accountType, rand, this.state.companyName);
        if(vendors.length > 0){
            exists = true;
        }else{
            exists = false;
        }
    }
    return rand.toString();
  }

  render() {
    return (
      <>
        <Formik
          initialValues={this.state}
          enableReinitialize
          onSubmit={this.handleSubmit}
          // validationSchema={this.validationSchema}
          validateOnMount
        >
          {({ isValid, isSubmitting }) => (
            <Form className="SignIn w-100">

              {(this?.props?.accountType !== 'User' && this.props?.accountType !=='SuperAdmin' && this.props?.accountType !=='SuperAdminUser') &&
                <>
                
                <div className="mb-3">
                  <FormikTextField
                    disabled={true}
                    className="w-100"
                    variant="outlined"
                    type="text"
                    label="Your 4 digit pin to redeem coupon"
                    name="redeemCode"
                    value={this.state.redeemCode}
                    onChange={this.handleChange}
                    placeholder="Your 4 digit pin to redeem coupon"
                  />
                </div>
                </>
              }
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Regenerate New Pin</Button>
              </div>

            </Form>
          )}
        </Formik>
        <DoxSnackBar snackBarDetails={this.state.snackBarDetails}></DoxSnackBar>
      </>
    );
  }
}

export default RedeemPin;
