import { Field } from "formik";
import React from "react";
import { TextField } from "formik-material-ui";

const FormikTextFieldPasswordGenerator = ({ children, render, ...props }) => {
  return (
    <Field {...props} style={{ width: '75%' }} component={TextField} variant="outlined">
      {children}
    </Field>
  );
};

export default FormikTextFieldPasswordGenerator;
