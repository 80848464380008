import {Box} from "@material-ui/core";
import QuestionList from "../components/vendor/question-list";
import React from 'react'
const VendorQuestion = (props) => 
    <Box container>
        
        <QuestionList user={props.user}/>
       
    </Box>

export default VendorQuestion;

