import { Box, IconButton } from "@material-ui/core";
import clsx from "clsx";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import projectLogo from "../../assets/dox-logo.png";

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarToggle,
          "app-header-logo-open": sidebarHover
        })}
      >
        <Box className="header-logo-wrapper" title="Doximple">
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn"
            >
              <img
                className="app-header-logo-img"
                alt="Doximple"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">Doximple</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
