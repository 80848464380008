import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "formik-material-ui-pickers";
import { Field } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
const FormikDatePicker = ({ render, ...props }) => {
  const { value, ...rest } = props;

  return (
    <>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Field value={value} component={DatePicker} {...rest} />
    </MuiPickersUtilsProvider>
    </>
  );
};

export default FormikDatePicker;
