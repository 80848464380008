import * as Yup from "yup";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { Auth } from "aws-amplify";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import FormikTextField from "../../common/components/FormikTextField";
import Header from "../../common/components/header";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import awsconfig from "../../aws-exports";
import clsx from "clsx";
import svgImage9 from "../../assets/images/illustrations/login.svg";
import { useUser } from "../../providers/UserProvider";
import Divider from '@material-ui/core/Divider';
import GoogleLogin from '../../common/components/GoogleLogin'
import AmazonLogin from "../../common/components/AmazonLogin";

const useStyles = makeStyles({
  divider: {
    width: '46%'
  },
  ortext: {
    margin: '0px 10px',
    fontWeight: '600'
  },
  signInBtn:{
    maxWidth:'250px',
    width:'100%',
    height:'50px'
  }
});


const queryString = require('query-string');

const Recaptcha = require("react-recaptcha");

Auth.configure(awsconfig);
const ALLOWED_FAILED_ATTEMPTS = 3;
// create a variable to store the component instance
let recaptchaInstance;

const initialValues = {
  email: "",
  password: "",
  recaptcha: ""
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  password: Yup.string().required("Password is required")
});

const SignInContainer = () => {
  const { signIn, signInGoogle, signInAmazon, loading } = useUser();
  console.log("🚀 ~ SignInContainer ~ loading", loading)
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    severity: "error",
    links: []
  });
  const [failedAttempt, setFailedAttempt] = useState(0);
  const [captcha, setCaptcha] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [googleSubmitting, setGoogleSubmitting] = useState(false);
  const [amazonSubmitting, setAmazonSubmitting] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  console.log("🚀 ~ SignInContainer ~ socialLoading", socialLoading)
  const localemail = localStorage.getItem('user_email')
  const classes = useStyles();
  useEffect(() => {

    setSocialLoading(loading);

    return () => {
      console.log('~ component did unmount');
    }

  }, [loading])

  useEffect(()=>{
    if(localemail){
      initialValues.email = localemail
    }
  }, [])

  const handleAmazon = async (e) => {
    setSocialLoading(true);
    try {
      setAmazonSubmitting(true);
      await signInAmazon();
      setAmazonSubmitting(false);
    } catch (err) {
      setAmazonSubmitting(false);
      setFailedAttempt(failedAttempt + 1);
      setSocialLoading(false);
      let message = "Unknown error has occured";
      if (err && err.message) {
        message = err.message;
      }
      setSnackBarDetails({
        message,
        severity: "error",
        links: false
      });
    }
  }

  function validateCaptcha(value) {
    let error;
    if (failedAttempt >= ALLOWED_FAILED_ATTEMPTS && !value) {
      error = "Recaptcha is required";
    }
    return error;
  }

  return (
    <div className={clsx("app-wrapper vh-100")}>
      <Header isCollapsedLayout={true}></Header>
      <div className="app-main flex-column ">

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              await signIn(values.email, values.password);
              setSubmitting(false);
              history.push({
                pathname: '/dashboard',
                search: `?coupon=${query.coupon || ''}&campaign=${query.campaign || ''}&isLogin=true`
              });
            } catch (err) {
              setSubmitting(false);
              setFailedAttempt(failedAttempt + 1);
              let message = "Unknown error has occured";
              if (err && err.message) {
                message = err.message;
              }
              setSnackBarDetails({
                message,
                severity: "error",
                links: false
              });
            }
            //Reinit catcha if failed attempt
            if (failedAttempt >= ALLOWED_FAILED_ATTEMPTS) {
              recaptchaInstance.reset();
            }
          }}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid, isSubmitting, setFieldValue }) => (
            <Form className="SignIn">
              <div className="app-content app-content-sidebar-collapsed app-content-sidebar-fixed">
                <div className="app-content--inner d-flex align-items-center">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--content py-5">
                      <Container maxWidth="lg">
                        <Grid container spacing={5}>
                          <Grid
                            item
                            xs={12}
                            lg={5}
                            className="d-none d-xl-flex align-items-center"
                          >
                            <img
                              alt="..."
                              className="w-100 mx-auto d-block img-fluid"
                              src={svgImage9}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={7}
                            className="d-flex flex-column align-items-center mx-auto"
                          >
                            <span className="w-100 text-left text-md-center pb-4">
                              <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                Login to your account
                              </h1>
                            </span>
                            <Card className="m-0 w-100 p-0 border-0">
                              <CardContent className="p-3">
                                <div className="text-center text-black mb-3">
                                  <span>Sign in with credentials</span>
                                </div>
                                {socialLoading ? (

                                  <div className="d-flex justify-content-center align-items-center">
                                    <CircularProgress />
                                  </div>
                                ) : (
                                  <div className="px-sm-5">
                                    <div className="mb-3">
                                      <FormikTextField
                                        name="email"
                                        label="Email address"
                                        placeholder="Email"
                                        margin="normal"
                                        fullWidth
                                      >
                                        <InputAdornment position="start">
                                          <MailOutlineTwoToneIcon />
                                        </InputAdornment>
                                      </FormikTextField>
                                    </div>
                                    <div className="mb-3">
                                      <FormikTextField
                                        name="password"
                                        label="Password"
                                        placeholder="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        margin="normal"
                                        fullWidth
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(bool => !bool)}
                                                onMouseDown={(event) => event.preventDefault()}
                                              >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                          )

                                        }}
                                      >
                                        <InputAdornment position="start">
                                          <LockTwoToneIcon />
                                        </InputAdornment>
                                      </FormikTextField>
                                    </div>

                                    {failedAttempt >= ALLOWED_FAILED_ATTEMPTS && (
                                      <div className="d-flex justify-content-center">
                                        <Recaptcha
                                          className="mt-10"
                                          ref={e => (recaptchaInstance = e)}
                                          sitekey="6LfuQcMUAAAAAEQJ2zjBL718HF8qGy_KzDJTuW30"
                                          verifyCallback={response => {
                                            setFieldValue("recaptcha", response);
                                            setCaptcha(response);
                                          }}
                                          validate={validateCaptcha}
                                        />
                                      </div>
                                    )}
                                    <div className="text-center my-3">
                                      {isSubmitting ? <CircularProgress /> : <Button
                                        color="secondary"
                                        variant="contained"
                                        size="large"
                                        className={classes.signInBtn}
                                        disabled={
                                          !isValid ||
                                          isSubmitting
                                        }
                                        type="submit"
                                      >
                                        Sign in
                                      </Button>}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                      <Divider className={classes.divider} />
                                      <div className={classes.ortext}>OR</div>
                                      <Divider className={classes.divider} />
                                    </div>
                                    <div className="d-flex justify-content-center my-2 ml-4">
                                      <div className="text-center mr-2">
                                        {googleSubmitting
                                          ? (<CircularProgress />
                                          ) : (
                                            <GoogleLogin
                                              setGoogleSubmitting={setGoogleSubmitting}
                                              setFailedAttempt={setFailedAttempt}
                                              setSnackBarDetails={setSnackBarDetails}
                                              failedAttempt={failedAttempt}
                                            />
                                          )}
                                      </div>
                                      <div className="text-center ml-2">
                                        {amazonSubmitting
                                          ? (<CircularProgress />
                                          ) : (
                                            <AmazonLogin 
                                              setAmazonSubmitting={setAmazonSubmitting}
                                              setFailedAttempt={setFailedAttempt}
                                              setSnackBarDetails={setSnackBarDetails}
                                              failedAttempt={failedAttempt} 
                                              setSocialLoading={setSocialLoading}
                                            />
                                          )}
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                    <div className="text-center">
                                      Not a member?{" "}
                                      <Link className="text-secondary h6" to={query.coupon ? `/signUp?coupon=${query.coupon}` : '/signUp'}>Sign Up</Link>
                                    </div>
                                    <div className="text-center">
                                      <Link to="/resetPassword">
                                        Forgot Password?
                                      </Link>
                                    </div>
                                    </div>
                                  </div>
                                )}
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <DoxSnackBar snackBarDetails={snackBarDetails}></DoxSnackBar>
    </div>
  );
};

export default SignInContainer;
