import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createTempWorkflowQuestionResponse = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.createTempSurveyRepsonse, { input })
  );
}

export const listTempWorkflowById = async (localId, alldata) => {
  let quesId = await Promise.all(localId.map(async(item, i) => {
    let { data: { getTempSurveyRepsonse } } = await API.graphql(
      graphqlOperation(queries.getTempSurveyRepsonse, { id: item })
    );
    return alldata ? getTempSurveyRepsonse  : getTempSurveyRepsonse.questionID
  }))
 return quesId
}


export const deleteTempWorkflowById = async (listid) => {

  for(let i = 0; i < listid.length; i++){
    let {data:{getTempSurveyRepsonse}} = await API.graphql(
      graphqlOperation(mutations.deleteTempSurveyRepsonse, {input:{id:listid[i].id}})
    )
  }
  return true
}