import { Button, Card } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import EditSymptomConditionFormContainer from './condition-symptom-container.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../common/components/userExitConfirmationModal";
import { Plus } from "react-feather";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { deleteUnTrackedUserResponses } from "../../common/utils/common"
import { getAllUnTrackedSymptomsByUser } from "../../common/services/cond-and-symp.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const modalInitialValue = {
  open: false,
  no: true,
  yes: false,
  resId: '',
  resIndex: ''
}
const moment = require("moment");
const DATE_FORMAT = "MM-DD-YYYY";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > [a.orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
const headCells = [
  { id: "symptoms", numeric: false, disablePadding: true, label: "Symptoms" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created At"
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Last updated"
  },
  { id: "action", disableSort: true, numeric: true, disablePadding: false, label: "Action" }
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.id === "symptoms" ? "left" : "right"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="font-weight-bold"
          >
            {!headCell?.disableSort ? <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
              :
              <span> {headCell.label}</span>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const UntrackedSynptomList = props => {
  const classes = useStyles();
  const [summarySymptoms, setSummarySymptoms] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [deleteResponseModal, setDeleteResponseModal] = useState(modalInitialValue);
  const [isEditing, setIsEditing] = useState(false)
  const [editableResponse, setEditableResponse] = useState(null)
  const [allUnTrackedSymptoms, setAllUnTrackedSymptoms] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    loadTrackedSymptoms();
  }, [props?.user?.id, isEditing]);

  // get symptoms using userID
  const loadTrackedSymptoms = async () => {
    let response = await getAllUnTrackedSymptomsByUser(props.user.id);
    setAllUnTrackedSymptoms(response);
    let filteredItem = response.filter(item => {
      return (
        (item["dox001"] !== null && item["dox001"].includes("No")) ||
        (item["dox003"] !== null && item["dox003"].includes("No"))
      );
    });
    let result = filteredItem.map(item => {
      let dox015 = item["dox015"] ? JSON.parse(item["dox015"]) : [];
      let dox019 = item["dox019"] ? JSON.parse(item["dox019"]) : [];
      return {
        ...item,
        dox015,
        dox019,
        createdAt: new Date(item?.createdAt).getTime(),
        updatedAt: new Date(item?.updatedAt).getTime(),
        symptoms: [...dox015, ...dox019].join(", ")
      };
    });
    setIsLoading(false)
    setSummarySymptoms(result || []);
  };

  function handleEdit(index, resp) {
    setIsEditing(true)
    setEditableResponse(resp)
  }

  function handleDelete(index, resp) {
    setDeleteResponseModal({
      ...deleteResponseModal,
      open: true,
      resId: resp['id'],
      resIndex: index,
      title: `Please be sure, this action would permanently delete your current response.`,
      button1: `Delete`,
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, summarySymptoms.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderList() {
    return (
      <Fragment>
        {!isLoading ? (
          <TableBody>
            {summarySymptoms && summarySymptoms.length > 0 ? (
              stableSort(summarySymptoms, getComparator(order, orderBy)).map(
                (resp, index) => {
                  return (
                    <TableRow key={index} className="border">
                      <TableCell align="left">
                        {resp?.symptoms}
                      </TableCell>
                      <TableCell align="right">{moment(resp.createdAt).format(DATE_FORMAT)}</TableCell>
                      <TableCell align="right"> {moment(resp.updatedAt).format(DATE_FORMAT)}</TableCell>
                      <TableCell align="right">
                        <button className="text-primary cursor-pointer" style={{ border: 'none', background: 'none' }} onClick={() => handleEdit(index, resp)}>
                          <FontAwesomeIcon icon={["fas", "edit"]} />
                        </button>
                        &nbsp;|&nbsp;
                        <button className="text-danger cursor-pointer" style={{ border: 'none', background: 'none' }} onClick={() => handleDelete(index, resp)}>
                          <FontAwesomeIcon icon={["fas", "trash"]} />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            )
              : (<TableRow>
                <TableCell className="text-muted" colSpan="5" style={{ margin: 10, textAlign: "center" }}>
                  No Symptoms!
                </TableCell>
              </TableRow>)
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headCells.length+1} align="center">
                      {summarySymptoms.length === 0 && (
                        "No data found"
                      )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )
          : (
            <TableBody>
              <TableRow>
                <TableCell colSpan="5" style={{ margin: 10, textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )
        }
      </Fragment>
    );
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const history = useHistory();
  return (
    <>
      {isEditing ? <EditSymptomConditionFormContainer goBack={() => setIsEditing(false)} editableResponse={editableResponse} allUnTrackedSymptoms={allUnTrackedSymptoms} checkPlace={'onSymptoms'} />
        : <Fragment>
          <Card className="card-box mb-4">
            <div className="card-header">
              <div className="card-header--title d-flex justify-content-between align-items-center">
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Conditions list
                </h4>
                <Button variant="contained" color="primary" onClick={() => history.push('/addSymptom')}><Plus /> Add New</Button>
              </div>
            </div>
            <div className="card-body px-0 pt-2 pb-3">
              <TableContainer className="table table-hover table-borderless table-alternate text-nowrap mb-0">
                <Table>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  {renderList()}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={summarySymptoms.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <Modal
                settings={deleteResponseModal}
                close={async success => {
                  if (success === 'delete and proceed') {
                    await deleteUnTrackedUserResponses(deleteResponseModal['resId']).then(() => {
                      setSummarySymptoms(symptoms => symptoms.filter(({ id }) => id != deleteResponseModal['resId']))
                    })
                  }
                  setDeleteResponseModal({ ...deleteResponseModal, open: false });
                }}
              />
            </div>
          </Card>
        </Fragment>}
    </>
  );
};

export default UntrackedSynptomList;
