import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import Header from "../../common/components/header";
import awsconfig from "../../aws-exports";
import clsx from "clsx";
import svgImage9 from "../../assets/images/illustrations/login.svg";
import atob from 'atob';
import { getSingleCouponScanned } from '../../user/containers/curd/couponScanned';
import { getCouponRedeem } from '../../user/containers/curd/couponRedeem';
import { createCouponRedeem } from '../../user/containers/curd/couponRedeem';
import moment from 'moment';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import OtpInput from "../../common/components/OtpInput";
import { getSingleUser, listVendorsByAccountType } from "../../user/containers/curd/vendors";
import { listCampaignByTrigger } from "../../user/containers/curd/campaign";
import { listWorkflowQuestionResponseByUser } from "../../user/containers/curd/workflowQuestionResponse";
import btoa from 'btoa';
import { triggerType } from "../../common/utils/constants";
import { getCustomerCategory } from "../../user/containers/curd/customerCategory";
const queryString = require('query-string');


Auth.configure(awsconfig);
// create a variable to store the component instance

const initialValues = {
  email: "",
  
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
});

const CouponRedeemContainer = () => {
  const [coupon, setCoupon] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpVerifed, setOtpVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [redeemed, setRedeemed] = useState(false);
  const [maxRedeemed, setMaxRedeemed] = useState(false);
  const [expired, setExpired] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    severity: "error",
    links: []
  });

  useEffect(() => {
    const getCouponData = async () => {
      if (query.coupon) {
        setLoading(true);
        let coupon = atob(query.coupon)
        let { data: { getCouponScanned: couponData } } = await getSingleCouponScanned(coupon);
        setCoupon(couponData);
        if(!couponData){
          setLoading(false);
          setInvalid(true);
        }
      }
    }
    getCouponData();
  }, [query.coupon])


  const accountType = ['VendorAdmin', 'VendorAdminUser']

  const handleSubmit =async(e)=> {
    e.preventDefault();
    try{

        setSubmitted(true);
        if(otp.length < 4){
            setSnackBarDetails({
              message: "Enter 4 digit PIN",
              severity: "error",
              links: [],
            });
            setSubmitted(false);
            return;
        }
        let user = await getSingleUser(coupon.couponCreatedBy)
        let companyName = user?.companyName || '';
        let vendors = await listVendorsByAccountType(accountType, otp, companyName);
        if(!companyName || vendors.length === 0){
            setSubmitted(false);
            setSnackBarDetails({
                message: "Redeem code is not valid",
                severity: "error",
                links: [],
            });
            return;
        }
        setOtpVerified(true);
        redeemCoupon(coupon, vendors[0].id);
    }catch(e){
        setSubmitted(false);
    }
  }

  const handleChange =(e) => {
        setOtp(e)
    }

  
  const checkCampaign = async(trigger, user, createdBy) => {

    let campaigns = await listCampaignByTrigger(trigger, createdBy);
    if(campaigns.length > 0){
      for(let i = 0; i < campaigns.length; i++) {

        let questions = campaigns[i]?.workflow?.questions
        ? JSON.parse(campaigns[i]?.workflow?.questions)
        : []

        let users = campaigns[i]?.users ? JSON.parse(campaigns[i]?.users) : [];
        if(users.length === 0){
          let categoryId = campaigns[i]?.customerCategoryID;
          let category = await getCustomerCategory(categoryId);
          let relations = category?.customerCategoryRelations?.items || [];
              
          let uniqueRelations = relations.filter((value, index, self)=>{
              return self.findIndex(item => item.userID === value.userID) === index
          })
          users = uniqueRelations.map(item => item?.userID);
        }
        if(users.includes(user)){

          let responses = await listWorkflowQuestionResponseByUser(
            user, 
            campaigns[i]?.id,
            campaigns[i]?.workflowID
          )

          if(responses.length < questions.length) {
            history.push(`/campaign-questions?campaign=${btoa(campaigns[i].id)}&user=${btoa(user)}`);
            return;
          }
        }
      }
    }
  }
   
  const format = 'MM/DD/YYYY';
  const redeemCoupon = async(couponData, id) => {

    if(couponData){
        // moment(row?.coupon?.expiry?.to).format(format)
      let startDate = Date.parse(moment(couponData?.coupon?.expiry?.from).format(format))
      let endDate = Date.parse(moment(couponData?.coupon?.expiry?.to).format(format))
      let max_redemptions = couponData?.coupon.max_redemptions
      let currentDate = Date.parse(new Date())
      
      if(currentDate >= startDate && currentDate <= endDate){

          let existing = await getCouponRedeem(couponData.userID, couponData.couponID);
    
          if(existing?.data?.listCouponRedeems?.items?.length >= max_redemptions){
            setLoading(false);
            setMaxRedeemed(true);
            setSubmitted(false);
            return;
          }
          let input = {
            couponID: couponData.couponID,
            userID: couponData.userID,
            couponCreatedBy:couponData.couponCreatedBy,
            redeemBy:id
          }
          let { data: {createCouponRedeem: couponRedeemData} } = await createCouponRedeem(input);
          if(couponRedeemData){
            setLoading(false);
            setRedeemed(true);
            setSubmitted(false);

            await checkCampaign(
              triggerType.couponRedeemed,
              couponData.userID, 
              couponData.couponCreatedBy
            );
          }

      } else {
        setLoading(false);
        setExpired(true);
        setSubmitted(false);
        return;
        
      }

    }
  }

  

  return (
    <div className={clsx("app-wrapper vh-100")}>
      <Header isCollapsedLayout={true}></Header>
      <div className="app-main flex-column ">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            
            //Reinit catcha if failed attempt
            
          }}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid, isSubmitting, setFieldValue }) => (
            <Form className="SignIn">
              <div className="app-content app-content-sidebar-collapsed app-content-sidebar-fixed">
                <div className="app-content--inner d-flex align-items-center">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--content py-5">
                      <Container maxWidth="lg">
                        <Grid container spacing={5}>
                          <Grid
                            item
                            xs={12}
                            lg={5}
                            className="d-none d-xl-flex align-items-center"
                          >
                            <img
                              alt="..."
                              className="w-100 mx-auto d-block img-fluid"
                              src={svgImage9}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={7}
                            className="d-flex flex-column align-items-center mx-auto"
                          >
                            <span className="w-100 text-left text-md-center pb-4">
                              <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                Coupon Redeem
                              </h1>
                            </span>
                            
                            <Card className="m-0 w-100 p-0 border-0">
                              <CardContent className="p-3">
                                <div className="text-center text-black-50 mb-3">
                                  <span>Redeem a coupon</span>
                                </div>
                                <Typography align="center" variant="h1" color="inherit">
                                  <CardGiftcardIcon style={{color: 'red',fontSize: '64px'}}/> 
                                </Typography>
                                {!otpVerifed
                                
                                ?(
                                    <div className="px-sm-5 p-3">
                                  <div className="form-group">
                                  <div className="otp-mdl-text-container">
                                      <p className="otp-mdl-text">To verify, enter redeem PIN below.</p>                                  
                                  </div>
                                    <OtpInput
                                        value={otp}
                                        onChange={handleChange}
                                        numInputs={4}
                                    />
                                    <Button
                                      type="button"
                                      className="mt-4 ml-0"
                                      variant="contained"
                                      color="primary"
                                      onClick={handleSubmit}
                                      disabled={submitted}
                                    >
                                      Submit
                                  </Button>
                                  </div>
                                </div>
                                )
                                :(

                                <div className="px-sm-5 p-3">
                                    {loading && (

                                      <Typography align="center" variant="h4" color="inherit">Please wait,while redeeming your coupon!</Typography>
                                    )}
                                    {expired && (

                                      <Typography align="center" variant="h4" color="inherit">Sorry, coupon is expired!</Typography>
                                    )}
                                    {invalid && (

                                      <Typography align="center" variant="h4" color="inherit">Sorry, coupon is invalid!</Typography>
                                    )}
                                    
                                    {maxRedeemed && (

                                      <Typography align="center" variant="h4" color="inherit">Sorry, max redeem limit reached!</Typography>
                                    )}
                                    
                                    {redeemed && (
                                      <>
                                      <Typography align="center" variant="h4" color="inherit">Congratulations! You have redeemed a discount coupon.</Typography>
                                      <div className="mt-4">
                                        <Typography variant="subtitle1">Name: {coupon?.coupon?.name}</Typography>
                                        <Typography variant="subtitle1">Discount: {coupon?.coupon?.amount_off ?'$'+coupon?.coupon?.amount_off: coupon?.coupon?.percent_off + "%"}</Typography>
                                      </div>
                                      </>
                                    )}

                                </div>
                                )
                                }
                                
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <DoxSnackBar snackBarDetails={snackBarDetails}></DoxSnackBar>
    </div>
  );
};

export default CouponRedeemContainer;
