import React from 'react'
import { styled, makeStyles } from '@material-ui/core/styles';
import { FormGroup } from '@material-ui/core'
import CreateSelect from '../../../../common/components/CreateSelect';

const useStyles = makeStyles({
    error: {
        color: '#f83245'
    },
})

const SelectOption = (props) => {

    const {createOptions, handleChangeCreate, selectedOption, submitted, values} = props
    const classes = useStyles()

    return (
        <FormGroup style={{ marginTop: '16px' }}>
            <CreateSelect
                name="options"
                label="Type options and press enter..."
                options={createOptions}
                onChange={handleChangeCreate}
                selectedOption={selectedOption}
                creatable={true}
            />
            {(submitted && (values.type !== 'TEXT' && selectedOption.length < 2)) && (
                <p className={classes.error}>More than one options are required.</p>
            )}
        </FormGroup>
    )
}

export default SelectOption