import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import * as customQueries from "./customQueries/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createCouponScanned = async (input) => {
    return await API.graphql(
        graphqlOperation(mutations.createCouponScanned, { input })
    );
}

export const updateCouponScanned = async (input) => {
    return await API.graphql(
        graphqlOperation(mutations.updateCouponScanned, { input })
    )
}

export const getCouponScanned = async (userId, couponId = null, nextToken, allDatas = []) => {
    let params = {
        filter: {
            userID: {
                eq: userId
            },
        },
    }
    if (couponId) {
        params.filter.couponID = {
            eq: couponId
        }
    }
    if (nextToken) params.nextToken = nextToken;
    let { data: { listCouponScanneds } } = await API.graphql(
        graphqlOperation(customQueries.listCouponScanneds, params)
    )
    allDatas.push(...listCouponScanneds.items)
    if (listCouponScanneds.nextToken) {
        await getCouponScanned(userId, couponId, listCouponScanneds.nextToken, allDatas)
    }

    return allDatas;
}

export const getCouponScannedByCouponId = async (couponId) => {
    let params = {
        filter: {
            couponID: {
                eq: couponId
            },
        },
    }
    let items = await API.graphql(
        graphqlOperation(customQueries.listCouponScanneds, params)
    )
    return items;
}

export const getCouponScannedByCouponCreatedBy = async (userId) => {
    let params = {
        filter: {
            couponCreatedBy: {
                eq: userId
            },
        },
    }
    let items = await API.graphql(
        graphqlOperation(customQueries.listCouponScanneds, params)
    )
    return items;
}

export const getSingleCouponScanned = async (input) => {
    return await API.graphql(
        graphqlOperation(queries.getCouponScanned, { id: input })
    );
}

export const deleteRelationByCouponId = async (userId, couponId) => {
    let params = {
        filter: {},
        limit:30
    };
    params.filter.couponID = {
        eq: couponId
    }
    params.filter.couponCreatedBy = {
        eq: userId
    }

    try {

        let { data: { listCouponScanneds } } = await API.graphql(
            graphqlOperation(customQueries.listCouponScanneds, params)
        )

        const toBeDeleted = listCouponScanneds.items.map((item) => item.id)

        for (let i = 0; i <= toBeDeleted.length; i++) {
            if(toBeDeleted[i]) {
                let payload = {
                    input:{
                        id:toBeDeleted[i]
                    }
                }
                await API.graphql(graphqlOperation(mutations.deleteCouponScanned, payload))
            }   
        }

    } catch (error) {
        console.log("Error:", error)
    }

}
