
import { makeStyles, TextField, InputAdornment, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'category', numeric: false, disablePadding: false, label: 'Category' },
    { id: 'brand', numeric: false, disablePadding: false, label: 'Brand' },
    { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
];

const useStyles = makeStyles((theme) => ({
    table: {

    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    inputRow: {
        maxWidth: '30px'
    },
    nodata:{
        textAlign:'center',
        fontSize:'19px',
        paddingBottom:'20px'
    }
}))

const ProductTableList = (props) => {
    const { selectItem, allproducts, setTableData, tableData } = props
    // const [selected, setSelected] = React.useState([]);
    const [inputval, setinputval] = useState({value:0, id:null})
    const [tempTabledata, setTempTableData] = useState([])
    const classes = useStyles()

    const handleInput = (e, inputid) => {
        const index = tempTabledata.findIndex(item => item.id === inputid);
        tempTabledata[index].price = e.target.value
        setinputval({value:e.target.value, id:inputid})
        setTableData(tempTabledata)
    }

    useEffect(() => {
        const getSelectedDataList = allproducts.filter((item, index) => {
            return selectItem.some((item2, i) => {
                return item2.value === item.id
            })
        })
        setTableData(getSelectedDataList)
    }, [selectItem, allproducts])

   useEffect(() => {
    setTempTableData(tableData)
   }, [tableData])

    return (
        <Paper className={classes.paper}>
            <table className="table mt-5">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Price</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tempTabledata.length > 0 && tempTabledata.map((item, i) => (
                            <tr>
                                <th scope="row">{i+1}</th>
                                <td>{item.title}</td>
                                <td>{item.category}</td>
                                <td>{item.brand}</td>
                                <td className={classes.inputRow}>
                                    <TextField
                                        value={item.price}
                                        onChange={(e)=>handleInput(e, item.id)}
                                        type="number"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>)
                                        }} id="standard-basic" label="Price" />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {tableData.length < 1 && <div className={classes.nodata}>No Data Found</div>}
        </Paper>
    )
}

export default ProductTableList