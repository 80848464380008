import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createNotificationsLog = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.createNotificationsLog, { input })
  );
}


export const listNotifications = async (userId, nextToken, items = []) => {
  let date = new Date();
  let params = {
    filter: {
      user: {
        eq: userId
      },
    },
  };
  if (nextToken) params.nextToken = nextToken;
  let { data: { listNotificationsLogs } } = await API.graphql(
    graphqlOperation(queries.listNotificationsLogs, params)
  );

  items.push(...listNotificationsLogs.items);
  let token = listNotificationsLogs.nextToken;
  if (listNotificationsLogs.nextToken) {
    // if (items.length <= 9) {
    await listNotifications(userId, listNotificationsLogs.nextToken, items);
    // }
  }
  return { items: items, token: token };
}

export const searchNotificationByLink = async(userId, link) => {
  let params = {
    filter: {
      user: {
        eq: userId
      },
      link:{
        eq:link
      }
    },
  };

  let { data: { listNotificationsLogs } } = await API.graphql(
    graphqlOperation(queries.listNotificationsLogs, params)
  );

  return listNotificationsLogs.items
}

export const deleteNotificationsLog = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    const id = coupons[index];
    let { data: { deleteNotificationsLog } } = await API.graphql(
      graphqlOperation(mutations.deleteNotificationsLog, { input: { id } })
    )
    promises.push(deleteNotificationsLog);
  }
  return Promise.all(promises);
}
export const updateNotificationsLog = async (input) => {
  let promises = [];

  for (let index = 0; index < input.length; index++) {
    let { data: { updateNotificationsLog } } = await API.graphql(
      graphqlOperation(mutations.updateNotificationsLog, { input: { id: input[index].id, isRead: true, createdAt: input[index].createdAt } })
    )
    promises.push(updateNotificationsLog);
  }
  return Promise.all(promises);
}


export const updateNotificationsLogFromQuestions = async (input) => {
  let promises = [];

  for (let index = 0; index < input.length; index++) {
    let { data: { updateNotificationsLog } } = await API.graphql(
      graphqlOperation(mutations.updateNotificationsLog, { input: { id: input[index].id, isResponded: true } })
    )
    promises.push(updateNotificationsLog);
  }
  return Promise.all(promises);
}
