import * as Yup from "yup";

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import svgImage9 from "../../assets/images/illustrations/login.svg";
import { getUser, useUser } from "../../providers/UserProvider";
import { Update } from "../containers/curd/vendors";
import FormikTextField from "../../common/components/FormikTextField";
import { LockTwoTone, MailOutlineTwoTone, Visibility, VisibilityOff } from "@material-ui/icons";
Auth.configure(awsconfig);


const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
        ),
    confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .test("passwords-match", "Passwords does not match.", function (value) {
            return this.parent.password === value;
        }),
});

const SetPasswordComponent = (props) => {
    const { setSnackBarDetails, title, containerSpacing, change, uid, password, closeModal, username } = props;
    const { user } = useUser()
    const [failedAttempt, setFailedAttempt] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const initialValues = {
        email: username != undefined ? username : user.email,
        password: "",
        confimrPassword: ""
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                try {

                    let userDetails;
                    let userPassword;
                    if (uid) {
                        userPassword = password;
                        userDetails = await Auth.signIn(username, password);

                    } else {
                        userPassword = JSON.parse(localStorage.getItem('password_user'));
                        userDetails = await Auth.currentAuthenticatedUser()
                    }
                    let setpassword = await Auth.changePassword(
                        userDetails,
                        userPassword,
                        values.password
                    );
                    var id = userDetails.attributes.sub;
                    if (setpassword === 'SUCCESS') {
                        let { data: { updateUsers } } = await Update({ id: id, isPasswordSet: true })
                        if (updateUsers && !change) {
                            setSnackBarDetails({
                                message:
                                    `Your password is set successfully, redirecting to dashboard`,
                                severity: "success",
                                links: [],
                            });
                            localStorage.removeItem('password_user')
                            localStorage.setItem('doximple_user', JSON.stringify(updateUsers));
                            window.location.reload();
                        } else {
                            setSnackBarDetails({
                                message:
                                    `password set successfully`,
                                severity: "success",
                                links: [],
                            });
                            closeModal();
                        }
                    }


                } catch (err) {
                    setFailedAttempt(failedAttempt + 1);
                    let message = "Unknown error has occured";
                    if (err && err.message) {
                        message = err.message;
                    }
                    setSnackBarDetails({
                        message,
                        severity: "error",
                        links: false
                    });
                }
                setSubmitting(false);
            }}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ isValid, isSubmitting, setFieldValue }) => (
                <Form className="SignIn">
                    <div className={change ? '' : "app-content app-content-sidebar-collapsed app-content-sidebar-fixed"}>
                        <div className={!change && "app-content--inner d-flex align-items-center"}>
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className={`bg-composed-wrapper--content ${change ? 'py-0' : 'py-5'}`}>
                                    <Container maxWidth="lg">

                                        <Grid container spacing={containerSpacing}>
                                            {!change ? (<Grid
                                                item
                                                xs={12}
                                                lg={5}
                                                className="d-none d-xl-flex align-items-center"
                                            >
                                                <img
                                                    alt="..."
                                                    className="w-100 mx-auto d-block img-fluid"
                                                    src={svgImage9}
                                                />
                                            </Grid>) : ''}
                                            <Grid
                                                item
                                                xs={12}
                                                lg={change ? 12 : 7}
                                                className="d-flex flex-column align-items-center mx-auto"
                                            >
                                                {!change ? <span className="w-100 text-left text-md-center pb-4">
                                                    <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                                        {title}
                                                    </h1>
                                                </span> : ""
                                                }
                                                <Card className={!change && "m-0 w-100 p-0 border-0"}>
                                                    <CardContent className={change ? 'cardSize' : "p-3"}>
                                                        <div className={!change && "px-sm-5"}>
                                                            <div className="mb-3">
                                                                <FormikTextField
                                                                    variant="outlined"
                                                                    name="email"
                                                                    label="Email"
                                                                    placeholder="Email"
                                                                    margin="normal"
                                                                    disabled
                                                                    fullWidth
                                                                >
                                                                    <InputAdornment position="start">
                                                                        <MailOutlineTwoTone />
                                                                    </InputAdornment>
                                                                </FormikTextField>
                                                            </div>
                                                            <div className="mb-3">
                                                                <FormikTextField
                                                                    name="password"
                                                                    label="New Password"
                                                                    placeholder="Password"
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={() => setShowPassword(bool => !bool)}
                                                                                    onMouseDown={(event) => event.preventDefault()}
                                                                                >
                                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )

                                                                    }}
                                                                >
                                                                    <InputAdornment position="start">
                                                                        <LockTwoTone />
                                                                    </InputAdornment>
                                                                </FormikTextField>
                                                            </div>
                                                            <div className="mb-3">
                                                                <FormikTextField
                                                                    name="confirmPassword"
                                                                    label="Confirm New Password"
                                                                    placeholder="Confirm Password"
                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={() => setShowConfirmPassword(bool => !bool)}
                                                                                    onMouseDown={(event) => event.preventDefault()}
                                                                                >
                                                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )

                                                                    }}
                                                                >
                                                                    <InputAdornment position="start">
                                                                        <LockTwoTone />
                                                                    </InputAdornment>
                                                                </FormikTextField>
                                                            </div>
                                                            <div className="text-center">
                                                                {isSubmitting ? <CircularProgress /> : <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="large"
                                                                    className="my-2"
                                                                    disabled={
                                                                        !isValid ||
                                                                        isSubmitting
                                                                    }
                                                                    type="submit"
                                                                >
                                                                    Set Password
                                                                </Button>}
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default SetPasswordComponent;