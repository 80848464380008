import { Box, IconButton, Tooltip } from "@material-ui/core";
import React, { Fragment } from "react";

import { Link } from "react-router-dom";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import clsx from "clsx";
import { connect } from "react-redux";
import projectLogo from "../../assets/dox-logo.png";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";
const SidebarHeader = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    sidebarHover
  } = props;

  const handleClick = (href) => {
    setSidebarToggleMobile(false)
  }
  return (
    <Fragment>
      <div
        className={clsx("app-sidebar-header", {
          "app-sidebar-header-close": sidebarToggle && !sidebarHover
        })}
      >
        <Box
          className="header-logo-wrapper"
          title="Carolina React Admin Dashboard with Material-UI PRO"
        >
          <Link onClick={() => handleClick('/dashboard')} to="/dashboard" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn"
            >
              <img
                className="app-sidebar-logo"
                alt="Carolina React Admin Dashboard with Material-UI PRO"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">Doximple</Box>
        </Box>
        <Box
          className={clsx("app-sidebar-header-btn", {
            "app-sidebar-header-btn-close": sidebarToggle && !sidebarHover
          })}
        >
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium"
            >
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
