import React from 'react'

export default function CustomError({message}) {
    return (
        <p 
        className="text-danger" 
        style={{
            fontSize: '12px',
            marginLeft: '14px', 
            marginRight: '14px',
            marginTop: '3px'
        }}>{message}</p>
    )
}
