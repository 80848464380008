import {Box} from "@material-ui/core";
import CampaignList from "../components/vendor/campaign-list";
import React from 'react'
const Campaign = (props) => 
    <Box container>
        <CampaignList user={props.user}/>
    </Box>

export default Campaign;

