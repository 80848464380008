import { CircularProgress } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmationModal from "../../../common/components/modalConfirmation";
import CustomModal from '../CustomModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CreateQuestion from './create-question';
import DoxSnackBar from '../../../common/components/DoxSnackBar';
import EnhancedTableHead, { EnhancedTableToolbar, getComparator, stableSort } from '../../../common/components/Tables/EnhancedTableHead';
import { useHistory } from 'react-router-dom';
import { deleteCampaign, listCampaign, updateCampaign } from '../../containers/curd/campaign';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getCustomerCategory } from '../../containers/curd/customerCategory';
import { sendSMSTwilio } from '../../../utils/commonFunctions';
import BackdropLoader from '../../../common/components/BackdropLoader';
import { notificationMessage, notificationType } from '../../../common/utils/constants';
import btoa from 'btoa';
import { createNotificationsLog } from '../../containers/curd/notifications';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'trigger', numeric: false, disablePadding: true, label: 'Trigger' },
  { id: 'bitlyLink', numeric: false, disablePadding: true, label: 'Bitly Link' },
  { id: 'workflowID', numeric: false, disablePadding: true, label: 'Workflow' },
  { id: 'customerCategoryID', numeric: false, disablePadding: true, label: 'Customer Category' },
  { id: 'isActive', numeric: false, disablePadding: true, label: 'Status' },

];



EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function CampaignList(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const type = urlParams.get("type");
  const history = useHistory();
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [loading, setLoading] = useState(false);
  const [smsLoader, setSmsLoader] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const open = false;
  const [editable, setEditable] = useState(null);
  const [createdItem, setCreatedItem] = useState(null);
  const [updatedItem, setUpdatedItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({
    title: `Are you sure, You want to delete this!`,
    open: false,
    no: true,
    yes: false
  });
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState({
    title: `Enabling the campaign toggle will make it live and send the notification/sms to all the users associated to the campaign!`,
    title2: `Are you sure to make it live?`,
    confirm: `Ok`,
    open: false,
    no: true,
    yes: false
  });
  useEffect(() => {
    setConfirmationModal({ ...confirmationModal, title: `Are you sure, You want to delete ${selected.length && selected.length > 1 ? `these ${selected.length} records!` : `this record!`}` })
  }, [selected])


  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    links: [],
    severity: "error",
  });

  let accountType = [];
  if (props.user.accountType === 'SuperAdmin' || props.user.accountType === 'SuperAdminUser') {
    accountType = ['VendorAdmin']
  } else if (props.user.accountType === 'VendorAdmin' || props.user.accountType === 'VendorAdminUser') {
    accountType = ['VendorAdmin', 'VendorAdminUser']
  }
  useEffect(() => {

    if (type) {

      setSnackBarDetails({
        message:
          `Campaign ${type === "updated" ? "updated" : "created"} successfully`,
        severity: "success",
        links: [],
      });
      history.push(`/campaigns`)
    }

  }, [type])

  useEffect(() => {

    const fetchListVendors = async () => {
      setLoading(true)
      let vendors = await listCampaign(props.user.id);
      console.log("Vendors List", vendors)
      setRows(vendors)
      setLoading(false);
    }
    fetchListVendors();
  }, [])

  useEffect(() => {
    setRows(rows => {
      if (!createdItem) return rows;
      let newItem = {
        ...createdItem
      }
      return [newItem, ...rows]
    })

    setCreatedItem(null);
  }, [createdItem])

  useEffect(() => {
    setRows(rows => {
      if (!updatedItem) return rows;
      let index = rows.map((data, index) => data.id === updatedItem.id ? index : undefined).filter(item => item != undefined);
      rows[index[0]] = updatedItem;
      return rows;
    })
    setUpdatedItem(null);
    setEditable(null);
    setSelected([]);
  }, [updatedItem])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeStatus = async (row) => {
    row.isActive = !row.isActive;
    delete row.trigger;
    delete row.workflow;
    delete row.customerCategory;
    if (!row.isNotified) {

      if (row.isActive) {

        setSelectedRow(row);
        openToggleConfirm();
      } else {

        let data = await updateCampaign({ ...row, id: row.id })
        setRows((rows) => rows.map(item => row.id === item.id ? data : item));
      }
    } else {

      let data = await updateCampaign({ ...row, id: row.id })
      setRows((rows) => rows.map(item => row.id === item.id ? data : item));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDeleteConfirm = async () => {
    setConfirmationModal((values) => ({ ...values, open: true }));
  }

  const handleDelete = async () => {
    setLoadingDelete(true);
    await deleteCampaign(selected);
    setRows((rows) => rows.filter(({ id }) => !selected.includes(id)));
    setSelected([]);
    setLoadingDelete(false)
  }
  // CampaignList
  const setOpen = (value, id = null) => {
    if (id) {
      history.push(`/campaign/edit/${id}`);
      return;
    }
    history.push('/campaign/add');
  }

  const handleEdit = () => {
    let item = rows.find(({ id }) => id === selected[0]);
    setOpen(true, item.id);
    setEditable(item);
  }
  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setSnackBarDetails({
      message:
        `Link copied successfully`,
      severity: "success",
      links: [],
    });
  }

  const getCustomersAndSendMessage = async (row) => {
    const { id, customerCategoryID, bitlyLink, message } = row;
    console.log("Sewnding sms....")
    let link;
    if (row?.triggerID === `3`) {
      link = `/campaign-questions?campaign=${btoa(id)}&isBitly=true`;
    } else {
      link = `/campaign-questions?campaign=${btoa(id)}`;
    }
    const CategoryJson = JSON.parse(customerCategoryID)
    if (CategoryJson) {
      CategoryJson.map(async(categoryID) => {
        let category = await getCustomerCategory(categoryID)
        let relations = category?.customerCategoryRelations?.items || [];

        let uniqueRelations = relations.filter((value, index, self) => {
          return self.findIndex(item => item.userID === value.userID) === index
        })



        let idsOnly = uniqueRelations.map(item => item?.user?.id);
        await Promise.all(
          idsOnly.map(async (item) => {
            await sendNotification(
              item,
              notificationMessage.campaignCreated,
              notificationType.campaignCreated,
              link
            );
          })
        )

        if (bitlyLink) {

          let numberOnly = uniqueRelations.filter(item => item?.user?.mobile);
          let numbers = numberOnly.map(item => item?.user?.mobile);

          await Promise.all(
            numbers.map(async (item) => {
              await sendSms({
                mobile: item,
                message: message
              });
            })
          )
        }
      })

      return true;
    }
    return false;
  }

  const sendNotification = async (userId, message, type, link) => {
    try {

      let data = {
        user: userId,
        message: message,
        type: type,
        link: link,
        expired: false,
        isRead: false,
        isResponded: false,
      }
      await createNotificationsLog(data);

    } catch (error) {
      setSnackBarDetails({
        message: error.message,
        severity: "error",
        links: [],
      });

    }
  }

  const sendSms = async (values) => {
    try {

      const to = values.mobile.replace(/[^0-9\+]/g, "");
      const message = values.message;

      let resp = await sendSMSTwilio(to, message);


    } catch (error) {
      setSnackBarDetails({
        message: error.message,
        severity: "error",
        links: [],
      });

    }
  }

  const closeToggleConfirm = async (success) => {
    let row = selectedRow;

    if (success) {
      delete row.trigger;
      delete row.workflow;
      delete row.customerCategory;
      if (row.isActive && !row.isNotified) {

        row.isNotified = true;
        setSmsLoader(true);

        await getCustomersAndSendMessage(row);
      }
      let data = await updateCampaign({ ...row, id: row.id })
      setRows((rows) => rows.map(item => row.id === item.id ? data : item));
      setSmsLoader(false);
    } else {
      row.isActive = false;
      let data = await updateCampaign({ ...row, id: row.id })
      setRows((rows) => rows.map(item => row.id === item.id ? data : item));

    }
    setSelectedRow(null);
    setToggleConfirmationModal({ ...toggleConfirmationModal, open: false });
  }

  const openToggleConfirm = () => {
    setToggleConfirmationModal((values) => ({ ...values, open: true }));
  }

  return (
    <>
      {!loading ? <>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <DoxSnackBar snackBarDetails={snackBarDetails} />
            <EnhancedTableToolbar numSelected={selected.length} user={props.user} setOpen={setOpen} handleDelete={handleDeleteConfirm} handleEdit={handleEdit} loadingDelete={loadingDelete} title="Campaigns" />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row?.name || '-'}
                          </TableCell>
                          <TableCell align="left" padding="none">
                            {row?.trigger?.name || '-'}
                          </TableCell>
                          <TableCell align="left" padding="none">
                            {row?.bitlyLink && (
                              <FileCopyIcon
                                onClick={() => handleCopy(row?.bitlyLink)}
                                titleAccess="Copy"
                                color="secondary"
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                            {row?.bitlyLink || '-'}
                          </TableCell>
                          <TableCell align="left" padding="none">
                            {row?.workflow?.name || '-'}
                          </TableCell>
                          <TableCell align="left" padding="none">
                            {row?.customerCategory?.name || '-'}
                          </TableCell>
                          <TableCell align="left" padding="none">
                            <Switch
                              checked={row?.isActive}
                              onChange={(e) => handleChangeStatus(row)}
                              color="secondary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={headCells.length + 1} align="center">
                        {rows.length === 0 && (
                          "No data found"
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </div>

        <CustomModal fullWidth open={open} setOpen={(bool) => { setOpen(bool); setEditable(null) }} title={`Question > ${editable ? " Update Campaign" : "Add New Campaign"} `}>
          <CreateQuestion user={props.user} closeModal={() => { setOpen(false); setEditable(null) }} setCreatedItem={setCreatedItem} editable={editable} setUpdatedItem={setUpdatedItem} setSnackBarDetails={setSnackBarDetails} />
        </CustomModal>
      </> : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>
      }
      <ConfirmationModal
        items={selectedItem}
        settings={confirmationModal}
        close={success => {
          if (success) {
            handleDelete()
          }
          setConfirmationModal({ ...confirmationModal, open: false });
        }}
      ></ConfirmationModal>
      <ConfirmationModal
        settings={toggleConfirmationModal}
        close={closeToggleConfirm}
      ></ConfirmationModal>
      <BackdropLoader open={smsLoader} />
    </>
  );
}

