import { Field } from "formik";
import React from "react";
import { TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    borderColor: 'rgba(0, 0, 0, 0.3)',
    padding: "10px 14px 18.5px 14px",
    "&:focus": {
      border: "2px solid #5383ff",
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  },

  error: {
    borderColor: 'red',
    borderRadius: 10,
    padding: "10px 14px 18.5px 14px",
    "&:focus": {
      border: "2px solid #5383ff",
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  }
});

const FormikTextarea = ({ children, render, ...props }) => {
  return (
    <Field type="textarea" {...props} component={CustomInputComponent} variant="outlined">
      {children}
    </Field>
  );
};
const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const classes = useStyles();
  return <>
    <TextareaAutosize className={
      (touched[field.name] && errors[field.name]) ? classes.error : classes.root}
      rows={4}
      {...field} {...props} />
    {touched[field.name] &&
      errors[field.name] && <p style={{ fontSize: '12px', marginLeft: '14px', marginRight: '14px', marginTop: '3px' }} className="text-danger">{errors[field.name]}</p>}
  </>
};
export default FormikTextarea;
