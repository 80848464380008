export const questionsTable = [
  { questionId: "DOX-0001", dbColumnName: "dox001" },
  { questionId: "DOX-0002", dbColumnName: "dox002" },
  { questionId: "DOX-0003", dbColumnName: "dox003" },
  { questionId: "DOX-0008", dbColumnName: "dox008" },
  { questionId: "DOX-0011", dbColumnName: "dox011" },
  { questionId: "DOX-0013", dbColumnName: "dox013" },
  { questionId: "DOX-0014", dbColumnName: "dox014" },
  { questionId: "DOX-0015", dbColumnName: "dox015" },
  { questionId: "DOX-0019", dbColumnName: "dox019" },
  { questionId: "DOX-0020", dbColumnName: "dox020" },
  { questionId: "DOX-0021", dbColumnName: "dox021" },
  { questionId: "DOX-0024", dbColumnName: "dox024" },
  { questionId: "DOX-0028", dbColumnName: "dox024" }
];

export const notificationType = {
  question: "question",
  couponUpdated: "coupon-updated",
  couponCreated: "coupon-created",
  campaignCreated: "campaign-created",
  userLoggedIn:"user-loggedIn"
}

export const notificationMessage = {
  question: "question",
  couponUpdated: "A coupon has been updated",
  couponCreated: "Congrats!, You have received a new coupon",
  campaignCreated: "New campaign created!, respond to avail the benefit",
  newUserCampaign: "to answer the question in order to receive rewards "
}



export const triggerType = {
  couponScanned: 1,
  couponRedeemed: 2,
  bitlyLinkClicked: 3,
 
}