import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createQuestion = async (input) => {
  
  let { data: { createMasterQuestion } } = await API.graphql(
    graphqlOperation(mutations.createMasterQuestion, { input: {...input, isDeleted: false }})
  );
  return createMasterQuestion;
}
export const listQuestion = async (userId) => {
  let params = {
    filter: {
      createdBy: {
        eq: userId
      },
      isDeleted: {
        eq: false
      }
    }
  };
  let { data: { listMasterQuestions } } = await API.graphql(
    graphqlOperation(queries.listMasterQuestions, params)
  );
  return listMasterQuestions.items;
}

export const deleteQuestion = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    let id = coupons[index];
    id['isDeleted'] = true;
    let { data: { updateMasterQuestion } } = await API.graphql(
      graphqlOperation(mutations.updateMasterQuestion, { input: { ...id } })
    )
    promises.push(updateMasterQuestion);
  }
  return Promise.all(promises);
}

export const updateQuestion = async (input) => {
  input['isDeleted'] = false;
  let { data: { updateMasterQuestion } } = await API.graphql(
    graphqlOperation(mutations.updateMasterQuestion, { input })
  );
  return updateMasterQuestion;
}

export const getQuestion = async (input) => {
  let { data: { getMasterQuestion } } = await API.graphql(
    graphqlOperation(queries.getMasterQuestion, { id: input })
  );
  return getMasterQuestion;
}
