/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      email
      mobile
      firstName
      lastName
      createdBy
      createdAt
      updatedAt
      displayName
      photoURL
      streetAddress
      city
      state
      zipCode
      isProvider
      providerType
      appAdminRole
      apiRole
      developerRole
      familyAdminRole
      providerRole
      providerAdminRole
      userRole
      userAdminRole
      subscriptionType
      SubscriptionExpiryDate
      userActive
      accountType
      isAccountActive
      isDeleted
      isPasswordSet
      companyName
      redeemCode
      companyLogo
      owner
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      email
      mobile
      firstName
      lastName
      createdBy
      createdAt
      updatedAt
      displayName
      photoURL
      streetAddress
      city
      state
      zipCode
      isProvider
      providerType
      appAdminRole
      apiRole
      developerRole
      familyAdminRole
      providerRole
      providerAdminRole
      userRole
      userAdminRole
      subscriptionType
      SubscriptionExpiryDate
      userActive
      accountType
      isAccountActive
      isDeleted
      isPasswordSet
      companyName
      redeemCode
      companyLogo
      owner
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      email
      mobile
      firstName
      lastName
      createdBy
      createdAt
      updatedAt
      displayName
      photoURL
      streetAddress
      city
      state
      zipCode
      isProvider
      providerType
      appAdminRole
      apiRole
      developerRole
      familyAdminRole
      providerRole
      providerAdminRole
      userRole
      userAdminRole
      subscriptionType
      SubscriptionExpiryDate
      userActive
      accountType
      isAccountActive
      isDeleted
      isPasswordSet
      companyName
      redeemCode
      companyLogo
      owner
    }
  }
`;
export const createTrackedItems = /* GraphQL */ `
  mutation CreateTrackedItems(
    $input: CreateTrackedItemsInput!
    $condition: ModelTrackedItemsConditionInput
  ) {
    createTrackedItems(input: $input, condition: $condition) {
      id
      cat
      name
      desc
      createdAt
      updatedAt
      lnkdBus
      lnkdCond
      lnkdProd
      lnkdPrvder
      lnkdServ
      lnkdSymp
      lnkdTrtmnt
      lnkdBodyPart
      lnkdTypePain
    }
  }
`;
export const updateTrackedItems = /* GraphQL */ `
  mutation UpdateTrackedItems(
    $input: UpdateTrackedItemsInput!
    $condition: ModelTrackedItemsConditionInput
  ) {
    updateTrackedItems(input: $input, condition: $condition) {
      id
      cat
      name
      desc
      createdAt
      updatedAt
      lnkdBus
      lnkdCond
      lnkdProd
      lnkdPrvder
      lnkdServ
      lnkdSymp
      lnkdTrtmnt
      lnkdBodyPart
      lnkdTypePain
    }
  }
`;
export const deleteTrackedItems = /* GraphQL */ `
  mutation DeleteTrackedItems(
    $input: DeleteTrackedItemsInput!
    $condition: ModelTrackedItemsConditionInput
  ) {
    deleteTrackedItems(input: $input, condition: $condition) {
      id
      cat
      name
      desc
      createdAt
      updatedAt
      lnkdBus
      lnkdCond
      lnkdProd
      lnkdPrvder
      lnkdServ
      lnkdSymp
      lnkdTrtmnt
      lnkdBodyPart
      lnkdTypePain
    }
  }
`;
export const createQuestions = /* GraphQL */ `
  mutation CreateQuestions(
    $input: CreateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    createQuestions(input: $input, condition: $condition) {
      id
      questionID
      category
      followupToID
      responseType
      otherField
      question
      possibleResponses
      nextQuestion
      listType
      loop
      enteredBy
      tags
      active
      onboarding
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestions = /* GraphQL */ `
  mutation UpdateQuestions(
    $input: UpdateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    updateQuestions(input: $input, condition: $condition) {
      id
      questionID
      category
      followupToID
      responseType
      otherField
      question
      possibleResponses
      nextQuestion
      listType
      loop
      enteredBy
      tags
      active
      onboarding
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestions = /* GraphQL */ `
  mutation DeleteQuestions(
    $input: DeleteQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    deleteQuestions(input: $input, condition: $condition) {
      id
      questionID
      category
      followupToID
      responseType
      otherField
      question
      possibleResponses
      nextQuestion
      listType
      loop
      enteredBy
      tags
      active
      onboarding
      createdAt
      updatedAt
    }
  }
`;
export const createUserResponses = /* GraphQL */ `
  mutation CreateUserResponses(
    $input: CreateUserResponsesInput!
    $condition: ModelUserResponsesConditionInput
  ) {
    createUserResponses(input: $input, condition: $condition) {
      id
      userID
      sessionID
      createdAt
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const updateUserResponses = /* GraphQL */ `
  mutation UpdateUserResponses(
    $input: UpdateUserResponsesInput!
    $condition: ModelUserResponsesConditionInput
  ) {
    updateUserResponses(input: $input, condition: $condition) {
      id
      userID
      sessionID
      createdAt
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const deleteUserResponses = /* GraphQL */ `
  mutation DeleteUserResponses(
    $input: DeleteUserResponsesInput!
    $condition: ModelUserResponsesConditionInput
  ) {
    deleteUserResponses(input: $input, condition: $condition) {
      id
      userID
      sessionID
      createdAt
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const createConditionsAndSymptoms = /* GraphQL */ `
  mutation CreateConditionsAndSymptoms(
    $input: CreateConditionsAndSymptomsInput!
    $condition: ModelConditionsAndSymptomsConditionInput
  ) {
    createConditionsAndSymptoms(input: $input, condition: $condition) {
      id
      userID
      trackIt
      diagBy
      beingTreated
      createdAt
      active
      sessionID
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const updateConditionsAndSymptoms = /* GraphQL */ `
  mutation UpdateConditionsAndSymptoms(
    $input: UpdateConditionsAndSymptomsInput!
    $condition: ModelConditionsAndSymptomsConditionInput
  ) {
    updateConditionsAndSymptoms(input: $input, condition: $condition) {
      id
      userID
      trackIt
      diagBy
      beingTreated
      createdAt
      active
      sessionID
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const deleteConditionsAndSymptoms = /* GraphQL */ `
  mutation DeleteConditionsAndSymptoms(
    $input: DeleteConditionsAndSymptomsInput!
    $condition: ModelConditionsAndSymptomsConditionInput
  ) {
    deleteConditionsAndSymptoms(input: $input, condition: $condition) {
      id
      userID
      trackIt
      diagBy
      beingTreated
      createdAt
      active
      sessionID
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const createPreference = /* GraphQL */ `
  mutation CreatePreference(
    $input: CreatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    createPreference(input: $input, condition: $condition) {
      id
      frequency
      questions
      notification_on {
        email
        sms
        app
      }
      remind_me
      user
      sendedNotifications
      createdAt
      updatedAt
    }
  }
`;
export const updatePreference = /* GraphQL */ `
  mutation UpdatePreference(
    $input: UpdatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    updatePreference(input: $input, condition: $condition) {
      id
      frequency
      questions
      notification_on {
        email
        sms
        app
      }
      remind_me
      user
      sendedNotifications
      createdAt
      updatedAt
    }
  }
`;
export const deletePreference = /* GraphQL */ `
  mutation DeletePreference(
    $input: DeletePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    deletePreference(input: $input, condition: $condition) {
      id
      frequency
      questions
      notification_on {
        email
        sms
        app
      }
      remind_me
      user
      sendedNotifications
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationResponse = /* GraphQL */ `
  mutation CreateNotificationResponse(
    $input: CreateNotificationResponseInput!
    $condition: ModelNotificationResponseConditionInput
  ) {
    createNotificationResponse(input: $input, condition: $condition) {
      id
      questionID
      label
      user
      response
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationResponse = /* GraphQL */ `
  mutation UpdateNotificationResponse(
    $input: UpdateNotificationResponseInput!
    $condition: ModelNotificationResponseConditionInput
  ) {
    updateNotificationResponse(input: $input, condition: $condition) {
      id
      questionID
      label
      user
      response
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationResponse = /* GraphQL */ `
  mutation DeleteNotificationResponse(
    $input: DeleteNotificationResponseInput!
    $condition: ModelNotificationResponseConditionInput
  ) {
    deleteNotificationResponse(input: $input, condition: $condition) {
      id
      questionID
      label
      user
      response
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      name
      expiry {
        from
        to
      }
      amount_off
      percent_off
      description
      max_redemptions
      tag
      createdBy
      campaignID
      couponIssued {
        items {
          id
          couponID
          userID
          couponCreatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      couponRedeemed {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      allProducts {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      name
      expiry {
        from
        to
      }
      amount_off
      percent_off
      description
      max_redemptions
      tag
      createdBy
      campaignID
      couponIssued {
        items {
          id
          couponID
          userID
          couponCreatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      couponRedeemed {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      allProducts {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      name
      expiry {
        from
        to
      }
      amount_off
      percent_off
      description
      max_redemptions
      tag
      createdBy
      campaignID
      couponIssued {
        items {
          id
          couponID
          userID
          couponCreatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      couponRedeemed {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      allProducts {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationsLog = /* GraphQL */ `
  mutation CreateNotificationsLog(
    $input: CreateNotificationsLogInput!
    $condition: ModelNotificationsLogConditionInput
  ) {
    createNotificationsLog(input: $input, condition: $condition) {
      id
      user
      message
      type
      link
      isRead
      isResponded
      expired
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationsLog = /* GraphQL */ `
  mutation UpdateNotificationsLog(
    $input: UpdateNotificationsLogInput!
    $condition: ModelNotificationsLogConditionInput
  ) {
    updateNotificationsLog(input: $input, condition: $condition) {
      id
      user
      message
      type
      link
      isRead
      isResponded
      expired
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationsLog = /* GraphQL */ `
  mutation DeleteNotificationsLog(
    $input: DeleteNotificationsLogInput!
    $condition: ModelNotificationsLogConditionInput
  ) {
    deleteNotificationsLog(input: $input, condition: $condition) {
      id
      user
      message
      type
      link
      isRead
      isResponded
      expired
      createdAt
      updatedAt
    }
  }
`;
export const createCouponScanned = /* GraphQL */ `
  mutation CreateCouponScanned(
    $input: CreateCouponScannedInput!
    $condition: ModelCouponScannedConditionInput
  ) {
    createCouponScanned(input: $input, condition: $condition) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponRedeems {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      couponCreatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCouponScanned = /* GraphQL */ `
  mutation UpdateCouponScanned(
    $input: UpdateCouponScannedInput!
    $condition: ModelCouponScannedConditionInput
  ) {
    updateCouponScanned(input: $input, condition: $condition) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponRedeems {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      couponCreatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCouponScanned = /* GraphQL */ `
  mutation DeleteCouponScanned(
    $input: DeleteCouponScannedInput!
    $condition: ModelCouponScannedConditionInput
  ) {
    deleteCouponScanned(input: $input, condition: $condition) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponRedeems {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      couponCreatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createCouponRedeem = /* GraphQL */ `
  mutation CreateCouponRedeem(
    $input: CreateCouponRedeemInput!
    $condition: ModelCouponRedeemConditionInput
  ) {
    createCouponRedeem(input: $input, condition: $condition) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponCreatedBy
      redeemBy
      createdAt
      updatedAt
      couponCouponRedeemedId
    }
  }
`;
export const updateCouponRedeem = /* GraphQL */ `
  mutation UpdateCouponRedeem(
    $input: UpdateCouponRedeemInput!
    $condition: ModelCouponRedeemConditionInput
  ) {
    updateCouponRedeem(input: $input, condition: $condition) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponCreatedBy
      redeemBy
      createdAt
      updatedAt
      couponCouponRedeemedId
    }
  }
`;
export const deleteCouponRedeem = /* GraphQL */ `
  mutation DeleteCouponRedeem(
    $input: DeleteCouponRedeemInput!
    $condition: ModelCouponRedeemConditionInput
  ) {
    deleteCouponRedeem(input: $input, condition: $condition) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponCreatedBy
      redeemBy
      createdAt
      updatedAt
      couponCouponRedeemedId
    }
  }
`;
export const createMasterQuestion = /* GraphQL */ `
  mutation CreateMasterQuestion(
    $input: CreateMasterQuestionInput!
    $condition: ModelMasterQuestionConditionInput
  ) {
    createMasterQuestion(input: $input, condition: $condition) {
      id
      type
      question
      options
      createdBy
      isDeleted
      fileSize
      minDate
      maxDate
      formTime
      minRange
      maxRange
      createdAt
      updatedAt
    }
  }
`;
export const updateMasterQuestion = /* GraphQL */ `
  mutation UpdateMasterQuestion(
    $input: UpdateMasterQuestionInput!
    $condition: ModelMasterQuestionConditionInput
  ) {
    updateMasterQuestion(input: $input, condition: $condition) {
      id
      type
      question
      options
      createdBy
      isDeleted
      fileSize
      minDate
      maxDate
      formTime
      minRange
      maxRange
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasterQuestion = /* GraphQL */ `
  mutation DeleteMasterQuestion(
    $input: DeleteMasterQuestionInput!
    $condition: ModelMasterQuestionConditionInput
  ) {
    deleteMasterQuestion(input: $input, condition: $condition) {
      id
      type
      question
      options
      createdBy
      isDeleted
      fileSize
      minDate
      maxDate
      formTime
      minRange
      maxRange
      createdAt
      updatedAt
    }
  }
`;
export const createMasterQuestionWorkflow = /* GraphQL */ `
  mutation CreateMasterQuestionWorkflow(
    $input: CreateMasterQuestionWorkflowInput!
    $condition: ModelMasterQuestionWorkflowConditionInput
  ) {
    createMasterQuestionWorkflow(input: $input, condition: $condition) {
      id
      name
      questions
      createdBy
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateMasterQuestionWorkflow = /* GraphQL */ `
  mutation UpdateMasterQuestionWorkflow(
    $input: UpdateMasterQuestionWorkflowInput!
    $condition: ModelMasterQuestionWorkflowConditionInput
  ) {
    updateMasterQuestionWorkflow(input: $input, condition: $condition) {
      id
      name
      questions
      createdBy
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasterQuestionWorkflow = /* GraphQL */ `
  mutation DeleteMasterQuestionWorkflow(
    $input: DeleteMasterQuestionWorkflowInput!
    $condition: ModelMasterQuestionWorkflowConditionInput
  ) {
    deleteMasterQuestionWorkflow(input: $input, condition: $condition) {
      id
      name
      questions
      createdBy
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createTriggerType = /* GraphQL */ `
  mutation CreateTriggerType(
    $input: CreateTriggerTypeInput!
    $condition: ModelTriggerTypeConditionInput
  ) {
    createTriggerType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateTriggerType = /* GraphQL */ `
  mutation UpdateTriggerType(
    $input: UpdateTriggerTypeInput!
    $condition: ModelTriggerTypeConditionInput
  ) {
    updateTriggerType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteTriggerType = /* GraphQL */ `
  mutation DeleteTriggerType(
    $input: DeleteTriggerTypeInput!
    $condition: ModelTriggerTypeConditionInput
  ) {
    deleteTriggerType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      triggerID
      trigger {
        id
        name
        createdAt
        updatedAt
      }
      bitlyLink
      workflowID
      workflow {
        id
        name
        questions
        createdBy
        isDeleted
        createdAt
        updatedAt
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      coupons
      introScreen
      isNotified
      isFuture
      message
      createdBy
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      triggerID
      trigger {
        id
        name
        createdAt
        updatedAt
      }
      bitlyLink
      workflowID
      workflow {
        id
        name
        questions
        createdBy
        isDeleted
        createdAt
        updatedAt
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      coupons
      introScreen
      isNotified
      isFuture
      message
      createdBy
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      triggerID
      trigger {
        id
        name
        createdAt
        updatedAt
      }
      bitlyLink
      workflowID
      workflow {
        id
        name
        questions
        createdBy
        isDeleted
        createdAt
        updatedAt
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      coupons
      introScreen
      isNotified
      isFuture
      message
      createdBy
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createWorkflowQuestionResponse = /* GraphQL */ `
  mutation CreateWorkflowQuestionResponse(
    $input: CreateWorkflowQuestionResponseInput!
    $condition: ModelWorkflowQuestionResponseConditionInput
  ) {
    createWorkflowQuestionResponse(input: $input, condition: $condition) {
      id
      campaignID
      workflowID
      questionID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkflowQuestionResponse = /* GraphQL */ `
  mutation UpdateWorkflowQuestionResponse(
    $input: UpdateWorkflowQuestionResponseInput!
    $condition: ModelWorkflowQuestionResponseConditionInput
  ) {
    updateWorkflowQuestionResponse(input: $input, condition: $condition) {
      id
      campaignID
      workflowID
      questionID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkflowQuestionResponse = /* GraphQL */ `
  mutation DeleteWorkflowQuestionResponse(
    $input: DeleteWorkflowQuestionResponseInput!
    $condition: ModelWorkflowQuestionResponseConditionInput
  ) {
    deleteWorkflowQuestionResponse(input: $input, condition: $condition) {
      id
      campaignID
      workflowID
      questionID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const createTempSurveyRepsonse = /* GraphQL */ `
  mutation CreateTempSurveyRepsonse(
    $input: CreateTempSurveyRepsonseInput!
    $condition: ModelTempSurveyRepsonseConditionInput
  ) {
    createTempSurveyRepsonse(input: $input, condition: $condition) {
      id
      campaignID
      workflowID
      questionID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const updateTempSurveyRepsonse = /* GraphQL */ `
  mutation UpdateTempSurveyRepsonse(
    $input: UpdateTempSurveyRepsonseInput!
    $condition: ModelTempSurveyRepsonseConditionInput
  ) {
    updateTempSurveyRepsonse(input: $input, condition: $condition) {
      id
      campaignID
      workflowID
      questionID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const deleteTempSurveyRepsonse = /* GraphQL */ `
  mutation DeleteTempSurveyRepsonse(
    $input: DeleteTempSurveyRepsonseInput!
    $condition: ModelTempSurveyRepsonseConditionInput
  ) {
    deleteTempSurveyRepsonse(input: $input, condition: $condition) {
      id
      campaignID
      workflowID
      questionID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerCategory = /* GraphQL */ `
  mutation CreateCustomerCategory(
    $input: CreateCustomerCategoryInput!
    $condition: ModelCustomerCategoryConditionInput
  ) {
    createCustomerCategory(input: $input, condition: $condition) {
      id
      name
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerCategory = /* GraphQL */ `
  mutation UpdateCustomerCategory(
    $input: UpdateCustomerCategoryInput!
    $condition: ModelCustomerCategoryConditionInput
  ) {
    updateCustomerCategory(input: $input, condition: $condition) {
      id
      name
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerCategory = /* GraphQL */ `
  mutation DeleteCustomerCategory(
    $input: DeleteCustomerCategoryInput!
    $condition: ModelCustomerCategoryConditionInput
  ) {
    deleteCustomerCategory(input: $input, condition: $condition) {
      id
      name
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerCategoryRelation = /* GraphQL */ `
  mutation CreateCustomerCategoryRelation(
    $input: CreateCustomerCategoryRelationInput!
    $condition: ModelCustomerCategoryRelationConditionInput
  ) {
    createCustomerCategoryRelation(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerCategoryRelation = /* GraphQL */ `
  mutation UpdateCustomerCategoryRelation(
    $input: UpdateCustomerCategoryRelationInput!
    $condition: ModelCustomerCategoryRelationConditionInput
  ) {
    updateCustomerCategoryRelation(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerCategoryRelation = /* GraphQL */ `
  mutation DeleteCustomerCategoryRelation(
    $input: DeleteCustomerCategoryRelationInput!
    $condition: ModelCustomerCategoryRelationConditionInput
  ) {
    deleteCustomerCategoryRelation(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMasterProduct = /* GraphQL */ `
  mutation CreateMasterProduct(
    $input: CreateMasterProductInput!
    $condition: ModelMasterProductConditionInput
  ) {
    createMasterProduct(input: $input, condition: $condition) {
      id
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateMasterProduct = /* GraphQL */ `
  mutation UpdateMasterProduct(
    $input: UpdateMasterProductInput!
    $condition: ModelMasterProductConditionInput
  ) {
    updateMasterProduct(input: $input, condition: $condition) {
      id
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasterProduct = /* GraphQL */ `
  mutation DeleteMasterProduct(
    $input: DeleteMasterProductInput!
    $condition: ModelMasterProductConditionInput
  ) {
    deleteMasterProduct(input: $input, condition: $condition) {
      id
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createVendorProduct = /* GraphQL */ `
  mutation CreateVendorProduct(
    $input: CreateVendorProductInput!
    $condition: ModelVendorProductConditionInput
  ) {
    createVendorProduct(input: $input, condition: $condition) {
      id
      vendorID
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      rate
      isDeleted
      allCoupons {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVendorProduct = /* GraphQL */ `
  mutation UpdateVendorProduct(
    $input: UpdateVendorProductInput!
    $condition: ModelVendorProductConditionInput
  ) {
    updateVendorProduct(input: $input, condition: $condition) {
      id
      vendorID
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      rate
      isDeleted
      allCoupons {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendorProduct = /* GraphQL */ `
  mutation DeleteVendorProduct(
    $input: DeleteVendorProductInput!
    $condition: ModelVendorProductConditionInput
  ) {
    deleteVendorProduct(input: $input, condition: $condition) {
      id
      vendorID
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      rate
      isDeleted
      allCoupons {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProductCoupon = /* GraphQL */ `
  mutation CreateProductCoupon(
    $input: CreateProductCouponInput!
    $condition: ModelProductCouponConditionInput
  ) {
    createProductCoupon(input: $input, condition: $condition) {
      id
      couponID
      vendorProductID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorProduct {
        id
        vendorID
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        rate
        isDeleted
        allCoupons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProductCoupon = /* GraphQL */ `
  mutation UpdateProductCoupon(
    $input: UpdateProductCouponInput!
    $condition: ModelProductCouponConditionInput
  ) {
    updateProductCoupon(input: $input, condition: $condition) {
      id
      couponID
      vendorProductID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorProduct {
        id
        vendorID
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        rate
        isDeleted
        allCoupons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductCoupon = /* GraphQL */ `
  mutation DeleteProductCoupon(
    $input: DeleteProductCouponInput!
    $condition: ModelProductCouponConditionInput
  ) {
    deleteProductCoupon(input: $input, condition: $condition) {
      id
      couponID
      vendorProductID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorProduct {
        id
        vendorID
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        rate
        isDeleted
        allCoupons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
