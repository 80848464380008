import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";
import { listVendorProduct } from "./vendorProducts";


export const createMasterProduct = async (input) => {
    const { Manufacturer, title } = input
    let filter = {
        Manufacturer: {
            eq: Manufacturer
        },
        title:{
            eq:title
        }
    };

    const {data:{listMasterProducts}} = await API.graphql(
        graphqlOperation(queries.listMasterProducts, { filter })
    )
    console.log("listpprodct", listMasterProducts)
    if(listMasterProducts.items.length < 1){
        return await API.graphql(
            graphqlOperation(mutations.createMasterProduct, { input })
        )
    } else {
        throw new Error('Duplicate data cannot be added')
    }

}


export const updateMasterProduct = async (input) => {
    return await API.graphql(graphqlOperation(mutations.updateMasterProduct, { input }));
}

export const listMasterProducts = async(nextToken, items = []) => {
    let params = {
        limit: 30
    }
    
    if (nextToken) params.nextToken = nextToken;
    let  {data:{listMasterProducts}} = await API.graphql(graphqlOperation(queries.listMasterProducts, params));
    items.push(...listMasterProducts?.items);
    if (listMasterProducts.nextToken) {
        await listMasterProducts(listMasterProducts.nextToken, items);
    }
    return listMasterProducts.items;
}

export const listUniqueMasterProduct = async(userId) => {

    const data = await listVendorProduct(userId)
    const filterId = data.map((item) => {
        return {id:{ne:item.productID}}
    })

    let params = {
        filter: {
            and: filterId
        },
    }

    let  {data:{listMasterProducts}} = await API.graphql(graphqlOperation(queries.listMasterProducts, params));
    return listMasterProducts.items
}