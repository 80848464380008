import Header from "../../common/components/header";
import { LandingSection } from "../../common/components/landing-section";
import React from "react";

function HomeContainer(props) {
  
  return (
      <>
        <Header></Header>
        <LandingSection></LandingSection>
      </>
  )
}

export default HomeContainer
