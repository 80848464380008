import { Box, Container, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import ChangePassword from "../components/change-password";
import CompanyInfo from "./company-info";
import CurrentUser from "../components/current-user.js";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useState } from "react";
import UserProfile from "../components/user-profile.js";
import RedeemPin from "../components/redeem-pin";
import avatar7 from "../../assets/images/avatars/avatar7.png";
import { css } from "@emotion/react";
import { useUser } from "../../providers/UserProvider";
import { withStyles } from "@material-ui/core/styles";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "None",
    height: "6px",
    "& > div": {
      maxWidth: 120,
      height: "4px",
      borderRadius: "25px",
      width: "100%",
      backgroundColor: "None"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: theme.palette.primary[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const CurrentUserProfileContainer = () => {
  // const user = useContext(UserContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user } = useUser();
  if (user) {
    return (
      <div>
        <Link
          css={css`
            color: #a8d8f0;
            text-decoration: none;
            padding: 10px;
            margin-left: 15px;
            margin-top: 15px;
            top: 16px;
            left: 145px;
            position: fixed;
            font-weight: 300;
            background-color: #05293b;
          `}
          to="/"
        >
          &larr;
        </Link>
        {/* <CurrentUser {...user}></CurrentUser> */}
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <div className="bg-midnight-bloom p-3 rounded text-white h-100">
              <div className="d-flex align-items-start justify-content-between">
                <div className="avatar-icon-wrapper d-100">
                  <span className="badge-circle badge badge-success">
                    Online
                  </span>
                  <div className="avatar-icon d-100">
                    <img alt="..." src={avatar7} />
                  </div>
                </div>
              </div>
              {/* <div className="font-weight-bold font-size-lg d-flex align-items-center mt-2 mb-0">

              </div> */}
              <div className="mb-4 font-size-md text-white-50">
                <CurrentUser {...user}></CurrentUser>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={8} className="d-flex align-items-center">
            <Container>
              <div className="pb-4">
                <StyledTabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                >
                  <StyledTab label="Update Profile" />
                  <StyledTab label="Change Password" />
                  {(user?.accountType !== 'User' && user?.accountType !== 'SuperAdmin' && user?.accountType !== 'SuperAdminUser') && (
                    <StyledTab label="Redeem PIN" />
                  )}
                  {(user?.accountType !== 'User' && user?.accountType !== 'SuperAdmin' && user?.accountType !== 'SuperAdminUser') && (
                    <StyledTab label="Company Info" />
                  )}
                </StyledTabs>
              </div>
              <TabPanel value={value} index={0}>
                <div className="rounded card card-box bg-white">
                  <div className="p-4">
                    <div className="align-box-row font-weight-bold">
                      <UserProfile {...user} />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="rounded card card-box bg-white mb-4">
                  <div className="p-4">
                    <div className="align-box-row font-weight-bold">
                      <ChangePassword />
                    </div>
                  </div>
                </div>
              </TabPanel>
              {(user?.accountType !== 'User' && user?.accountType !== 'SuperAdmin' && user?.accountType !== 'SuperAdminUser') && (
                <TabPanel value={value} index={2}>
                  <div className="rounded card card-box bg-white mb-4">
                    <div className="p-4">
                      <div className="align-box-row font-weight-bold">
                        <RedeemPin {...user} />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
              {(user?.accountType === "VendorAdmin") && (
                <TabPanel value={value} index={3}>
                  <div className="rounded card card-box bg-white mb-4">
                    <div className="p-4">
                      <div className="align-box-row font-weight-bold">
                        <CompanyInfo />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
            </Container>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default CurrentUserProfileContainer;
