import React from 'react'
import SelectOption from '../SelectOption';
import UploadOption from '../UploadOption';
import DateOption from '../DateOption';
import TimePicker from '../TimePicker';
import RangePicker from '../RangePicker';

const TypeSelectionForm = (props) => {
    const { 
        values, 
        createOptions, 
        handleChangeCreate, 
        selectedOption, 
        submitted, setUploadLimit, uploadLimit } = props
    if (values.type == '') return null

    if (values.type === 'MULTIPLE' || values.type === 'SINGLE' || values.type === 'SELECT') {
        return (
            <SelectOption 
                createOptions={createOptions} 
                handleChangeCreate={handleChangeCreate} 
                selectedOption={selectedOption} 
                submitted={submitted} 
                values={values}
            />
        )
    } else if (values.type === 'DATE') {
        return <DateOption values={values} selectedOption={selectedOption} />
    } else if(values.type === "FILE"){
        return <UploadOption selectedOption={selectedOption} setUploadLimit={setUploadLimit} uploadLimit={uploadLimit} />
    } else if(values.type === "RANGE") {
        return <RangePicker values={values} selectedOption={selectedOption} />
    } else {
        return null
    }
}

export default TypeSelectionForm