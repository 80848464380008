import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import AddProducts from "./user/containers/products/add-products.js";
import AddVendorQuestionWorkflow from "./user/containers/vendors/add-vendor-question-workflow.js";
import AddCampaign from "./user/containers/vendors/add-campaign.js";
import EditProducts from "./user/containers/products/edit-products.js";
import Analytics from "./user/containers/analytics.js";
import Conditions from "./user/containers/conditions.js";
import Coupons from "./user/containers/coupons.js";
import CustomerCategory from "./user/containers/customer-categories.js";
import Customer from "./user/containers/customers.js";
import VendorQuestion from "./user/containers/vendor-question.js";
import Campaign from "./user/containers/campaign.js";
import VendorQuestionWorkflow from "./user/containers/vendor-question-workflow.js";
import CurrentUserProfileContainer from "./user/containers/current-user-profile-container.js";
import ForgotPasswordContainer from "./public/containers/forgot-password-container.js";
import HomeContainer from "./public/containers/home-container.js";
import LeftSidebar from "./layouts/LeftSidebar/index.js";
import Preferences from './user/containers/preferences'
import Products from "./user/containers/products/index.js";
import QuestionContainer from "./user/containers/questionContainer.js";
import Questions from "./user/containers/questions.js";
import CampaignQuestion from "./user/containers/campaign-question.js";
import React, { useEffect, useRef } from "react";
import Settings from "./user/containers/settings.js";
import SignInContainer from "./public/containers/sign-in-container.js";
import SignUpContainer from "./public/containers/sign-up-container.js";
import SymptomConditionFormsContainer from "./user/containers/symptom-condition-forms-container.js";
import SymptomContainer from "./user/containers/symptom-container.js";
import Symptoms from "./user/containers/symptoms";
import TermsAndConditions from "./public/containers/terms-and-conditions-container.js";
import UserDashBoardContainer from "./user/containers/user-dashboard-container.js";
import { useLocation, useHistory } from 'react-router-dom';
import { useUser } from "./providers/UserProvider";
import Vendors from "./user/containers/vendors/index.js";
import UserMangement from "./user/containers/user-management.js";
import SetPassword from "./user/containers/set-password";
import { ref } from "yup";
import CouponRedeemContainer from "./public/containers/coupon-redeem-container.js";
import Signinloader from './user/components/signin-loader.js'
import Userproducts from "./user/containers/userproducts.js";


const jwt = require('jsonwebtoken');

const queryString = require('query-string');

const PrivateRoute = ({ component: Component, ...rest }) => {
  let { user } = useUser();
  return <Route
    {...rest}
    render={props => (user?.isPasswordSet === false || user?.isPasswordSet === null) ? (<Redirect to="/set-password" />) : user ? (
      <LeftSidebar>
        <Component user={user} {...props} />
      </LeftSidebar>
    ) : (<Redirect to="/signIn" />)} />
};

const App = () => {
  const { user } = useUser();
  const location = useLocation();
  const query = queryString.parse(location.search);


  return <div className="App"><Router>
    <Switch>
        {/* <HomeContainer user={user} /> */}
        <Route exact path="/" render={() => user ? <Redirect to={(user?.isPasswordSet === false || user?.isPasswordSet === null) ? '/set-password' : `/dashboard/?coupon=${query.coupon || ''}&campaign=${query.campaign || ''}`} /> : <Redirect to={`/signIn?coupon=${query.coupon || ''}&campaign=${query.campaign || ''}`} />} />
        <PrivateRoute path="/dashboard" component={UserDashBoardContainer} />
        <PrivateRoute path="/users" component={user?.accountType === 'SuperAdmin' || user?.accountType === 'SuperAdminUser' || user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? UserMangement : UserDashBoardContainer} />
        <PrivateRoute exact path="/vendors" component={user?.accountType === 'SuperAdmin' || user?.accountType === 'SuperAdminUser' ? Vendors : UserDashBoardContainer} />
        <PrivateRoute exact path="/profile" component={CurrentUserProfileContainer} />
        <PrivateRoute exact path="/addSymptom" component={SymptomConditionFormsContainer} />
        <PrivateRoute exact path="/addCondition" component={SymptomConditionFormsContainer} />
        <PrivateRoute exact path="/conditions" component={Conditions} />
        <PrivateRoute exact path="/symptoms" component={Symptoms} />
        <PrivateRoute exact path="/symptoms/:id" component={Symptoms} />
        <PrivateRoute exact path="/preferences" component={Preferences} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/products" component={user?.accountType === 'SuperAdmin' || user?.accountType === 'SuperAdminUser' || user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? Products : UserDashBoardContainer} />
        <PrivateRoute exact path="/products/add" component={user?.accountType === 'SuperAdmin' || user?.accountType === 'SuperAdminUser' || user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? AddProducts : UserDashBoardContainer} />
        <PrivateRoute exact path="/products/edit" component={user?.accountType === 'SuperAdmin' || user?.accountType === 'SuperAdminUser' || user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? EditProducts : UserDashBoardContainer} />
        <PrivateRoute exact path="/coupons" component={Coupons} />
        <PrivateRoute exact path="/customer-categories" component={CustomerCategory} />
        <PrivateRoute exact path="/customers" component={Customer} />
        <PrivateRoute exact path="/vendor-questions" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? VendorQuestion : UserDashBoardContainer} />
        <PrivateRoute exact path="/vendor-question-workflow" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? VendorQuestionWorkflow : UserDashBoardContainer} />
        <PrivateRoute exact path="/vendor-question-workflow/add" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? AddVendorQuestionWorkflow : UserDashBoardContainer} />
        <PrivateRoute exact path="/vendor-question-workflow/edit/:id" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? AddVendorQuestionWorkflow : UserDashBoardContainer} />
        <PrivateRoute exact path="/campaigns" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? Campaign : UserDashBoardContainer} />
        <PrivateRoute exact path="/campaign/add" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? AddCampaign : UserDashBoardContainer} />
        <PrivateRoute exact path="/campaign/edit/:id" component={user?.accountType === 'VendorAdmin' || user?.accountType === 'VendorAdminUser' ? AddCampaign : UserDashBoardContainer} />
        <PrivateRoute exact path="/addQuestion" component={QuestionContainer} />
        <PrivateRoute exact path="/user-products" component={Userproducts} />
        <PrivateRoute exact path="/analytics" component={Analytics} />
        <PrivateRoute exact path="/symptom/:id" component={SymptomContainer} />
        <PrivateRoute exact path="/questions" component={Questions} />
        <Route exact path="/campaign-questions" render={() => <CampaignQuestion user={user}/>} />
        <Route exact path="/signIn" render={() => user ? <Redirect to="/dashboard" /> : <SignInContainer />} />
        <Route exact path="/signInloader" render={() => user ? <Redirect to="/dashboard" /> : <Signinloader />} />
        <Route exact path="/set-password" render={() => !user ? <HomeContainer user={user} /> : user?.isPasswordSet ? <Redirect to="/dashboard" /> : <SetPassword />} />
        <Route exact path="/signUp" render={() => user ? <Redirect to="/dashboard" /> : <SignUpContainer />} />
        <Route
          exact
          path="/resetPassword"
          render={() => user ? <Redirect to="/dashboard" /> : <ForgotPasswordContainer />}
        />
        <Route
          exact
          path="/terms"
          render={() => <TermsAndConditions />}
        />
        <Route exact path="/confirmUser">
          <Redirect to="/signIn" />
        </Route>
        <Route exact path="/questions-response" render={(props) => {
          let user = getUserFromToken(query.token);
          return user ? <Questions user={user} {...props} /> : <Redirect to="/" />
        }} />
        <Route exact path="/coupon-redeem">
          <CouponRedeemContainer/>
        </Route>
        {/* <Route path="*">
          <Redirect to="/" />
        </Route> */}
    </Switch>
  </Router>
  </div>
};
const getUserFromToken = (token) => {
  try {
    return jwt.verify(token, 'doximple_sddfxfJA1t');
  } catch (error) {
    return null;
  }
}
export default App;

