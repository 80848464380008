

import * as Yup from 'yup';

import {
    Button,
    FormGroup,
    Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikTextField from '../../../common/components/FormikTextField';
import FormikTextarea from '../../../common/components/FormikTextarea';
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormikSelectField from '../../../common/components-V2/FormikSelectField';
import { useHistory } from 'react-router';
import {listVendors} from '../../containers/curd/vendors'
import { updateMasterProduct } from '../../containers/curd/MasterProduct';
import { updateVendorProduct } from '../../containers/curd/vendorProducts';

const validationSchema = Yup.object({
    title: Yup.string().required("Title is required."),
    category: Yup.string().required("Category is required."),
    brand: Yup.string().required("Brand is required."),
    price: Yup.number().typeError("Price must be an integer value.").required("Price is required."),
    description: Yup.string().required("Description is required."),
    // vendor: Yup.string().required("Vendor is required."),
})
const useStyles = makeStyles({
    root: {
        border: '1.5px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 10,
        padding: "10px 14px 18.5px 14px"
    },
});

function CreateProduct(props) {
    const history = useHistory();
    const { user, editable, setSnackBarDetails, vendorList = [], initialValues, rowid } = props
    
    const [allVendors, setallVendors] = useState([])

    useEffect(()=>{
        const fetchVendor = async() => {
            console.log(user.id)
            const getVendors = await listVendors(user.id, ['VendorAdminUser'])
            const getList = await getVendors.map((vendor, index) => {
                let insert = {
                    id:vendor.id,
                    value: vendor.email
                }
                return insert
            })
            setallVendors(getList)
        }
        fetchVendor()
    }, [])
    
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        if (editable) {
                            const data = await updateVendorProduct(values)
                        }
                        setSnackBarDetails({
                            message:
                                `Product ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        history.push(`/products?type=${editable ? "updated" : "created"}`);
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset
                }) => (
                    <Form>

                        <FormGroup>
                            <FormikTextField
                                name="title"
                                margin="normal"
                                label="Product Title"
                            />
                        </FormGroup>
                        <Grid container spacing={2}>
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="barcode_number"
                                        margin="normal"
                                        label="Barcode Number"
                                        disabled={true}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="model"
                                        margin="normal"
                                        label="Model"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="category"
                                        margin="normal"
                                        label="Category"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="brand"
                                        margin="normal"
                                        label="Brand"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="Manufacturer"
                                        margin="normal"
                                        label="Manufacturer"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="multipack"
                                        margin="normal"
                                        label="Multipack"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="format"
                                        margin="normal"
                                        label="Format"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid container item xs={6} direction="column">
                                <FormGroup>
                                    <FormikTextField
                                        name="price"
                                        margin="normal"
                                        label="Price"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <FormGroup className="mt-3">
                            <FormikTextarea
                                name="description"
                                margin="normal"
                                maxrows={4}
                                aria-label="Description"
                                placeholder="Description"
                            />
                        </FormGroup>
                        <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid || isLoading}
                        >
                            {editable ? 'Update' : 'Submit'}
                        </Button>
                        {!editable && <Button
                            type="reset"
                            className="mt-4 mb-2 ml-4"
                            variant="contained"
                        >
                            Reset
                        </Button>}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateProduct

