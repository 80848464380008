/* eslint-disable no-console */
import { useEffect, useState } from "react";
// import { Mutation } from "react-apollo";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";

export const useQuestion = questionItem => {
  const [questionNumber, setQuestionNumber] = useState("DOX-0001");
  const [questions, setQuestions] = useState({
    questionID: "DOX-0001",
    category: [],
    // followupToID: "",
    question: [],
    possibleResponses: []
  });

  useEffect(() => {
    async function fetchData(questionItem) {
      const response = await API.graphql(
        graphqlOperation(queries.listQuestions, {
          filter: {
            questionID: {
              contains: questionNumber
            }
          }
        })
      )
      .then(response => setQuestions(response));
      // .then(response.JSON.parse() )
    }
    fetchData();
  }, [questionItem, setQuestions]);

  return { questions, setQuestions };
};

// const [questionNumber, setQuestionNumber] = useState("DOX-0001");

// const addQuestion = () => {
//     setQuestionNumber([
//         ...questionNumber,
//         {
//             0: "DOX-0001"
//         }
//     ])
// };

// This async function worked as a test in symptom-condition-forms-container
// async getQuestions() {
//     // get question by questionID
//       const response = await API.graphql(graphqlOperation(queries.listQuestions, {
//         filter: {
//           questionID: {
//             contains: "DOX-0001"
//           }
//         }
//       }
//       ))
//       .then(response => this.setState({questions: response}))
//       ;

//  need to update the syntax of the graphQL query, and make the dependency questionID
//   useEffect(async () => {
//     const response = await API.graphql(graphqlOperation(queries.listQuestions, {
//         filter: {
//           questionID: {
//             contains: questionNumber
//           }
//         }
//     })
//     )
//     .then(question => setQuestion(question))
// }, [questionItem, setQuestion]);

//   async function getQuestion(questionID) {
//     // get question by questionID
//       const response = await API.graphql(graphqlOperation(queries.listQuestions, {
//         filter: {
//           questionID: {
//             contains: {questionID}
//           }
//         }
//       }
//       ));
//   };

// Line 11:13:  Effect callbacks are synchronous to prevent race conditions. Put the async function inside:

// useEffect(() => {
//   async function fetchData() {
//     // You can await here
//     const response = await MyAPI.getData(someId);
//     // ...
//   }
//   fetchData();
// }, [someId]); // Or [] if effect doesn't need props or state

// Learn more about data fetching with Hooks: https://fb.me/react-hooks-data-fetching  react-hooks/exhaustive-deps
