import Checkbox from "@material-ui/core/Checkbox";
import ControlField from "./ControlField";
import { Field } from "formik";
import React from "react";

const FormikCheckboxField = ({ render, ...props }) => {
  return (
    <Field {...props} component={ControlField} controlComponent={Checkbox} />
  );
};
export default FormikCheckboxField;
