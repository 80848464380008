import { makeStyles } from '@material-ui/core'
import React from 'react'
import FormikTextField from '../../../../common/components/FormikTextField'

const useStyles = makeStyles({
    container:{
        gap:'10px',
        display:'flex'
    }
})

const RangePicker = (props) => {
    const {values, selectedOption} = props
    const classes = useStyles()
  return (
    <div className={classes.container}>
        <FormikTextField
            name="minRange"
            margin="normal"
            className='w-25'
            label='Min Range'
            value={values.minRange ?? selectedOption.minRange ?? 0}
        />
        <FormikTextField
            name="maxRange"
            margin="normal"
            className='w-25'
            label='Max Range'
            value={values.maxRange ?? selectedOption.maxRange ?? 0}
        />
    </div>

  )
}

export default RangePicker