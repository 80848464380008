import { Button, Collapse, ListItem } from "@material-ui/core";
import React, { forwardRef, useState, useEffect } from "react";
import { NavLink as RouterLink, useHistory, useLocation } from "react-router-dom";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import clsx from "clsx";

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarMenuListItem = props => {
  const {
    title,
    href,
    setSidebarToggleMobile,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    links,
    ...rest
  } = props;
  const history = useHistory();
  const [open, setOpen] = useState(openProp);

  const location = useLocation();

  const [active, setActive] = useState(false);

  useEffect(() => {
    let count = 0;
    if(links){

      let linkArr = links ? links?.split(",") : [];
      linkArr.map((item) => {
        if (location.pathname.includes(item)) {
          count++;
        }
      });

    }else{
      if(location.pathname === href){
        count++;
      }
    }
    if (count > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft = 32 + 20 * depth;
  }

  const style = {
    paddingLeft
  };
  const handleClick = (href) => {
    setSidebarToggleMobile(false)
  }
  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx("app-sidebar-item", className)}
        disableGutters
      >
        <Button
          color="primary"
          className={clsx("app-sidebar-button", { active: open })}
          onClick={handleToggle}
          style={{ ...style, fontWeight: '500', fontSize: '14px' }}
        >
          {Icon && <Icon className="app-sidebar-icon" />}
          <span>{title}</span>
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>

        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx("app-sidebar-item", className)}
        disableGutters
      >
        <Link onClick={() => handleClick(href)} to={href}>
          <Button
            color="primary"
            disableRipple
            variant="text"
            className={clsx("app-sidebar-button-wrapper", `depth-${depth}`, active ? 'active-item' : '')}
            style={style}
          >
            {Icon && <Icon className="app-sidebar-icon" />}
            {title}
            {Label && (
              <span className="menu-item-label">
                <Label />
              </span>
            )}
          </Button>
        </Link>
      </ListItem>
    );
  }
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false
};

export default SidebarMenuListItem;
