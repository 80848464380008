/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      email
      mobile
      firstName
      lastName
      createdBy
      createdAt
      updatedAt
      displayName
      photoURL
      streetAddress
      city
      state
      zipCode
      isProvider
      providerType
      appAdminRole
      apiRole
      developerRole
      familyAdminRole
      providerRole
      providerAdminRole
      userRole
      userAdminRole
      subscriptionType
      SubscriptionExpiryDate
      userActive
      accountType
      isAccountActive
      isDeleted
      isPasswordSet
      companyName
      redeemCode
      companyLogo
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      nextToken
    }
  }
`;
export const getTrackedItems = /* GraphQL */ `
  query GetTrackedItems($id: ID!, $name: String!) {
    getTrackedItems(id: $id, name: $name) {
      id
      cat
      name
      desc
      createdAt
      updatedAt
      lnkdBus
      lnkdCond
      lnkdProd
      lnkdPrvder
      lnkdServ
      lnkdSymp
      lnkdTrtmnt
      lnkdBodyPart
      lnkdTypePain
    }
  }
`;
export const listTrackedItems = /* GraphQL */ `
  query ListTrackedItems(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelTrackedItemsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrackedItems(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cat
        name
        desc
        createdAt
        updatedAt
        lnkdBus
        lnkdCond
        lnkdProd
        lnkdPrvder
        lnkdServ
        lnkdSymp
        lnkdTrtmnt
        lnkdBodyPart
        lnkdTypePain
      }
      nextToken
    }
  }
`;
export const getQuestions = /* GraphQL */ `
  query GetQuestions($id: ID!) {
    getQuestions(id: $id) {
      id
      questionID
      category
      followupToID
      responseType
      otherField
      question
      possibleResponses
      nextQuestion
      listType
      loop
      enteredBy
      tags
      active
      onboarding
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        category
        followupToID
        responseType
        otherField
        question
        possibleResponses
        nextQuestion
        listType
        loop
        enteredBy
        tags
        active
        onboarding
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserResponses = /* GraphQL */ `
  query GetUserResponses($id: ID!) {
    getUserResponses(id: $id) {
      id
      userID
      sessionID
      createdAt
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const listUserResponses = /* GraphQL */ `
  query ListUserResponses(
    $filter: ModelUserResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        sessionID
        createdAt
        questionID
        question
        JSONresp
        resp
        dox001
        dox002
        dox003
        dox008
        dox011
        dox013
        dox014
        dox015
        dox019
        dox020
        dox021
        dox024
        isSubmitted
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConditionsAndSymptoms = /* GraphQL */ `
  query GetConditionsAndSymptoms($id: ID!) {
    getConditionsAndSymptoms(id: $id) {
      id
      userID
      trackIt
      diagBy
      beingTreated
      createdAt
      active
      sessionID
      questionID
      question
      JSONresp
      resp
      dox001
      dox002
      dox003
      dox008
      dox011
      dox013
      dox014
      dox015
      dox019
      dox020
      dox021
      dox024
      isSubmitted
      updatedAt
    }
  }
`;
export const listConditionsAndSymptoms = /* GraphQL */ `
  query ListConditionsAndSymptoms(
    $filter: ModelConditionsAndSymptomsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditionsAndSymptoms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trackIt
        diagBy
        beingTreated
        createdAt
        active
        sessionID
        questionID
        question
        JSONresp
        resp
        dox001
        dox002
        dox003
        dox008
        dox011
        dox013
        dox014
        dox015
        dox019
        dox020
        dox021
        dox024
        isSubmitted
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPreference = /* GraphQL */ `
  query GetPreference($id: ID!) {
    getPreference(id: $id) {
      id
      frequency
      questions
      notification_on {
        email
        sms
        app
      }
      remind_me
      user
      sendedNotifications
      createdAt
      updatedAt
    }
  }
`;
export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $id: ID
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPreferences(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        frequency
        questions
        notification_on {
          email
          sms
          app
        }
        remind_me
        user
        sendedNotifications
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationResponse = /* GraphQL */ `
  query GetNotificationResponse($id: ID!) {
    getNotificationResponse(id: $id) {
      id
      questionID
      label
      user
      response
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationResponses = /* GraphQL */ `
  query ListNotificationResponses(
    $id: ID
    $filter: ModelNotificationResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotificationResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        questionID
        label
        user
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      name
      expiry {
        from
        to
      }
      amount_off
      percent_off
      description
      max_redemptions
      tag
      createdBy
      campaignID
      couponIssued {
        items {
          id
          couponID
          userID
          couponCreatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      couponRedeemed {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      allProducts {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $id: ID
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoupons(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsLog = /* GraphQL */ `
  query GetNotificationsLog($id: ID!, $createdAt: AWSDateTime!) {
    getNotificationsLog(id: $id, createdAt: $createdAt) {
      id
      user
      message
      type
      link
      isRead
      isResponded
      expired
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationsLogs = /* GraphQL */ `
  query ListNotificationsLogs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelNotificationsLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotificationsLogs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        message
        type
        link
        isRead
        isResponded
        expired
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCouponScanned = /* GraphQL */ `
  query GetCouponScanned($id: ID!) {
    getCouponScanned(id: $id) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponRedeems {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
          couponCouponRedeemedId
        }
        nextToken
      }
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      couponCreatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listCouponScanneds = /* GraphQL */ `
  query ListCouponScanneds(
    $id: ID
    $filter: ModelCouponScannedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCouponScanneds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        couponID
        coupon {
          id
          name
          amount_off
          percent_off
          description
          max_redemptions
          tag
          createdBy
          campaignID
          createdAt
          updatedAt
        }
        userID
        user {
          id
          email
          mobile
          firstName
          lastName
          createdBy
          createdAt
          updatedAt
          displayName
          photoURL
          streetAddress
          city
          state
          zipCode
          isProvider
          providerType
          appAdminRole
          apiRole
          developerRole
          familyAdminRole
          providerRole
          providerAdminRole
          userRole
          userAdminRole
          subscriptionType
          SubscriptionExpiryDate
          userActive
          accountType
          isAccountActive
          isDeleted
          isPasswordSet
          companyName
          redeemCode
          companyLogo
          owner
        }
        couponRedeems {
          nextToken
        }
        customerCategoryRelations {
          nextToken
        }
        couponCreatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCouponRedeem = /* GraphQL */ `
  query GetCouponRedeem($id: ID!) {
    getCouponRedeem(id: $id) {
      id
      couponID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      couponCreatedBy
      redeemBy
      createdAt
      updatedAt
      couponCouponRedeemedId
    }
  }
`;
export const listCouponRedeems = /* GraphQL */ `
  query ListCouponRedeems(
    $id: ID
    $filter: ModelCouponRedeemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCouponRedeems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        couponID
        coupon {
          id
          name
          amount_off
          percent_off
          description
          max_redemptions
          tag
          createdBy
          campaignID
          createdAt
          updatedAt
        }
        userID
        user {
          id
          email
          mobile
          firstName
          lastName
          createdBy
          createdAt
          updatedAt
          displayName
          photoURL
          streetAddress
          city
          state
          zipCode
          isProvider
          providerType
          appAdminRole
          apiRole
          developerRole
          familyAdminRole
          providerRole
          providerAdminRole
          userRole
          userAdminRole
          subscriptionType
          SubscriptionExpiryDate
          userActive
          accountType
          isAccountActive
          isDeleted
          isPasswordSet
          companyName
          redeemCode
          companyLogo
          owner
        }
        couponCreatedBy
        redeemBy
        createdAt
        updatedAt
        couponCouponRedeemedId
      }
      nextToken
    }
  }
`;
export const getMasterQuestion = /* GraphQL */ `
  query GetMasterQuestion($id: ID!) {
    getMasterQuestion(id: $id) {
      id
      type
      question
      options
      createdBy
      isDeleted
      fileSize
      minDate
      maxDate
      formTime
      minRange
      maxRange
      createdAt
      updatedAt
    }
  }
`;
export const listMasterQuestions = /* GraphQL */ `
  query ListMasterQuestions(
    $id: ID
    $filter: ModelMasterQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMasterQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        question
        options
        createdBy
        isDeleted
        fileSize
        minDate
        maxDate
        formTime
        minRange
        maxRange
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasterQuestionWorkflow = /* GraphQL */ `
  query GetMasterQuestionWorkflow($id: ID!) {
    getMasterQuestionWorkflow(id: $id) {
      id
      name
      questions
      createdBy
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listMasterQuestionWorkflows = /* GraphQL */ `
  query ListMasterQuestionWorkflows(
    $id: ID
    $filter: ModelMasterQuestionWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMasterQuestionWorkflows(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        questions
        createdBy
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTriggerType = /* GraphQL */ `
  query GetTriggerType($id: ID!) {
    getTriggerType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listTriggerTypes = /* GraphQL */ `
  query ListTriggerTypes(
    $id: ID
    $filter: ModelTriggerTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTriggerTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      triggerID
      trigger {
        id
        name
        createdAt
        updatedAt
      }
      bitlyLink
      workflowID
      workflow {
        id
        name
        questions
        createdBy
        isDeleted
        createdAt
        updatedAt
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      coupons
      introScreen
      isNotified
      isFuture
      message
      createdBy
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $id: ID
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaigns(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        triggerID
        trigger {
          id
          name
          createdAt
          updatedAt
        }
        bitlyLink
        workflowID
        workflow {
          id
          name
          questions
          createdBy
          isDeleted
          createdAt
          updatedAt
        }
        customerCategoryID
        customerCategory {
          id
          name
          createdBy
          createdAt
          updatedAt
        }
        coupons
        introScreen
        isNotified
        isFuture
        message
        createdBy
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkflowQuestionResponse = /* GraphQL */ `
  query GetWorkflowQuestionResponse($id: ID!) {
    getWorkflowQuestionResponse(id: $id) {
      id
      campaignID
      workflowID
      questionID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const listWorkflowQuestionResponses = /* GraphQL */ `
  query ListWorkflowQuestionResponses(
    $id: ID
    $filter: ModelWorkflowQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkflowQuestionResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        campaignID
        workflowID
        questionID
        userID
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTempSurveyRepsonse = /* GraphQL */ `
  query GetTempSurveyRepsonse($id: ID!) {
    getTempSurveyRepsonse(id: $id) {
      id
      campaignID
      workflowID
      questionID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const listTempSurveyRepsonses = /* GraphQL */ `
  query ListTempSurveyRepsonses(
    $id: ID
    $filter: ModelTempSurveyRepsonseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTempSurveyRepsonses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        campaignID
        workflowID
        questionID
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerCategory = /* GraphQL */ `
  query GetCustomerCategory($id: ID!) {
    getCustomerCategory(id: $id) {
      id
      name
      customerCategoryRelations {
        items {
          id
          userID
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerCategories = /* GraphQL */ `
  query ListCustomerCategories(
    $id: ID
    $filter: ModelCustomerCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerCategoryRelation = /* GraphQL */ `
  query GetCustomerCategoryRelation($id: ID!) {
    getCustomerCategoryRelation(id: $id) {
      id
      userID
      user {
        id
        email
        mobile
        firstName
        lastName
        createdBy
        createdAt
        updatedAt
        displayName
        photoURL
        streetAddress
        city
        state
        zipCode
        isProvider
        providerType
        appAdminRole
        apiRole
        developerRole
        familyAdminRole
        providerRole
        providerAdminRole
        userRole
        userAdminRole
        subscriptionType
        SubscriptionExpiryDate
        userActive
        accountType
        isAccountActive
        isDeleted
        isPasswordSet
        companyName
        redeemCode
        companyLogo
        owner
      }
      customerCategoryID
      customerCategory {
        id
        name
        customerCategoryRelations {
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerCategoryRelations = /* GraphQL */ `
  query ListCustomerCategoryRelations(
    $id: ID
    $filter: ModelCustomerCategoryRelationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerCategoryRelations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        user {
          id
          email
          mobile
          firstName
          lastName
          createdBy
          createdAt
          updatedAt
          displayName
          photoURL
          streetAddress
          city
          state
          zipCode
          isProvider
          providerType
          appAdminRole
          apiRole
          developerRole
          familyAdminRole
          providerRole
          providerAdminRole
          userRole
          userAdminRole
          subscriptionType
          SubscriptionExpiryDate
          userActive
          accountType
          isAccountActive
          isDeleted
          isPasswordSet
          companyName
          redeemCode
          companyLogo
          owner
        }
        customerCategoryID
        customerCategory {
          id
          name
          createdBy
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasterProduct = /* GraphQL */ `
  query GetMasterProduct($id: ID!) {
    getMasterProduct(id: $id) {
      id
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listMasterProducts = /* GraphQL */ `
  query ListMasterProducts(
    $id: ID
    $filter: ModelMasterProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMasterProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorProduct = /* GraphQL */ `
  query GetVendorProduct($id: ID!) {
    getVendorProduct(id: $id) {
      id
      vendorID
      title
      price
      barcode_number
      model
      category
      Manufacturer
      brand
      format
      multipack
      description
      rate
      isDeleted
      allCoupons {
        items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVendorProducts = /* GraphQL */ `
  query ListVendorProducts(
    $id: ID
    $filter: ModelVendorProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVendorProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vendorID
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        rate
        isDeleted
        allCoupons {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductCoupon = /* GraphQL */ `
  query GetProductCoupon($id: ID!) {
    getProductCoupon(id: $id) {
      id
      couponID
      vendorProductID
      coupon {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        campaignID
        couponIssued {
          nextToken
        }
        couponRedeemed {
          nextToken
        }
        allProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorProduct {
        id
        vendorID
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        rate
        isDeleted
        allCoupons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProductCoupons = /* GraphQL */ `
  query ListProductCoupons(
    $filter: ModelProductCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        couponID
        vendorProductID
        coupon {
          id
          name
          amount_off
          percent_off
          description
          max_redemptions
          tag
          createdBy
          campaignID
          createdAt
          updatedAt
        }
        vendorProduct {
          id
          vendorID
          title
          price
          barcode_number
          model
          category
          Manufacturer
          brand
          format
          multipack
          description
          rate
          isDeleted
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
