import * as Yup from "yup";

import {
  Button,
  Container,
  InputAdornment,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { compose, palette, spacing } from "@material-ui/system";

// import { signOut } from "../../firebase";
// import { useUser, signOut } from "../../providers/UserProvider";
import { Auth } from "aws-amplify";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import FormikTextField from "../../common/components/FormikTextField";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import awsconfig from "../../aws-exports";
import { styled } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { withStyles } from "@material-ui/core/styles";

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }))(Tooltip);

const initialValues = {
  password: "",
  confirmPassword: "",
  oldPassword: "",
};

const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
    ).notOneOf([Yup.ref('oldPassword')], 'Old and new password should not be same.'),
  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .test("passwords-match", "Passwords does not match.", function (value) {
      return this.parent.password === value;
    }),
});

Auth.configure(awsconfig);
const Box = styled("div")(compose(spacing, palette));

const ChangePassword = () => {
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    severity: "error",
    links: [],
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  function renderEmailForm() {

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
              user,
              values.oldPassword,
              values.password
            );
            setSnackBarDetails({
              message: "Password changed successfully",
              severity: "success",
              links: [],
            });
          } catch (err) {
            let message = "Unknown error has occured";
            let links = [];
            if (err && err.message) {
              message = err.message;
            }
            if (err.code === "NotAuthorizedException") {
              message = "Old password is incorrect";
            }
            setSnackBarDetails({ message, severity: "error", links });
          }
          setSubmitting(false);
        }}
        validationSchema={passwordValidationSchema}
        validateOnMount
      >
        {({ isValid, isSubmitting }) => (
          <Form className="SignUp w-100">
            <FormikTextField
              className="w-100"
              name="oldPassword"
              label="Old Password"
              placeholder="Old Password"
              type={showOldPassword ? 'text' : 'password'}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(bool => !bool)}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )

              }}
            />
            <FormikTextField
              className="w-100"
              name="password"
              label="New Password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(bool => !bool)}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )

              }}
            />
            <FormikTextField
              className="w-100"
              name="confirmPassword"
              label="Confirm New Password"
              placeholder="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(bool => !bool)}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )

              }}
            />
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              variant="contained"
              color="primary"
              className="mt-2"
            >
              Reset Password
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
  return (
    // <section className="CurrentUser">
    <>
      <Box bgcolor="white">{renderEmailForm()}</Box>
      <DoxSnackBar snackBarDetails={snackBarDetails}></DoxSnackBar>
    </>
  );
};

export default ChangePassword;
