import * as mutations from "../../graphql/mutations";

import API, { graphqlOperation } from "@aws-amplify/api";
import { Button, Card } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import EditTrackedSymptomConditionFormContainer from './tracked-condition-symptom-edit-container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from '@material-ui/core/Grid';
import Modal from "../../common/components/userExitConfirmationModal";
import { Plus } from "react-feather";
import PropTypes from "prop-types";
import Switch from '@material-ui/core/Switch';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from '@material-ui/core/Typography';
import { css } from "@emotion/react";
import {deleteTrackedUserResponses} from "../../common/utils/common"
import { getAllTrackedSymptomsByUser, getTrackedConditionsById } from "../../common/services/cond-and-symp.js";
import { getUnSavedUserSession } from "../../common/services/question-engine.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import {
  useParams,
} from "react-router-dom";
import atob from 'atob';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const modalInitialValue = {
  open: false,
  no: true,
  yes: false,
  resId: '',
  resIndex: ''
}
const moment = require("moment");
const DATE_FORMAT = "MM-DD-YYYY";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > [a.orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
const headCells = [
  { id: "symtoms", numeric: false, disablePadding: true, label: "Symptoms" },
  { id: "bodyParts", numeric: false, disablePadding: true, label: "Body parts" },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created At"
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Last updated"
  },
  {
    id: "isTracked",
    numeric: false,
    disablePadding: false,
    label: "Is Tracked"
  },
  { id: "action", disableSort: true, numeric: true, disablePadding: false, label: "Action" }
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={["symtoms", "bodyParts"].includes(headCell.id) ? "left" : "right"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="font-weight-bold"
          >
           {!headCell?.disableSort ? <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
                      ) : null}
                  </TableSortLabel>
                  :
                 <span> { headCell.label}</span>
                  }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const SynptomList = (props) => {

  const [hasId, setHasId] = useState(false)
  const { id } = useParams();
  
  useEffect(() => {
    fetchOld();
  }, [id])

  const fetchOld = async () => {
    if(id) {
      const decodedId = atob(id);
      setHasId(true)
      let getData = await getTrackedConditionsById(decodedId);
      setSymptoms([getData]);
    }else {
      setHasId(false)
      loadTrackedSymptoms();
    }
  }
  const classes = useStyles();
  const [time, setTime] = useState(null);
  const [summarySymptoms, setSummarySymptoms] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const[isLoading, setIsLoading]  = useState(true)
  const [deleteResponseModal, setDeleteResponseModal] = useState(modalInitialValue);
  const [isEditing, setIsEditing] = useState(false)
  const [editableResponse, setEditableResponse] = useState(null)
  const [allTrackedSymptoms, setAllTrackedSymptoms] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  useEffect(() => {
    getUnsavedSession();
  }, []);
  // Check if user has unsaved session
  async function getUnsavedSession() {
    const response = await getUnSavedUserSession(props.user.id);

    if (response && response.createdAt) {
      let t = moment(response.createdAt);
      setTime(t.format(DATE_FORMAT));
    }
  }


  function handleEdit(index, resp) {
    setIsEditing(true)
    setEditableResponse(resp)
  }

  function handleDelete(index, resp) {
    setDeleteResponseModal({
      ...deleteResponseModal, 
      open: true, 
      resId: resp['id'],
      resIndex: resp['index'],
      title: `Please be sure, this action would permanently delete your current response.`,
      button1: `Delete`,
    })
  }

  // get symptoms using userID
  const loadTrackedSymptoms = async () => {
    let response = await getAllTrackedSymptomsByUser(props.user.id);

    let filteredItem = response.filter(item => {
      return !!(item["dox001"]?.includes("No") || item["dox003"]?.includes("No"));
    });
    setSymptoms(filteredItem,"symptoms-page")
  };

  const setSymptoms = (filteredItem, page = null) => {
    //set
    let result = filteredItem.map(item => {
      let dox015 =item["dox015"] ? JSON.parse(item["dox015"]) : [];
      let dox019 = item["dox019"] ? JSON.parse(item["dox019"]) : [];
      let dox020 = item["dox020"] ? JSON.parse(item["dox020"]) : [];

      let dox013 = item["dox013"]? JSON.parse(item["dox013"]):[];
      let dox021 = item["dox021"]? JSON.parse(item["dox021"]):[];
      
      let bodyParts = item["dox024"] ? JSON.parse(item["dox024"]) : [];

      let symptoms = [];
      if(page && page ==="symptoms-page") {
        symptoms = [...dox015, ...dox019]
      }else{
        symptoms = [...dox013, ...dox021]
      }

      return {
        ...item,
        dox015,
        dox019,
        dox020,
        bodyParts,
        createdAt: new Date(item?.createdAt).getTime(),
        updatedAt: new Date(item?.updatedAt).getTime(),
        symtoms: symptoms,
      };
    });
    let splitSymps = []
    result.map((items)=>{
      if(items.symtoms.length > 1)
      {
        return items.symtoms.map((item)=>{
          splitSymps.push({...items, 
            index: uuidv4(), 
            bodyParts: item.toLowerCase() == 'pain' 
            ? items.bodyParts
            : "", 
            symtoms: item,
            isTracked: items.dox020.includes(item)
          });
          return item;
        })
      }
      splitSymps.push({...items, 
        index: uuidv4(),
        bodyParts: items.symtoms[0]?.toLowerCase() == 'pain' 
      ? items.bodyParts
      : '', 
      isTracked: items.dox020.includes(items.symtoms[0])
    });
      return items;
    })
    setIsLoading(false)

    setSummarySymptoms(splitSymps || []);
    // set
  }
  const handleIsTracked = (data, isTracked)=>{
    setSummarySymptoms(items=>{
     return items.map((item, i)=>{
      if(item.id == data.id)
      {
        if(isTracked)
        {
          let dox020 = [...item.dox020, ...data.symtoms];
          if(item.index == data.index)
          {
            updateConditionsAndSymptoms('dox020', data.id , dox020 )
            return { ...item, isTracked, dox020  }
          }
          return { ...item, dox020 }
        }
        if(item.index == data.index)
        {
          updateConditionsAndSymptoms('dox020', data.id , item.dox020.filter(i=>i != data.symtoms) )
          return { ...item, isTracked, dox020: item.dox020.filter(i=>i != data.symtoms) }
        }
        return { ...item, dox020: item.dox020.filter(i=>i != data.symtoms) }  
      }
      return item;
     })
    })

  }

  const showBodyPars = (data) => {
    if(!data)
    return "--";
    let res = data?.map(item=>{
      return `<span>${item.label} : ${item.value}</span>`
    })
   return res ? res.join(',</br>') :res; 
  }
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, summarySymptoms.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderList() {
    return (
      <Fragment>
       { !isLoading ? <TableBody>
        {summarySymptoms && summarySymptoms.length !=0 ? (
            stableSort(summarySymptoms, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              (resp, index) => {
                return (
                  <TableRow key={index} className="border">
                    <TableCell align="left">
                      { resp.symtoms }
                    </TableCell>
                    <TableCell align="left" dangerouslySetInnerHTML={{ __html: showBodyPars(resp.bodyParts) }}>
                    </TableCell>
                    <TableCell align="right">{moment(resp.createdAt).format(DATE_FORMAT)}</TableCell>
                    <TableCell align="right"> {moment(resp.updatedAt).format(DATE_FORMAT)}</TableCell>
                    <TableCell align="right" className="d-flex"> 
                    <Typography component="div">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Off</Grid>
                        <Grid item>
                          <AntSwitch checked={resp.isTracked} onChange={()=>handleIsTracked(resp, !resp.isTracked)} />
                        </Grid>
                        <Grid item>On</Grid>
                      </Grid>
                    </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <button className="text-danger cursor-pointer" style={{border: 'none', background: 'none'}} onClick={() => handleDelete(index, resp)}>
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              }
            )
        ) : <TableCell className="text-muted" colSpan="6" style={{ margin: 10, textAlign: "center" }}>
        No Symptoms!
    </TableCell>
      }
      { emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
        </TableBody>
        : <TableBody>
          <TableCell colSpan="6" style={{ margin: 10, textAlign: "center" }}>
        <CircularProgress />
        </TableCell>
        </TableBody>
        }
      </Fragment>
    );
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

   // This function is used to update user responses
   const updateConditionsAndSymptoms = async (columnName, id, resp)=> {
    const response = await API.graphql(
      graphqlOperation(mutations.updateConditionsAndSymptoms, {
        input: {
          id,
          [columnName]: JSON.stringify(resp)
        }
      })
    );
    return response;
  }
const history = useHistory()
  return (
    <>
    { isEditing ? <EditTrackedSymptomConditionFormContainer editableResponse={editableResponse} allTrackedSymptoms={allTrackedSymptoms} checkPlace={'onSymptoms'} goBack={()=>setIsEditing(false)} />
      : <Fragment>
        <Card className="card-box mb-4">
          <div className="card-header">
          <div className="card-header--title d-flex justify-content-between align-items-center">
              <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                Symptoms list
              </h4>
              {hasId && (

                <Button variant="contained" color="primary" 
                  onClick={()=>{
                    history.push('/symptoms')
                  }}
                  style={{
                    position: 'absolute',
                    left: '165px'
                  }}
                  ><CachedIcon />
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={()=>history.push('/addSymptom')}><Plus />Add New</Button>
            </div>
          </div>
          <div className="card-body px-0 pt-2 pb-3">
            <TableContainer className="table table-hover table-borderless table-alternate text-nowrap mb-0">
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {renderList()}
              </Table>
            </TableContainer>
            <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={summarySymptoms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

            <Modal 
              settings={deleteResponseModal}
              close={async success => {
                if (success === 'delete and proceed') {
                  await deleteTrackedUserResponses(deleteResponseModal['resId']).then(() => {
                    setSummarySymptoms(symptoms=>symptoms.filter(({id}) => id != deleteResponseModal['resId']))
                  })
                }
                setDeleteResponseModal({ ...deleteResponseModal, open: false });
              }}
            />
          </div>
        </Card>
      </Fragment>
    }
    </>
  );
};

export default SynptomList;
