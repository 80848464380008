import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";


export const listTriggerType = async () => {
  let params = {
    
  };
  let { data: { listTriggerTypes } } = await API.graphql(
    graphqlOperation(queries.listTriggerTypes, params)
  );
  return listTriggerTypes.items;
}

