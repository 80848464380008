import * as Yup from 'yup';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { createCoupon, updateCoupon } from '../../containers/curd/coupon';
import FormikCheckboxField from '../../../common/components/FormikCheckboxField';
import FormikDatePicker from '../../../common/components/FormikDatePicker';
import FormikTextField from '../../../common/components/FormikTextField';
import FormikTextarea from '../../../common/components/FormikTextarea';
import FormikSelectField from '../../../common/components-V2/FormikSelectField';
import { Label } from 'aws-amplify-react';
import React, { useEffect, useState } from 'react'
import ReactSelect from '../../../common/components/ReactSelect';
import { createNotificationsLog } from '../../containers/curd/notifications';
import { createCouponScanned, getCouponScannedByCouponCreatedBy, getCouponScannedByCouponId } from '../../containers/curd/couponScanned';
import { notificationType, notificationMessage } from '../../../common/utils/constants';
import { createProductCoupon, deleteProductCoupon } from '../../containers/curd/ProductCouponRelation';
import ReactMultiSelect from '../../../common/components/ReactMultiSelect';
import { arrayIncludes } from '@material-ui/pickers/_helpers/utils';
import { listVendorProduct } from '../../containers/curd/vendorProducts';


const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    discount: Yup.string().required("Discount is required.").matches(/^[+-]?\d+(\.\d+)?$/, "Discount is not valid."),
    description: Yup.string().required('Description is required.'),
    max_redemptions: Yup.number().typeError("Max Redemptions must be a number."),
    expiry: Yup.object({
        from: Yup.date().typeError("Expiry from is required.").required('Expiry from is required.'),
        to: Yup.date().typeError("Expiry to is required.").required('Expiry to is required.')
    }),
})


function CreateCoupon({ user, closeModal, setCreatedItem, editable, setUpdatedItem, setSnackBarDetails }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
        discount: '',
        isPercentOff: true,
        isAmountOff: false,
        description: '',
        max_redemptions: '',
        expiry: {
            from: new Date(),
            to: null
        },
        tag: '',
    });


    const maxRedemptionOptions = [
        { value: '1', id: '1' },
        { value: '2', id: '2' },
        { value: '3', id: '3' },
        { value: '4', id: '4' },
        { value: '5', id: '5' }
    ]
    const defaultOption = {
        value: [],
        label: 'Search Product',
        isAllSelect:true
    }
    const [selectedOption, setSelectedOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [products, setProducts] = useState([]);
    const [ItemTobeDeleted, setItemTobeDeleted] = useState([])
    const demoUser = [
        {
            value: '1',
            label: 'user 1'
        },
        {
            value: '2',
            label: 'user 2'
        },

    ]
    const [couponScannedUser, setCouponScannedUser] = useState(demoUser);

    const [selectedUser, setSelectedUser] = useState([]);
    const [sendCoupon, setSendCoupon] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const handleSendCoupon = (e) => {
        const value = e.target.checked;
        setSendCoupon(value);
    }

    const handleSelectAll = (e) => {
        const value = e.target.checked;
        if(value) {
            setSelectedUser(couponScannedUser);
        }else{
            setSelectedUser([]);
        }
        setSelectAll(value);
    }

    useEffect(() => {
        if (selectedUser.length === couponScannedUser.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedUser, couponScannedUser])

    const handleChangeSelectedUser = (selectedOption) => {
        setSelectedUser(selectedOption);
    }

    const handleChangeProduct = (selectedOption) => {
        setSelectedOption(selectedOption);
    }

    const resetSelectedOption = () => {
        setSelectedOption(defaultOption);
    }

    const findBrand = async (arr, item) => {
        let obj = arr.find((o, i) => {
            if (o.label === item) {
                return true; 
            }
            return false
        });
        return obj
    }

    const findProduct = async(arr, item) => {
        console.log("Array of product", arr)
        let obj = arr.find((val, i) => {
            if (val.id === item.vendorProductID) {
                return item.vendorProductID; 
            }
            return false
        });
        return obj
    }

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true)
            let allProductdata = []
            const vendorProduct = await listVendorProduct(user.id, user.accountType)

            let product = vendorProduct.map((item) => {return {...item, vendorProductId: item.id}})
            // await listProducts(user.id, user.accountType);
            console.log("Vendorproduct list", product)
            for (let index = 0; index < product.length; index++) {
                let brandLabel = await findBrand(allProductdata, product[index].brand)
                let item = product[index]
                if (brandLabel) {
                    let insert = {
                        label: item.title, id: item.vendorProductId, value: item.vendorProductId
                    }
                    brandLabel.value.push(insert)
                } else {
                    let insert = {
                        id: index,
                        value: [{ label: item.title, id: item.vendorProductId, value:item.vendorProductId }],
                        label: item.brand
                    }
                    allProductdata.push(insert)
                }
            }
            allProductdata.unshift(defaultOption)
            setProducts(allProductdata);
            setLoading(false);

            if (editable) {
                // console.log("Product of vendor", product)
                const { vendorProductID, allProducts } = editable;
                console.log("Allproduct", allProducts, editable)
                allProducts?.items.map((item) => setItemTobeDeleted(state => [...state ,item.id]))
                // if (vendorProductID) {
                    // value === productID   
                    const selectedProduct = []
                    for (let index = 0; index < allProducts.items.length; index++) {
                        let allValuesid = allProductdata.map(async(items,i)=> {
                            let work = await findProduct(items.value, allProducts.items[index])
                            if(work){
                                return work.id
                            }
                        })
                        Promise.all(allValuesid).then((res)=>{
                            res.map((item)=>{
                                if(item){
                                    selectedProduct.push(item)
                                }
                            })
                        })
                    }
                    setSelectedOption(selectedProduct)
                // }
            }
        }
        fetchData();
        
    }, [user, editable])

    useEffect(() => {
        setInitialValues((values) => {
            if (!editable) return values;
            let { name, discount, expiry, description, percent_off, max_redemptions, tag } = editable;
            return {
                name,
                discount: percent_off ? discount.split('%')[0] : discount.split('$')[1],
                isPercentOff: percent_off ? true : false,
                isAmountOff: percent_off ? false : true,
                description,
                max_redemptions,
                expiry: {
                    from: new Date(expiry.from),
                    to: new Date(expiry.to)
                },
                tag: tag || '',
            }
        })

    }, [editable])


    useEffect(() => {
        const getExistingUser = async () => {

            let existing = await getCouponScannedByCouponCreatedBy(user.id);

            let items = existing?.data?.listCouponScanneds?.items;
            if (items) {
                let user = []
                user = items.map(item => {
                    let name = item.user.firstName ? ' - ' + item.user.firstName + ' ' + item.user.lastName : ''
                    return {
                        value: item.userID,
                        label: item.user.email + name
                    }
                })
                let uniqueUser = user.filter((value, index, self) => {
                    return self.findIndex(item => item.value === value.value) === index
                })
                setCouponScannedUser(uniqueUser);

            }
        }
        getExistingUser()

    }, [user])



    const resetAll = (e) => {
        setSelectedUser([]);
        resetSelectedOption();
        setSelectAll(false);
        setSendCoupon(false);
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        setLoading(true)
                        if (values.max_redemptions === '') {
                            return;
                        }
                        if (values.isPercentOff) {
                            values.percent_off = parseInt(values.discount);
                            values.amount_off = null
                        }
                        else {
                            values.amount_off = parseInt(values.discount);
                            values.percent_off = null;
                        }
                        let sendNotification = false;
                        if (initialValues.discount !== values.discount || initialValues.expiry.to !== values.expiry.to) {
                            sendNotification = true;
                        }
                        delete values.isPercentOff;
                        delete values.isAmountOff;
                        delete values.discount;
                        values.max_redemptions = parseInt(values.max_redemptions)
                        // values.productID = selectedOption ? selectedOption[0] : 'null';
                        let couponId = '';
                        if (!editable) {
                            let { data } = await createCoupon({ ...values, createdBy: user.id });
                            setCreatedItem(data.createCoupon)
                            couponId = data.createCoupon.id;
                            if(selectedOption.length > 0){
                                for (let index = 0; index < selectedOption.length; index++) {  
                                    let input = {
                                        couponID: couponId,
                                        vendorProductID: selectedOption[index]
                                    }
                                    console.log("Input", input)
                                    const check = await createProductCoupon(input) 
                                    //  console.log("Selcted",selectedOption[index],check)                                    
                                }  
                            }
                        }
                        else {
                            let { data } = await updateCoupon({ ...values, id: editable.id })
                            setUpdatedItem(data.updateCoupon);
                            let arr = [];   
                            const deletedItem = await deleteProductCoupon(ItemTobeDeleted)
                            if(selectedOption.length > 0){
                                for (let index = 0; index < selectedOption.length; index++) {  
                                    let input = {
                                        couponID: data.updateCoupon.id,
                                        vendorProductID: selectedOption[index]
                                    }
                                    const check = await createProductCoupon(input)                                    
                                }
                            }
                        }

                        setInitialValues({
                            name: '',
                            discount: '',
                            isPercentOff: false,
                            description: '',
                            max_redemptions: '',
                            expiry: {
                                from: '',
                                to: ''
                            },
                            tag: '',
                        })
                        resetSelectedOption();
                        //send notification
                        let link = "/coupons";
                        if (editable && sendNotification) {

                            let existing = await getCouponScannedByCouponId(editable.id);

                            if (existing?.data?.listCouponScanneds?.items?.length > 0) {
                                let items = existing?.data?.listCouponScanneds?.items;

                                await Promise.all(
                                    items.map(async (node) => {

                                        let data = {
                                            user: node.userID,
                                            message: notificationMessage.couponUpdated,
                                            type: notificationType.couponUpdated,
                                            link: link,
                                            expired: false,
                                            isRead: false,
                                            isResponded: false,
                                        }
                                        await createNotificationsLog(data);
                                    })
                                )
                            }

                        }

                        if (sendCoupon && selectedUser.length > 0) {
                            await Promise.all(

                                selectedUser.map(async (node) => {

                                    let input = {
                                        couponID: couponId,
                                        userID: node.value,
                                        couponCreatedBy: user.id
                                    }
                                    let { data } = await createCouponScanned(input);
                                    if (data) {
                                        let notification = {
                                            user: node.value,
                                            message: notificationMessage.couponCreated,
                                            type: notificationType.couponCreated,
                                            link: link,
                                            expired: false,
                                            isRead: false,
                                            isResponded: false,
                                        }
                                        await createNotificationsLog(notification);

                                    }
                                })

                            )
                        }

                        setSnackBarDetails({
                            message:
                                `Coupon ${editable ? "updated" : "created"} successfully`,
                            severity: "success",
                            links: [],
                        });
                        closeModal()
                    } catch (error) {
                        setSnackBarDetails({
                            message: error.message,
                            severity: "error",
                            links: [],
                        });
                        // closeModal()
                    } finally {
                        setLoading(false)
                        fetchData()
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleReset,
                }) => (
                    <Form>
                        <FormGroup>
                            <FormikTextField
                                name="name"
                                margin="normal"
                                label="Name"
                            />
                        </FormGroup>
                        <FormGroup className="d-flex flex-row justify-content-between align-items-center">
                            <FormikTextField
                                name="discount"
                                margin="normal"
                                label="Discount"
                                className="w-50"
                            />
                            <FormikCheckboxField
                                name="isPercentOff"
                                color="secondary"
                                trueValue={false}
                                falseValue={true}
                                checked={!values.isPercentOff}
                                controlLabel={"$ Off"}
                            />
                            <FormikCheckboxField
                                name="isPercentOff"
                                color="secondary"
                                trueValue={true}
                                falseValue={false}
                                checked={values.isPercentOff}
                                controlLabel={"Percent Off"}
                            />
                        </FormGroup>
                        <FormGroup>

                            <FormikSelectField
                                name="max_redemptions"
                                margin="normal"
                                label="Max Redemptions per user"
                                options={maxRedemptionOptions}
                                error={submitted && values.max_redemptions === ''}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormikTextField
                                name="tag"
                                margin="normal"
                                label="Tag"
                                className="w-100"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ReactMultiSelect setSelectedOption={setSelectedOption}  selectedOption={selectedOption} products={products} />
                        </FormGroup>
                        <FormGroup className="my-3">
                            <Label>Description:</Label>
                            <FormikTextarea
                                name="description"
                                margin="normal"
                                maxrows={4}
                                aria-label="Description"
                                placeholder="Description"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Expiry:</Label>
                            <Grid container >
                                <Grid item xs={12} sm={6} >
                                    <Label style={{ marginRight: '20px', marginTop: '5px' }}>From: </Label>
                                    {editable
                                        ?
                                        (
                                            <FormikDatePicker
                                                value={values.expiry.from || new Date()}
                                                name="expiry.from"
                                                format="MM/dd/yyyy"
                                            ></FormikDatePicker>
                                        ) : (
                                            <FormikDatePicker
                                                value={values.expiry.from || new Date()}
                                                name="expiry.from"
                                                format="MM/dd/yyyy"
                                                minDate={new Date()}
                                            ></FormikDatePicker>
                                        )
                                    }

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Label style={{ marginRight: '20px', marginTop: '5px' }}>To: </Label>
                                    <FormikDatePicker
                                        value={values.expiry.to}
                                        name="expiry.to"
                                        format="MM/dd/yyyy"
                                        minDate={new Date(values.expiry.from)}
                                        className="p-0"
                                    ></FormikDatePicker>
                                </Grid>
                            </Grid>
                        </FormGroup>
                        {!editable && (
                            <FormGroup className="my-3">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendCoupon}
                                            onChange={handleSendCoupon}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Send coupon to existing users"
                                />
                                {sendCoupon && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Select all"
                                        />
                                        <ReactSelect
                                            name="user"
                                            label="User"
                                            options={couponScannedUser}
                                            onChange={handleChangeSelectedUser}
                                            selectedOption={selectedUser}
                                            isMulti={true}
                                        />
                                    </>
                                )}
                            </FormGroup>
                        )}
                        {loading ?<CircularProgress size="1.5rem" color="inherit" /> : <Button
                            type="submit"
                            className="mt-4 mb-2"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            onClick={(e) => { setSubmitted(true) }}
                        >
                            Submit
                        </Button>}
                        {!editable && (
                            <Button
                                type="reset"
                                className="mt-4 mb-2 ml-4"
                                variant="contained"
                                onClick={resetAll}
                            >
                                Reset
                            </Button>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CreateCoupon
