import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
      height:'100%',
      '& .MuiCard-root':{
          height:'100%'
      }
    },
});


export default useStyles