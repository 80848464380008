import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import API, { graphqlOperation } from "@aws-amplify/api";

export const createProductCoupon = async (input) => {
  console.log("ProductCouponRelation", input)
  try {
    let { data: { createProductCoupon } } = await API.graphql(
      graphqlOperation(mutations.createProductCoupon, { input })
    );

    console.log("CreateProduct", data)

    return createProductCoupon;
  } catch (error) {
    console.log("Error", error)
  }
}

export const ListProductCoupons = async() => {
  let params;

  params = {
    limit: 30,
  }

  try {
    let { data: { listProductCoupons } } = await API.graphql(
      graphqlOperation(queries.listProductCoupons, params)
    );
    return listProductCoupons;
  } catch (error) {
    console.log("Error", error)
  }
}

// check 
export const deleteProductCoupon = async (coupons) => {
  let promises = [];
  try {
    for (let index = 0; index < coupons.length; index++) {
      const id = coupons[index];
      let { data: { deleteProductCoupon } } = await API.graphql(
        graphqlOperation(mutations.deleteProductCoupon, { input: { id } })
      )
      promises.push(deleteProductCoupon);
    }
  } catch (e) {
    console.log("Error", e.message)
  }
  return Promise.all(promises);
}
