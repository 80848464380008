import React, { useEffect, useState } from 'react'
import { CKEditor } from 'ckeditor4-react';

export default function Editor({ onChange, value, edit }) {

    const [data, setData] = useState('');
    const classic = 'classic';

    const readOnly = false;

    useEffect(() => {
        onChange(data);
    }, [data]);

    return (
        <>
          {edit && (
            <>
              <CKEditor
                type={classic ? 'classic' : 'inline'}
                initData={value}
                readOnly={readOnly}
                onChange={(e) => setData(readOnly ? '' : e.editor.getData())}
              />
            </>
          )}
          {!edit && (
            <CKEditor
              type={classic ? 'classic' : 'inline'}
              readOnly={readOnly}
              onChange={(e) => setData(readOnly ? '' : e.editor.getData())}
            />
          )}
        </>
      );
}
