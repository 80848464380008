import React from 'react'
import CreatableSelect from 'react-select/creatable';
import { useState } from 'react';

const components = {
  DropdownIndicator: null,
};


  const createOption = (label) => ({
    label,
    value: label,
  });
  

export default function CreateSelect({isMulti, options, label, selectedOption, onChange}) {

    const [inputValue, setInputValue] = useState('');

    const handleChange = (
        value,
        actionMeta
      ) => {
        
        onChange(value);
        
      };
    const  handleInputChange = (inputValue) => {
        setInputValue(inputValue)
      };

      const handleKeyDown= (event) => {
        if (!inputValue) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            
            setInputValue('');
            onChange([...selectedOption, createOption(inputValue)])
            
            event.preventDefault();
        }
      };

    const customStyles = {
        control: base => ({
          ...base,
          minHeight: 55,
          borderRadius: 10
        })
    };
    return (
        <>
            <div className='react-select-div'>
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder={label}
                    value={selectedOption}
                    styles={customStyles}
                />
            </div>            
        </>
        
    )
}
