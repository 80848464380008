import { Box, Button, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import CreateQuestionWorkflow from '../../components/vendor/create-question-workflow';
import { ArrowBackOutlined } from "@material-ui/icons";
import React, { useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import DoxSnackBar from '../../../common/components/DoxSnackBar';
import { useState } from 'react';
import { getSingleQuestionWorkflow } from '../curd/vendorQuestionWorkflow';
import { listQuestion } from '../curd/vendorQuestion';
import CustomModal from '../../components/CustomModal';
import CreateQuestion from '../../components/vendor/create-question';
import CampaignPreview from '../../components/vendor/campaign-preview';
function AddVendorQuestionWorkflow(props) {
    const { id } = useParams();
    const [vendorList, setVendorList] = useState([])
    const [editable, setEditable] = useState(null);
    const [open, setOpen] = useState(false)
    const [openPreview, setOpenPreview] = useState(false);
    const history = useHistory();
    const [snackBarDetails, setSnackBarDetails] = useState({
        message: null,
        links: [],
        severity: "error",
    });
    const [initialValues, setInitialValues] = useState({
        name: '',
        
    });
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [createOptions, setCreateOptions] = useState([]);
    const [createdItem, setCreatedItem] = useState(null);

    useEffect(() => {
        const fetchList = async () => {
            if(createdItem){

                let vendors = await listQuestion(props.user.id);
                let selectoption = vendors.map(item=>{
                    return {
                        value: item.id,
                        label: item.question
                    }
                })
                setCreateOptions(selectoption)
            }
            
        }
        fetchList();
    
        setCreatedItem(null);
      }, [createdItem])

    useEffect( () => {

        const fetchWorkflow = async () => {
            if(id){

                let vendors = await getSingleQuestionWorkflow(id);
                
                setEditable(vendors)
            }
        }
        fetchWorkflow();
    }, [id])

    useEffect( () => {

        const fetchQuestionList = async () => {

            let vendors = await listQuestion(props.user.id);
            let selectoption = vendors.map(item=>{
                return {
                    value: item.id,
                    label: item.question
                }
            })
            setCreateOptions(selectoption)

                let questions = editable?.questions?JSON.parse(editable?.questions):[];
                let selected = []
                questions.map(item=>{
                    let itemToPush = selectoption.find(sel => sel.value === item);
                    if(itemToPush){
                        selected.push(itemToPush);
                    }
                })
                setSelectedQuestions(selected)
            
        }
        fetchQuestionList();
    }, [editable?.questions])
    
    
    const addQuestion = async (e) => {
        setOpen(true);
    }

    return (
        <>
            <Box>
                <Card className='mh-500'style={{minHeight:530}}>
                    <DoxSnackBar snackBarDetails={snackBarDetails} />
                    <CardHeader component={() => <div className="m-3 d-flex justify-content-between align-items-center">
                        <Link to='/vendor-question-workflow'><Typography color="secondary" className="m-0" variant="h4"><ArrowBackOutlined /> {id ? "Edit Question Workflow" : "Add Question Workflow"}</Typography></Link>

                        <div className='divMargin'>
                            <Button
                                onClick={addQuestion}
                                color="secondary" variant="contained">{'Add Question'}</Button>
                        </div>
                    </div>} />
                    <CardContent>
                        <CreateQuestionWorkflow 
                            setSelectedOption={setSelectedOption}
                            selectedOption={selectedOption} 
                            selectedQuestions={selectedQuestions}
                            setSelectedQuestions={setSelectedQuestions}
                            createOptions={createOptions} 
                            editable={editable} 
                            user={props.user} 
                            location={props.location} 
                            vendorList={vendorList} 
                            initialValues={initialValues} 
                            setSnackBarDetails={setSnackBarDetails} 
                            setOpenPreview={setOpenPreview}
                            setEditable={setEditable}
                        />
                    </CardContent>
                </Card>
            </Box>
            <CustomModal  fullWidth open={open} setOpen={(bool) => { setOpen(bool); setEditable(null) }} title={`Question > ${editable ? " Update Question" : "Add New Question"} `}>
                <CreateQuestion user={props.user} closeModal={() => { setOpen(false); setEditable(null) }} setCreatedItem={setCreatedItem} editable={null} setSnackBarDetails={setSnackBarDetails} />
            </CustomModal>
            <CustomModal  fullWidth open={openPreview} setOpen={(bool) => { setOpenPreview(bool); history.push(`/vendor-question-workflow`) }} title={`Question Workflow Preview`}>
                <CampaignPreview isWorkflow={true} user={props.user} closeModal={() => { setOpenPreview(false); history.push(`/vendor-question-workflow`) }} editable={editable} setSnackBarDetails={setSnackBarDetails} />
            </CustomModal>
        </>
    )
}

export default AddVendorQuestionWorkflow
