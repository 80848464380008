import { Checkbox, FormControl, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import FormikTextField from '../../../../common/components/FormikTextField'

const useStyles = makeStyles({
    container: {}
})

const UploadOption = (props) => {
    const {setUploadLimit, uploadLimit, selectedOption} = props
    const [check, setCheck] = useState(false)
    const classes = useStyles()

    const handleChange = (e) => {
        setUploadLimit(e.target.value)
    }

    return (
        <div className={classes.container}>
            <FormControl component="fieldset">
                <FormGroup
                    aria-label="position" row
                    onChange={(value) => setCheck(value.target.checked)}
                >
                    <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" checked={check} />}
                        label="Set file upload size limit"
                        labelPlacement="end"
                    />
                </FormGroup>
            </FormControl>
            {check && 

                <div className="mb-3">
                    <FormikTextField
                        className="w-100"
                        variant="outlined"
                        type="number"
                        label="Maximum Limit (MB)"
                        name="fileSize"
                        value={selectedOption ?? uploadLimit}
                        onChange={handleChange}
                        placeholder="Default size limit is 10"
                    />
                </div>
            }

        </div>
    )
}

export default UploadOption