import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createCampaign = async (input) => {
  
  let { data: { createCampaign } } = await API.graphql(
    graphqlOperation(mutations.createCampaign, { input })
  );
  return createCampaign;
}
export const listCampaign = async (userId) => {
  let params = {
    filter: {
      createdBy: {
        eq: userId
      }
    }
  };
  let { data: { listCampaigns } } = await API.graphql(
    graphqlOperation(queries.listCampaigns, params)
  );
  return listCampaigns.items;
}

export const listCampaignByTrigger = async (trigger, createdBy) => {
  let params = {
    filter: {
      triggerID: {
        eq: `${trigger}`
      },
      createdBy: {
        eq: createdBy
      },
      isActive: {
        eq: true
      }
    }
  };
  let { data: { listCampaigns } } = await API.graphql(
    graphqlOperation(queries.listCampaigns, params)
  );
  return listCampaigns.items;
}

export const deleteCampaign = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    const id = coupons[index];
    let { data: { deleteCampaign } } = await API.graphql(
      graphqlOperation(mutations.deleteCampaign, { input: { id } })
    )
    promises.push(deleteCampaign);
  }
  return Promise.all(promises);
}

export const updateCampaign = async (input) => {
  let { data: { updateCampaign } } = await API.graphql(
    graphqlOperation(mutations.updateCampaign, { input })
  );
  return updateCampaign;
}

export const getCampaign = async (input) => {
  let { data: { getCampaign } } = await API.graphql(
    graphqlOperation(queries.getCampaign, { id: input })
  );
  return getCampaign;
}
