import { FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CustomError from "../components/CustomError";
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: "100%",
    },
    label: {
        backgroundColor: "white",
        transform: "translate(10px, 7px)",
    },
    select: {
        marginTop: "15px",
        padding: "15px",
        paddingTop: "15px",
        borderRadius: "10px",
        color: "hsl(0, 0%, 20%)",
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    selectError: {
        marginTop: "15px",
        padding: "15px",
        paddingTop: "15px",
        borderRadius: "10px",
        color: "hsl(0, 0%, 20%)",
        borderColor: "#f83245"
    },
}));

const FormikSelectField = ({ children, render, ...props }) => {
    const { label, options, required,submitted ,value,error, ...rest } = props;
    const classes = useStyles();
    return (
        <>
        <FormControl className={classes.formControl}>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <Field as="select" {...rest} className={error && error === true?classes.selectError:classes.select}>
                <option value="" style={{ color: 'gray' }}>{label}</option>
                {options.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>{option.value}</option>
                    );
                })}
            </Field>
        </FormControl>
        {(error && error === true) && (
            <CustomError message={`${label} is required`}/>               
        )}
        </>
    );
};

export default FormikSelectField;
