export const listCouponScanneds = /* GraphQL */ `
  query ListCouponScanneds(
    $id: ID
    $filter: ModelCouponScannedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCouponScanneds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        couponID
        coupon {
          id
          name
          expiry {
            from
            to
          }
          amount_off
          percent_off
          description
          max_redemptions
          tag
          createdBy
          createdAt
          updatedAt
        }
        userID
        user {
          id
          email
          mobile
          firstName
          lastName
          createdBy
          createdAt
          updatedAt
          displayName
          photoURL
          streetAddress
          city
          state
          zipCode
          isProvider
          providerType
          appAdminRole
          apiRole
          developerRole
          familyAdminRole
          providerRole
          providerAdminRole
          userRole
          userAdminRole
          subscriptionType
          SubscriptionExpiryDate
          userActive
          accountType
          isAccountActive
          isDeleted
          isPasswordSet
          companyName
        }
        couponRedeems {
          items {
            id
            couponID
            userID
            couponCreatedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCategoryRelations {
          items {
            id
            userID
            customerCategoryID
            customerCategory {
              id
              name
              createdBy
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCustomerCategories = /* GraphQL */ `
  query ListCustomerCategories(
    $id: ID
    $filter: ModelCustomerCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        customerCategoryRelations {
          items {
            id
            userID
            customerCategoryID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCustomerCategory = /* GraphQL */ `
  query GetCustomerCategory($id: ID!) {
    getCustomerCategory(id: $id) {
      id
      name
      customerCategoryRelations {
        items {
          id
          userID
          user {
            id
            email
            mobile
            firstName
            lastName
          }
          customerCategoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $id: ID
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoupons(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        expiry {
          from
          to
        }
        amount_off
        percent_off
        description
        max_redemptions
        tag
        createdBy
        couponIssued {
          items {
          id
          couponID
          userID
          couponCreatedBy
          createdAt
          updatedAt
        }
          nextToken
        }
        campaignID
        couponRedeemed {
        items {
          id
          couponID
          userID
          couponCreatedBy
          redeemBy
          createdAt
          updatedAt
        }
        nextToken
        }
        allProducts {
          items{
            id
            couponID
            vendorProductID
            createdAt
            updatedAt
          }
          nextToken
        }
      
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $id: ID
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        coupons {
          items {
          id
          name
          amount_off
          percent_off
          description
          max_redemptions
          tag
          productID
          createdBy
          createdAt
          updatedAt
        }
          nextToken
        }
        allCoupons {
          items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
          nextToken
        }
        vendor
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const listVendorProducts = /* GraphQL */ `
  query ListVendorProducts(
    $id: ID
    $filter: ModelVendorProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVendorProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vendorID
        title
        price
        barcode_number
        model
        category
        Manufacturer
        brand
        format
        multipack
        description
        rate
        isDeleted
        allCoupons {
          items {
          id
          couponID
          vendorProductID
          createdAt
          updatedAt
        }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;