import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import { getIn } from "formik";
import React from "react";
import { toggle } from "../utils/functions";
import s from "../utils/rowStyles";

class DoxCheckboxGroupField extends React.Component {
  static defaultProps = {
    row: false,
    multiple: false
  };

  filterValue = (value, stringresponse) => {
    const optionValues = this.props.options.map(option => option.value);
    let resValue = value
    return resValue ? resValue.filter(v => optionValues.includes(v)) : [];
  };

  handleChange = event => {
    const { field, multiple, onChange, stringresponse } = this.props;
    let value;
    if (multiple) {
      value = toggle(this.filterValue(field.value, stringresponse), event.target.value);
    } else if (event.target.checked) {
      value = event.target.value;
    }

    field.onChange({
      target: { value: value, name: field.name }
    });
    if (onChange) {
      onChange(value, event);
    }
  };

  

  render() {
    const {
      field,
      form: { touched, errors },
      options,
      multiple,
      label,
      row,
      helperText,
      FormLabelProps,
      FormHelperTextProps,
      FormControlLabelProps,
      CheckboxProps,
      FormGroupProps,
      onChange,
      className,
      classes,
      stringresponse,
      ...props
    } = this.props;
    const message = getIn(touched, field.name) && getIn(errors, field.name);
    return (
      <FormControl
        className={cx({ [classes.rowContainer]: row === "all" }, className)}
        error={Boolean(message)}
        {...props}
      >
        {label && (
          <FormLabel
            {...FormLabelProps}
            className={cx(
              { [classes.rowLabel]: row === "all" },
              FormLabelProps && FormLabelProps.className
            )}>
            {label}
          </FormLabel>
        )}
        <FormGroup {...FormGroupProps} row={Boolean(row)}>
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  {...CheckboxProps}
                  value={option.value}
                  onChange={this.handleChange}
                  checked={
                    multiple
                      ? field.value
                        ? field.value.includes(option.value)
                        : false
                      : field.value
                      ? option.value === field.value
                      : false
                  }
                />
              }
              {...FormControlLabelProps}
              label={option.label}
            />
          ))}
        </FormGroup>
        {(message || helperText) && (
          <FormHelperText
            {...FormHelperTextProps}
            className={cx(
              { [classes.rowHelperText]: row === "all" },
              FormHelperTextProps && FormHelperTextProps.className
            )}>
            {message || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(s)(DoxCheckboxGroupField);
