import { List, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import SidebarMenuListItem from "./SidebarMenuListItem";
// import { matchPath } from "react-router-dom";
import useRouter from "../../utils/useRouter";

const SidebarMenuList = props => {
  const { pages, ...rest } = props;
  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const reduceChildRoutes = props => {
  const { router, items, page, depth, setSidebarToggleMobile } = props;
  if (page.content) {
    // const open = matchPath(router.location.pathname, {
    //   path: page.to,
    //   exact: false
    // });
    const open = page.content.some(({ to }) => to == window.location.pathname)
    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}
        setSidebarToggleMobile={setSidebarToggleMobile}
      >
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList
            depth={depth + 1}
            pages={page.content}
            router={router}
            setSidebarToggleMobile={setSidebarToggleMobile}
          />
        </div>
      </SidebarMenuListItem>
    );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        links={page.links}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        title={page.label}
        setSidebarToggleMobile={setSidebarToggleMobile}
      />
    );
  }

  return items;
};

const SidebarMenu = props => {
  const { title, setSidebarToggleMobile, pages, className, component: Component, ...rest } = props;

  const router = useRouter();
  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} router={router} setSidebarToggleMobile={setSidebarToggleMobile} />
    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

SidebarMenu.defaultProps = {
  component: "nav"
};

export default SidebarMenu;
