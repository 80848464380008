import React from "react";
// import { signOut } from "../../firebase";
import { Link } from "react-router-dom";
// import { useUser, signOut } from "../../providers/UserProvider";
import { Auth, } from 'aws-amplify';
import awsconfig from '../../aws-exports';
//import * as queries from '../../graphql/queries'

Auth.configure(awsconfig);



const CurrentUser = ({ firstName, lastName, photoURL, email, children }) => {
  return (
    <section className="CurrentUser">
      <div className="CurrentUser--profile">
        {photoURL && <img src={photoURL} alt={firstName} />}
        <div className="CurrentUser--information">
          <Link to="/" style={{ color: '#ffffff' }}>
            <div className="font-weight-bold-white font-size-lg d-flex align-items-center mt-2 mb-0">
              <h2>
                {firstName} {lastName}
              </h2>

            </div>
          </Link>
          <p className="email">{email}</p>
        </div>
      </div>
      {/* <div>
        <div>{children}</div>
        <button onClick={signOut}>Sign Out</button>
      </div> */}
    </section>
  );
};

CurrentUser.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  photoURL: "",
  createdAt: ""
};

export default CurrentUser;
