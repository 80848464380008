import {Box} from "@material-ui/core";
import ListCategory from "../components/customer/list-category";
import React from 'react'
const CustomerCategory = (props) => 
    <Box container>
        <ListCategory user={props.user}/>
    </Box>

export default CustomerCategory;

