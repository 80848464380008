import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import API, { graphqlOperation } from "@aws-amplify/api";

export const createCustomerCategoryRelation = async (input) => {
  
  let { data: { createCustomerCategoryRelation } } = await API.graphql(
    graphqlOperation(mutations.createCustomerCategoryRelation, { input })
  );
  return createCustomerCategoryRelation;
}

export const deleteCustomerCategoryRelation = async (coupons) => {
  let promises = [];
  for (let index = 0; index < coupons.length; index++) {
    const id = coupons[index];
    let { data: { deleteCustomerCategoryRelation } } = await API.graphql(
      graphqlOperation(mutations.deleteCustomerCategoryRelation, { input: { id } })
    )
    promises.push(deleteCustomerCategoryRelation);
  }
  return Promise.all(promises);
}

export const listCustomerCategoriesRelation = async(user) => {

  let params = {
    filter: {
      userID: {
        eq: user
      }
    }
  };

  let { data:{listCustomerCategoryRelations:{items}} } = await API.graphql(
    graphqlOperation(queries.listCustomerCategoryRelations, params)
  )

  return items
}
