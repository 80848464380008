import React, { useState } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { styled } from "@material-ui/core";
import ReactSelect from './ReactSelect';

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
    listStyleType: "none",
    display: "inline",
}));

const useStyles = makeStyles({
  chip: {
    backgroundColor: 'rgb(233, 233, 233)',
    margin: 4,
    marginBottom: 8
  },
  selector: {
    width: '100%',
    marginTop: 10
  }
});
export default function ReactSelectChip({label, options, setOptions, selectedOptions, setSelectedOptions, onDelete}) {

  const classes = useStyles();

  const defaultOption = {
      value:'',
      label:label
  }
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  const resetOption = () => {
    setSelectedOption(defaultOption);
  }

  const handleChange = (e) => {
    if (e) {
          let old = [];
          selectedOptions.map((item) => {
            old.push(item);
          });
          old.push(e);
          setSelectedOptions(old);
          let oldOptions = [];
          options.map((item) => {
            if(item.value !== e.value){
              oldOptions.push(item);
            }
          });
          setOptions(oldOptions);
    }

  }
  return (
    <div className="mat-chip-list-wrapper">
      {selectedOptions.map((data, i) => {
        return (
          <ListItem key={data.value}>
            <Chip className={classes.chip} label={data.label} onDelete={onDelete(i)} />
          </ListItem>
        );
      })}
      
      <ReactSelect
          name="product"
          label="Product"
          options={options}
          onChange={handleChange}
          selectedOption={selectedOption}
      />
    </div> 
  );
}
