import { CircularProgress } from '@material-ui/core';
import { deleteCoupons, listCoupons } from '../../containers/curd/coupon';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import ConfirmationModal from "../../../common/components/modalConfirmation";
import CreateCoupon from './create-coupon';
import { CropFree } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CustomModal from '../CustomModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import QrCode from 'qrcode.react'
import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import btoa from 'btoa';
import moment from 'moment';
import DoxSnackBar from '../../../common/components/DoxSnackBar';
import EnhancedTableHead, { EnhancedTableToolbar, getComparator, stableSort } from '../../../common/components/Tables/EnhancedTableHead';

// import { deleteProductCoupon } from '../../containers/curd/productCouponRelation';
import { getSingleUser } from '../../containers/curd/vendors';
import { deleteRelationByCouponId } from '../../containers/curd/couponScanned';
import { deleteCouponRedeemByCouponId } from '../../containers/curd/couponRedeem';

const format = 'MM/DD/YYYY';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'discount', numeric: true, disablePadding: false, label: 'Discount' },
  { id: 'couponIssues', numeric: true, disablePadding: false, label: 'Coupon Issued' },
  { id: 'reedemtionCount', numeric: true, disablePadding: false, label: 'Customers Redeemed' },
  { id: 'totalRedemption', numeric: true, disablePadding: false, label: 'Total Redemptions' },
  // { id: 'max_redemptions', numeric: true, disablePadding: false, label: 'Max redemptions' },
  { id: 'from', numeric: true, disablePadding: false, label: 'Valid from' },
  { id: 'to', numeric: true, disablePadding: false, label: 'Valid to' },
  { id: 'qr1', numeric: true, disablePadding: false, sortable: false, label: 'QR' },
  // { id: 'assignProduct', numeric: false, disablePadding: false, sortable: false, label: 'Assign Product' }
];

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};




EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ListCoupons(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(null);
  const [createdItem, setCreatedItem] = useState(null);
  const [updatedItem, setUpdatedItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showQr, setShowQr] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [showAssignProduct, setShowAssignProduct] = useState(false);
  const [assignProduct, setAssignProduct] = useState(null);
  const [allcouponID, setallcouponID] = useState([])
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    links: [],
    severity: "error",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    title: "Are you sure, You want to delete this!",
    open: false,
    no: true,
    yes: false
  });
  useEffect(() => {
    setConfirmationModal({ ...confirmationModal, title: `Are you sure, You want to delete ${selected.length && selected.length > 1 ? `these ${selected.length} coupans!` : `this coupan!`}` })
  }, [selected])

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true)

      let userId = props.user.id
      if (props.user.accountType === "VendorAdminUser") {
        const { createdBy } = await getSingleUser(props.user.id)
        userId = createdBy
      }
      try {
        let coupons = await listCoupons(userId);
        coupons = coupons?.map((coupon) => {
          return {
            ...coupon,
            discount: coupon.amount_off ? '$' + coupon.amount_off : coupon.percent_off + '%',
            from: coupon.expiry.from,
            to: coupon.expiry.to
          };
        })
        setRows(coupons)
        setLoading(false);
      } catch (error) {
        console.log("Error",error)
      }

    }
    fetchData();
  }, [])


  useEffect(() => {
    setRows(rows => {
      if (!createdItem) return rows;
      let newItem = {
        ...createdItem,
        discount: createdItem.amount_off ?? createdItem.percent_off + '%',
        from: createdItem.expiry.from,
        to: createdItem.expiry.to
      }
      return [newItem, ...rows]
    })
    setCreatedItem(null);
  }, [createdItem])

  useEffect(() => {
    setRows(rows => {
      if (!updatedItem) return rows;
      let items = rows.filter(({ id }) => id !== updatedItem.id);
      let newItem = {
        ...updatedItem,
        discount: updatedItem.amount_off ? '$' + updatedItem.amount_off : updatedItem.percent_off + '%',
        from: updatedItem.expiry.from,
        to: updatedItem.expiry.to
      }
      return [newItem, ...items]
    })
    setUpdatedItem(null);
    setEditable(null);
    setSelected([]);
  }, [updatedItem])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDeleteConfirm = async () => {
    setConfirmationModal((values) => ({ ...values, open: true }));
  }
  const handleDelete = async () => {
    setLoadingDelete(true);
    console.log(selected, allcouponID)
    selected.map(async (couponid)=>{
      await deleteRelationByCouponId(props.user.id, couponid)
      await deleteCouponRedeemByCouponId(props.user.id, couponid)
    })
    await deleteCoupons(selected);
    setRows((rows) => rows.filter(({ id }) => !selected.includes(id)));
    setSelected([]);
    setLoadingDelete(false)
  }

  useEffect(() => {
    //let allcouponID = []
    for (let index = 0; index < selected.length; index++) {
      let items = rows.find(({ id }) => id === selected[index]);
      if (items?.allProducts?.items[0]?.id) {
        setallcouponID(state => [...state, items?.allProducts?.items[0]?.id])
      }
    }
  }, [selected])

  const handleEdit = () => {
    let item = rows.find(({ id }) => id === selected[0]);
    setOpen(true);
    setEditable(item);
  }
  const handleQrCode = (coupon) => {
    console.log({ coupon })
    setShowQr(true);
    if (coupon.campaignID) {
      setQrCode(`${process.env.REACT_APP_SERVER_URL}/campaign-questions?campaign=${btoa(coupon.campaignID)}&coupon=${btoa(coupon.id)}`)
    } else {
      setQrCode(`${process.env.REACT_APP_SERVER_URL}?coupon=${btoa(coupon.id)}`)
    }
  }


  const getRedeememCustomer = (items) => {
    const key = 'userID';
    if (items) {
      const arrayUniqueByKey = [...new Map(items.map(item =>
        [item[key], item])).values()];
      return arrayUniqueByKey.length
    } else {
      return 0
    }

  }

  return (
    <>
      {!loading ? <>
        <div className={classes.root}>
          <DoxSnackBar snackBarDetails={snackBarDetails} />
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} user={props.user} setOpen={setOpen} handleDelete={handleDeleteConfirm} handleEdit={handleEdit} loadingDelete={loadingDelete} title={'Coupons'} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  user={props.user}
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}

                        >
                          <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.discount}</TableCell>
                          <TableCell align="right">{row.couponIssued.items.length}</TableCell>
                          {/* <TableCell align="right">{row.max_redemptions ?? "--"}</TableCell> */}
                          <TableCell align="right">{getRedeememCustomer(row.couponRedeemed.items)}</TableCell>
                          <TableCell align="right">{row.couponRedeemed.items.length}</TableCell>
                          <TableCell align="right">{moment(row.expiry.from).format(format)}</TableCell>
                          <TableCell align="right">{moment(row.expiry.to).format(format)}</TableCell>
                          <TableCell align="right">
                            <CropFree onClick={() => handleQrCode(row)} titleAccess="QR Code" color="primary" />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={headCells.length + 1} align="center">
                        {rows.length === 0 && (
                          "No data found"
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </div>
        <CustomModal fullWidth open={open} setOpen={(bool) => { setOpen(bool); setEditable(null) }} title="Coupon">
          <CreateCoupon user={props.user} closeModal={() => { setOpen(false); setEditable(null) }} setCreatedItem={setCreatedItem} editable={editable} setUpdatedItem={setUpdatedItem} setSnackBarDetails={setSnackBarDetails} />
        </CustomModal>
        <CustomModal open={showQr} setOpen={(bool) => { setShowQr(bool); setQrCode("") }} title="QR Code">
          <QrCode size={200} value={qrCode} />
        </CustomModal>
      </> : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>
      }
      <ConfirmationModal
        settings={confirmationModal}
        close={success => {
          if (success) {
            handleDelete()
          }
          setConfirmationModal({ ...confirmationModal, open: false });
        }}
      ></ConfirmationModal>
    </>
  );
}

