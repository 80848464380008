import React from 'react'
import FormikDatePicker from '../../../common/components/FormikDatePicker'

const DateSelection = (props) => {

  const {currentQuestion} = props
  console.log("CurrQuestion", currentQuestion)
  return(
    <FormikDatePicker
      name="questionResponse"
      format="MM/dd/yyyy"
      label="Select date"
      minDate={currentQuestion.minDate ? new Date(currentQuestion.minDate) : undefined}
      maxDate={currentQuestion.maxDate ? new Date(currentQuestion.maxDate) : undefined}
      value={new Date()}
    />
  )
}

export default DateSelection