import { css as emoCSS } from "@emotion/react";
import matchSorter from "match-sorter";

// easier way to use emotion css

const css = (...args) => {
  return { className: emoCSS(...args) };
};
// collect ids and docs from firebase
const collectIdsandDocs = doc => {
  return { id: doc.id, ...doc.data() };
};

const getItems = (items, filter) => {
  return filter
    ? matchSorter(items, filter, {
      keys: ["name"]
    })
    : items;
};

const getStringItems = (items, filter) => {
  return getItems(items, filter).map(({ name }) => name);
};

const itemToString = i => (i ? i.name : "");

function uniqArray(arr, label) {
  return arr.filter((v,i,a)=>a.findIndex(t=>(t[label]===v[label]))===i)
}
function getDayByLabel(frequency){
  return frequency == 'Daily' ? 1 : frequency == "Weekly" ? 7 : 2;
}

export { css, collectIdsandDocs, getDayByLabel, uniqArray, getItems, getStringItems, itemToString };
