import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

export const createPreference = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.createPreference, { input })
  );
}

export const listPreferences = async (userId, filter = {}, rest) => {
  let params = {
    filter: {
      user: {
        eq: userId
      },
      ...filter
    },
    ...rest
  };
  return await API.graphql(
    graphqlOperation(queries.listPreferences, params)
  );
}

export const updatePreference = async (input) => {
  return await API.graphql(
    graphqlOperation(mutations.updatePreference, {input})
  );
}