import * as Yup from "yup";

import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Auth } from "aws-amplify";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormikTextField from "../../common/components/FormikTextField";
import Header from "../../common/components/header";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import hero3 from '../../assets/images/hero-bg/hero-3.jpg';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const initialValues = {
  email: "",
};

const initialPasswordFormValues = {
  code: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
});

const passwordValidationSchema = Yup.object().shape({
  code: Yup.number().required("Code is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords does not match", function (value) {
      return this.parent.password === value;
    }),
});

const ForgotPasswordContainer = () => {
  const [email, setEmail] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [snackBarDetails, setSnackBarDetails] = useState({
    message: null,
    severity: "error",
    links: [],
  });
  const history = useHistory();

  function renderPasswordForm() {
    return (
      <div >
        <h3 className="font-size-lg line-height-sm font-weight-light d-block px-3 mb-5 text-white-50">
          Enter your code and new password
          to reset your password.
        </h3>
        <Card className="p-5 mx-5 text-center">
          <Formik
            initialValues={initialPasswordFormValues}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              try {
                await Auth.forgotPasswordSubmit(
                  email,
                  values.code,
                  values.password
                );

                setSnackBarDetails({
                  message: "Password reset successfully",
                  severity: "success",
                  links: [],
                });
                history.push("/signIn");
              } catch (err) {
                let message = "Unknown error has occured";
                if (err && err.message) {
                  message = err.message;
                }
                setSnackBarDetails({
                  message,
                  severity: "error",
                  links: [],
                });
              }
              setSubmitting(false);
            }}
            validationSchema={passwordValidationSchema}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => (
              <Form className="SignUp">
                <FormikTextField
                  name="code"
                  label="Code"
                  placeholder="Code"
                  margin="normal"
                  fullWidth
                >
                  <FontAwesomeIcon
                    icon={['far', 'keyboard']}
                    className="font-size-xl bold mr-2"
                  />
                </FormikTextField>

                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Password must be at least 8 characters, and consist of at
                        least one of each of the following:
                      </Typography>
                      <ul>
                        <li>uppercase letter</li>
                        <li>lowercase letter</li>
                        <li>special character</li>
                        <li>number</li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <FormikTextField
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    margin="normal"
                    fullWidth>
                    <InputAdornment position="start">
                      <LockTwoToneIcon />
                    </InputAdornment>
                  </FormikTextField>
                </HtmlTooltip>
                <FormikTextField
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(bool => !bool)}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )

                  }}
                >
                  <InputAdornment position="start">
                    <LockTwoToneIcon />
                  </InputAdornment>
                </FormikTextField>

                <Button
                  type="submit"
                  className="mt-4 mb-2"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Set New Password
                </Button>

                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item>
                    Not a member? <Link to="/signUp">Sign Up</Link>
                  </Grid>
                  <Grid item>
                    Already a member? <Link to="/signIn">Sign In</Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  }


  function renderEmailFormWithTemplate() {
    return (
      <React.Fragment>

        <h3 className="font-size-lg line-height-sm font-weight-light d-block px-3 mb-5 text-white-50">
          Enter your email address and we will
          send you a link to reset your password.
        </h3>
        <Card className="p-5 mx-5 text-center">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              try {
                await Auth.forgotPassword(values.email);
                setEmail(values.email);
                setSnackBarDetails({
                  message:
                    "An email with a code to reset your password has been sent",
                  severity: "success",
                  links: [],
                });
              } catch (err) {
                let message = "Unknown error has occured";
                let links = [];
                if (err && err.message) {
                  message = err.message;
                }
                if (err && err.code === "UserNotFoundException") {
                  message = "User with this email does not exist";
                  links = [{ name: "Sign Up", route: "/signUp" }];
                }
                setSnackBarDetails({ message, severity: "error", links });
              }
              setSubmitting(false);
            }}
            validationSchema={validationSchema}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => (
              <Form className="SignUp">
                <FormikTextField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  margin="normal"
                  fullWidth

                >
                  <InputAdornment position="start">
                    <MailOutlineTwoToneIcon />
                  </InputAdornment>
                </FormikTextField>

                <Button
                  type="submit"
                  className="mt-4 mb-2"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  <span className="btn-wrapper--label">
                    Reset Password
                  </span>
                </Button>

                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item>
                    Not a member? <Link to="/signUp">Sign Up</Link>
                  </Grid>
                  <Grid item>
                    Already a member? <Link to="/signIn">Sign In</Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>

      </React.Fragment>

    );
  }

  return (
    <div>
      <Header></Header>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper bg-arielle-smile min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image"
                        style={{ backgroundImage: 'url(' + hero3 + ')' }}
                      />
                      <div className="bg-composed-wrapper--bg bg-night-sky opacity-5" />
                      <div className="bg-composed-wrapper--content text-center py-5">
                        <Grid
                          item
                          xl={5}
                          lg={6}
                          md={10}
                          sm={12}
                          className="mx-auto text-center text-white">
                          <h1 className="display-2 mb-3 px-4 mt-4 font-weight-bold">
                            Recover Password
                          </h1>
                          {!email && renderEmailFormWithTemplate()}

                          {email && renderPasswordForm()}


                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DoxSnackBar snackBarDetails={snackBarDetails}></DoxSnackBar>
    </div>

  );
};

export default ForgotPasswordContainer;
