import * as Yup from "yup";
import * as mutations from '../../graphql/mutations';

import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import { Form, Formik } from "formik";
/* eslint-disable no-console */
import React, { Component } from "react";

import { Button } from "@material-ui/core";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import FormikTextField from "../../common/components/FormikTextField";
import awsconfig from "../../aws-exports";
import { css } from "@emotion/react";
import { UserContext } from '../../providers/UserProvider';

// API.configure(awsconfig);
Amplify.configure(awsconfig);
Auth.configure(awsconfig);



class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      firstName: this.props.firstName ? this.props.firstName : "",
      lastName: this.props.lastName ? this.props.lastName : "",
      email: this.props.email ? this.props.email : "",
      mobile: this.props.mobile ? this.props.mobile : "",
      companyName: this.props.companyName ? this.props.companyName : "",
      snackBarDetails: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  static contextType = UserContext;

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async function (values) {
    try {
      const { firstName, lastName, mobile } = values;
      let id = this.props?.sub;
      if(!id) {
        id = this.props.id;
      }

      if (firstName) {
        let data = await API.graphql(graphqlOperation(mutations.updateUsers, { input: { id, firstName, lastName, mobile } }));
        this.context.updateUser(data?.data?.updateUsers);
        this.setState({
          firstName,
          lastName,
          mobile,
          snackBarDetails: {
            message: "Profile Updated",
            severity: "success",
            links: []
          }
        })
      }
    } catch (error) {
      this.setState({
        "snackBarDetails": {
          message: error.message,
          severity: "error",
          links: []
        }
      })
    }
  };
  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .max(50, 'less than or equal 50 character'),
    lastName: Yup.string()
      .max(50, 'less than or equal to 50 character'),
    mobile: Yup.string()
      .required("Mobile number is required").matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Mobile number is not valid"
      ).min(7, "Mobile number is not valid")
      .max(12, "Mobile number is not valid"),
  });

  // componentWillUnmount = () => {};

  render() {
    const { firstName, lastName } = this.state;
    return (
      <>
        <Formik
          initialValues={this.state}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validationSchema={this.validationSchema}
          validateOnMount
        >
          {({ isValid }) => (
            <Form className="SignIn w-100">

              <div className="mb-4 mt-3">
                <FormikTextField
                  className="w-100"
                  variant="outlined"
                  label="Email"
                  name="email"
                  placeholder="Email"
                  disabled
                />

              </div>
              <div className="mb-4">
                <FormikTextField
                  className="w-100"
                  variant="outlined"
                  type="text"
                  label="First name"
                  name="firstName"
                  onChange={this.handleChange}
                  placeholder="First Name"

                />

              </div>
              <div className="mb-3">
                {/* <label id="last_name_label" htmlFor="last_name" css={css``} className ="mr-3">
           
            <FontAwesomeIcon
            icon={['far', 'keyboard']}
            className="font-size-xl bold mr-2"
            position ="start"
          />
          </label> */}
                <FormikTextField
                  className="w-100"
                  variant="outlined"
                  type="text"
                  label="Last name"
                  name="lastName"
                  onChange={this.handleChange}
                  placeholder="Last Name"
                />

              </div>
              <div className="mb-3">
                <FormikTextField
                  className="w-100"
                  variant="outlined"
                  type="text"
                  label="Mobile"
                  name="mobile"
                  value={this.state.mobile}
                  onChange={this.handleChange}
                  placeholder="Mobile"
                />
              </div>
              {(this?.props?.accountType !== 'User' && this.props?.accountType !=='SuperAdmin' && this.props?.accountType !=='SuperAdminUser') &&
                <>
                <div className="mb-3">
                  <FormikTextField
                    disabled={true}
                    className="w-100"
                    variant="outlined"
                    type="text"
                    label="Company Name"
                    name="companyName"
                    value={this.state.companyName}
                    onChange={this.handleChange}
                    placeholder="Mobile"
                  />
                </div>
                </>
              }
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!isValid}
                >
                  Update</Button>
              </div>

            </Form>
          )}
        </Formik>
        <DoxSnackBar snackBarDetails={this.state.snackBarDetails}></DoxSnackBar>
      </>
    );
  }
}

export default UserProfile;
