const axios = require("axios");
const bitlyToken = process.env.REACT_APP_BITLY_API_TOKEN;
const bitlyUrl = process.env.REACT_APP_BITLY_API_URL;
const smsUrl = process.env.REACT_APP_SMS_API_URL;
console.log("~ smsUrl", smsUrl)
import { getCoupon } from "../user/containers/curd/coupon";
import { createCouponScanned, getCouponScanned } from "../user/containers/curd/couponScanned";
import { createNotificationsLog, searchNotificationByLink } from "../user/containers/curd/notifications";

export const getBitlyLink = async (long_url) => {
    try {
      let link = "";
      if (long_url) {
        const config = {
          headers: {
            Authorization: `Bearer ${bitlyToken}`,
            "Content-Type": "application/json"
          }
        };
        const body = { long_url };
        const response = await axios.post(bitlyUrl, body, config);
        link = response.data.link;
        return link;
      }
    } catch (error) {
        console.log("error", error)
        return '';
    }
};

export const sendSMSTwilio = async (to, message) => {
  try {
    if (to && message) {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const body = { to, message };
      const response = await axios.post(smsUrl, body, config);
      console.log('~ response', response);
      return response;
    }
    return true
  } catch (error) {
      console.log("error", error)
      return true;
  }
};


export const getAllRewards = async(rewards, user) => {
  await Promise.all(
    rewards.map(async (item) => {
      let couponData = await getCoupon(item);
      if (couponData && user) {
        let userId = user?.id || null;
        let existing = await getCouponScanned(userId, item);
        if (existing?.length === 0) {
          let input = {
            couponID: item,
            userID: userId,
            couponCreatedBy: couponData.createdBy,
          }
          let { data } = await createCouponScanned(input);
        }
      }

    })

  )
}


export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const sendNotification = async(userId, message, type, link) => {
  try {

    let data= {
        user: userId,
        message: message,
        type: type,
        link: link,
        expired: false,
        isRead: false,
        isResponded: false,
    }
    const getSentNotification = await searchNotificationByLink(userId, link)
    if(getSentNotification.length < 1){
      await createNotificationsLog(data);
    }
      
  } catch (error) {
    console.log('NotificationError', error.message)      
  }
}