import React from 'react';
import { sortable } from 'react-sortable';
import styles from './SortableItem.module.css';
import CloseIcon from '@material-ui/icons/Close';
function Item({children, index, onRemove, ...rest}) {

  const handleRemove = (e) => {
    
    onRemove(index)
  }
  
  return (
    <li className={styles.sortableItem} {...rest}>
        {children}
        <CloseIcon
          className={styles.removeIcon}
          onClick={handleRemove}
        />
    </li>
  )
}
const SortableItem = sortable(Item);
export default SortableItem;
