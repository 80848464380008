import { Field } from "formik";
import React from "react";
import { TextField } from "formik-material-ui";

const FormikTextField = ({ children, render, ...props }) => {
  return (
    <Field {...props} component={TextField} variant="outlined">
      {children}
    </Field>
  );
};

export default FormikTextField;
