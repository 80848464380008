import React, { useState } from "react";
import DoxSnackBar from "../../common/components/DoxSnackBar";
import clsx from "clsx";
import SetPasswordComponent from "../components/set-password";
import SetPasswordHeader from "../../layouts/SetPasswordHeader";


const SetPassword = () => {
    const [snackBarDetails, setSnackBarDetails] = useState({
        message: null,
        severity: "error",
        links: []
    });

    return (
        <div className={clsx("app-wrapper vh-100")}>
            <SetPasswordHeader isCollapsedLayout={true}></SetPasswordHeader>
            <div className="app-main flex-column ">
                <SetPasswordComponent setSnackBarDetails={setSnackBarDetails} title="Set Password to your Account" containerSpacing={5} />
            </div>

            <DoxSnackBar snackBarDetails={snackBarDetails}></DoxSnackBar>
        </div>
    );
};

export default SetPassword;
