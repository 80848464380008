import React from "react";
import { TableHead, TableRow, TableCell, TableSortLabel, Toolbar, Typography, Tooltip, IconButton, CircularProgress, Button } from "@material-ui/core";
import { Add, Edit, Delete } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
const EnhancedTableHead = (props) => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, user, hideCheckbox } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" style={{ display: hideCheckbox || user?.accountType === 'User' || user?.isDeleted ? 'none' : '' }}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell?.width}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;


export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const useStylesDenseTable = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

export const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, handleDelete, setOpen, handleEdit, loadingDelete, title, user,hideAdd,hideEdit, hideDelete, filter, assign } = props;
    
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}

            {numSelected > 0 && (<>
                <Tooltip title="Delete" style={{ display: hideDelete || user.accountType === 'User' || user.accountType === 'SuperAdminUser' || user.accountType === 'VendorAdminUser' || user.isDeleted ? 'none' : '' }}>
                    {
                        loadingDelete ? <IconButton area-label="loading">
                            <CircularProgress size="1.5rem" />
                        </IconButton>
                            : <IconButton aria-label="delete" onClick={handleDelete}>
                                <Delete style={{ color: 'red' }} />
                            </IconButton>
                    }
                </Tooltip>
                {!hideEdit && numSelected == 1 && <Tooltip title="Edit" style={{ display: (user.accountType === 'User' || user.accountType === 'SuperAdminUser' || user.accountType === 'VendorAdminUser' || user.isDeleted) ? 'none' : '' }}>
                    <>
                    <IconButton aria-label="edit" onClick={handleEdit}>
                        <Edit style={{ color: 'blue' }} />
                    </IconButton>
                    
                    </>
                </Tooltip>}
                {numSelected == 1 && (
                    <>
                        {assign && (
                            <>
                            {assign}
                            </>
                        )}
                    </>
                )}
            </>)}
            <Tooltip title="Add Staff" style={{ display: hideAdd || user.accountType === 'User' || user.accountType === 'SuperAdminUser' || user.accountType === 'VendorAdminUser' || user.isDeleted ? 'none' : '' }} >
                <Button color="primary" variant="contained" onClick={() => setOpen(true)}><Add /> Add</Button>
            </Tooltip>
            {filter && (
                <>
                {filter}
                </>
            )}
        </Toolbar>
    );
};

